.general-settings-page {
  background-color: var(--white);
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
}

.settings-layout {
  display: grid;
  padding: 1.6rem;
  grid-template-columns: 1fr;
  grid-column-gap: 1.6rem;
}

@media (min-width: 786px) {
  .settings-layout {
    padding: 3.2rem 6.4rem;
    grid-template-columns: 1fr 2fr;
  }
}

.settings-title {
  font-family: var(--brandType);
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
  color: var(--mono-5);
  margin: 0 0 0.8rem 0;
}

.settings-description {
  display: none;
}

@media (min-width: 786px) {
  .settings-description {
    display: block;
    grid-column: 1/2;
    font-family: var(--brandType);
    font-size: var(--fs-s);
    line-height: var(--lh-4);
    color: var(--mono-4);
  }
}

.general-settings-page > .tow-column-layout {
  margin-bottom: 4.8rem;
}

.publisher-assets-container .loader {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--mono-2);
  width: 400px;
  min-height: 100px;
  margin: 0.8rem 0;
}

.publisher-assets-container .error-message {
  color: var(--danger);
  margin-top: 0.4rem;
  min-height: 1.6rem;
  font-size: var(--fs-s);
}

.publisher-assets-container {
  margin-top: 2.8rem;
}

.upload-button .error-message {
  display: none;
}

:global .settings-progress-main-area {
  margin: auto;
  padding: 0px;
  height: calc(100vh - var(--header-height) - 6.4rem);
  display: flex;
  justify-content: center;
}

.settings-page-container {
  transition: transform 0.5s;
}
