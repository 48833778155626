@value viewports: "../../../../../viewports.css";
@value desktop from viewports;

.webhook-logs-table-container {
  background-color: var(--white);
  padding: 0.8rem;
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
}

.webhook-logs-table {
  overflow: scroll;
}

.webhook-logs-table :global .-header {
  background-color: var(--mono-2);
  font-weight: var(--fw-bold);
  font-size: var(--fs-s);
  height: 4rem;
  padding: 0 1.6rem;
  display: flex;
  align-items: center;
  min-width: 78rem !important;
}

.webhook-logs-table :global .rt-tbody .rt-tr,
.webhook-logs-table :global .rt-thead .rt-tr {
  display: grid;
  grid-template-columns: 8.4rem 6rem 16.8rem minmax(16.8rem, 1fr) 10.8rem 9.6rem;
  grid-column-gap: 1.6rem;
}

.webhook-logs-table :global .rt-td:nth-child(1),
.webhook-logs-table :global .rt-td:nth-child(2) {
  justify-content: center;
}

.webhook-logs-table :global .rt-tbody .rt-tr {
  font-size: var(--fs-s);
  line-height: var(--lh-2);
  align-items: center;
  min-height: 4.8rem;
  border-bottom: 1px solid var(--mono-2);
  padding: 0.8rem 1.6rem;
}

.webhook-logs-table :global .rt-tbody .rt-tr .rt-td {
  display: flex;
  width: unset !important;
}

[dir="ltr"] .webhook-logs-table :global .rt-thead .rt-tr {
  text-align: left;
}

[dir="rtl"] .webhook-logs-table :global .rt-thead .rt-tr {
  text-align: right;
}

.webhook-logs-table :global .rt-thead .rt-tr:last-child {
  flex-grow: 1;
}

.webhook-logs-table :global .rt-tr-group:hover {
  background-color: var(--mono-2);
}

.webhook-logs-table :global .rt-noData {
  text-align: center;
  margin-top: 2.4rem;
  font-family: var(--brandType);
  font-size: var(--fs-m);
  color: var(--mono-4);
}

.webhooks-logs-pagination {
  justify-content: flex-end;
  margin-bottom: 1.2rem;
}

.log-status.is-success {
  color: var(--success);
}

.log-status {
  font-weight: var(--fw-semi-bold);
  color: var(--danger);
}

.log-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  overflow-wrap: anywhere;
}

[dir="ltr"] button.logs-go-back {
  padding-left: 0;
}

[dir="rtl"] button.logs-go-back {
  padding-right: 0;
}

@media (min-width: desktop) {
  .webhook-logs-table-container {
    padding: 3.2rem;
  }

  .webhook-logs-table {
    overflow: unset;
  }

  .webhook-logs-table :global .rt-thead,
  .webhook-logs-table :global .rt-tbody {
    min-width: unset !important;
  }

  .webhook-logs-table :global .rt-tbody .rt-tr,
  .webhook-logs-table :global .rt-thead .rt-tr {
    grid-template-columns: 8.4rem 6rem 16.8rem minmax(0, 1fr) 10.8rem 9.6rem;
  }
}
