.users-table-container {
  background-color: var(--white);
  padding: 3.2rem;
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
}
.users-table-roles {
  margin-right: 0.8rem;
}
.users-table-roles-separator {
  margin-right: 0.4rem;
}
.users-table-roles-separator:last-child {
  display: none;
}
.users-table-avatar-container {
  width: 3.2rem;
  height: 3.2rem;
  overflow: hidden;
  border-radius: 50%;
  background: url("~assets/icons/default-avatar.svg") no-repeat center center;
  background-color: var(--brand-3);
  background-size: cover;
}

:global .users-table {
  position: relative;
}
:global .users-table .rt-tbody .rt-tr .rt-td:nth-child(4) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: var(--lh-5);
}

:global .users-table .rt-table {
  margin-top: 4rem;
}

:global .users-table .rt-tbody {
  cursor: pointer;
}

:global .users-table .-header {
  background-color: var(--mono-2);
  font-weight: var(--fw-bold);
  font-size: var(--fs-s);
  height: 4rem;
  display: flex;
  align-items: center;
  padding: 0 1.6rem;
}

:global .users-table .rt-tbody .rt-tr {
  font-size: var(--fs-s);
  padding: 1.2rem 1.6rem;
  display: flex;
  align-items: center;
  min-height: 4.8rem;
  border-bottom: 1px solid var(--mono-2);
}

[dir="ltr"] :global .users-table .rt-thead .rt-tr {
  text-align: left;
  display: flex;
}

[dir="rtl"] :global .users-table .rt-thead .rt-tr {
  text-align: right;
  display: flex;
}

:global .users-table .rt-thead .rt-tr:last-child {
  flex-grow: 1;
}

.users-table-pagination-top {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
}

.users-edit-action {
  cursor: pointer;
  text-align: right;
  color: var(--brand-3);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}

[dir="ltr"] .users-edit-action {
  margin-right: 3.2rem;
}

[dir="rtl"] .users-edit-action {
  margin-left: 3.2rem;
}

:global .users-table .rt-tr-group:hover {
  background-color: var(--mono-2);
}

:global .users-table .rt-tr-group:hover .users-edit-action {
  opacity: 1;
  visibility: visible;
}

.users-table-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  pointer-events: none;
}

:global .users-spinner {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 20%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(-52%);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

:global .user-add-buttons-button {
  margin-left: 0.8rem;
}

[dir="ltr"] .users-searchbar {
  flex-grow: 1;
  margin-right: 1rem;
}

[dir="rtl"] .users-searchbar {
  flex-grow: 1;
  margin-left: 1rem;
}
