/* stylelint-disable */

/*!
 * This file is part of PhotoEditorSDK.
 *
 * Copyright (C) 2016-2019 img.ly GmbH <contact@img.ly>
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, without
 * modification, are permitted provided that the following license agreement
 * is approved and a legal/financial contract was signed by the user.
 * The license agreement can be found under following link:
 *
 * https://www.photoeditorsdk.com/LICENSE.txt
 */
.pesdk-desktop-editor .pesdk-Modal-1jkivgb {
  transform: rotate3d(1, 1, 0, -15deg) translateX(-50%) translateY(-50%);
  -webkit-transform: rotate3d(1, 1, 0, -15deg) translateX(-50%) translateY(-50%);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-Modal-1jkivgb,
.pesdk-desktop-editor .pesdk-Modal-1jkivgb--visible-7bce9v {
  transition: all 0.3s;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 280px;
  background: rgba(42, 42, 46, 0.95);
  z-index: 9;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.5);
}
.pesdk-desktop-editor .pesdk-Modal-1jkivgb--visible-7bce9v {
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-Modal-1jkivgb--large-1mlpyuc {
  transform: rotate3d(1, 1, 0, -15deg) translateX(-50%) translateY(-50%);
  -webkit-transform: rotate3d(1, 1, 0, -15deg) translateX(-50%) translateY(-50%);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-Modal-1jkivgb--large-1mlpyuc,
.pesdk-desktop-editor .pesdk-Modal-1jkivgb--large-1mlpyuc--visible-7bce9v {
  transition: all 0.3s;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 376px;
  background: #2a2a2e;
  z-index: 9;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;
}
.pesdk-desktop-editor .pesdk-Modal-1jkivgb--large-1mlpyuc--visible-7bce9v {
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-Top-1udylfs,
.pesdk-desktop-editor .pesdk-Top-1udylfs--error-120drhm {
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 0 20px 0 0;
}
.pesdk-desktop-editor .pesdk-Top-1udylfs--error-120drhm {
  border-bottom: 2px solid #c23a3a;
}
.pesdk-desktop-editor .pesdk-Top-1udylfs--error-120drhm--warning-120drhm,
.pesdk-desktop-editor .pesdk-Top-1udylfs--warning-120drhm {
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 0 20px 0 0;
  border-bottom: 2px solid #c18226;
}
.pesdk-desktop-editor .pesdk-Top-1udylfs--error-120drhm--loading-120drhm,
.pesdk-desktop-editor .pesdk-Top-1udylfs--loading-120drhm {
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 0 20px 0 0;
  border-bottom: 2px solid #2d7bff;
}
.pesdk-desktop-editor .pesdk-Top-1udylfs--error-120drhm--loading-120drhm--warning-120drhm,
.pesdk-desktop-editor .pesdk-Top-1udylfs--loading-120drhm--warning-120drhm {
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 0 20px 0 0;
  border-bottom: 2px solid #c18226;
}
.pesdk-desktop-editor .pesdk-IconContainer-z6arf2 {
  display: table-cell;
  width: 100px;
  text-align: center;
  vertical-align: middle;
}
.pesdk-desktop-editor .pesdk-TextContainer-245la5 {
  display: table-cell;
  text-align: left;
  padding: 45px 0;
}
.pesdk-desktop-editor .pesdk-Buttons-1943606 {
  display: table;
  table-layout: fixed;
  height: 48px;
  width: 100%;
}
.pesdk-desktop-editor .pesdk-Button-1kylmay {
  display: table-cell;
  vertical-align: middle;
  text-transform: uppercase;
  letter-spacing: 0.84;
  text-align: center;
  font-family: sourcesansprosemibold;
  color: #fff;
  cursor: pointer;
}
.pesdk-desktop-editor .pesdk-Button-1kylmay:hover {
  background: #2a2a2e;
}
.pesdk-desktop-editor .pesdk-Icon-jsue2c {
  width: 56px;
  height: 56px;
}
.pesdk-desktop-editor .pesdk-Title-1fx19is {
  font-family: sourcesansprosemibold;
  color: #888;
  text-transform: uppercase;
  letter-spacing: 1.2;
  font-size: 13px;
}
.pesdk-desktop-editor .pesdk-Text-1id6172 {
  font-family: firasansregular;
  color: #888;
  font-size: 13px;
  text-overflow: ellipsis;
  overflow-x: auto;
}
.pesdk-desktop-editor .pesdk-BlockingOverlay-mv80g2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.pesdk-desktop-editor .pesdk-BlockingOverlay-mv80g2--visible-2jlos {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
}
.pesdk-desktop-editor .pesdk-ProgressBar-1j1uq6u {
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  background: #2675f8;
  border: none;
}
.pesdk-desktop-editor .pesdk-ButtonBar-dh53lm {
  background-color: #262629;
  width: 100%;
  height: 40px;
  padding: 0 12px;
  margin-top: 18px;
}
.pesdk-desktop-editor .pesdk-InputContainer-1uahxoo {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
}
.pesdk-desktop-editor .pesdk-Image-1pb8m8c {
  width: 18px;
  height: 18px;
  margin-top: 11px;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-Image-1pb8m8c:hover {
  opacity: 1;
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-Image-1pb8m8c--right-1l7kz3k {
  width: 18px;
  height: 18px;
  margin-top: 11px;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.3s ease-out;
  float: right;
}
.pesdk-desktop-editor .pesdk-Image-1pb8m8c--right-1l7kz3k:hover {
  opacity: 1;
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-Title-1lyqewl {
  font-family: firasansregular;
  color: #888;
  letter-spacing: 1.2;
  font-size: 13px;
  margin-bottom: 14px;
}
.pesdk-desktop-editor .pesdk-TextArea-93mi9g {
  width: 100%;
  height: 80px;
  background: transparent;
  border: 0;
  border-bottom: 1px solid grey;
  resize: none;
  outline: none;
  font-family: firasansregular;
  color: #fff;
  font-size: 14px;
  opacity: 0.75;
}
.pesdk-desktop-editor .pesdk-LetterCount-19kk1u4 {
  display: block;
  text-align: right;
  font-family: firasansregular;
  color: #fff;
  font-size: 13px;
  line-height: 12px;
  margin-top: 14px;
  opacity: 0.45;
}
.pesdk-desktop-editor .pesdk-Container-11xw2tg {
  padding-top: 16px;
}
.pesdk-desktop-editor .pesdk-Overlay-ovs9m3 {
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-Overlay-ovs9m3,
.pesdk-desktop-editor .pesdk-Overlay-ovs9m3--visible-zgbg08 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(35, 35, 38, 0.7);
  z-index: 9;
  display: none;
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-Overlay-ovs9m3--visible-zgbg08 {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-Overlay-ovs9m3--rendered-2jlos {
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-Overlay-ovs9m3--rendered-2jlos,
.pesdk-desktop-editor .pesdk-Overlay-ovs9m3--rendered-2jlos--visible-zgbg08 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(35, 35, 38, 0.7);
  z-index: 9;
  display: block;
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-Overlay-ovs9m3--rendered-2jlos--visible-zgbg08 {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-Container-8gacw5 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-BaseItem-175e5wy {
  height: 48px;
  width: 100%;
  cursor: pointer;
}
.pesdk-desktop-editor .pesdk-BaseItem-175e5wy__NavigationItem-djzsp {
  height: 48px;
  width: 100%;
  cursor: pointer;
  z-index: 3;
  position: relative;
  color: #000;
}
.pesdk-desktop-editor .pesdk-BaseItem-175e5wy__NavigationItem-djzsp:hover > img:first-child {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-BaseItem-175e5wy__NavigationItem-djzsp:hover > div > div {
  margin-left: 8px;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-BaseItem-175e5wy__NavigationItem-djzsp--selected-b4k2au {
  height: 48px;
  width: 100%;
  cursor: pointer;
  z-index: 3;
  position: relative;
  color: #fff;
}
.pesdk-desktop-editor .pesdk-BaseItem-175e5wy__NavigationItem-djzsp--selected-b4k2au:hover > img:first-child {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-BaseItem-175e5wy__NavigationItem-djzsp--selected-b4k2au:hover > div > div {
  margin-left: 8px;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-BaseItem-175e5wy__NavigationItem-djzsp--disabled-kcb0uq {
  height: 48px;
  width: 100%;
  cursor: pointer;
  z-index: 3;
  position: relative;
  color: #000;
  opacity: 0.5;
}
.pesdk-desktop-editor .pesdk-BaseItem-175e5wy__NavigationItem-djzsp--disabled-kcb0uq:hover > img:first-child {
  opacity: 0.5;
}
.pesdk-desktop-editor .pesdk-BaseItem-175e5wy__NavigationItem-djzsp--disabled-kcb0uq:hover > div > div {
  margin-left: 8px;
  opacity: 0.5;
}
.pesdk-desktop-editor .pesdk-BaseItem-175e5wy__NavigationItem-djzsp--disabled-kcb0uq--selected-b4k2au {
  height: 48px;
  width: 100%;
  cursor: pointer;
  z-index: 3;
  position: relative;
  color: #fff;
  opacity: 0.5;
}
.pesdk-desktop-editor
  .pesdk-BaseItem-175e5wy__NavigationItem-djzsp--disabled-kcb0uq--selected-b4k2au:hover
  > img:first-child {
  opacity: 0.5;
}
.pesdk-desktop-editor
  .pesdk-BaseItem-175e5wy__NavigationItem-djzsp--disabled-kcb0uq--selected-b4k2au:hover
  > div
  > div {
  margin-left: 8px;
  opacity: 0.5;
}
.pesdk-desktop-editor .pesdk-BaseItem-175e5wy__SeparatorItem-w5rgvu {
  height: 24px;
  width: 100%;
  cursor: pointer;
}
.pesdk-desktop-editor .pesdk-Icon-1ew58rj,
.pesdk-desktop-editor .pesdk-Icon-1ew58rj__InactiveIcon-vd96gg {
  transition: opacity 0.3s ease-out;
  width: 48px;
  height: 48px;
  margin-left: 4px;
}
.pesdk-desktop-editor .pesdk-Icon-1ew58rj__InactiveIcon-vd96gg {
  opacity: 0.6;
}
.pesdk-desktop-editor .pesdk-Icon-1ew58rj__InactiveIcon-vd96gg:hover {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-Icon-1ew58rj__InactiveIcon-vd96gg--selected-1cfz9kz {
  transition: opacity 0.3s ease-out;
  width: 48px;
  height: 48px;
  margin-left: 4px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-Icon-1ew58rj__InactiveIcon-vd96gg--selected-1cfz9kz:hover {
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-Icon-1ew58rj__ActiveIcon-8p00lh {
  transition: opacity 0.3s ease-out;
  width: 48px;
  height: 48px;
  margin-left: 4px;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-Icon-1ew58rj__ActiveIcon-8p00lh--selected-zgbg08 {
  transition: opacity 0.3s ease-out;
  width: 48px;
  height: 48px;
  margin-left: 4px;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-TitleContainer-124navh {
  opacity: 0;
  left: 35px;
  visibility: hidden;
}
.pesdk-desktop-editor .pesdk-TitleContainer-124navh,
.pesdk-desktop-editor .pesdk-TitleContainer-124navh--withTitle-1j7o6on {
  pointer-events: none;
  transition: opacity 0.25s ease-in-out, left 0.25s ease-in-out, visibility 0.25s ease-in-out;
  position: absolute;
  right: -230px;
  bottom: 0;
  top: 0;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-TitleContainer-124navh--withTitle-1j7o6on {
  opacity: 1;
  left: 55px;
  visibility: visible;
}
.pesdk-desktop-editor .pesdk-Title-pw4had {
  transition: margin-left 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0.5;
  color: #fff;
  line-height: 48px;
  text-align: left;
  font-size: 13px;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  font-family: firasansregular;
}
.pesdk-desktop-editor .pesdk-NavigationContainer-61cdxs {
  z-index: 10;
  position: relative;
  display: inline-block;
  width: 55px;
  height: 100%;
  background: #202022;
}
.pesdk-desktop-editor .pesdk-Navigation-9phbku {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 5px 0;
  list-style-type: none;
  z-index: 4;
}
.pesdk-desktop-editor .pesdk-NavigationOverlay-5jr86g {
  left: -285px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-NavigationOverlay-5jr86g,
.pesdk-desktop-editor .pesdk-NavigationOverlay-5jr86g--visible-1aw31yo {
  transition: opacity 0.4s, left 0.4s;
  position: absolute;
  top: 0;
  margin: 0;
  width: 285px;
  height: 100vh;
  padding: 17px 0;
  list-style: none;
  background-repeat: repeat-y;
  background-position: 0;
  background-size: 100%;
}
.pesdk-desktop-editor .pesdk-NavigationOverlay-5jr86g--visible-1aw31yo {
  left: 0;
  opacity: 1;
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
}
.pesdk-desktop-editor .pesdk-Canvas-8gacw5 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-ControlsComponentRoot-ykggfd {
  position: relative;
  display: inline-block;
  height: 100%;
  max-height: 100%;
  width: 230px;
  z-index: 3;
  overflow: hidden;
}
.pesdk-desktop-editor .pesdk-ControlsComponentTable-rfecls {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-ControlsComponentHeader-1tkmkv6 {
  display: table-row;
  height: 48px;
  background: #2a2a2e;
}
.pesdk-desktop-editor .pesdk-RootElementContainer-vkb8c9 {
  display: table-row;
  position: relative;
  width: 100%;
  background: #232326;
}
.pesdk-desktop-editor .pesdk-ControlsComponent-rfecls {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-ControlsComponent-rfecls--withBorderTop-120drhm {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  border-top: 1px solid #1b1b1d;
}
.pesdk-desktop-editor .pesdk-ControlsComponentTitle-1ru8sfa {
  width: 100%;
  height: 100%;
  line-height: 48px;
  color: #888;
  text-transform: uppercase;
  font-size: 13px;
  font-family: sourcesansprosemibold;
  letter-spacing: 1.4px;
  text-align: center;
}
.pesdk-desktop-editor .pesdk-BackButton-gyx1pn {
  height: 14px;
  width: 14px;
  position: absolute;
  left: 16px;
  top: 16px;
  opacity: 0.7;
  border: none;
  background: no-repeat 50%;
  background-size: contain;
  cursor: pointer;
}
.pesdk-desktop-editor .pesdk-BackButton-gyx1pn:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-CanvasHeaderButtonComponent-11o197p {
  cursor: pointer;
}
.pesdk-desktop-editor .pesdk-CanvasHeaderButtonComponent-11o197p,
.pesdk-desktop-editor .pesdk-CanvasHeaderButtonComponent-11o197p--disabled-16s6vgr {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  width: 50px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.pesdk-desktop-editor .pesdk-CanvasHeaderButtonComponent-11o197p--disabled-16s6vgr {
  cursor: default;
}
.pesdk-desktop-editor .pesdk-Button-1d3mv64 {
  width: 100%;
  color: #fff;
  text-transform: uppercase;
  font-family: sourcesansprosemibold;
  font-size: 13px;
  padding: 0 17px;
}
.pesdk-desktop-editor .pesdk-Button-1d3mv64:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-Button-1d3mv64--disabled-1wcaxw7 {
  width: 100%;
  color: #fff;
  text-transform: uppercase;
  font-family: sourcesansprosemibold;
  font-size: 13px;
  padding: 0 17px;
  opacity: 0.3;
}
.pesdk-desktop-editor .pesdk-Button-1d3mv64--disabled-1wcaxw7:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-Image-1y2itdu {
  width: 24px;
  height: 24px;
}
.pesdk-desktop-editor .pesdk-Image-1y2itdu--disabled-vxlu5c {
  width: 24px;
  height: 24px;
  opacity: 0.25;
  cursor: default;
}
.pesdk-desktop-editor .pesdk-ZoomControl-12hdgpj {
  position: relative;
  left: -50%;
  height: 100%;
  color: #888;
  font-size: 13px;
  font-family: firasansregular;
  display: table;
  table-layout: fixed;
}
.pesdk-desktop-editor .pesdk-Button-eqhwwg {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  border-style: none;
  background: transparent;
  color: #888;
  font-size: 16px;
  padding: 0 10px;
  cursor: pointer;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-Button-eqhwwg:hover {
  color: #fff;
}
.pesdk-desktop-editor .pesdk-Button-eqhwwg:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-Button-eqhwwg--disabled-10fa721 {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  border-style: none;
  background: transparent;
  color: #888;
  font-size: 16px;
  padding: 0 10px;
  cursor: pointer;
  opacity: 0.3;
}
.pesdk-desktop-editor .pesdk-Button-eqhwwg--disabled-10fa721:hover {
  color: #888;
}
.pesdk-desktop-editor .pesdk-Button-eqhwwg--disabled-10fa721:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-Label-64aub5 {
  display: table-cell;
  vertical-align: middle;
}
.pesdk-desktop-editor .pesdk-CanvasHeaderControls-1vo3slj {
  position: absolute;
  top: 0;
  left: 0;
  height: 48px;
  width: 100%;
  text-align: center;
  background: linear-gradient(180deg, rgba(20, 20, 21, 0.6) 0, rgba(20, 20, 21, 0.17) 63%, rgba(20, 20, 21, 0));
  z-index: 6;
}
.pesdk-desktop-editor .pesdk-CenteredControlGroup-h5xlzy {
  position: absolute;
  left: 50%;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-ControlGroup-17ty59f,
.pesdk-desktop-editor .pesdk-ControlGroup-17ty59f--left-1elyhsq {
  display: table;
  table-layout: fixed;
  height: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.pesdk-desktop-editor .pesdk-ControlGroup-17ty59f--left-1elyhsq {
  float: left;
}
.pesdk-desktop-editor .pesdk-ControlGroup-17ty59f--left-1elyhsq--right-1l7kz3k,
.pesdk-desktop-editor .pesdk-ControlGroup-17ty59f--right-1l7kz3k {
  display: table;
  table-layout: fixed;
  height: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  float: right;
}
.pesdk-desktop-editor .pesdk-HiddenFileInput-19u9mq5 {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 0;
  height: 0;
}
.pesdk-desktop-editor .pesdk-Container-55byom {
  position: absolute;
  overflow: hidden;
  background: red;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-CanvasControls-1vszu45,
.pesdk-desktop-editor .pesdk-CanvasControls-1vszu45--draggable-ovipzq {
  display: inline-block;
  position: relative;
  height: 100%;
  width: calc(100% - 285px);
}
.pesdk-desktop-editor .pesdk-CanvasControls-1vszu45--draggable-ovipzq {
  cursor: move;
}
.pesdk-desktop-editor .pesdk-CanvasControlsContainer-1h6ank1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-Container-5x8wsv {
  width: 100%;
  height: 100%;
  position: absolute;
}
.pesdk-desktop-editor .pesdk-TableContainer-1wu5p5w {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: inline-block;
  z-index: 1;
  overflow: hidden;
}
.pesdk-desktop-editor .pesdk-Container-121fa7f {
  width: 100%;
  height: 100%;
  position: relative;
}
.pesdk-desktop-editor .pesdk-ImageContainer-110xr2i {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ImageContainer-110xr2i,
.pesdk-desktop-editor .pesdk-ImageContainer-110xr2i--hidden-qbdcep {
  transition: opacity 0.3s ease-out;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -52px;
  margin-top: -52px;
  width: 104px;
  height: 104px;
}
.pesdk-desktop-editor .pesdk-ImageContainer-110xr2i--hidden-qbdcep {
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-Image-1gqfg7n {
  position: absolute;
  top: 0;
  left: 0;
  width: 104px;
  height: 104px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-Image-1gqfg7n--visible-zgbg08 {
  position: absolute;
  top: 0;
  left: 0;
  width: 104px;
  height: 104px;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-Image-1gqfg7n__StartImage-120drhm {
  position: absolute;
  top: 0;
  left: 0;
  width: 104px;
  height: 104px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-Image-1gqfg7n--visible-zgbg08__StartImage-120drhm {
  position: absolute;
  top: 0;
  left: 0;
  width: 104px;
  height: 104px;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-Image-1gqfg7n__IdleImage-120drhm {
  position: absolute;
  top: 0;
  left: 0;
  width: 104px;
  height: 104px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-Image-1gqfg7n--visible-zgbg08__IdleImage-120drhm {
  position: absolute;
  top: 0;
  left: 0;
  width: 104px;
  height: 104px;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-AppContainer-1fw137a {
  width: 100%;
  height: 100%;
  background: #141415;
  overflow: hidden;
  position: relative;
  text-align: left;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-smooth: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pesdk-desktop-editor .pesdk-ScrollbarContainer-1ssoezr {
  width: 100%;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-Scrollbar-1s3c4f0,
.pesdk-desktop-editor .pesdk-Scrollbar-1s3c4f0--vertical-1dvqwqc {
  opacity: 0.3;
  position: absolute;
  top: 0;
  padding: 4px;
  cursor: pointer;
  z-index: 5;
}
.pesdk-desktop-editor .pesdk-Scrollbar-1s3c4f0--vertical-1dvqwqc {
  right: 5px;
  width: 12px;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-Scrollbar-1s3c4f0--horizontal-1hu63yp {
  opacity: 0.3;
  position: absolute;
  top: 0;
  padding: 4px;
  cursor: pointer;
  z-index: 5;
  bottom: 5px;
  width: 100%;
  height: 12px;
}
.pesdk-desktop-editor .pesdk-Scrollbar-1s3c4f0--horizontal-1hu63yp--vertical-1dvqwqc {
  opacity: 0.3;
  position: absolute;
  top: 0;
  padding: 4px;
  cursor: pointer;
  z-index: 5;
  bottom: 5px;
  width: 12px;
  height: 100%;
  right: 5px;
}
.pesdk-desktop-editor .pesdk-ScrollbarButton-zxujnb {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 12px;
  padding: 4px;
  transition: height 0.3s ease-in-out;
}
.pesdk-desktop-editor .pesdk-ScrollbarButton-zxujnb:before {
  border-radius: 4px;
  display: block;
  background: #fff;
  content: "";
}
.pesdk-desktop-editor .pesdk-ScrollbarButton-zxujnb--vertical-gma9v3 {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 12px;
  padding: 4px;
  transition: height 0.3s ease-in-out;
}
.pesdk-desktop-editor .pesdk-ScrollbarButton-zxujnb--vertical-gma9v3:before {
  border-radius: 4px;
  display: block;
  background: #fff;
  content: "";
  width: 4px;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-ScrollbarButton-zxujnb--horizontal-1h5ccdj {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 12px;
  padding: 4px;
  transition: height 0.3s ease-in-out;
}
.pesdk-desktop-editor .pesdk-ScrollbarButton-zxujnb--horizontal-1h5ccdj:before {
  border-radius: 4px;
  display: block;
  background: #fff;
  content: "";
  width: 100%;
  height: 4px;
}
.pesdk-desktop-editor .pesdk-ScrollbarButton-zxujnb--horizontal-1h5ccdj--vertical-gma9v3 {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 12px;
  padding: 4px;
  transition: height 0.3s ease-in-out;
}
.pesdk-desktop-editor .pesdk-ScrollbarButton-zxujnb--horizontal-1h5ccdj--vertical-gma9v3:before {
  border-radius: 4px;
  display: block;
  background: #fff;
  content: "";
  width: 4px;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-ScrollbarButton-zxujnb--visible-2jlos {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 12px;
  padding: 4px;
  transition: height 0.3s ease-in-out;
}
.pesdk-desktop-editor .pesdk-ScrollbarButton-zxujnb--visible-2jlos:before {
  border-radius: 4px;
  display: block;
  background: #fff;
  content: "";
}
.pesdk-desktop-editor .pesdk-ScrollbarButton-zxujnb--vertical-gma9v3--visible-2jlos {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 12px;
  padding: 4px;
  transition: height 0.3s ease-in-out;
}
.pesdk-desktop-editor .pesdk-ScrollbarButton-zxujnb--vertical-gma9v3--visible-2jlos:before {
  border-radius: 4px;
  display: block;
  background: #fff;
  content: "";
  width: 4px;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-ScrollbarButton-zxujnb--horizontal-1h5ccdj--visible-2jlos {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 12px;
  padding: 4px;
  transition: height 0.3s ease-in-out;
}
.pesdk-desktop-editor .pesdk-ScrollbarButton-zxujnb--horizontal-1h5ccdj--visible-2jlos:before {
  border-radius: 4px;
  display: block;
  background: #fff;
  content: "";
  width: 100%;
  height: 4px;
}
.pesdk-desktop-editor .pesdk-ScrollbarButton-zxujnb--horizontal-1h5ccdj--vertical-gma9v3--visible-2jlos {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 12px;
  padding: 4px;
  transition: height 0.3s ease-in-out;
}
.pesdk-desktop-editor .pesdk-ScrollbarButton-zxujnb--horizontal-1h5ccdj--vertical-gma9v3--visible-2jlos:before {
  border-radius: 4px;
  display: block;
  background: #fff;
  content: "";
  width: 4px;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-Gradient-pc5361 {
  position: absolute;
  z-index: 5;
  pointer-events: none;
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-Gradient-pc5361--horizontal-felmtl {
  position: absolute;
  z-index: 5;
  pointer-events: none;
  transition: opacity 0.3s ease-out;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 64px;
  background-image: linear-gradient(-90deg, rgba(34, 34, 38, 0), #232326 98%);
}
.pesdk-desktop-editor .pesdk-Gradient-pc5361--horizontal-felmtl--vertical-1enskgl,
.pesdk-desktop-editor .pesdk-Gradient-pc5361--vertical-1enskgl {
  position: absolute;
  z-index: 5;
  pointer-events: none;
  transition: opacity 0.3s ease-out;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 64px;
  background-image: linear-gradient(-180deg, rgba(34, 34, 38, 0), #232326 98%);
}
.pesdk-desktop-editor .pesdk-Slider-jc3w8i {
  z-index: 2;
  height: 12px;
  cursor: pointer;
  position: relative;
}
.pesdk-desktop-editor .pesdk-Slider-jc3w8i--disabled-1voy7lm {
  z-index: 2;
  height: 12px;
  cursor: pointer;
  position: relative;
  opacity: 0.4;
}
.pesdk-desktop-editor .pesdk-Bar-1pvw1zw {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 2px;
  margin-top: -1px;
}
.pesdk-desktop-editor .pesdk-BarSection-8gacw5 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-BarSection-8gacw5__BarBackgroundSection-120drhm {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 100%, 0.24);
}
.pesdk-desktop-editor .pesdk-BarSection-8gacw5__BarBackgroundSection-120drhm--darkBackground-120drhm {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1b1b1e;
}
.pesdk-desktop-editor .pesdk-BarSection-8gacw5__BarForegroundSection-1apine2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2675f8;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.65);
}
.pesdk-desktop-editor .pesdk-Knob-8i3bvo {
  background: #b5b5be;
  border-radius: 6px;
}
.pesdk-desktop-editor .pesdk-Knob-8i3bvo,
.pesdk-desktop-editor .pesdk-Knob-8i3bvo--idle-6eg4rp {
  position: relative;
  width: 12px;
  height: 12px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.45);
  transition: border, background, opacity 0.15s ease-in-out;
}
.pesdk-desktop-editor .pesdk-Knob-8i3bvo--idle-6eg4rp {
  background: #232326;
  border-radius: 6px;
  border: 1.5px solid #888;
  opacity: 0.85;
}
.pesdk-desktop-editor .pesdk-KnobTouchTarget-1x3cm77 {
  padding: 4px;
  cursor: pointer;
  margin-top: -9px;
  margin-left: -9px;
  position: absolute;
  outline: none;
  transition: transform 0.15s ease-in-out;
}
.pesdk-desktop-editor .pesdk-KnobTouchTarget-1x3cm77:active {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
}
.pesdk-desktop-editor .pesdk-BarContainer-1ssoezr {
  width: 100%;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-LoadingIndicatorContainer-eurrvc {
  width: 100%;
  height: 50px;
  text-align: center;
  margin-top: 24px;
}
.pesdk-desktop-editor .pesdk-Ball-vyyzkw {
  animation-name: bouncingLoadingIndicator;
  -webkit-animation-name: bouncingLoadingIndicator;
  animation-duration: 1.2s;
  -webkit-animation-duration: 1.2s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  width: 2px;
  height: 2px;
  background-color: #fff;
  display: inline-block;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
  margin-right: 7px;
}
.pesdk-desktop-editor .pesdk-Ball-vyyzkw:last-child {
  margin-right: 0;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__NumericInputComponent-120drhm {
  transition: opacity 0.2s ease-out;
  font-size: 14px;
  font-family: firasansregular;
  min-width: 45px;
  height: 26px;
  color: #fff;
  padding-bottom: 7px;
  border: none;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  background: transparent;
  opacity: 0.7;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__NumericInputComponent-120drhm:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__NumericInputComponent-120drhm:hover {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__NumericInputComponent-120drhm__AutoSizeNumericInputComponent-n0hw7r {
  transition: opacity 0.2s ease-out;
  font-size: 14px;
  font-family: firasansregular;
  min-width: 45px;
  height: 19px;
  color: #fff;
  padding-bottom: 7px;
  border: none;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  background: transparent;
  opacity: 0.7;
  box-sizing: initial;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__NumericInputComponent-120drhm__AutoSizeNumericInputComponent-n0hw7r:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__NumericInputComponent-120drhm__AutoSizeNumericInputComponent-n0hw7r:hover {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-Container-nfd1oy {
  display: inline-block;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  padding: 5px 15px;
  cursor: pointer;
}
.pesdk-desktop-editor .pesdk-Suffix-1ua5bzq {
  display: inline-block;
  color: #888;
  font-family: firasansregular;
  font-size: 14px;
  margin-left: 5px;
}
.pesdk-desktop-editor
  .pesdk-Input-nsck4j__NumericInputComponent-120drhm__AutoSizeNumericInputComponent-n0hw7r__Input-6ekjfo {
  transition: opacity 0.2s ease-out;
  font-size: 14px;
  font-family: firasansregular;
  min-width: 45px;
  height: 19px;
  color: #fff;
  padding-bottom: 0;
  border: none;
  border-bottom: none;
  background: transparent;
  opacity: 0.7;
  box-sizing: initial;
  background-color: transparent;
}
.pesdk-desktop-editor
  .pesdk-Input-nsck4j__NumericInputComponent-120drhm__AutoSizeNumericInputComponent-n0hw7r__Input-6ekjfo:focus {
  outline: none;
}
.pesdk-desktop-editor
  .pesdk-Input-nsck4j__NumericInputComponent-120drhm__AutoSizeNumericInputComponent-n0hw7r__Input-6ekjfo:hover {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-Checkbox-5nuoh {
  height: 16px;
  width: 16px;
  background-size: contain;
}
.pesdk-desktop-editor .pesdk-ControlGroup-1bxndxk__Section-1pofx54 {
  padding: 20px;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.pesdk-desktop-editor .pesdk-ControlGroupTitle-12fzsea__SectionTitle-1b0fixa {
  margin-bottom: 20px;
  font-size: 13px;
  font-family: sourcesansprosemibold;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  text-align: left;
  color: #888;
  transition: margin-bottom 0.2s 0s;
  padding: 0 5px;
}
.pesdk-desktop-editor .pesdk-SectionHeader-1b4y7iu {
  position: relative;
  cursor: pointer;
}
.pesdk-desktop-editor .pesdk-SectionContent-10cp0qa {
  transition: opacity 0.3s ease-out;
  padding: 5px;
  opacity: 0;
  display: none;
}
.pesdk-desktop-editor .pesdk-SectionContent-10cp0qa--rendered-2jlos {
  transition: opacity 0.3s ease-out;
  padding: 5px;
  opacity: 0;
  display: block;
}
.pesdk-desktop-editor .pesdk-SectionContent-10cp0qa--rendered-2jlos--visible-82svov,
.pesdk-desktop-editor .pesdk-SectionContent-10cp0qa--visible-82svov {
  transition: opacity 0.3s ease-in;
  padding: 5px;
  opacity: 1;
  display: block;
}
.pesdk-desktop-editor .pesdk-ArrowDown-1bagiey {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}
.pesdk-desktop-editor .pesdk-ArrowDown-1bagiey,
.pesdk-desktop-editor .pesdk-ArrowDown-1bagiey--turnUp-gucvcl {
  transition: transform 0.5s;
  float: right;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #888;
}
.pesdk-desktop-editor .pesdk-ArrowDown-1bagiey--turnUp-gucvcl {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}
.pesdk-desktop-editor .pesdk-Checkbox-5nuoh__Checkbox-15tfhjl {
  height: 16px;
  width: 16px;
  background-size: contain;
  float: right;
  text-align: right;
}
.pesdk-desktop-editor .pesdk-ListWrapper-1tmindq {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 3rem);
  height: 100%;
  overflow: auto;
  padding-right: 3rem;
}
.pesdk-desktop-editor .pesdk-ScrollbarContainer-1ssoezr__ScrollbarContainer-quhluw {
  width: 100%;
  height: 100%;
  position: relative;
  display: table-cell;
  overflow: hidden;
}
.pesdk-desktop-editor .pesdk-ValueSelection-12okzzb,
.pesdk-desktop-editor .pesdk-ValueSelection-12okzzb--disabled-1voy7lm {
  display: table;
  width: 100%;
  height: 32px;
  margin-bottom: 16px;
  position: relative;
}
.pesdk-desktop-editor .pesdk-ValueSelection-12okzzb--disabled-1voy7lm {
  opacity: 0.4;
}
.pesdk-desktop-editor .pesdk-Row-15u7gri {
  display: table-row;
}
.pesdk-desktop-editor .pesdk-Column-1hy9z1m {
  display: table-cell;
  width: 100%;
  height: 100%;
  padding-right: 15px;
  text-align: left;
  vertical-align: top;
}
.pesdk-desktop-editor .pesdk-Column-1hy9z1m:last-child {
  padding-right: 0;
}
.pesdk-desktop-editor .pesdk-Column-1hy9z1m--bottomAligned-17xdalt {
  display: table-cell;
  width: 100%;
  height: 100%;
  padding-right: 15px;
  text-align: left;
  vertical-align: bottom;
}
.pesdk-desktop-editor .pesdk-Column-1hy9z1m--bottomAligned-17xdalt:last-child {
  padding-right: 0;
}
.pesdk-desktop-editor .pesdk-Title-ts1901,
.pesdk-desktop-editor .pesdk-Title-ts1901--left-1ff3rj6 {
  font-size: 13px;
  font-family: firasansregular;
  color: #888;
  margin-bottom: 6px;
}
.pesdk-desktop-editor .pesdk-Title-ts1901--left-1ff3rj6 {
  text-align: left;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__NumericInputComponent-120drhm__InputField-myl4ow {
  transition: opacity 0.2s ease-out;
  font-size: 14px;
  font-family: firasansregular;
  min-width: 45px;
  height: 26px;
  color: #fff;
  padding-bottom: 7px;
  border: none;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  background: transparent;
  opacity: 0.7;
  text-align: center;
  width: 32px;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__NumericInputComponent-120drhm__InputField-myl4ow:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__NumericInputComponent-120drhm__InputField-myl4ow:hover {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__NumericInputComponent-120drhm__InputField-myl4ow--disabled-18if3pn {
  transition: opacity 0.2s ease-out;
  font-size: 14px;
  font-family: firasansregular;
  min-width: 45px;
  height: 26px;
  color: #fff;
  padding-bottom: 7px;
  border: none;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  background: transparent;
  opacity: 0.4;
  text-align: center;
  width: 32px;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__NumericInputComponent-120drhm__InputField-myl4ow--disabled-18if3pn:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__NumericInputComponent-120drhm__InputField-myl4ow--disabled-18if3pn:hover {
  opacity: 0.4;
}
.pesdk-desktop-editor .pesdk-CircleSlider-1ht9t4w__HueSlider-120drhm {
  position: relative;
  height: 14px;
  width: 100%;
}
.pesdk-desktop-editor .pesdk-CircleSliderKnob-7g0rty__Knob-120drhm {
  position: absolute;
  left: 0;
  top: 0;
  height: 12px;
  width: 12px;
  margin-left: -6px;
  border-radius: 50%;
  border: 2px solid #c8c8d2;
  cursor: pointer;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 1px 3px 0 rgba(0, 0, 0, 0.46);
}
.pesdk-desktop-editor .pesdk-CircleSliderBackground-1ldv9bx__Background-u98vhm {
  position: absolute;
  top: 3px;
  bottom: 2px;
  left: 0;
  width: 100%;
  border-radius: 1px;
  cursor: pointer;
  margin-top: 1px;
  height: 8px;
}
.pesdk-desktop-editor .pesdk-CircleSlider-1ht9t4w__OpacitySlider-120drhm {
  position: relative;
  height: 14px;
  width: 100%;
}
.pesdk-desktop-editor .pesdk-CircleSliderBackground-1ldv9bx__Background-120drhm {
  position: absolute;
  top: 3px;
  bottom: 2px;
  left: 0;
  width: 100%;
  border-radius: 1px;
  cursor: pointer;
}
.pesdk-desktop-editor .pesdk-CircleSliderBackground-1ldv9bx__Background-120drhm__TiledBackground-m87q12 {
  position: absolute;
  top: 3px;
  bottom: 2px;
  left: 0;
  width: 100%;
  border-radius: 1px;
  cursor: pointer;
  background-repeat: y;
  background-size: contain;
  background-position: 100%;
}
.pesdk-desktop-editor .pesdk-CircleSlider-1ht9t4w__SaturationValueSlider-1jf8fwr {
  position: relative;
  height: 150px;
  width: 100%;
  cursor: pointer;
}
.pesdk-desktop-editor .pesdk-CircleSliderKnob-7g0rty__Knob-icy3f1 {
  position: absolute;
  left: 0;
  top: 0;
  height: 12px;
  width: 12px;
  margin-left: -8px;
  border-radius: 50%;
  border: 2px solid #c8c8d2;
  cursor: pointer;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 1px 3px 0 rgba(0, 0, 0, 0.46);
  margin-top: -6px;
}
.pesdk-desktop-editor .pesdk-CircleSliderBackground-1ldv9bx__Background-15alfoa {
  position: absolute;
  top: 3px;
  bottom: 2px;
  left: 0;
  width: 100%;
  border-radius: 2px;
  cursor: pointer;
  background: #fff;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__NumericInputComponent-120drhm__InputField-12dz026 {
  transition: opacity 0.2s ease-out;
  font-size: 14px;
  font-family: firasansregular;
  min-width: auto;
  height: 26px;
  color: #fff;
  padding-bottom: 7px;
  border: none;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  background: transparent;
  opacity: 0.7;
  text-align: center;
  width: 28px;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__NumericInputComponent-120drhm__InputField-12dz026:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__NumericInputComponent-120drhm__InputField-12dz026:hover {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ValueSelection-12okzzb__ColorComponentInput-9ngfap {
  display: table-cell;
  width: 100%;
  height: 32px;
  margin-bottom: 16px;
  position: relative;
  padding-left: 4px;
}
.pesdk-desktop-editor .pesdk-ValueSelection-12okzzb--disabled-1voy7lm__ColorComponentInput-9ngfap {
  display: table-cell;
  width: 100%;
  height: 32px;
  margin-bottom: 16px;
  position: relative;
  opacity: 0.4;
  padding-left: 4px;
}
.pesdk-desktop-editor .pesdk-Title-szltve {
  font-size: 13px;
  font-family: firasansregular;
  color: #888;
  margin-bottom: 6px;
  text-align: center;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__NumericInputComponent-120drhm__InputField-t0ruqq {
  transition: opacity 0.2s ease-out;
  font-size: 14px;
  font-family: firasansregular;
  min-width: auto;
  height: 26px;
  color: #fff;
  padding-bottom: 7px;
  border: none;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  background: transparent;
  opacity: 0.7;
  text-align: center;
  width: 62px;
  text-transform: uppercase;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__NumericInputComponent-120drhm__InputField-t0ruqq:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__NumericInputComponent-120drhm__InputField-t0ruqq:hover {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ColorPickerContainer-txlwuh {
  position: absolute;
  left: 0;
  z-index: 6;
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-ColorPickerContainer-txlwuh--rendered-2jlos {
  position: absolute;
  left: 0;
  z-index: 6;
  display: block;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-ColorPickerContainer-txlwuh--visible-zgbg08 {
  position: absolute;
  left: 0;
  z-index: 6;
  display: none;
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-ColorPickerContainer-txlwuh--rendered-2jlos--visible-zgbg08 {
  position: absolute;
  left: 0;
  z-index: 6;
  display: block;
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-TopArrow-ts4br3 {
  height: 10px;
  width: 32px;
  position: relative;
}
.pesdk-desktop-editor .pesdk-TopArrow-ts4br3:after {
  position: absolute;
  bottom: 0;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  border-bottom-color: #2a2a2e;
  border-width: 13px;
  margin-left: 12px;
  pointer-events: none;
}
.pesdk-desktop-editor .pesdk-ColorPicker-1gzvppb {
  cursor: auto;
  position: absolute;
  left: 0;
  padding: 25px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 8px 0 rgba(0, 0, 0, 0.3),
    0 16px 16px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  width: 230px;
  z-index: 6;
  overflow-y: auto;
}
.pesdk-desktop-editor .pesdk-CircleSlider-1ht9t4w__SaturationValueSlider-1jf8fwr__SaturationValueSlider-120drhm {
  position: relative;
  height: 150px;
  width: 100%;
  cursor: pointer;
}
.pesdk-desktop-editor .pesdk-CircleSlider-1ht9t4w__HueSlider-120drhm__HueSlider-1cbv91b {
  position: relative;
  height: 14px;
  width: 100%;
  margin-top: 16px;
}
.pesdk-desktop-editor .pesdk-CircleSlider-1ht9t4w__OpacitySlider-120drhm__OpacitySlider-tnkwnw {
  position: relative;
  height: 14px;
  width: 100%;
  margin-top: 10px;
}
.pesdk-desktop-editor .pesdk-ColorValueInputs-142apfo {
  display: table;
  table-layout: fixed;
  margin-top: 30px;
}
.pesdk-desktop-editor .pesdk-ColorSelection-120c4v2 {
  text-align: left;
  width: 100%;
  margin-left: 0;
}
.pesdk-desktop-editor .pesdk-ColorSelectionBubble-1ds328h {
  transition: width 0.1s ease-in-out 0.1s;
  display: inline-block;
  max-width: 18px;
  min-width: 18px;
  height: 18px;
  margin-left: 0;
  margin-right: 2px;
  margin-bottom: -1px;
  border-radius: 2px;
  cursor: pointer;
}
.pesdk-desktop-editor .pesdk-ColorSelectionBubble-1ds328h--selected-16ccmsf {
  transition: width 0.1s ease-in-out 0.1s;
  display: inline-block;
  max-width: 20px;
  min-width: 20px;
  height: 20px;
  margin: -1px 1px -2px -1px;
  border-radius: 2px;
  cursor: pointer;
  border-color: #2675f8;
}
.pesdk-desktop-editor .pesdk-InnerBorder-i2obyt,
.pesdk-desktop-editor .pesdk-InnerBorder-i2obyt--selected-9c3pr8 {
  width: 100%;
  height: 100%;
  padding: 1px;
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  position: absolute;
  border-radius: 2px;
}
.pesdk-desktop-editor .pesdk-InnerBorder-i2obyt--selected-9c3pr8 {
  border-color: #2675f8;
  border-width: 2px;
}
.pesdk-desktop-editor .pesdk-ColorSelectionPreview-h8zan2 {
  position: relative;
  width: 100%;
  height: 100%;
  corner-radius: 2px;
}
.pesdk-desktop-editor .pesdk-ColorSelectionPreviewContent-yk72ip {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-size: 8px;
}
.pesdk-desktop-editor .pesdk-ColorSelectionPreviewContent-yk72ip__ColorSelectionBubbleContent-nahwei {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background-size: 8px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.11), 0 1px 3px 0 rgba(0, 0, 0, 0.46);
}
.pesdk-desktop-editor .pesdk-DotContainer-1lws0px {
  height: 2px;
  width: 12px;
  margin: 0 auto;
  position: absolute;
  top: 9px;
  left: 0;
  bottom: 9px;
  right: 0;
}
.pesdk-desktop-editor .pesdk-Dot-1lamas5 {
  transition: opacity 0.1s ease-in-out;
  opacity: 0;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 2px;
  height: 2px;
  border-radius: 1px;
  margin-right: 3px;
  margin-bottom: 0;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor .pesdk-Dot-1lamas5:last-child {
  margin-right: 0;
}
.pesdk-desktop-editor .pesdk-Dot-1lamas5--selected-xfn5qu {
  transition: opacity 0.1s ease-in-out 0.1s;
  opacity: 1;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 2px;
  height: 2px;
  border-radius: 1px;
  margin-right: 3px;
  margin-bottom: 0;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor .pesdk-Dot-1lamas5--selected-xfn5qu:last-child {
  margin-right: 0;
}
.pesdk-desktop-editor .pesdk-Button-7lpbp3 {
  transition: color 0.2s ease-out;
  width: 100%;
  height: 40px;
  background: #2a2a2e;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  border: 1px solid #303034;
  font-size: 13px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  font-family: sourcesansprosemibold;
  color: #888;
  cursor: pointer;
}
.pesdk-desktop-editor .pesdk-Button-7lpbp3:hover {
  color: #fff;
}
.pesdk-desktop-editor .pesdk-Button-7lpbp3:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-Button-7lpbp3--disabled-1iaw3op {
  transition: color 0.2s ease-out;
  width: 100%;
  height: 40px;
  background: #2a2a2e;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  border: 1px solid #303034;
  font-size: 13px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  font-family: sourcesansprosemibold;
  color: #888;
  cursor: default;
  opacity: 0.3;
}
.pesdk-desktop-editor .pesdk-Button-7lpbp3--disabled-1iaw3op:hover {
  color: #888;
}
.pesdk-desktop-editor .pesdk-Button-7lpbp3--disabled-1iaw3op:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-DropdownContainer-13hv8io {
  position: relative;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__Dropdown-13hv8io {
  transition: opacity 0.2s ease-out;
  font-size: 14px;
  font-family: firasansregular;
  min-width: 45px;
  height: 26px;
  color: #fff;
  padding-bottom: 7px;
  border: none;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  background: transparent;
  opacity: 0.7;
  position: relative;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__Dropdown-13hv8io:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__Dropdown-13hv8io:hover {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-DropdownOverlay-1fqdopp {
  position: absolute;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 8px 0 rgba(0, 0, 0, 0.3),
    0 16px 16px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  width: 100%;
  overflow: hidden;
  z-index: 6;
}
.pesdk-desktop-editor .pesdk-DropdownList-zy0cu9 {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 200px;
}
.pesdk-desktop-editor .pesdk-ArrowDown-7w2heg {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}
.pesdk-desktop-editor .pesdk-ArrowDown-7w2heg,
.pesdk-desktop-editor .pesdk-ArrowDown-7w2heg--turnUp-gucvcl {
  transition: transform 0.5s;
  position: absolute;
  right: 4px;
  bottom: 10px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #888;
}
.pesdk-desktop-editor .pesdk-ArrowDown-7w2heg--turnUp-gucvcl {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}
.pesdk-desktop-editor .pesdk-DropdownItem-cibatp {
  position: relative;
  color: #fff;
  font-size: 14px;
  line-height: 32px;
  padding: 0 16px;
  cursor: default;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pesdk-desktop-editor .pesdk-DropdownItem-cibatp:hover {
  background: hsla(0, 0%, 100%, 0.04);
}
.pesdk-desktop-editor .pesdk-DropdownItem-cibatp--selected-60ro2s {
  position: relative;
  color: #fff;
  font-size: 14px;
  line-height: 32px;
  padding: 0 16px;
  cursor: default;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pesdk-desktop-editor .pesdk-DropdownItem-cibatp--selected-60ro2s:hover {
  background: hsla(0, 0%, 100%, 0.04);
}
.pesdk-desktop-editor .pesdk-DropdownItem-cibatp--selected-60ro2s:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: #2675f8;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.65);
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__DropdownComponent-120drhm {
  transition: opacity 0.2s ease-out;
  font-size: 14px;
  font-family: firasansregular;
  min-width: 45px;
  height: 26px;
  color: #fff;
  padding-bottom: 7px;
  border: none;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  background: transparent;
  opacity: 0.7;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__DropdownComponent-120drhm:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__DropdownComponent-120drhm:hover {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__TextInputComponent-120drhm {
  transition: opacity 0.2s ease-out;
  font-size: 14px;
  font-family: firasansregular;
  min-width: 45px;
  height: 26px;
  color: #fff;
  padding-bottom: 7px;
  border: none;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  background: transparent;
  opacity: 0.7;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__TextInputComponent-120drhm:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__TextInputComponent-120drhm:hover {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-CanvasOverlayControl-1lf1r1r {
  position: absolute;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  display: block;
  background: #2a2a2e;
  padding: 8px;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  white-space: nowrap;
  z-index: 8;
}
.pesdk-desktop-editor .pesdk-ControlItem-1l53y3r {
  display: inline-block;
  height: 24px;
  width: 24px;
  margin-right: 8px;
  cursor: pointer;
  background-size: contain;
}
.pesdk-desktop-editor .pesdk-ControlItem-1l53y3r:last-child {
  margin-right: 0;
}
.pesdk-desktop-editor a,
.pesdk-desktop-editor abbr,
.pesdk-desktop-editor acronym,
.pesdk-desktop-editor address,
.pesdk-desktop-editor applet,
.pesdk-desktop-editor article,
.pesdk-desktop-editor aside,
.pesdk-desktop-editor audio,
.pesdk-desktop-editor b,
.pesdk-desktop-editor big,
.pesdk-desktop-editor blockquote,
.pesdk-desktop-editor body,
.pesdk-desktop-editor canvas,
.pesdk-desktop-editor caption,
.pesdk-desktop-editor center,
.pesdk-desktop-editor cite,
.pesdk-desktop-editor code,
.pesdk-desktop-editor dd,
.pesdk-desktop-editor del,
.pesdk-desktop-editor details,
.pesdk-desktop-editor dfn,
.pesdk-desktop-editor div,
.pesdk-desktop-editor dl,
.pesdk-desktop-editor dt,
.pesdk-desktop-editor em,
.pesdk-desktop-editor embed,
.pesdk-desktop-editor fieldset,
.pesdk-desktop-editor figcaption,
.pesdk-desktop-editor figure,
.pesdk-desktop-editor footer,
.pesdk-desktop-editor form,
.pesdk-desktop-editor h1,
.pesdk-desktop-editor h2,
.pesdk-desktop-editor h3,
.pesdk-desktop-editor h4,
.pesdk-desktop-editor h5,
.pesdk-desktop-editor h6,
.pesdk-desktop-editor header,
.pesdk-desktop-editor hgroup,
.pesdk-desktop-editor html,
.pesdk-desktop-editor i,
.pesdk-desktop-editor iframe,
.pesdk-desktop-editor img,
.pesdk-desktop-editor ins,
.pesdk-desktop-editor kbd,
.pesdk-desktop-editor label,
.pesdk-desktop-editor legend,
.pesdk-desktop-editor li,
.pesdk-desktop-editor mark,
.pesdk-desktop-editor menu,
.pesdk-desktop-editor nav,
.pesdk-desktop-editor object,
.pesdk-desktop-editor ol,
.pesdk-desktop-editor output,
.pesdk-desktop-editor p,
.pesdk-desktop-editor pre,
.pesdk-desktop-editor q,
.pesdk-desktop-editor ruby,
.pesdk-desktop-editor s,
.pesdk-desktop-editor samp,
.pesdk-desktop-editor section,
.pesdk-desktop-editor small,
.pesdk-desktop-editor span,
.pesdk-desktop-editor strike,
.pesdk-desktop-editor strong,
.pesdk-desktop-editor sub,
.pesdk-desktop-editor summary,
.pesdk-desktop-editor sup,
.pesdk-desktop-editor table,
.pesdk-desktop-editor tbody,
.pesdk-desktop-editor td,
.pesdk-desktop-editor tfoot,
.pesdk-desktop-editor th,
.pesdk-desktop-editor thead,
.pesdk-desktop-editor time,
.pesdk-desktop-editor tr,
.pesdk-desktop-editor tt,
.pesdk-desktop-editor u,
.pesdk-desktop-editor ul,
.pesdk-desktop-editor var,
.pesdk-desktop-editor video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
.pesdk-desktop-editor html {
  line-height: 1;
}
.pesdk-desktop-editor ol,
.pesdk-desktop-editor ul {
  list-style: none;
}
.pesdk-desktop-editor table {
  border-collapse: collapse;
  border-spacing: 0;
}
.pesdk-desktop-editor caption,
.pesdk-desktop-editor td,
.pesdk-desktop-editor th {
  text-align: left;
  font-weight: 400;
  vertical-align: middle;
}
.pesdk-desktop-editor blockquote,
.pesdk-desktop-editor q {
  quotes: none;
}
.pesdk-desktop-editor blockquote:after,
.pesdk-desktop-editor blockquote:before,
.pesdk-desktop-editor q:after,
.pesdk-desktop-editor q:before {
  content: "";
  content: none;
}
.pesdk-desktop-editor a img {
  border: none;
}
.pesdk-desktop-editor article,
.pesdk-desktop-editor aside,
.pesdk-desktop-editor details,
.pesdk-desktop-editor figcaption,
.pesdk-desktop-editor figure,
.pesdk-desktop-editor footer,
.pesdk-desktop-editor header,
.pesdk-desktop-editor hgroup,
.pesdk-desktop-editor main,
.pesdk-desktop-editor menu,
.pesdk-desktop-editor nav,
.pesdk-desktop-editor section,
.pesdk-desktop-editor summary {
  display: block;
}
@media only screen and (min-width: 768px) {
  .pesdk-desktop-editor {
    border-radius: 5px;
  }
}
.pesdk-desktop-editor,
.pesdk-desktop-editor * {
  box-sizing: border-box;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__ListItem-prcvvt {
  transition: color 0.3s ease-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__ListItem-prcvvt:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__ListItem-prcvvt:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__ListItem-prcvvt:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__ListItem-prcvvt {
  transition: color 0.3s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(100px);
  -webkit-transform: translateY(100px);
  opacity: 0;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__ListItem-prcvvt:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__ListItem-prcvvt:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__ListItem-prcvvt:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__ListItem-prcvvt {
  transition: color 0.3s ease-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  z-index: 2;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__ListItem-prcvvt:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__ListItem-prcvvt:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__ListItem-prcvvt:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__ListItem-prcvvt {
  transition: color 0.3s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(100px);
  -webkit-transform: translateY(100px);
  opacity: 0;
  z-index: 2;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__ListItem-prcvvt:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__ListItem-prcvvt:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__ListItem-prcvvt:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__ListItem-prcvvt {
  transition: color 0.3s ease-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__ListItem-prcvvt:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__ListItem-prcvvt:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__ListItem-prcvvt:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__ListItem-prcvvt {
  transition: color 0.3s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__ListItem-prcvvt:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__ListItem-prcvvt:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__ListItem-prcvvt:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__ListItem-prcvvt {
  transition: color 0.3s ease-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__ListItem-prcvvt:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__ListItem-prcvvt:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__ListItem-prcvvt:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__ListItem-prcvvt {
  transition: color 0.3s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__ListItem-prcvvt:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__ListItem-prcvvt:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__ListItem-prcvvt:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx__ListItemTitle-1ler8h7 {
  opacity: 0.8;
  transition: opacity 0.3s ease-out;
  transition-property: bottom, margin;
  -webkit-transition-property: bottom, margin;
  transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx--hovered-zgbg08__ListItemTitle-1ler8h7,
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx__ListItemTitle-1ler8h7 {
  display: block;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50%;
  line-height: 13px;
  font-size: 13px;
  letter-spacing: 3px;
  text-shadow: 0 0 6px #000;
  text-align: center;
  font-family: sourcesansprosemibold;
  margin-bottom: -7px;
  text-transform: uppercase;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx--hovered-zgbg08__ListItemTitle-1ler8h7 {
  opacity: 1;
  transition: opacity 0.3s ease-in;
  transition-property: bottom, margin;
  -webkit-transition-property: bottom, margin;
  transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0__Container-fujban {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0__Container-fujban:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0__Container-fujban:hover * {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64__Container-fujban {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 55px;
  height: 55px;
  margin-left: 8px;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64__Container-fujban:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64__Container-fujban:hover * {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392__Container-fujban {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392__Container-fujban:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392__Container-fujban:hover * {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392__Container-fujban {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392__Container-fujban:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392__Container-fujban:hover
  * {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--withText-rbroui__Container-fujban {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--withText-rbroui__Container-fujban:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--withText-rbroui__Container-fujban:hover * {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--withText-rbroui__Container-fujban {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 55px;
  height: 55px;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--withText-rbroui__Container-fujban:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--withText-rbroui__Container-fujban:hover * {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392--withText-rbroui__Container-fujban {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392--withText-rbroui__Container-fujban:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392--withText-rbroui__Container-fujban:hover * {
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__Container-fujban {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: relative;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__Container-fujban:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__Container-fujban:hover
  * {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a__Container-fujban {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a__Container-fujban:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a__Container-fujban:hover * {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64__Container-fujban {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 55px;
  height: 55px;
  margin-left: 8px;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64__Container-fujban:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64__Container-fujban:hover * {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392__Container-fujban {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392__Container-fujban:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392__Container-fujban:hover * {
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392__Container-fujban {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  position: relative;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392__Container-fujban:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392__Container-fujban:hover
  * {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--withText-rbroui__Container-fujban {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--withText-rbroui__Container-fujban:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--withText-rbroui__Container-fujban:hover * {
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--withText-rbroui__Container-fujban {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 55px;
  height: 55px;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: relative;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--withText-rbroui__Container-fujban:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--withText-rbroui__Container-fujban:hover
  * {
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392--withText-rbroui__Container-fujban {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: relative;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392--withText-rbroui__Container-fujban:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392--withText-rbroui__Container-fujban:hover
  * {
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__Container-fujban {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: relative;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__Container-fujban:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__Container-fujban:hover
  * {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-PlainControlItemBackground-1lmjuk__Background-17hsmc4 {
  padding: 0;
  border-radius: 1px;
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  width: 100%;
  height: 80px;
  display: table;
  table-layout: fixed;
}
.pesdk-desktop-editor .pesdk-PlainControlItemBackground-1lmjuk__Background-17hsmc4:hover {
  background: hsla(0, 0%, 100%, 0.04);
}
.pesdk-desktop-editor .pesdk-PlainControlItemBackground-1lmjuk--selected-q4p805__Background-17hsmc4 {
  padding: 0;
  border-radius: 1px;
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  background: rgba(38, 117, 253, 0.2);
  border-color: #2675f8;
  width: 100%;
  height: 80px;
  display: table;
  table-layout: fixed;
}
.pesdk-desktop-editor .pesdk-PlainControlItemBackground-1lmjuk--selected-q4p805__Background-17hsmc4:hover {
  background: rgba(38, 117, 253, 0.2);
}
.pesdk-desktop-editor .pesdk-DropZone-yw0i2r {
  border-right: 1px solid hsla(0, 0%, 100%, 0.08);
}
.pesdk-desktop-editor .pesdk-DropZone-yw0i2r,
.pesdk-desktop-editor .pesdk-DropZone-yw0i2r--hovered-1xndhux {
  display: table-cell;
  padding: 18px;
  font-family: firasansregular;
  font-size: 13px;
  text-align: center;
  vertical-align: middle;
  color: #888;
}
.pesdk-desktop-editor .pesdk-DropZone-yw0i2r--hovered-1xndhux {
  border-right: none;
  background: hsla(0, 0%, 100%, 0.05);
}
.pesdk-desktop-editor .pesdk-WebcamButton-1y2fyfw {
  display: table-cell;
  width: 46px;
  background: rgba(0, 0, 0, 0.06) no-repeat 50%;
  background-size: contain;
}
.pesdk-desktop-editor .pesdk-RootPanelWrapper-rfecls {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  border-top: 1px solid #1b1b1d;
}
.pesdk-desktop-editor .pesdk-CategoryList-5jg0ou {
  list-style: none;
  margin: 0;
  padding: 25px;
  padding-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__ListItem-1a5mxw5 {
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  position: relative;
  display: block;
  height: 112px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__ListItem-1a5mxw5:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__ListItem-1a5mxw5:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__ListItem-1a5mxw5:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__ListItem-1a5mxw5 {
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  position: relative;
  display: block;
  height: 112px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(100px);
  -webkit-transform: translateY(100px);
  opacity: 0;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__ListItem-1a5mxw5:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__ListItem-1a5mxw5:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__ListItem-1a5mxw5:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__ListItem-1a5mxw5 {
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  position: relative;
  display: block;
  height: 112px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  z-index: 2;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__ListItem-1a5mxw5:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__ListItem-1a5mxw5:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__ListItem-1a5mxw5:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__ListItem-1a5mxw5 {
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  position: relative;
  display: block;
  height: 112px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(100px);
  -webkit-transform: translateY(100px);
  opacity: 0;
  z-index: 2;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__ListItem-1a5mxw5:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__ListItem-1a5mxw5:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__ListItem-1a5mxw5:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__ListItem-1a5mxw5 {
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  position: relative;
  display: block;
  height: 112px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__ListItem-1a5mxw5:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__ListItem-1a5mxw5:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__ListItem-1a5mxw5:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__ListItem-1a5mxw5 {
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  position: relative;
  display: block;
  height: 112px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__ListItem-1a5mxw5:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__ListItem-1a5mxw5:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__ListItem-1a5mxw5:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__ListItem-1a5mxw5 {
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  position: relative;
  display: block;
  height: 112px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__ListItem-1a5mxw5:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__ListItem-1a5mxw5:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__ListItem-1a5mxw5:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__ListItem-1a5mxw5 {
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  position: relative;
  display: block;
  height: 112px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__ListItem-1a5mxw5:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__ListItem-1a5mxw5:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__ListItem-1a5mxw5:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__ListItem-1a5mxw5--portrait-otbyv0 {
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  position: relative;
  display: block;
  height: 184px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__ListItem-1a5mxw5--portrait-otbyv0:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__ListItem-1a5mxw5--portrait-otbyv0:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__ListItem-1a5mxw5--portrait-otbyv0:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__ListItem-1a5mxw5--portrait-otbyv0 {
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  position: relative;
  display: block;
  height: 184px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(100px);
  -webkit-transform: translateY(100px);
  opacity: 0;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__ListItem-1a5mxw5--portrait-otbyv0:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__ListItem-1a5mxw5--portrait-otbyv0:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__ListItem-1a5mxw5--portrait-otbyv0:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__ListItem-1a5mxw5--portrait-otbyv0 {
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  position: relative;
  display: block;
  height: 184px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  z-index: 2;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__ListItem-1a5mxw5--portrait-otbyv0:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__ListItem-1a5mxw5--portrait-otbyv0:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__ListItem-1a5mxw5--portrait-otbyv0:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__ListItem-1a5mxw5--portrait-otbyv0 {
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  position: relative;
  display: block;
  height: 184px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(100px);
  -webkit-transform: translateY(100px);
  opacity: 0;
  z-index: 2;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__ListItem-1a5mxw5--portrait-otbyv0:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__ListItem-1a5mxw5--portrait-otbyv0:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__ListItem-1a5mxw5--portrait-otbyv0:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__ListItem-1a5mxw5--portrait-otbyv0 {
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  position: relative;
  display: block;
  height: 184px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__ListItem-1a5mxw5--portrait-otbyv0:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__ListItem-1a5mxw5--portrait-otbyv0:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__ListItem-1a5mxw5--portrait-otbyv0:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__ListItem-1a5mxw5--portrait-otbyv0 {
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  position: relative;
  display: block;
  height: 184px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__ListItem-1a5mxw5--portrait-otbyv0:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__ListItem-1a5mxw5--portrait-otbyv0:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__ListItem-1a5mxw5--portrait-otbyv0:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__ListItem-1a5mxw5--portrait-otbyv0 {
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  position: relative;
  display: block;
  height: 184px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__ListItem-1a5mxw5--portrait-otbyv0:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__ListItem-1a5mxw5--portrait-otbyv0:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__ListItem-1a5mxw5--portrait-otbyv0:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__ListItem-1a5mxw5--portrait-otbyv0 {
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  position: relative;
  display: block;
  height: 184px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__ListItem-1a5mxw5--portrait-otbyv0:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__ListItem-1a5mxw5--portrait-otbyv0:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__ListItem-1a5mxw5--portrait-otbyv0:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItemActiveOverlay-x1p1ki__ListItemOverlay-120drhm {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-ControlItemActiveOverlay-x1p1ki--visible-zgbg08__ListItemOverlay-120drhm,
.pesdk-desktop-editor .pesdk-ControlItemActiveOverlay-x1p1ki__ListItemOverlay-120drhm {
  z-index: 6;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 1px solid #2675f8;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  display: none;
}
.pesdk-desktop-editor .pesdk-ControlItemActiveOverlay-x1p1ki--visible-zgbg08__ListItemOverlay-120drhm {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-ControlItemActiveOverlay-x1p1ki--rendered-2jlos__ListItemOverlay-120drhm {
  z-index: 6;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 1px solid #2675f8;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  opacity: 0;
  display: block;
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-ControlItemActiveOverlay-x1p1ki--rendered-2jlos--visible-zgbg08__ListItemOverlay-120drhm {
  z-index: 6;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 1px solid #2675f8;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  opacity: 1;
  display: block;
  transition: opacity 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-AuthorAvatar-1owxkzt {
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  vertical-align: top;
}
.pesdk-desktop-editor .pesdk-AuthorName-1jylqsv {
  display: inline-block;
  line-height: 24px;
  font-family: firasansregular;
  font-size: 13px;
  opacity: 1;
  max-width: 120px;
  white-space: nowrap;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}
.pesdk-desktop-editor .pesdk-Author-mchu36 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 12px;
  z-index: 7;
  display: none;
  opacity: 0;
  transition: all 0.3s ease-out;
  margin-bottom: -10px;
}
.pesdk-desktop-editor .pesdk-Author-mchu36:before {
  content: "";
  position: absolute;
  height: 30px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  opacity: 0.9;
  background-image: linear-gradient(-180deg, transparent, transparent 54%, rgba(0, 0, 0, 0.45));
}
.pesdk-desktop-editor .pesdk-Author-mchu36--rendered-2jlos {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 12px;
  z-index: 7;
  display: block;
  opacity: 0;
  transition: all 0.3s ease-out;
  margin-bottom: -10px;
}
.pesdk-desktop-editor .pesdk-Author-mchu36--rendered-2jlos:before {
  content: "";
  position: absolute;
  height: 30px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  opacity: 0.9;
  background-image: linear-gradient(-180deg, transparent, transparent 54%, rgba(0, 0, 0, 0.45));
}
.pesdk-desktop-editor .pesdk-Author-mchu36--visible-1fmxelx {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 12px;
  z-index: 7;
  display: none;
  opacity: 1;
  transition: all 0.3s ease-in;
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-Author-mchu36--visible-1fmxelx:before {
  content: "";
  position: absolute;
  height: 30px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  opacity: 0.9;
  background-image: linear-gradient(-180deg, transparent, transparent 54%, rgba(0, 0, 0, 0.45));
}
.pesdk-desktop-editor .pesdk-Author-mchu36--rendered-2jlos--visible-1fmxelx {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 12px;
  z-index: 7;
  display: block;
  opacity: 1;
  transition: all 0.3s ease-in;
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-Author-mchu36--rendered-2jlos--visible-1fmxelx:before {
  content: "";
  position: absolute;
  height: 30px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  opacity: 0.9;
  background-image: linear-gradient(-180deg, transparent, transparent 54%, rgba(0, 0, 0, 0.45));
}
.pesdk-desktop-editor .pesdk-ImageList-5jg0ou {
  list-style: none;
  margin: 0;
  padding: 25px;
  padding-bottom: 0;
}
.pesdk-desktop-editor .pesdk-NoResultsText-6kl290 {
  text-align: center;
  color: #fff;
  font-family: firasansregular;
  font-size: 14px;
}
.pesdk-desktop-editor .pesdk-Header-1tkmkv6 {
  display: table-row;
  height: 48px;
  background: #2a2a2e;
}
.pesdk-desktop-editor .pesdk-InputContainer-mz0a5q {
  display: table-cell;
  padding: 8px 24px;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__TextInputComponent-120drhm__TextInput-py3uvl {
  transition: opacity 0.2s ease-out;
  font-size: 14px;
  font-family: firasansregular;
  min-width: 45px;
  height: 26px;
  color: #fff;
  padding-bottom: 7px;
  border: none;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  background: transparent;
  opacity: 0.7;
  width: 100%;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__TextInputComponent-120drhm__TextInput-py3uvl:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-Input-nsck4j__TextInputComponent-120drhm__TextInput-py3uvl:hover {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-Video-2iod8f {
  visibility: hidden;
}
.pesdk-desktop-editor .pesdk-Video-2iod8f,
.pesdk-desktop-editor .pesdk-Video-2iod8f--visible-jsuk9w {
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 8px 0 rgba(0, 0, 0, 0.3),
    0 16px 16px 0 rgba(0, 0, 0, 0.3);
}
.pesdk-desktop-editor .pesdk-Video-2iod8f--visible-jsuk9w {
  visibility: visible;
}
.pesdk-desktop-editor .pesdk-Container-154zhv1 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  z-index: 6;
  background: #141415;
}
.pesdk-desktop-editor .pesdk-Gradient-4d756t {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 72px;
  background-image: linear-gradient(0deg, transparent 2%, rgba(0, 0, 0, 0.6) 59%, rgba(0, 0, 0, 0.85));
  text-align: center;
}
.pesdk-desktop-editor .pesdk-CancelButton-1p4kwwq {
  position: relative;
  z-index: 8;
  font-family: sourcesansprosemibold;
  color: #fff;
  display: inline-block;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 10px;
  margin-top: 6px;
  cursor: pointer;
}
.pesdk-desktop-editor .pesdk-OuterVideoContainer-1kcrztx {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 70px 50px 50px;
}
.pesdk-desktop-editor .pesdk-VideoContainer-12pjfqx {
  height: 100%;
  width: 100%;
  position: relative;
  text-align: center;
}
.pesdk-desktop-editor .pesdk-Button-1fkab7z {
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -30px;
  cursor: pointer;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  border: 2px solid #fff;
  background: #2d7bff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 8px 0 rgba(0, 0, 0, 0.3),
    0 16px 16px 0 rgba(0, 0, 0, 0.3);
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0__Container-1xesje3 {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0__Container-1xesje3:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0__Container-1xesje3:hover * {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64__Container-1xesje3 {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 100%;
  height: 100%;
  margin-left: 8px;
  position: absolute;
  top: 0;
  left: 0;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64__Container-1xesje3:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64__Container-1xesje3:hover * {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392__Container-1xesje3 {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 100%;
  height: 100%;
  margin-left: 8px;
  position: absolute;
  top: 0;
  left: 0;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392__Container-1xesje3:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392__Container-1xesje3:hover * {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392__Container-1xesje3 {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 100%;
  height: 100%;
  margin-left: 8px;
  position: absolute;
  top: 0;
  left: 0;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392__Container-1xesje3:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392__Container-1xesje3:hover
  * {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--withText-rbroui__Container-1xesje3 {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--withText-rbroui__Container-1xesje3:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--withText-rbroui__Container-1xesje3:hover * {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--withText-rbroui__Container-1xesje3 {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 100%;
  height: 100%;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: absolute;
  top: 0;
  left: 0;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--withText-rbroui__Container-1xesje3:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--withText-rbroui__Container-1xesje3:hover * {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392--withText-rbroui__Container-1xesje3 {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 100%;
  height: 100%;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: absolute;
  top: 0;
  left: 0;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392--withText-rbroui__Container-1xesje3:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392--withText-rbroui__Container-1xesje3:hover * {
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__Container-1xesje3 {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 100%;
  height: 100%;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: absolute;
  top: 0;
  left: 0;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__Container-1xesje3:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__Container-1xesje3:hover
  * {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a__Container-1xesje3 {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a__Container-1xesje3:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a__Container-1xesje3:hover * {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64__Container-1xesje3 {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 100%;
  height: 100%;
  margin-left: 8px;
  position: absolute;
  top: 0;
  left: 0;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64__Container-1xesje3:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64__Container-1xesje3:hover * {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392__Container-1xesje3 {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 100%;
  height: 100%;
  margin-left: 8px;
  position: absolute;
  top: 0;
  left: 0;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392__Container-1xesje3:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392__Container-1xesje3:hover * {
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392__Container-1xesje3 {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 100%;
  height: 100%;
  margin-left: 8px;
  position: absolute;
  top: 0;
  left: 0;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392__Container-1xesje3:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392__Container-1xesje3:hover
  * {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--withText-rbroui__Container-1xesje3 {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--withText-rbroui__Container-1xesje3:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--withText-rbroui__Container-1xesje3:hover * {
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--withText-rbroui__Container-1xesje3 {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 100%;
  height: 100%;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: absolute;
  top: 0;
  left: 0;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--withText-rbroui__Container-1xesje3:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--withText-rbroui__Container-1xesje3:hover
  * {
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392--withText-rbroui__Container-1xesje3 {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 100%;
  height: 100%;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: absolute;
  top: 0;
  left: 0;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392--withText-rbroui__Container-1xesje3:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392--withText-rbroui__Container-1xesje3:hover
  * {
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__Container-1xesje3 {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 100%;
  height: 100%;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: absolute;
  top: 0;
  left: 0;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__Container-1xesje3:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__Container-1xesje3:hover
  * {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-PlainControlItemBackground-1lmjuk__Background-z914p5 {
  padding: 0;
  border-radius: 1px;
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  width: 100%;
  height: 100%;
  display: table;
  table-layout: fixed;
}
.pesdk-desktop-editor .pesdk-PlainControlItemBackground-1lmjuk__Background-z914p5:hover {
  background: hsla(0, 0%, 100%, 0.04);
}
.pesdk-desktop-editor .pesdk-PlainControlItemBackground-1lmjuk--selected-q4p805__Background-z914p5 {
  padding: 0;
  border-radius: 1px;
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  background: rgba(38, 117, 253, 0.2);
  border-color: #2675f8;
  width: 100%;
  height: 100%;
  display: table;
  table-layout: fixed;
}
.pesdk-desktop-editor .pesdk-PlainControlItemBackground-1lmjuk--selected-q4p805__Background-z914p5:hover {
  background: rgba(38, 117, 253, 0.2);
}
.pesdk-desktop-editor .pesdk-WebcamButton-1jg5y1q {
  display: table-cell;
  height: 100px;
  background: rgba(0, 0, 0, 0.06) no-repeat 50%;
  background-size: 48px 48px;
}
.pesdk-desktop-editor .pesdk-LibraryUploadContainer-1up9ikz {
  position: absolute;
  top: 100px;
  left: 50px;
  right: 50px;
  bottom: 100px;
}
.pesdk-desktop-editor .pesdk-ControlGroupList-1f3fuxn__SectionList-x32ss4 {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}
.pesdk-desktop-editor .pesdk-SectionItemList-gz4y54 {
  list-style: none;
  padding: 0;
}
.pesdk-desktop-editor .pesdk-BottomSection-7abnmv {
  padding: 25px;
  padding-bottom: 10px;
}
.pesdk-desktop-editor .pesdk-PlainControlItemBackground-1lmjuk__BrushListItem-8908c2 {
  padding: 0;
  border-radius: 0;
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  height: 65px;
  border-width: 0;
  border-bottom-width: 1px;
  border-top: 1px solid transparent;
}
.pesdk-desktop-editor .pesdk-PlainControlItemBackground-1lmjuk__BrushListItem-8908c2:hover {
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid hsla(0, 0%, 100%, 0.08);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItemBackground-1lmjuk--selected-q4p805__BrushListItem-8908c2--selected-120drhm {
  padding: 0;
  border-radius: 0;
  border: 1px solid #2675f8;
  background: rgba(38, 117, 253, 0.2);
  border-color: #2675f8;
  height: 65px;
  border-width: 0;
  border-bottom-width: 1px;
  border-top: 1px solid transparent;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItemBackground-1lmjuk--selected-q4p805__BrushListItem-8908c2--selected-120drhm:hover {
  background: rgba(38, 117, 253, 0.2);
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid hsla(0, 0%, 100%, 0.08);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItemBackground-1lmjuk--selected-q4p805--selected-q4p805__BrushListItem-8908c2--selected-120drhm--selected-120drhm {
  padding: 0;
  border-radius: 0;
  border: 1px solid #2675f8;
  background: rgba(38, 117, 253, 0.2);
  border-color: #2675f8;
  height: 65px;
  border-width: 0;
  border-bottom-width: 1px;
  border-top: 1px solid transparent;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItemBackground-1lmjuk--selected-q4p805--selected-q4p805__BrushListItem-8908c2--selected-120drhm--selected-120drhm:hover {
  background: rgba(38, 117, 253, 0.2);
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid hsla(0, 0%, 100%, 0.08);
}
.pesdk-desktop-editor .pesdk-Canvas-1ssoezr {
  width: 100%;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-ValueSelection-12okzzb__SliderControl-6vpx1b {
  display: table;
  width: 100%;
  height: 32px;
  margin-bottom: 16px;
  position: relative;
}
.pesdk-desktop-editor .pesdk-ValueSelection-12okzzb__SliderControl-6vpx1b:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ValueSelection-12okzzb--disabled-1voy7lm__SliderControl-6vpx1b {
  display: table;
  width: 100%;
  height: 32px;
  margin-bottom: 16px;
  position: relative;
  opacity: 0.4;
}
.pesdk-desktop-editor .pesdk-ValueSelection-12okzzb--disabled-1voy7lm__SliderControl-6vpx1b:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlGroup-1bxndxk__Settings-r92vc7 {
  padding: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #1b1b1e;
}
.pesdk-desktop-editor .pesdk-ControlGroupTitle-12fzsea__SettingsTitle-120drhm {
  margin-bottom: 16px;
  font-size: 13px;
  font-family: sourcesansprosemibold;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  text-align: left;
  color: #888;
}
.pesdk-desktop-editor .pesdk-ColorSelection-yzsuxp {
  margin-bottom: 16px;
}
.pesdk-desktop-editor .pesdk-Brushes-16dil83 {
  position: absolute;
  top: 211px;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.12);
}
.pesdk-desktop-editor .pesdk-BrushList-1lb1mg4 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  margin: 0;
  padding: 0;
}
.pesdk-desktop-editor .pesdk-OuterContainer-ygnw89:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-Container-bkf7u8 {
  position: absolute;
  box-sizing: border-box;
}
.pesdk-desktop-editor .pesdk-Container-bkf7u8--editing-64zghi {
  box-sizing: border-box;
  z-index: 8;
}
.pesdk-desktop-editor .pesdk-Container-bkf7u8--editing-64zghi,
.pesdk-desktop-editor .pesdk-ItemControls-36haff {
  position: absolute;
  border: 2px solid hsla(0, 0%, 100%, 0.6);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), inset 0 2px 6px 0 rgba(0, 0, 0, 0.2);
}
.pesdk-desktop-editor .pesdk-ItemControls-36haff {
  z-index: 7;
  cursor: move;
}
.pesdk-desktop-editor .pesdk-PaddingAdjustmentArrowLeft-1wn24iy {
  left: -32px;
}
.pesdk-desktop-editor .pesdk-PaddingAdjustmentArrowLeft-1wn24iy,
.pesdk-desktop-editor .pesdk-PaddingAdjustmentArrowLeft-1wn24iy__PaddingAdjustmentArrowRight-1md0hz0 {
  position: absolute;
  top: 50%;
  width: 24px;
  height: 24px;
  cursor: ew-resize;
  background-size: contain;
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.6));
  -webkit-filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.6));
}
.pesdk-desktop-editor .pesdk-PaddingAdjustmentArrowLeft-1wn24iy__PaddingAdjustmentArrowRight-1md0hz0 {
  left: auto;
  right: -32px;
}
.pesdk-desktop-editor .pesdk-RotateControl-77tiy3 {
  position: absolute;
  bottom: -18px;
  left: 50%;
  width: 2px;
  height: 16px;
  margin-left: -1px;
  background-color: hsla(0, 0%, 100%, 0.6);
}
.pesdk-desktop-editor .pesdk-Knob-mznefm {
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.pesdk-desktop-editor .pesdk-Knob-mznefm:before {
  display: block;
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  margin-top: -4px;
  top: 50%;
  left: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__RotateKnob-18w0qe3 {
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: ew-resize;
  margin-left: -11px;
  outline: none;
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__RotateKnob-18w0qe3:before {
  display: block;
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  margin-top: -4px;
  top: 50%;
  left: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy {
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-top: -13px;
  margin-left: -13px;
  outline: none;
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy:before {
  display: block;
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  margin-top: -4px;
  top: 50%;
  left: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy--top-left-1dtvxe8 {
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: nwse-resize;
  margin-top: -13px;
  margin-left: -13px;
  outline: none;
  top: 0;
  left: 0;
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy--top-left-1dtvxe8:before {
  display: block;
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  margin-top: -4px;
  top: 50%;
  left: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy--top-right-1tu191k {
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: nesw-resize;
  margin-top: -13px;
  margin-left: auto;
  outline: none;
  margin-right: -13px;
  top: 0;
  right: 0;
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy--top-right-1tu191k:before {
  display: block;
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  margin-top: -4px;
  top: 50%;
  left: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy--top-left-1dtvxe8--top-right-1tu191k {
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: nesw-resize;
  margin-top: -13px;
  margin-left: auto;
  outline: none;
  top: 0;
  left: 0;
  margin-right: -13px;
  right: 0;
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy--top-left-1dtvxe8--top-right-1tu191k:before {
  display: block;
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  margin-top: -4px;
  top: 50%;
  left: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-left-1we5ut6 {
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: nesw-resize;
  margin-top: auto;
  margin-left: -13px;
  outline: none;
  margin-bottom: -13px;
  bottom: 0;
  left: 0;
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-left-1we5ut6:before {
  display: block;
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  margin-top: -4px;
  top: 50%;
  left: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-left-1we5ut6--top-left-1dtvxe8 {
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: nwse-resize;
  margin-top: auto;
  margin-left: -13px;
  outline: none;
  margin-bottom: -13px;
  bottom: 0;
  left: 0;
  top: 0;
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-left-1we5ut6--top-left-1dtvxe8:before {
  display: block;
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  margin-top: -4px;
  top: 50%;
  left: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-left-1we5ut6--top-right-1tu191k {
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: nesw-resize;
  margin: auto -13px -13px auto;
  outline: none;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-left-1we5ut6--top-right-1tu191k:before {
  display: block;
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  margin-top: -4px;
  top: 50%;
  left: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-left-1we5ut6--top-left-1dtvxe8--top-right-1tu191k {
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: nesw-resize;
  margin: auto -13px -13px auto;
  outline: none;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
}
.pesdk-desktop-editor
  .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-left-1we5ut6--top-left-1dtvxe8--top-right-1tu191k:before {
  display: block;
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  margin-top: -4px;
  top: 50%;
  left: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-right-f2wva1 {
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: nwse-resize;
  margin: auto -13px -13px auto;
  outline: none;
  bottom: 0;
  right: 0;
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-right-f2wva1:before {
  display: block;
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  margin-top: -4px;
  top: 50%;
  left: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-right-f2wva1--top-left-1dtvxe8 {
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: nwse-resize;
  margin: auto -13px -13px auto;
  outline: none;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-right-f2wva1--top-left-1dtvxe8:before {
  display: block;
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  margin-top: -4px;
  top: 50%;
  left: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-right-f2wva1--top-right-1tu191k {
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: nesw-resize;
  margin: auto -13px -13px auto;
  outline: none;
  bottom: 0;
  right: 0;
  top: 0;
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-right-f2wva1--top-right-1tu191k:before {
  display: block;
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  margin-top: -4px;
  top: 50%;
  left: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-right-f2wva1--top-left-1dtvxe8--top-right-1tu191k {
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: nesw-resize;
  margin: auto -13px -13px auto;
  outline: none;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
}
.pesdk-desktop-editor
  .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-right-f2wva1--top-left-1dtvxe8--top-right-1tu191k:before {
  display: block;
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  margin-top: -4px;
  top: 50%;
  left: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-left-1we5ut6--bottom-right-f2wva1 {
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: nwse-resize;
  margin: auto -13px -13px auto;
  outline: none;
  bottom: 0;
  left: 0;
  right: 0;
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-left-1we5ut6--bottom-right-f2wva1:before {
  display: block;
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  margin-top: -4px;
  top: 50%;
  left: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-left-1we5ut6--bottom-right-f2wva1--top-left-1dtvxe8 {
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: nwse-resize;
  margin: auto -13px -13px auto;
  outline: none;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.pesdk-desktop-editor
  .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-left-1we5ut6--bottom-right-f2wva1--top-left-1dtvxe8:before {
  display: block;
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  margin-top: -4px;
  top: 50%;
  left: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor
  .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-left-1we5ut6--bottom-right-f2wva1--top-right-1tu191k {
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: nesw-resize;
  margin: auto -13px -13px auto;
  outline: none;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.pesdk-desktop-editor
  .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-left-1we5ut6--bottom-right-f2wva1--top-right-1tu191k:before {
  display: block;
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  margin-top: -4px;
  top: 50%;
  left: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor
  .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-left-1we5ut6--bottom-right-f2wva1--top-left-1dtvxe8--top-right-1tu191k {
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: nesw-resize;
  margin: auto -13px -13px auto;
  outline: none;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.pesdk-desktop-editor
  .pesdk-Knob-mznefm__CornerKnob-wzcqy--bottom-left-1we5ut6--bottom-right-f2wva1--top-left-1dtvxe8--top-right-1tu191k:before {
  display: block;
  position: absolute;
  content: "";
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  margin-top: -4px;
  top: 50%;
  left: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor .pesdk-SnappingGuideComponent-1khi7fo {
  position: absolute;
  z-index: 7;
  display: block;
  background: #f0f;
}
.pesdk-desktop-editor .pesdk-SnappingGuideComponent-1khi7fo--horizontal-1qfe7jn {
  position: absolute;
  z-index: 7;
  display: block;
  background: #f0f;
  left: 0;
  width: 100%;
  height: 2px;
  margin-top: -1px;
}
.pesdk-desktop-editor .pesdk-SnappingGuideComponent-1khi7fo--horizontal-1qfe7jn--vertical-1q2afaf,
.pesdk-desktop-editor .pesdk-SnappingGuideComponent-1khi7fo--vertical-1q2afaf {
  position: absolute;
  z-index: 7;
  display: block;
  background: #f0f;
  top: 0;
  width: 2px;
  height: 100%;
  margin-left: -1px;
}
.pesdk-desktop-editor .pesdk-SnappingGuideComponent-1khi7fo--horizontal-1qfe7jn--vertical-1q2afaf {
  left: 0;
  margin-top: -1px;
}
.pesdk-desktop-editor .pesdk-SnappingGuideComponent-1khi7fo--horizontal-1qfe7jn--rotational-mx9yp5,
.pesdk-desktop-editor .pesdk-SnappingGuideComponent-1khi7fo--rotational-mx9yp5 {
  position: absolute;
  z-index: 7;
  display: block;
  background: transparent;
  border: none;
  height: 2px;
  width: 1000%;
  margin-left: -500%;
  border-top: 2px dashed #0ff;
}
.pesdk-desktop-editor .pesdk-SnappingGuideComponent-1khi7fo--horizontal-1qfe7jn--rotational-mx9yp5 {
  left: 0;
  margin-top: -1px;
}
.pesdk-desktop-editor .pesdk-SnappingGuideComponent-1khi7fo--horizontal-1qfe7jn--rotational-mx9yp5--vertical-1q2afaf,
.pesdk-desktop-editor .pesdk-SnappingGuideComponent-1khi7fo--rotational-mx9yp5--vertical-1q2afaf {
  position: absolute;
  z-index: 7;
  display: block;
  background: transparent;
  border: none;
  height: 100%;
  width: 2px;
  margin-left: -1px;
  border-top: 2px dashed #0ff;
  top: 0;
}
.pesdk-desktop-editor .pesdk-SnappingGuideComponent-1khi7fo--horizontal-1qfe7jn--rotational-mx9yp5--vertical-1q2afaf {
  left: 0;
  margin-top: -1px;
}
.pesdk-desktop-editor .pesdk-Image-1ssoezr,
.pesdk-desktop-editor .pesdk-SVGImage-1ssoezr {
  width: 100%;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-TextField-12u5t8c {
  overflow: hidden;
  border: none;
  width: 100%;
  resize: none;
  padding: 0;
}
.pesdk-desktop-editor .pesdk-TextField-12u5t8c:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-TextField-12u5t8c--updateBecauseIESucks-ckktkq {
  overflow: hidden;
  border: none;
  width: 0;
  resize: none;
  padding: 0;
}
.pesdk-desktop-editor .pesdk-TextField-12u5t8c--updateBecauseIESucks-ckktkq:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-TextField-12u5t8c--editing-15kzu1c {
  overflow: hidden;
  border: none;
  width: 100%;
  resize: none;
  padding: 0;
  position: absolute;
  z-index: 7;
}
.pesdk-desktop-editor .pesdk-TextField-12u5t8c--editing-15kzu1c:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-TextField-12u5t8c--editing-15kzu1c--updateBecauseIESucks-ckktkq {
  overflow: hidden;
  border: none;
  width: 0;
  resize: none;
  padding: 0;
  position: absolute;
  z-index: 7;
}
.pesdk-desktop-editor .pesdk-TextField-12u5t8c--editing-15kzu1c--updateBecauseIESucks-ckktkq:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-DisabledOverlay-8gacw5 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-WidthControl-dv4w7b {
  position: absolute;
  width: 8px;
  height: 20px;
  margin-top: -10px;
  cursor: ew-resize;
  outline: none;
}
.pesdk-desktop-editor .pesdk-WidthControl-dv4w7b:before {
  display: block;
  position: absolute;
  content: "";
  background: #fff;
  width: 4px;
  height: 12px;
  margin-left: -2px;
  margin-top: -6px;
  top: 50%;
  left: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor .pesdk-WidthControl-dv4w7b--left-tr97e1 {
  position: absolute;
  width: 8px;
  height: 20px;
  margin-top: -10px;
  cursor: ew-resize;
  outline: none;
  left: -5px;
  top: 50%;
}
.pesdk-desktop-editor .pesdk-WidthControl-dv4w7b--left-tr97e1:before {
  display: block;
  position: absolute;
  content: "";
  background: #fff;
  width: 4px;
  height: 12px;
  margin-left: -2px;
  margin-top: -6px;
  top: 50%;
  left: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor .pesdk-WidthControl-dv4w7b--right-1llojum {
  position: absolute;
  width: 8px;
  height: 20px;
  margin-top: -10px;
  cursor: ew-resize;
  outline: none;
  right: -5px;
  top: 50%;
}
.pesdk-desktop-editor .pesdk-WidthControl-dv4w7b--right-1llojum:before {
  display: block;
  position: absolute;
  content: "";
  background: #fff;
  width: 4px;
  height: 12px;
  margin-left: -2px;
  margin-top: -6px;
  top: 50%;
  left: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor .pesdk-WidthControl-dv4w7b--left-tr97e1--right-1llojum {
  position: absolute;
  width: 8px;
  height: 20px;
  margin-top: -10px;
  cursor: ew-resize;
  outline: none;
  left: -5px;
  top: 50%;
  right: -5px;
}
.pesdk-desktop-editor .pesdk-WidthControl-dv4w7b--left-tr97e1--right-1llojum:before {
  display: block;
  position: absolute;
  content: "";
  background: #fff;
  width: 4px;
  height: 12px;
  margin-left: -2px;
  margin-top: -6px;
  top: 50%;
  left: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6);
}
.pesdk-desktop-editor .pesdk-BrushItem-8gacw5 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-Container-1anj0of {
  position: absolute;
  overflow: hidden;
}
.pesdk-desktop-editor .pesdk-ClickHandlerForInternetExplorer-o35414 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: red;
  opacity: 0;
  z-index: 6;
}
.pesdk-desktop-editor .pesdk-BrushCanvasControls-1sc5z35 {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  cursor: none;
  width: 100%;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-Cursor-y4v90o {
  display: none;
}
.pesdk-desktop-editor .pesdk-Cursor-y4v90o,
.pesdk-desktop-editor .pesdk-Cursor-y4v90o--visible-2jlos {
  position: absolute;
  border: 2px solid #fff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), inset 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}
.pesdk-desktop-editor .pesdk-Cursor-y4v90o--visible-2jlos {
  display: block;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 0;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 0;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 0;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 0;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 0;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 0;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 0;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 0;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--hasMarginBottom-yzsuxp {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--hasMarginBottom-yzsuxp:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--hasMarginBottom-yzsuxp:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--hasMarginBottom-yzsuxp {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--hasMarginBottom-yzsuxp:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--hasMarginBottom-yzsuxp:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 190px;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-13hmz39--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8__Item-13hmz39--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  overflow: hidden;
  transform: none;
  -webkit-transform: initial;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7 {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: transparent;
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(-25%);
  -webkit-transform: translateY(-25%);
  opacity: 0;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--animated-1e40zg6--selected-10th7ra--visible-gc4bxi--visible-gc4bxi__Item-13hmz39--animated-1o7b5tz--animated-1o7b5tz--expanded-p8r0wp--hasMarginBottom-yzsuxp--indentation-n70ook--isIdentityFilter-h7mqj7--visible-19h4onn--visible-19h4onn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-Slider-19g9ike {
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-Slider-19g9ike,
.pesdk-desktop-editor .pesdk-Slider-19g9ike--visible-zgbg08 {
  position: absolute;
  z-index: 2;
  height: 15px;
  top: 50%;
  margin-top: -8px;
  left: 15px;
  right: 15px;
  display: none;
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-Slider-19g9ike--visible-zgbg08 {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-Slider-19g9ike--rendered-2jlos {
  position: absolute;
  z-index: 2;
  height: 15px;
  top: 50%;
  margin-top: -8px;
  left: 15px;
  right: 15px;
  opacity: 0;
  display: block;
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-Slider-19g9ike--rendered-2jlos--visible-zgbg08 {
  position: absolute;
  z-index: 2;
  height: 15px;
  top: 50%;
  margin-top: -8px;
  left: 15px;
  right: 15px;
  opacity: 1;
  display: block;
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-ItemContainer-164bps9 {
  height: 100%;
  background: transparent 50% no-repeat;
  background-size: 100% auto;
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-ItemContainer-164bps9:before {
  content: "";
  position: absolute;
  height: 30px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  opacity: 0.9;
  background-image: linear-gradient(-180deg, transparent, transparent 54%, rgba(0, 0, 0, 0.45));
}
.pesdk-desktop-editor .pesdk-ItemContainer-164bps9--hidden-qbdcep {
  height: 100%;
  background: transparent 50% no-repeat;
  background-size: 100% auto;
  transition: opacity 0.3s ease-out;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ItemContainer-164bps9--hidden-qbdcep:before {
  content: "";
  position: absolute;
  height: 30px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  opacity: 0.9;
  background-image: linear-gradient(-180deg, transparent, transparent 54%, rgba(0, 0, 0, 0.45));
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx__ItemTitle-11d53sa {
  opacity: 0.8;
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx--hovered-zgbg08__ItemTitle-11d53sa,
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx__ItemTitle-11d53sa {
  display: inline-block;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  line-height: 13px;
  font-size: 13px;
  letter-spacing: 0.18px;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  text-align: center;
  font-family: firasansregular;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx--hovered-zgbg08__ItemTitle-11d53sa {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-ControlItemActiveOverlay-x1p1ki__Overlay-120drhm {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-ControlItemActiveOverlay-x1p1ki--visible-zgbg08__Overlay-120drhm,
.pesdk-desktop-editor .pesdk-ControlItemActiveOverlay-x1p1ki__Overlay-120drhm {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 1px solid #2675f8;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  display: none;
}
.pesdk-desktop-editor .pesdk-ControlItemActiveOverlay-x1p1ki--visible-zgbg08__Overlay-120drhm {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-ControlItemActiveOverlay-x1p1ki--rendered-2jlos__Overlay-120drhm {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-ControlItemActiveOverlay-x1p1ki--rendered-2jlos--visible-zgbg08__Overlay-120drhm,
.pesdk-desktop-editor .pesdk-ControlItemActiveOverlay-x1p1ki--rendered-2jlos__Overlay-120drhm {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 1px solid #2675f8;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  display: block;
}
.pesdk-desktop-editor .pesdk-ControlItemActiveOverlay-x1p1ki--rendered-2jlos--visible-zgbg08__Overlay-120drhm {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PaddingContainer-13qin9t {
  padding: 20px;
}
.pesdk-desktop-editor .pesdk-ListItemContainer-13hv8io {
  position: relative;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__CategoryHeader-mis9wn {
  transition: margin-bottom 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #343437 50% no-repeat;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1;
  margin-top: 0;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__CategoryHeader-mis9wn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__CategoryHeader-mis9wn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__CategoryHeader-mis9wn {
  transition: margin-bottom 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #343437 50% no-repeat;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(100px);
  -webkit-transform: translateY(100px);
  opacity: 0;
  z-index: 1;
  margin-top: 0;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__CategoryHeader-mis9wn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__CategoryHeader-mis9wn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__CategoryHeader-mis9wn {
  transition: margin-bottom 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #343437 50% no-repeat;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1;
  margin-top: 0;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__CategoryHeader-mis9wn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__CategoryHeader-mis9wn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__CategoryHeader-mis9wn {
  transition: margin-bottom 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #343437 50% no-repeat;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(100px);
  -webkit-transform: translateY(100px);
  opacity: 0;
  z-index: 1;
  margin-top: 0;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__CategoryHeader-mis9wn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__CategoryHeader-mis9wn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__CategoryHeader-mis9wn {
  transition: margin-bottom 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #343437 50% no-repeat;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 1;
  margin-top: 0;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__CategoryHeader-mis9wn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__CategoryHeader-mis9wn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__CategoryHeader-mis9wn {
  transition: margin-bottom 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #343437 50% no-repeat;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 1;
  margin-top: 0;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__CategoryHeader-mis9wn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__CategoryHeader-mis9wn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__CategoryHeader-mis9wn {
  transition: margin-bottom 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #343437 50% no-repeat;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-top: 0;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__CategoryHeader-mis9wn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__CategoryHeader-mis9wn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__CategoryHeader-mis9wn {
  transition: margin-bottom 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #343437 50% no-repeat;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 1;
  margin-top: 0;
  background-size: cover;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__CategoryHeader-mis9wn:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__CategoryHeader-mis9wn:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__CategoryHeader-mis9wn--expanded-xbkerq {
  transition: margin-bottom 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #343437 50% no-repeat;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1;
  margin-top: 0;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__CategoryHeader-mis9wn--expanded-xbkerq:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__CategoryHeader-mis9wn--expanded-xbkerq:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__CategoryHeader-mis9wn--expanded-xbkerq {
  transition: margin-bottom 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #343437 50% no-repeat;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(100px);
  -webkit-transform: translateY(100px);
  opacity: 0;
  z-index: 1;
  margin-top: 0;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__CategoryHeader-mis9wn--expanded-xbkerq:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__CategoryHeader-mis9wn--expanded-xbkerq:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__CategoryHeader-mis9wn--expanded-xbkerq {
  transition: margin-bottom 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #343437 50% no-repeat;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1;
  margin-top: 0;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__CategoryHeader-mis9wn--expanded-xbkerq:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__CategoryHeader-mis9wn--expanded-xbkerq:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__CategoryHeader-mis9wn--expanded-xbkerq {
  transition: margin-bottom 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #343437 50% no-repeat;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(100px);
  -webkit-transform: translateY(100px);
  opacity: 0;
  z-index: 1;
  margin-top: 0;
  background-size: cover;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__CategoryHeader-mis9wn--expanded-xbkerq:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__CategoryHeader-mis9wn--expanded-xbkerq:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__CategoryHeader-mis9wn--expanded-xbkerq {
  transition: margin-bottom 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #343437 50% no-repeat;
  border-radius: 4px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 1;
  margin-top: 0;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__CategoryHeader-mis9wn--expanded-xbkerq:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__CategoryHeader-mis9wn--expanded-xbkerq:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__CategoryHeader-mis9wn--expanded-xbkerq {
  transition: margin-bottom 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #343437 50% no-repeat;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 1;
  margin-top: 0;
  background-size: cover;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__CategoryHeader-mis9wn--expanded-xbkerq:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__CategoryHeader-mis9wn--expanded-xbkerq:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__CategoryHeader-mis9wn--expanded-xbkerq {
  transition: margin-bottom 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #343437 50% no-repeat;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  margin-top: 0;
  background-size: cover;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__CategoryHeader-mis9wn--expanded-xbkerq:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__CategoryHeader-mis9wn--expanded-xbkerq:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__CategoryHeader-mis9wn--expanded-xbkerq {
  transition: margin-bottom 0.3s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #343437 50% no-repeat;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 1;
  margin-top: 0;
  background-size: cover;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__CategoryHeader-mis9wn--expanded-xbkerq:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__CategoryHeader-mis9wn--expanded-xbkerq:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx__CategoryHeaderTitleWrapper-hpn4lp {
  opacity: 0.8;
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx--hovered-zgbg08__CategoryHeaderTitleWrapper-hpn4lp,
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx__CategoryHeaderTitleWrapper-hpn4lp {
  display: block;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  line-height: 13px;
  font-size: 13px;
  letter-spacing: 0.18px;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  text-align: center;
  font-family: firasansregular;
  color: #fff;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx--hovered-zgbg08__CategoryHeaderTitleWrapper-hpn4lp {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-CategoryHeaderTitle-13hv8io {
  position: relative;
}
.pesdk-desktop-editor .pesdk-CategoryHeaderTitleArrowIcon-7wtnq9 {
  position: absolute;
  left: calc(100% + 4px);
  top: 50%;
  width: 20px;
  display: inline-block;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transition: transform 0.3s ease-in-out;
}
.pesdk-desktop-editor .pesdk-CategoryHeaderTitleArrowIcon-7wtnq9--active-6ro6jp {
  position: absolute;
  left: calc(100% + 4px);
  top: 50%;
  width: 20px;
  display: inline-block;
  transform: translateY(-50%) rotate(180deg);
  -webkit-transform: translateY(-50%) rotate(180deg);
  transition: transform 0.3s ease-in-out;
}
.pesdk-desktop-editor .pesdk-CategoryHeaderTitleFading-133t8sq:before {
  content: "";
  position: absolute;
  height: 30px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  opacity: 0.9;
  background-image: linear-gradient(-180deg, transparent, transparent 54%, rgba(0, 0, 0, 0.45));
}
.pesdk-desktop-editor .pesdk-CategoryHeaderTitleFading-133t8sq--expanded-1etxnci:before {
  content: "";
  position: absolute;
  height: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  opacity: 0.9;
  background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.45), transparent 54%, rgba(0, 0, 0, 0.45));
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__ListItem-19qom6j {
  transition: background-image 0.4s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__ListItem-19qom6j:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__ListItem-19qom6j:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__ListItem-19qom6j:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__ListItem-19qom6j {
  transition: background-image 0.4s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(100px);
  -webkit-transform: translateY(100px);
  opacity: 0;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__ListItem-19qom6j:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__ListItem-19qom6j:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__ListItem-19qom6j:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__ListItem-19qom6j {
  transition: background-image 0.4s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  z-index: 2;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__ListItem-19qom6j:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__ListItem-19qom6j:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__ListItem-19qom6j:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__ListItem-19qom6j {
  transition: background-image 0.4s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(100px);
  -webkit-transform: translateY(100px);
  opacity: 0;
  z-index: 2;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__ListItem-19qom6j:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__ListItem-19qom6j:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__ListItem-19qom6j:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__ListItem-19qom6j {
  transition: background-image 0.4s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__ListItem-19qom6j:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__ListItem-19qom6j:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__ListItem-19qom6j:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__ListItem-19qom6j {
  transition: background-image 0.4s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__ListItem-19qom6j:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__ListItem-19qom6j:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__ListItem-19qom6j:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__ListItem-19qom6j {
  transition: background-image 0.4s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__ListItem-19qom6j:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__ListItem-19qom6j:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__ListItem-19qom6j:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__ListItem-19qom6j {
  transition: background-image 0.4s ease-in-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  background-color: #343437;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__ListItem-19qom6j:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__ListItem-19qom6j:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__ListItem-19qom6j:last-child {
  margin-bottom: 0;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx__ListItemTitle-1cwztpc {
  opacity: 0.8;
  transition: opacity 0.3s ease-out;
  transition-property: bottom, margin;
  -webkit-transition-property: bottom, margin;
  transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: ease-out;
  -webkit-transition-timing-function: ease-out;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx--hovered-zgbg08__ListItemTitle-1cwztpc,
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx__ListItemTitle-1cwztpc {
  display: block;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50%;
  line-height: 13px;
  font-size: 13px;
  letter-spacing: 3px;
  text-shadow: 0 0 6px #000;
  text-align: center;
  font-family: sourcesansprosemibold;
  margin-bottom: -7px;
  text-transform: uppercase;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx--hovered-zgbg08__ListItemTitle-1cwztpc {
  opacity: 1;
  transition: opacity 0.3s ease-in;
  transition-property: bottom, margin;
  -webkit-transition-property: bottom, margin;
  transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: ease-out;
  -webkit-transition-timing-function: ease-out;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx__ListItemTitle-1cwztpc--selected-2znik8 {
  opacity: 0.8;
  transition: opacity 0.3s ease-out;
  transition-property: bottom, margin;
  -webkit-transition-property: bottom, margin;
  transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  transition-delay: 0;
  -webkit-transition-delay: 0;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx--hovered-zgbg08__ListItemTitle-1cwztpc--selected-2znik8,
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx__ListItemTitle-1cwztpc--selected-2znik8 {
  display: block;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50%;
  line-height: 13px;
  font-size: 13px;
  letter-spacing: 3px;
  text-shadow: 0 0 6px #000;
  text-align: center;
  font-family: sourcesansprosemibold;
  margin-bottom: -7px;
  text-transform: uppercase;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx--hovered-zgbg08__ListItemTitle-1cwztpc--selected-2znik8 {
  opacity: 1;
  transition: opacity 0.3s ease-in;
  transition-property: bottom, margin;
  -webkit-transition-property: bottom, margin;
  transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  transition-delay: 0;
  -webkit-transition-delay: 0;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx__ListItemTitle-1cwztpc--withSlider-fsvvyb {
  opacity: 0.8;
  transition: opacity 0.3s ease-out;
  transition-property: bottom, margin;
  -webkit-transition-property: bottom, margin;
  transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: ease-out;
  -webkit-transition-timing-function: ease-out;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx--hovered-zgbg08__ListItemTitle-1cwztpc--withSlider-fsvvyb,
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx__ListItemTitle-1cwztpc--withSlider-fsvvyb {
  display: block;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 8px;
  line-height: 13px;
  font-size: 13px;
  letter-spacing: 3px;
  text-shadow: 0 0 6px #000;
  text-align: center;
  font-family: sourcesansprosemibold;
  margin-bottom: 0;
  text-transform: uppercase;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx--hovered-zgbg08__ListItemTitle-1cwztpc--withSlider-fsvvyb {
  opacity: 1;
  transition: opacity 0.3s ease-in;
  transition-property: bottom, margin;
  -webkit-transition-property: bottom, margin;
  transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: ease-out;
  -webkit-transition-timing-function: ease-out;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx__ListItemTitle-1cwztpc--selected-2znik8--withSlider-fsvvyb {
  display: block;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 8px;
  line-height: 13px;
  font-size: 13px;
  letter-spacing: 3px;
  text-shadow: 0 0 6px #000;
  text-align: center;
  opacity: 0.8;
  font-family: sourcesansprosemibold;
  transition: opacity 0.3s ease-out;
  transition-property: bottom, margin;
  -webkit-transition-property: bottom, margin;
  transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  margin-bottom: 0;
  text-transform: uppercase;
  transition-delay: 0;
  -webkit-transition-delay: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItemTitle-d17ppx--hovered-zgbg08__ListItemTitle-1cwztpc--selected-2znik8--withSlider-fsvvyb {
  display: block;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 8px;
  line-height: 13px;
  font-size: 13px;
  letter-spacing: 3px;
  text-shadow: 0 0 6px #000;
  text-align: center;
  opacity: 1;
  font-family: sourcesansprosemibold;
  transition: opacity 0.3s ease-in;
  transition-property: bottom, margin;
  -webkit-transition-property: bottom, margin;
  transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  margin-bottom: 0;
  text-transform: uppercase;
  transition-delay: 0;
  -webkit-transition-delay: 0;
}
.pesdk-desktop-editor .pesdk-ControlItemActiveOverlay-x1p1ki__ListItemOverlay-rctda3 {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-ControlItemActiveOverlay-x1p1ki--visible-zgbg08__ListItemOverlay-rctda3,
.pesdk-desktop-editor .pesdk-ControlItemActiveOverlay-x1p1ki__ListItemOverlay-rctda3 {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 1px solid #2675f8;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  display: none;
  background-image: linear-gradient(-180deg, rgba(20, 45, 87, 0.12), rgba(0, 0, 0, 0.14));
  background-repeat: no-repeat;
}
.pesdk-desktop-editor .pesdk-ControlItemActiveOverlay-x1p1ki--visible-zgbg08__ListItemOverlay-rctda3 {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-ControlItemActiveOverlay-x1p1ki--rendered-2jlos__ListItemOverlay-rctda3 {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-ControlItemActiveOverlay-x1p1ki--rendered-2jlos--visible-zgbg08__ListItemOverlay-rctda3,
.pesdk-desktop-editor .pesdk-ControlItemActiveOverlay-x1p1ki--rendered-2jlos__ListItemOverlay-rctda3 {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 1px solid #2675f8;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15), 0 2px 10px 0 rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  display: block;
  background-image: linear-gradient(-180deg, rgba(20, 45, 87, 0.12), rgba(0, 0, 0, 0.14));
  background-repeat: no-repeat;
}
.pesdk-desktop-editor .pesdk-ControlItemActiveOverlay-x1p1ki--rendered-2jlos--visible-zgbg08__ListItemOverlay-rctda3 {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-Slider-chwlso {
  transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
  position: absolute;
  z-index: 2;
  height: 15px;
  top: 50%;
  margin-top: -8px;
  left: 15px;
  right: 15px;
  opacity: 0;
  visibility: hidden;
}
.pesdk-desktop-editor .pesdk-Slider-chwlso--visible-vpr3tu {
  transition: opacity 0.2s ease-in, visibility 0.2s ease-in;
  position: absolute;
  z-index: 2;
  height: 15px;
  top: 50%;
  margin-top: -8px;
  left: 15px;
  right: 15px;
  opacity: 1;
  visibility: visible;
}
.pesdk-desktop-editor .pesdk-List-1snluvd {
  list-style: none;
  margin: 0;
  padding: 25px;
}
.pesdk-desktop-editor .pesdk-FullContainer-vl6nc6__CanvasControls-1rineb {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.pesdk-desktop-editor .pesdk-OnCanvasControls-12e79nm {
  transition: opacity 0.3s ease-out;
  position: relative;
  display: none;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-OnCanvasControls-12e79nm--rendered-2jlos {
  transition: opacity 0.3s ease-out;
  position: relative;
  display: block;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-OnCanvasControls-12e79nm--visible-zgbg08 {
  transition: opacity 0.3s ease-out;
  position: relative;
  display: none;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-OnCanvasControls-12e79nm--rendered-2jlos--visible-zgbg08 {
  transition: opacity 0.3s ease-out;
  position: relative;
  display: block;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-Knob-ywjahx__Knob-120drhm {
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  cursor: pointer;
  position: absolute;
}
.pesdk-desktop-editor .pesdk-Circle-kgwi8g {
  position: absolute;
  border-radius: 50%;
  border: 2px solid hsla(0, 0%, 100%, 0.6);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), inset 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  cursor: move;
}
.pesdk-desktop-editor .pesdk-Line-1rrhaqy {
  position: absolute;
  height: 2px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  background: hsla(0, 0%, 100%, 0.6);
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}
.pesdk-desktop-editor .pesdk-OnCanvasControls-aovk7s {
  transition: opacity 0.3s ease-out;
  position: relative;
  display: none;
  opacity: 0;
  overflow: hidden;
}
.pesdk-desktop-editor .pesdk-OnCanvasControls-aovk7s--rendered-2jlos {
  transition: opacity 0.3s ease-out;
  position: relative;
  display: block;
  opacity: 0;
  overflow: hidden;
}
.pesdk-desktop-editor .pesdk-OnCanvasControls-aovk7s--visible-zgbg08 {
  transition: opacity 0.3s ease-out;
  position: relative;
  display: none;
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor .pesdk-OnCanvasControls-aovk7s--rendered-2jlos--visible-zgbg08 {
  transition: opacity 0.3s ease-out;
  position: relative;
  display: block;
  opacity: 1;
  overflow: hidden;
}
.pesdk-desktop-editor .pesdk-Area-36haff {
  position: absolute;
  border: 2px solid hsla(0, 0%, 100%, 0.6);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), inset 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  cursor: move;
}
.pesdk-desktop-editor .pesdk-Container-o5vg7n {
  position: absolute;
  overflow: hidden;
  background: #ff000000;
}
.pesdk-desktop-editor .pesdk-FrameList-18qztam {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 25px;
  padding-left: 17px;
  text-align: left;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0__FrameListItem-u8nfgk {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0__FrameListItem-u8nfgk:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0__FrameListItem-u8nfgk:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64__FrameListItem-u8nfgk {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 55px;
  height: 55px;
  margin-left: 8px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64__FrameListItem-u8nfgk:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64__FrameListItem-u8nfgk:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392__FrameListItem-u8nfgk {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392__FrameListItem-u8nfgk:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392__FrameListItem-u8nfgk:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392__FrameListItem-u8nfgk {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392__FrameListItem-u8nfgk:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392__FrameListItem-u8nfgk:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--withText-rbroui__FrameListItem-u8nfgk {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  vertical-align: top;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  display: inline-block;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--withText-rbroui__FrameListItem-u8nfgk:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--withText-rbroui__FrameListItem-u8nfgk:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--withText-rbroui__FrameListItem-u8nfgk {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 55px;
  height: 55px;
  margin-left: 8px;
  vertical-align: top;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  display: inline-block;
  position: relative;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--withText-rbroui__FrameListItem-u8nfgk:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--withText-rbroui__FrameListItem-u8nfgk:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392--withText-rbroui__FrameListItem-u8nfgk {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  vertical-align: top;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  display: inline-block;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392--withText-rbroui__FrameListItem-u8nfgk:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392--withText-rbroui__FrameListItem-u8nfgk:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__FrameListItem-u8nfgk {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  vertical-align: top;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  display: inline-block;
  position: relative;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__FrameListItem-u8nfgk:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__FrameListItem-u8nfgk:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a__FrameListItem-u8nfgk {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a__FrameListItem-u8nfgk:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a__FrameListItem-u8nfgk:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64__FrameListItem-u8nfgk {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 55px;
  height: 55px;
  margin-left: 8px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64__FrameListItem-u8nfgk:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64__FrameListItem-u8nfgk:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392__FrameListItem-u8nfgk {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392__FrameListItem-u8nfgk:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392__FrameListItem-u8nfgk:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392__FrameListItem-u8nfgk {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392__FrameListItem-u8nfgk:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392__FrameListItem-u8nfgk:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--withText-rbroui__FrameListItem-u8nfgk {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  vertical-align: top;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  display: inline-block;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--withText-rbroui__FrameListItem-u8nfgk:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--withText-rbroui__FrameListItem-u8nfgk:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--withText-rbroui__FrameListItem-u8nfgk {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 55px;
  height: 55px;
  margin-left: 8px;
  vertical-align: top;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  display: inline-block;
  position: relative;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--withText-rbroui__FrameListItem-u8nfgk:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--withText-rbroui__FrameListItem-u8nfgk:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392--withText-rbroui__FrameListItem-u8nfgk {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  vertical-align: top;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  display: inline-block;
  position: relative;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392--withText-rbroui__FrameListItem-u8nfgk:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392--withText-rbroui__FrameListItem-u8nfgk:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__FrameListItem-u8nfgk {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  vertical-align: top;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  display: inline-block;
  position: relative;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__FrameListItem-u8nfgk:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__FrameListItem-u8nfgk:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor .pesdk-FrameImage-7r0ke8 {
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
}
.pesdk-desktop-editor .pesdk-Wrapper-rfecls {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-TopSection-1vnvjau {
  padding: 25px;
  border-bottom: 1px solid #1b1b1e;
}
.pesdk-desktop-editor .pesdk-Details-16woy02 {
  position: relative;
  margin-bottom: 25px;
  text-align: left;
  height: 72px;
}
.pesdk-desktop-editor .pesdk-PlainControlItemBackground-1lmjuk__Thumbnail-6u8g0p {
  padding: 0;
  border-radius: 1px;
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  position: absolute;
  left: 0;
  top: 0;
  height: 72px;
  width: 72px;
  cursor: pointer;
}
.pesdk-desktop-editor .pesdk-PlainControlItemBackground-1lmjuk__Thumbnail-6u8g0p:hover {
  background: hsla(0, 0%, 100%, 0.04);
}
.pesdk-desktop-editor .pesdk-PlainControlItemBackground-1lmjuk--selected-q4p805__Thumbnail-6u8g0p {
  padding: 0;
  border-radius: 1px;
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  background: rgba(38, 117, 253, 0.2);
  border-color: #2675f8;
  position: absolute;
  left: 0;
  top: 0;
  height: 72px;
  width: 72px;
  cursor: pointer;
}
.pesdk-desktop-editor .pesdk-PlainControlItemBackground-1lmjuk--selected-q4p805__Thumbnail-6u8g0p:hover {
  background: rgba(38, 117, 253, 0.2);
}
.pesdk-desktop-editor .pesdk-ThumbnailImage-7r0ke8 {
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
}
.pesdk-desktop-editor .pesdk-DetailsContainer-1trlo6v {
  position: absolute;
  right: 0;
  left: 87px;
  top: 0;
  font-size: 13px;
}
.pesdk-desktop-editor .pesdk-Title-w9mc0y {
  height: auto;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 8px;
  color: #888;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: firasansregular;
}
.pesdk-desktop-editor .pesdk-ReplaceButton-1rhky7p {
  width: 100%;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: sourcesansprosemibold;
  cursor: pointer;
}
.pesdk-desktop-editor .pesdk-ColorSelection-120c4v2__ColorSelection-1ouwzwk {
  text-align: left;
  width: 100%;
  margin-left: 0;
  padding-top: 0;
}
.pesdk-desktop-editor .pesdk-BlendingControlContainer-15zte1z {
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 0 15px;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx__Label-1v7ogyl {
  opacity: 0.8;
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx--hovered-zgbg08__Label-1v7ogyl,
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx__Label-1v7ogyl {
  display: table-cell;
  z-index: 2;
  position: static;
  left: 0;
  right: 0;
  bottom: 10px;
  line-height: 13px;
  font-size: 13px;
  letter-spacing: 0.18px;
  text-shadow: none;
  text-align: center;
  font-family: firasansregular;
  vertical-align: middle;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx--hovered-zgbg08__Label-1v7ogyl {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-Arrow-1v32l0l {
  display: table-cell;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  opacity: 0.8;
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-Arrow-1v32l0l:hover {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-193vjnw {
  transition: color 0.3s ease-out;
  position: relative;
  display: block;
  height: 96px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #4b4b4c;
  border-radius: 2px;
  cursor: pointer;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-193vjnw:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-193vjnw:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-193vjnw {
  transition: color 0.3s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out;
  position: relative;
  display: block;
  height: 96px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #4b4b4c;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(100px);
  -webkit-transform: translateY(100px);
  opacity: 0;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-193vjnw:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-193vjnw:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-193vjnw {
  transition: color 0.3s ease-out;
  position: relative;
  display: block;
  height: 96px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #4b4b4c;
  border-radius: 2px;
  cursor: pointer;
  z-index: 2;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-193vjnw:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-193vjnw:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-193vjnw {
  transition: color 0.3s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out;
  position: relative;
  display: block;
  height: 96px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #4b4b4c;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(100px);
  -webkit-transform: translateY(100px);
  opacity: 0;
  z-index: 2;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-193vjnw:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-193vjnw:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-193vjnw {
  transition: color 0.3s ease-out;
  position: relative;
  display: block;
  height: 96px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #4b4b4c;
  border-radius: 2px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-193vjnw:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-193vjnw:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-193vjnw {
  transition: color 0.3s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out;
  position: relative;
  display: block;
  height: 96px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #4b4b4c;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-193vjnw:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-193vjnw:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-193vjnw {
  transition: color 0.3s ease-out;
  position: relative;
  display: block;
  height: 96px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #4b4b4c;
  border-radius: 2px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-193vjnw:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-193vjnw:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-193vjnw {
  transition: color 0.3s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out;
  position: relative;
  display: block;
  height: 96px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #4b4b4c;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  background-size: cover;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-193vjnw:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-193vjnw:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-193vjnw--lastChild-229bd5 {
  transition: color 0.3s ease-out;
  position: relative;
  display: block;
  height: 96px;
  width: 100%;
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #4b4b4c;
  border-radius: 2px;
  cursor: pointer;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-193vjnw--lastChild-229bd5:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__Item-193vjnw--lastChild-229bd5:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-193vjnw--lastChild-229bd5 {
  transition: color 0.3s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out;
  position: relative;
  display: block;
  height: 96px;
  width: 100%;
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #4b4b4c;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(100px);
  -webkit-transform: translateY(100px);
  opacity: 0;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-193vjnw--lastChild-229bd5:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__Item-193vjnw--lastChild-229bd5:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-193vjnw--lastChild-229bd5 {
  transition: color 0.3s ease-out;
  position: relative;
  display: block;
  height: 96px;
  width: 100%;
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #4b4b4c;
  border-radius: 2px;
  cursor: pointer;
  z-index: 2;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-193vjnw--lastChild-229bd5:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__Item-193vjnw--lastChild-229bd5:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-193vjnw--lastChild-229bd5 {
  transition: color 0.3s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out;
  position: relative;
  display: block;
  height: 96px;
  width: 100%;
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #4b4b4c;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(100px);
  -webkit-transform: translateY(100px);
  opacity: 0;
  z-index: 2;
  background-size: cover;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-193vjnw--lastChild-229bd5:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__Item-193vjnw--lastChild-229bd5:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-193vjnw--lastChild-229bd5 {
  transition: color 0.3s ease-out;
  position: relative;
  display: block;
  height: 96px;
  width: 100%;
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #4b4b4c;
  border-radius: 2px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-193vjnw--lastChild-229bd5:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__Item-193vjnw--lastChild-229bd5:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-193vjnw--lastChild-229bd5 {
  transition: color 0.3s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out;
  position: relative;
  display: block;
  height: 96px;
  width: 100%;
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #4b4b4c;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  background-size: cover;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-193vjnw--lastChild-229bd5:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__Item-193vjnw--lastChild-229bd5:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-193vjnw--lastChild-229bd5 {
  transition: color 0.3s ease-out;
  position: relative;
  display: block;
  height: 96px;
  width: 100%;
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #4b4b4c;
  border-radius: 2px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  background-size: cover;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-193vjnw--lastChild-229bd5:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__Item-193vjnw--lastChild-229bd5:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-193vjnw--lastChild-229bd5 {
  transition: color 0.3s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out;
  position: relative;
  display: block;
  height: 96px;
  width: 100%;
  margin-bottom: 0;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #4b4b4c;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  background-size: cover;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-193vjnw--lastChild-229bd5:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__Item-193vjnw--lastChild-229bd5:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-Slider-12dmntm {
  position: absolute;
  z-index: 3;
  height: 15px;
  bottom: 16px;
  left: 25px;
  right: 25px;
}
.pesdk-desktop-editor .pesdk-ItemContainer-1m161zg {
  height: 100%;
  position: relative;
}
.pesdk-desktop-editor .pesdk-ItemContainer-1m161zg:before {
  content: "";
  position: absolute;
  height: 30px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  opacity: 0.9;
  background-image: linear-gradient(-180deg, transparent, transparent 54%, rgba(0, 0, 0, 0.45));
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx__ItemTitle-120drhm {
  opacity: 0.8;
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx--hovered-zgbg08__ItemTitle-120drhm,
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx__ItemTitle-120drhm {
  display: block;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  line-height: 13px;
  font-size: 13px;
  letter-spacing: 0.18px;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  text-align: center;
  font-family: firasansregular;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx--hovered-zgbg08__ItemTitle-120drhm {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx__ItemTitle-120drhm__BlendingLabel-1kbikta {
  display: block;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  bottom: auto;
  line-height: 13px;
  font-size: 13px;
  letter-spacing: 0.18px;
  text-shadow: none;
  text-align: center;
  opacity: 0.45;
  font-family: firasansregular;
  transition: opacity 0.3s ease-out;
  top: 17px;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx--hovered-zgbg08__ItemTitle-120drhm__BlendingLabel-1kbikta {
  display: block;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  bottom: auto;
  line-height: 13px;
  font-size: 13px;
  letter-spacing: 0.18px;
  text-shadow: none;
  text-align: center;
  opacity: 0.45;
  font-family: firasansregular;
  transition: opacity 0.3s ease-in;
  top: 17px;
}
.pesdk-desktop-editor .pesdk-ItemContent-tj7k39 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-ItemContent-tj7k39--rendered-cuo4v2 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
  transition: opacity 0.3s ease-out;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ItemContent-tj7k39--rendered-cuo4v2--visible-82svov,
.pesdk-desktop-editor .pesdk-ItemContent-tj7k39--visible-82svov {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
  transition: opacity 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-BlendingControlContainer-15zte1z__BlendingControl-ii685b {
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 0 15px;
  position: absolute;
  top: 34px;
  z-index: 3;
}
.pesdk-desktop-editor .pesdk-List-9uviv9 {
  list-style: none;
  margin: 0;
  padding: 25px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0__NoneItem-1sgdmny {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 100%;
  height: 96px;
  display: table;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0__NoneItem-1sgdmny:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64__NoneItem-1sgdmny {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 100%;
  height: 96px;
  margin-left: 8px;
  display: table;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64__NoneItem-1sgdmny:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392__NoneItem-1sgdmny {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 100%;
  height: 96px;
  margin-left: 8px;
  display: table;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392__NoneItem-1sgdmny:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392__NoneItem-1sgdmny {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 100%;
  height: 96px;
  margin-left: 8px;
  display: table;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392__NoneItem-1sgdmny:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--withText-rbroui__NoneItem-1sgdmny {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  width: 100%;
  height: 96px;
  display: table;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--withText-rbroui__NoneItem-1sgdmny:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--withText-rbroui__NoneItem-1sgdmny {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 100%;
  height: 96px;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  display: table;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--withText-rbroui__NoneItem-1sgdmny:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392--withText-rbroui__NoneItem-1sgdmny {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 100%;
  height: 96px;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  display: table;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392--withText-rbroui__NoneItem-1sgdmny:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__NoneItem-1sgdmny {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 100%;
  height: 96px;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  display: table;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__NoneItem-1sgdmny:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a__NoneItem-1sgdmny {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 100%;
  height: 96px;
  display: table;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a__NoneItem-1sgdmny:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64__NoneItem-1sgdmny {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 100%;
  height: 96px;
  margin-left: 8px;
  display: table;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64__NoneItem-1sgdmny:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392__NoneItem-1sgdmny {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 100%;
  height: 96px;
  margin-left: 8px;
  display: table;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392__NoneItem-1sgdmny:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392__NoneItem-1sgdmny {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 100%;
  height: 96px;
  margin-left: 8px;
  display: table;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392__NoneItem-1sgdmny:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--withText-rbroui__NoneItem-1sgdmny {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  width: 100%;
  height: 96px;
  display: table;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--withText-rbroui__NoneItem-1sgdmny:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--withText-rbroui__NoneItem-1sgdmny {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 100%;
  height: 96px;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  display: table;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--withText-rbroui__NoneItem-1sgdmny:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392--withText-rbroui__NoneItem-1sgdmny {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 100%;
  height: 96px;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  display: table;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392--withText-rbroui__NoneItem-1sgdmny:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__NoneItem-1sgdmny {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 100%;
  height: 96px;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  display: table;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__NoneItem-1sgdmny:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor .pesdk-PlainControlItemBackground-1lmjuk__NoneItemBackground-yogtp1 {
  padding: 10px;
  border-radius: 1px;
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  width: 100%;
  height: 96px;
  position: relative;
  display: table-cell;
  vertical-align: middle;
}
.pesdk-desktop-editor .pesdk-PlainControlItemBackground-1lmjuk__NoneItemBackground-yogtp1:hover {
  background: hsla(0, 0%, 100%, 0.04);
}
.pesdk-desktop-editor .pesdk-PlainControlItemBackground-1lmjuk--selected-q4p805__NoneItemBackground-yogtp1 {
  padding: 10px;
  border-radius: 1px;
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  background: rgba(38, 117, 253, 0.2);
  border-color: #2675f8;
  width: 100%;
  height: 96px;
  position: relative;
  display: table-cell;
  vertical-align: middle;
}
.pesdk-desktop-editor .pesdk-PlainControlItemBackground-1lmjuk--selected-q4p805__NoneItemBackground-yogtp1:hover {
  background: rgba(38, 117, 253, 0.2);
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx__ItemTitle-5krmrh {
  opacity: 0.8;
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx--hovered-zgbg08__ItemTitle-5krmrh,
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx__ItemTitle-5krmrh {
  display: block;
  z-index: 2;
  position: static;
  left: 0;
  right: 0;
  bottom: 10px;
  line-height: 13px;
  font-size: 13px;
  letter-spacing: 0.18px;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  text-align: center;
  font-family: firasansregular;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx--hovered-zgbg08__ItemTitle-5krmrh {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__StickerCategoryItem-1dc04zg {
  transition: color 0.3s ease-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  background-color: #4b4b4c;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__StickerCategoryItem-1dc04zg:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8__StickerCategoryItem-1dc04zg:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__StickerCategoryItem-1dc04zg {
  transition: color 0.3s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(100px);
  -webkit-transform: translateY(100px);
  opacity: 0;
  background-color: #4b4b4c;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__StickerCategoryItem-1dc04zg:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6__StickerCategoryItem-1dc04zg:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__StickerCategoryItem-1dc04zg {
  transition: color 0.3s ease-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  z-index: 2;
  background-color: #4b4b4c;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__StickerCategoryItem-1dc04zg:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra__StickerCategoryItem-1dc04zg:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__StickerCategoryItem-1dc04zg {
  transition: color 0.3s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(100px);
  -webkit-transform: translateY(100px);
  opacity: 0;
  z-index: 2;
  background-color: #4b4b4c;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__StickerCategoryItem-1dc04zg:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra__StickerCategoryItem-1dc04zg:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__StickerCategoryItem-1dc04zg {
  transition: color 0.3s ease-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  background-color: #4b4b4c;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__StickerCategoryItem-1dc04zg:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--visible-gc4bxi__StickerCategoryItem-1dc04zg:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__StickerCategoryItem-1dc04zg {
  transition: color 0.3s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  background-color: #4b4b4c;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__StickerCategoryItem-1dc04zg:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--visible-gc4bxi__StickerCategoryItem-1dc04zg:hover:after {
  transition: all 0.3s ease-in;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__StickerCategoryItem-1dc04zg {
  transition: color 0.3s ease-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  z-index: 2;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  background-color: #4b4b4c;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__StickerCategoryItem-1dc04zg:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--selected-10th7ra--visible-gc4bxi__StickerCategoryItem-1dc04zg:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__StickerCategoryItem-1dc04zg {
  transition: color 0.3s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out;
  position: relative;
  display: block;
  height: 80px;
  width: 100%;
  margin-bottom: 8px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  background: #2a2a2e;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1;
  z-index: 2;
  background-color: #4b4b4c;
  background-repeat: no-repeat;
  background-size: cover;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__StickerCategoryItem-1dc04zg:after {
  transition: all 0.3s ease-out;
  content: "";
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
  border-radius: 2px;
  opacity: 0;
}
.pesdk-desktop-editor
  .pesdk-ControlItem-gxhkr8--animated-1e40zg6--selected-10th7ra--visible-gc4bxi__StickerCategoryItem-1dc04zg:hover:after {
  transition: all 0.3s ease-in;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-BackgroundImage-ugq8dv {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.pesdk-desktop-editor .pesdk-Gradient-1fxaw8w {
  position: absolute;
  height: 30px;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.5));
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx__Title-120drhm {
  opacity: 0.8;
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx--hovered-zgbg08__Title-120drhm,
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx__Title-120drhm {
  display: block;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  line-height: 13px;
  font-size: 13px;
  letter-spacing: 0.18px;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  text-align: center;
  font-family: firasansregular;
}
.pesdk-desktop-editor .pesdk-ControlItemTitle-d17ppx--hovered-zgbg08__Title-120drhm {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-StickerList-18qztam {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 25px;
  padding-left: 17px;
  text-align: left;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0__StickerListItem-m4lnou {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0__StickerListItem-m4lnou:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0__StickerListItem-m4lnou:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64__StickerListItem-m4lnou {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 55px;
  height: 55px;
  margin-left: 8px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64__StickerListItem-m4lnou:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64__StickerListItem-m4lnou:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392__StickerListItem-m4lnou {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392__StickerListItem-m4lnou:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392__StickerListItem-m4lnou:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392__StickerListItem-m4lnou {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392__StickerListItem-m4lnou:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392__StickerListItem-m4lnou:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--withText-rbroui__StickerListItem-m4lnou {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  vertical-align: top;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  display: inline-block;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--withText-rbroui__StickerListItem-m4lnou:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--withText-rbroui__StickerListItem-m4lnou:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--withText-rbroui__StickerListItem-m4lnou {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 55px;
  height: 55px;
  margin-left: 8px;
  vertical-align: top;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  display: inline-block;
  position: relative;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--withText-rbroui__StickerListItem-m4lnou:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--withText-rbroui__StickerListItem-m4lnou:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392--withText-rbroui__StickerListItem-m4lnou {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  vertical-align: top;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  display: inline-block;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392--withText-rbroui__StickerListItem-m4lnou:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392--withText-rbroui__StickerListItem-m4lnou:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__StickerListItem-m4lnou {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  vertical-align: top;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  display: inline-block;
  position: relative;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__StickerListItem-m4lnou:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__StickerListItem-m4lnou:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a__StickerListItem-m4lnou {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a__StickerListItem-m4lnou:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a__StickerListItem-m4lnou:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64__StickerListItem-m4lnou {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 55px;
  height: 55px;
  margin-left: 8px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64__StickerListItem-m4lnou:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64__StickerListItem-m4lnou:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392__StickerListItem-m4lnou {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392__StickerListItem-m4lnou:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392__StickerListItem-m4lnou:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392__StickerListItem-m4lnou {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392__StickerListItem-m4lnou:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392__StickerListItem-m4lnou:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--withText-rbroui__StickerListItem-m4lnou {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  vertical-align: top;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  display: inline-block;
  position: relative;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--withText-rbroui__StickerListItem-m4lnou:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--withText-rbroui__StickerListItem-m4lnou:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--withText-rbroui__StickerListItem-m4lnou {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 55px;
  height: 55px;
  margin-left: 8px;
  vertical-align: top;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  display: inline-block;
  position: relative;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--withText-rbroui__StickerListItem-m4lnou:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--withText-rbroui__StickerListItem-m4lnou:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392--withText-rbroui__StickerListItem-m4lnou {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  vertical-align: top;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  display: inline-block;
  position: relative;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392--withText-rbroui__StickerListItem-m4lnou:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392--withText-rbroui__StickerListItem-m4lnou:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__StickerListItem-m4lnou {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 80px;
  height: 80px;
  margin-left: 8px;
  vertical-align: top;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  display: inline-block;
  position: relative;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__StickerListItem-m4lnou:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__StickerListItem-m4lnou:last-child {
  margin-bottom: 25px;
}
.pesdk-desktop-editor .pesdk-StickerImage-1382xgm {
  background-size: contain;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 5px;
}
.pesdk-desktop-editor .pesdk-SectionListHeader-7abnmv {
  padding: 25px;
  padding-bottom: 10px;
}
.pesdk-desktop-editor .pesdk-StickerCategoryPanel-iauaz2 {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  padding-top: 0;
}
.pesdk-desktop-editor .pesdk-Header-1w3j3fe {
  display: table-row;
  z-index: 2;
  height: 70px;
  width: 100%;
  color: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
}
.pesdk-desktop-editor .pesdk-HeaderContent-3wd3qf {
  display: table-cell;
  padding-top: 20px;
}
.pesdk-desktop-editor .pesdk-Content-zb78lb {
  display: table-row;
  left: 0;
}
.pesdk-desktop-editor .pesdk-ControlItemTextStyles-1us1ll5__Title-kswtk0 {
  line-height: 13px;
  font-size: 13px;
  letter-spacing: 0.18px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.24);
  text-align: center;
  font-family: firasansregular;
  margin-bottom: 4px;
}
.pesdk-desktop-editor .pesdk-ControlItemTextStyles-1us1ll5__Subtitle-1qn5s6u {
  line-height: 13px;
  font-size: 13px;
  letter-spacing: 0.18px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.24);
  text-align: center;
  font-family: firasansregular;
  font-style: italic;
  opacity: 0.75;
}
.pesdk-desktop-editor .pesdk-ControlItemTextStyles-1us1ll5__Subtitle-1qn5s6u > * {
  font-style: normal;
  opacity: 0.5;
}
.pesdk-desktop-editor .pesdk-BackButton-1t2nim8 {
  height: 14px;
  width: 14px;
  position: absolute;
  left: 16px;
  top: 20px;
  opacity: 0.7;
  border: none;
  background: no-repeat 50%;
  background-size: contain;
  cursor: pointer;
}
.pesdk-desktop-editor .pesdk-BackButton-1t2nim8:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-Details-aqfteq {
  position: relative;
  margin: 25px 0;
  text-align: left;
  height: 72px;
}
.pesdk-desktop-editor .pesdk-ThumbnailImage-wupchq {
  position: relative;
  padding: 5px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.pesdk-desktop-editor .pesdk-TextControlsStyling-1hzgwcr {
  width: 100%;
  height: 24px;
  display: table;
  table-layout: fixed;
  margin-bottom: 16px;
}
.pesdk-desktop-editor .pesdk-Group-64aub5 {
  display: table-cell;
  vertical-align: middle;
}
.pesdk-desktop-editor .pesdk-Group-64aub5--right-9ygmmm {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
}
.pesdk-desktop-editor .pesdk-ItemContainer-tgbgl1 {
  position: relative;
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 2px;
  opacity: 0.8;
  transition: opacity 0.3 ease-out;
}
.pesdk-desktop-editor .pesdk-ItemContainer-tgbgl1:last-child {
  margin-right: 0;
}
.pesdk-desktop-editor .pesdk-ItemContainer-tgbgl1:hover {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ItemContainer-tgbgl1--selected-zgbg08 {
  position: relative;
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 2px;
  opacity: 1;
  transition: opacity 0.3 ease-in;
}
.pesdk-desktop-editor .pesdk-ItemContainer-tgbgl1--selected-zgbg08:last-child {
  margin-right: 0;
}
.pesdk-desktop-editor .pesdk-ItemContainer-tgbgl1--selected-zgbg08:hover {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ItemImage-7ni881 {
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-ItemImage-7ni881,
.pesdk-desktop-editor .pesdk-ItemImage-7ni881--visible-zgbg08 {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.2s ease-out;
  height: 24px;
  width: 24px;
  cursor: pointer;
  border: none;
}
.pesdk-desktop-editor .pesdk-ItemImage-7ni881--visible-zgbg08 {
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-ControlGroupList-1f3fuxn__SectionList-mgrj1m {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-x: visible;
  overflow-y: visible;
  width: 100%;
}
.pesdk-desktop-editor .pesdk-Details-tymb4t {
  margin-top: 25px;
}
.pesdk-desktop-editor .pesdk-Wrapper-tix0xu {
  display: inline-block;
  cursor: pointer;
  width: 217px;
  margin: 0 8px;
  vertical-align: top;
}
.pesdk-desktop-editor .pesdk-RotationSlider-6mdana {
  display: inline-block;
  position: relative;
  width: 92px;
  height: 24px;
  vertical-align: top;
  background: repeat-x 0;
  background-size: 55px 3px;
  overflow: hidden;
}
.pesdk-desktop-editor .pesdk-Indicator-nxjpo {
  position: absolute;
  top: 9px;
  width: 2px;
  height: 6px;
  background: #fff;
  z-index: 1;
}
.pesdk-desktop-editor .pesdk-Value-d97kws {
  display: inline-block;
  width: 33px;
  text-align: center;
  line-height: 24px;
  font-size: 15px;
  color: #fff;
  z-index: 2;
  font-family: firasansmedium;
}
.pesdk-desktop-editor .pesdk-OnCanvasControls-8gacw5 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-OnCanvasControls-8gacw5--draggable-ovipzq {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: move;
}
.pesdk-desktop-editor .pesdk-BottomControlsContainer-nmw377 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: auto;
  height: 48px;
  text-align: center;
}
.pesdk-desktop-editor .pesdk-BottomControls-1gk061a {
  margin: 0 auto;
  height: 24px;
  margin-top: 12px;
  vertical-align: top;
}
.pesdk-desktop-editor .pesdk-TransformContainer-1v9hd4r {
  position: absolute;
  display: table;
  top: 0;
  left: 0;
}
.pesdk-desktop-editor .pesdk-ImageContainer-15kzu1c {
  position: absolute;
}
.pesdk-desktop-editor .pesdk-TransformContainer-1v9hd4r__OuterTransformContainer-1kjfwvf {
  position: absolute;
  display: table;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease-out;
  opacity: 0;
  width: 100%;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-TransformContainer-1v9hd4r__OuterTransformContainer-1kjfwvf--visible-zgbg08 {
  position: absolute;
  display: table;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease-out;
  opacity: 1;
  width: 100%;
  height: 100%;
}
.pesdk-desktop-editor .pesdk-TransformContainer-1v9hd4r__InnerTransformContainer-120drhm {
  position: absolute;
  display: table;
  top: 0;
  left: 0;
}
.pesdk-desktop-editor .pesdk-TransformRow-15u7gri {
  display: table-row;
}
.pesdk-desktop-editor .pesdk-TransformCell-1q481v0 {
  display: table-cell;
}
.pesdk-desktop-editor .pesdk-TransformCell-1q481v0__BorderedTransformCell-1qh9j3j {
  display: table-cell;
  position: relative;
  width: 33.3%;
  height: 33.3%;
}
.pesdk-desktop-editor .pesdk-TransformCell-1q481v0__BorderedTransformCell-1qh9j3j--top-1mup05p {
  display: table-cell;
  position: relative;
  width: 33.3%;
  height: 33.3%;
  border-top: 1px solid hsla(0, 0%, 100%, 0.5);
}
.pesdk-desktop-editor .pesdk-TransformCell-1q481v0__BorderedTransformCell-1qh9j3j--right-12l7t8k {
  display: table-cell;
  position: relative;
  width: 33.3%;
  height: 33.3%;
  border-right: 1px solid hsla(0, 0%, 100%, 0.5);
}
.pesdk-desktop-editor .pesdk-TransformCell-1q481v0__BorderedTransformCell-1qh9j3j--right-12l7t8k--top-1mup05p {
  display: table-cell;
  position: relative;
  width: 33.3%;
  height: 33.3%;
  border-right: 1px solid hsla(0, 0%, 100%, 0.5);
  border-top: 1px solid hsla(0, 0%, 100%, 0.5);
}
.pesdk-desktop-editor .pesdk-TransformCell-1q481v0__BorderedTransformCell-1qh9j3j--bottom-1ebuiz6 {
  display: table-cell;
  position: relative;
  width: 33.3%;
  height: 33.3%;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
}
.pesdk-desktop-editor .pesdk-TransformCell-1q481v0__BorderedTransformCell-1qh9j3j--bottom-1ebuiz6--top-1mup05p {
  display: table-cell;
  position: relative;
  width: 33.3%;
  height: 33.3%;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
  border-top: 1px solid hsla(0, 0%, 100%, 0.5);
}
.pesdk-desktop-editor .pesdk-TransformCell-1q481v0__BorderedTransformCell-1qh9j3j--bottom-1ebuiz6--right-12l7t8k,
.pesdk-desktop-editor
  .pesdk-TransformCell-1q481v0__BorderedTransformCell-1qh9j3j--bottom-1ebuiz6--right-12l7t8k--top-1mup05p {
  display: table-cell;
  position: relative;
  width: 33.3%;
  height: 33.3%;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
  border-right: 1px solid hsla(0, 0%, 100%, 0.5);
}
.pesdk-desktop-editor
  .pesdk-TransformCell-1q481v0__BorderedTransformCell-1qh9j3j--bottom-1ebuiz6--right-12l7t8k--top-1mup05p {
  border-top: 1px solid hsla(0, 0%, 100%, 0.5);
}
.pesdk-desktop-editor .pesdk-TransformCell-1q481v0__BorderedTransformCell-1qh9j3j--left-txqhsl {
  display: table-cell;
  position: relative;
  width: 33.3%;
  height: 33.3%;
  border-left: 1px solid hsla(0, 0%, 100%, 0.5);
}
.pesdk-desktop-editor .pesdk-TransformCell-1q481v0__BorderedTransformCell-1qh9j3j--left-txqhsl--top-1mup05p {
  display: table-cell;
  position: relative;
  width: 33.3%;
  height: 33.3%;
  border-left: 1px solid hsla(0, 0%, 100%, 0.5);
  border-top: 1px solid hsla(0, 0%, 100%, 0.5);
}
.pesdk-desktop-editor .pesdk-TransformCell-1q481v0__BorderedTransformCell-1qh9j3j--left-txqhsl--right-12l7t8k,
.pesdk-desktop-editor
  .pesdk-TransformCell-1q481v0__BorderedTransformCell-1qh9j3j--left-txqhsl--right-12l7t8k--top-1mup05p {
  display: table-cell;
  position: relative;
  width: 33.3%;
  height: 33.3%;
  border-left: 1px solid hsla(0, 0%, 100%, 0.5);
  border-right: 1px solid hsla(0, 0%, 100%, 0.5);
}
.pesdk-desktop-editor
  .pesdk-TransformCell-1q481v0__BorderedTransformCell-1qh9j3j--left-txqhsl--right-12l7t8k--top-1mup05p {
  border-top: 1px solid hsla(0, 0%, 100%, 0.5);
}
.pesdk-desktop-editor .pesdk-TransformCell-1q481v0__BorderedTransformCell-1qh9j3j--bottom-1ebuiz6--left-txqhsl,
.pesdk-desktop-editor
  .pesdk-TransformCell-1q481v0__BorderedTransformCell-1qh9j3j--bottom-1ebuiz6--left-txqhsl--top-1mup05p {
  display: table-cell;
  position: relative;
  width: 33.3%;
  height: 33.3%;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
  border-left: 1px solid hsla(0, 0%, 100%, 0.5);
}
.pesdk-desktop-editor
  .pesdk-TransformCell-1q481v0__BorderedTransformCell-1qh9j3j--bottom-1ebuiz6--left-txqhsl--top-1mup05p {
  border-top: 1px solid hsla(0, 0%, 100%, 0.5);
}
.pesdk-desktop-editor
  .pesdk-TransformCell-1q481v0__BorderedTransformCell-1qh9j3j--bottom-1ebuiz6--left-txqhsl--right-12l7t8k {
  display: table-cell;
  position: relative;
  width: 33.3%;
  height: 33.3%;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
  border-left: 1px solid hsla(0, 0%, 100%, 0.5);
  border-right: 1px solid hsla(0, 0%, 100%, 0.5);
}
.pesdk-desktop-editor
  .pesdk-TransformCell-1q481v0__BorderedTransformCell-1qh9j3j--bottom-1ebuiz6--left-txqhsl--right-12l7t8k--top-1mup05p {
  display: table-cell;
  position: relative;
  width: 33.3%;
  height: 33.3%;
  border: 1px solid hsla(0, 0%, 100%, 0.5);
}
.pesdk-desktop-editor .pesdk-TransformCell-1q481v0__CenterTransformCell-52h18o {
  display: table-cell;
  position: relative;
  box-shadow: inset 0 0 0 2px hsla(0, 0%, 100%, 0.5);
  cursor: move;
}
.pesdk-desktop-editor .pesdk-TransformCell-1q481v0__CenterTransformCell-52h18o--undraggable-16s6vgr {
  display: table-cell;
  position: relative;
  box-shadow: inset 0 0 0 2px hsla(0, 0%, 100%, 0.5);
  cursor: default;
}
.pesdk-desktop-editor .pesdk-TransformCell-1q481v0__DarkTransformCell-17b4s67 {
  display: table-cell;
  background: rgba(0, 0, 0, 0.7);
}
.pesdk-desktop-editor .pesdk-Dragger-1tai30v,
.pesdk-desktop-editor .pesdk-Dragger-1tai30v--top-1stnln8 {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  z-index: 2;
}
.pesdk-desktop-editor .pesdk-Dragger-1tai30v--top-1stnln8 {
  border-top: 2px solid #fff;
  top: 0;
}
.pesdk-desktop-editor .pesdk-Dragger-1tai30v--right-1wj5vxi,
.pesdk-desktop-editor .pesdk-Dragger-1tai30v--right-1wj5vxi--top-1stnln8 {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  z-index: 2;
  border-right: 2px solid #fff;
  right: 0;
}
.pesdk-desktop-editor .pesdk-Dragger-1tai30v--right-1wj5vxi--top-1stnln8 {
  border-top: 2px solid #fff;
  top: 0;
}
.pesdk-desktop-editor .pesdk-Dragger-1tai30v--bottom-17bojqc,
.pesdk-desktop-editor .pesdk-Dragger-1tai30v--bottom-17bojqc--top-1stnln8 {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  z-index: 2;
  border-bottom: 2px solid #fff;
  bottom: 0;
}
.pesdk-desktop-editor .pesdk-Dragger-1tai30v--bottom-17bojqc--top-1stnln8 {
  border-top: 2px solid #fff;
  top: 0;
}
.pesdk-desktop-editor .pesdk-Dragger-1tai30v--bottom-17bojqc--right-1wj5vxi,
.pesdk-desktop-editor .pesdk-Dragger-1tai30v--bottom-17bojqc--right-1wj5vxi--top-1stnln8 {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  z-index: 2;
  border-bottom: 2px solid #fff;
  bottom: 0;
  border-right: 2px solid #fff;
  right: 0;
}
.pesdk-desktop-editor .pesdk-Dragger-1tai30v--bottom-17bojqc--right-1wj5vxi--top-1stnln8 {
  border-top: 2px solid #fff;
  top: 0;
}
.pesdk-desktop-editor .pesdk-Dragger-1tai30v--left-1a9ompl,
.pesdk-desktop-editor .pesdk-Dragger-1tai30v--left-1a9ompl--top-1stnln8 {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  z-index: 2;
  border-left: 2px solid #fff;
  left: 0;
}
.pesdk-desktop-editor .pesdk-Dragger-1tai30v--left-1a9ompl--top-1stnln8 {
  border-top: 2px solid #fff;
  top: 0;
}
.pesdk-desktop-editor .pesdk-Dragger-1tai30v--left-1a9ompl--right-1wj5vxi,
.pesdk-desktop-editor .pesdk-Dragger-1tai30v--left-1a9ompl--right-1wj5vxi--top-1stnln8 {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  z-index: 2;
  border-left: 2px solid #fff;
  left: 0;
  border-right: 2px solid #fff;
  right: 0;
}
.pesdk-desktop-editor .pesdk-Dragger-1tai30v--left-1a9ompl--right-1wj5vxi--top-1stnln8 {
  border-top: 2px solid #fff;
  top: 0;
}
.pesdk-desktop-editor .pesdk-Dragger-1tai30v--bottom-17bojqc--left-1a9ompl,
.pesdk-desktop-editor .pesdk-Dragger-1tai30v--bottom-17bojqc--left-1a9ompl--top-1stnln8 {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  z-index: 2;
  border-bottom: 2px solid #fff;
  bottom: 0;
  border-left: 2px solid #fff;
  left: 0;
}
.pesdk-desktop-editor .pesdk-Dragger-1tai30v--bottom-17bojqc--left-1a9ompl--top-1stnln8 {
  border-top: 2px solid #fff;
  top: 0;
}
.pesdk-desktop-editor .pesdk-Dragger-1tai30v--bottom-17bojqc--left-1a9ompl--right-1wj5vxi {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  z-index: 2;
  border-bottom: 2px solid #fff;
  bottom: 0;
  border-left: 2px solid #fff;
  left: 0;
  border-right: 2px solid #fff;
  right: 0;
}
.pesdk-desktop-editor .pesdk-Dragger-1tai30v--bottom-17bojqc--left-1a9ompl--right-1wj5vxi--top-1stnln8 {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  z-index: 2;
  bottom: 0;
  border: 2px solid #fff;
  left: 0;
  right: 0;
  top: 0;
}
.pesdk-desktop-editor .pesdk-Button-127b6wl {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0 8px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: no-repeat 50%;
  background-size: contain;
}
.pesdk-desktop-editor .pesdk-Button-127b6wl:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-Button-127b6wl__FlipButton-120drhm {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0 8px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: no-repeat 50%;
  background-size: contain;
}
.pesdk-desktop-editor .pesdk-Button-127b6wl__FlipButton-120drhm:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-Button-127b6wl__FlipButton-120drhm--vertical-12fqiy2 {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0 8px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: no-repeat 50%;
  background-size: contain;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}
.pesdk-desktop-editor .pesdk-Button-127b6wl__FlipButton-120drhm--vertical-12fqiy2:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-Button-127b6wl__RotateButton-120drhm {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0 8px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: no-repeat 50%;
  background-size: contain;
}
.pesdk-desktop-editor .pesdk-Button-127b6wl__RotateButton-120drhm:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-Button-127b6wl__RotateButton-120drhm--clockwise-o7ss47 {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0 8px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: no-repeat 50%;
  background-size: contain;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}
.pesdk-desktop-editor .pesdk-Button-127b6wl__RotateButton-120drhm--clockwise-o7ss47:focus {
  outline: none;
}
.pesdk-desktop-editor .pesdk-AcceptButton-sbdqjz {
  transition: backgroundColor 0.3s ease-out;
  background: no-repeat 50%;
  background-color: #888;
  background-size: 18px 17px;
}
.pesdk-desktop-editor .pesdk-AcceptButton-sbdqjz,
.pesdk-desktop-editor .pesdk-AcceptButton-sbdqjz--enabled-ro0g1e {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 6px 0 rgba(0, 0, 0, 0.3), 0 6px 8px 0 rgba(0, 0, 0, 0.3),
    0 8px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 5px;
  left: 50%;
  margin-left: -24px;
  z-index: 6;
}
.pesdk-desktop-editor .pesdk-AcceptButton-sbdqjz--enabled-ro0g1e {
  transition: backgroundColor 0.3s ease-in;
  background: no-repeat 50%;
  background-color: #2d7bff;
  background-size: 18px 17px;
  cursor: pointer;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0__TransformListItem-71xi0n {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  position: relative;
  display: inline-block;
  width: 94px;
  height: 104px;
  margin-right: 0;
  list-style: none;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0__TransformListItem-71xi0n:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0__TransformListItem-71xi0n:nth-child(odd) {
  margin-right: 8px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64__TransformListItem-71xi0n {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 94px;
  height: 104px;
  margin-left: 8px;
  position: relative;
  display: inline-block;
  margin-right: 0;
  list-style: none;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64__TransformListItem-71xi0n:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64__TransformListItem-71xi0n:nth-child(odd) {
  margin-right: 8px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392__TransformListItem-71xi0n {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 94px;
  height: 104px;
  margin-left: 8px;
  position: relative;
  display: inline-block;
  margin-right: 0;
  list-style: none;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392__TransformListItem-71xi0n:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392__TransformListItem-71xi0n:nth-child(odd) {
  margin-right: 8px;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392__TransformListItem-71xi0n {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 94px;
  height: 104px;
  margin-left: 8px;
  position: relative;
  display: inline-block;
  margin-right: 0;
  list-style: none;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392__TransformListItem-71xi0n:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392__TransformListItem-71xi0n:nth-child(odd) {
  margin-right: 8px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--withText-rbroui__TransformListItem-71xi0n {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: relative;
  display: inline-block;
  width: 94px;
  height: 104px;
  margin-right: 0;
  list-style: none;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--withText-rbroui__TransformListItem-71xi0n:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--withText-rbroui__TransformListItem-71xi0n:nth-child(odd) {
  margin-right: 8px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--withText-rbroui__TransformListItem-71xi0n {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 94px;
  height: 104px;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: relative;
  display: inline-block;
  margin-right: 0;
  list-style: none;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--withText-rbroui__TransformListItem-71xi0n:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--withText-rbroui__TransformListItem-71xi0n:nth-child(odd) {
  margin-right: 8px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392--withText-rbroui__TransformListItem-71xi0n {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 94px;
  height: 104px;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: relative;
  display: inline-block;
  margin-right: 0;
  list-style: none;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392--withText-rbroui__TransformListItem-71xi0n:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--twoColumns-4nk392--withText-rbroui__TransformListItem-71xi0n:nth-child(odd) {
  margin-right: 8px;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__TransformListItem-71xi0n {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: hsla(0, 0%, 100%, 0.04);
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  color: #fff;
  width: 94px;
  height: 104px;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: relative;
  display: inline-block;
  margin-right: 0;
  list-style: none;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__TransformListItem-71xi0n:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__TransformListItem-71xi0n:nth-child(odd) {
  margin-right: 8px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a__TransformListItem-71xi0n {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  position: relative;
  display: inline-block;
  width: 94px;
  height: 104px;
  margin-right: 0;
  list-style: none;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a__TransformListItem-71xi0n:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a__TransformListItem-71xi0n:nth-child(odd) {
  margin-right: 8px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64__TransformListItem-71xi0n {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 94px;
  height: 104px;
  margin-left: 8px;
  position: relative;
  display: inline-block;
  margin-right: 0;
  list-style: none;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64__TransformListItem-71xi0n:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64__TransformListItem-71xi0n:nth-child(odd) {
  margin-right: 8px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392__TransformListItem-71xi0n {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 94px;
  height: 104px;
  margin-left: 8px;
  position: relative;
  display: inline-block;
  margin-right: 0;
  list-style: none;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392__TransformListItem-71xi0n:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392__TransformListItem-71xi0n:nth-child(odd) {
  margin-right: 8px;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392__TransformListItem-71xi0n {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 94px;
  height: 104px;
  margin-left: 8px;
  position: relative;
  display: inline-block;
  margin-right: 0;
  list-style: none;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392__TransformListItem-71xi0n:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392__TransformListItem-71xi0n:nth-child(odd) {
  margin-right: 8px;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--withText-rbroui__TransformListItem-71xi0n {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: relative;
  display: inline-block;
  width: 94px;
  height: 104px;
  margin-right: 0;
  list-style: none;
}
.pesdk-desktop-editor .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--withText-rbroui__TransformListItem-71xi0n:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--withText-rbroui__TransformListItem-71xi0n:nth-child(odd) {
  margin-right: 8px;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--withText-rbroui__TransformListItem-71xi0n {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 94px;
  height: 104px;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: relative;
  display: inline-block;
  margin-right: 0;
  list-style: none;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--withText-rbroui__TransformListItem-71xi0n:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--withText-rbroui__TransformListItem-71xi0n:nth-child(odd) {
  margin-right: 8px;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392--withText-rbroui__TransformListItem-71xi0n {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 94px;
  height: 104px;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: relative;
  display: inline-block;
  margin-right: 0;
  list-style: none;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392--withText-rbroui__TransformListItem-71xi0n:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--twoColumns-4nk392--withText-rbroui__TransformListItem-71xi0n:nth-child(odd) {
  margin-right: 8px;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__TransformListItem-71xi0n {
  opacity: 1;
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  background: rgba(45, 123, 255, 0.14);
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  color: #fff;
  width: 94px;
  height: 104px;
  margin-left: 8px;
  vertical-align: center;
  font-family: firasansregular;
  font-size: 14px;
  padding-top: 32px;
  position: relative;
  display: inline-block;
  margin-right: 0;
  list-style: none;
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__TransformListItem-71xi0n:hover {
  border: 1px solid #2d7bff;
  transition: all 0.3s ease-in;
  background: rgba(45, 123, 255, 0.14);
}
.pesdk-desktop-editor
  .pesdk-PlainControlItem-9ej9h0--selected-eb0k9a--threeColumns-14meo64--twoColumns-4nk392--withText-rbroui__TransformListItem-71xi0n:nth-child(odd) {
  margin-right: 8px;
}
.pesdk-desktop-editor .pesdk-Icon-u0ryhr {
  transition: opacity 0.3s ease-out;
  display: none;
}
.pesdk-desktop-editor .pesdk-Icon-u0ryhr,
.pesdk-desktop-editor .pesdk-Icon-u0ryhr--rendered-2jlos {
  position: absolute;
  top: 0;
  left: 0;
  background: no-repeat 50%;
  background-size: contain;
  z-index: 2;
  width: 100%;
  height: 104px;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-Icon-u0ryhr--rendered-2jlos {
  transition: opacity 0.3s ease-in;
  display: block;
}
.pesdk-desktop-editor .pesdk-Icon-u0ryhr--visible-82svov {
  transition: opacity 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-Icon-u0ryhr--rendered-2jlos--visible-82svov,
.pesdk-desktop-editor .pesdk-Icon-u0ryhr--visible-82svov {
  position: absolute;
  top: 0;
  left: 0;
  background: no-repeat 50%;
  background-size: contain;
  z-index: 2;
  width: 100%;
  height: 104px;
  display: block;
  opacity: 1;
}
.pesdk-desktop-editor .pesdk-Icon-u0ryhr--rendered-2jlos--visible-82svov {
  transition: opacity 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-Title-jpjwq7 {
  z-index: 3;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 8px;
  color: #fff;
  opacity: 0.4;
  font-size: 13px;
  font-family: firasansregular;
}
.pesdk-desktop-editor .pesdk-Title-jpjwq7--selected-zgbg08 {
  z-index: 3;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 8px;
  color: #fff;
  opacity: 1;
  font-size: 13px;
  font-family: firasansmedium;
}
.pesdk-desktop-editor .pesdk-Container-nfd1oy__DimensionInput-cw1txx {
  display: block;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  padding: 4px 12px;
  cursor: pointer;
}
.pesdk-desktop-editor .pesdk-Cross-1dew3s1 {
  width: 8px;
  height: 8px;
}
.pesdk-desktop-editor .pesdk-TransformDimensions-1yz821h {
  display: table-row;
  width: 100%;
  height: 56px;
  background: #2a2a2e;
  border-top: 1px solid #141415;
  z-index: 6;
  text-align: center;
}
.pesdk-desktop-editor .pesdk-TransformDimensionsWrapper-1po10b5 {
  display: table-cell;
  padding: 12px 20px;
  position: relative;
}
.pesdk-desktop-editor .pesdk-Table-wlk9p6 {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.pesdk-desktop-editor .pesdk-Cell-1q481v0 {
  display: table-cell;
}
.pesdk-desktop-editor .pesdk-Cell-1q481v0__CrossCell-13lk3i9 {
  display: table-cell;
  text-align: center;
  width: 24px;
}
.pesdk-desktop-editor .pesdk-LockOverlay-943mx5 {
  display: none;
}
.pesdk-desktop-editor .pesdk-LockOverlay-943mx5,
.pesdk-desktop-editor .pesdk-LockOverlay-943mx5--rendered-2jlos {
  transition: opacity 0.3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2a2a2e;
  opacity: 0;
}
.pesdk-desktop-editor .pesdk-LockOverlay-943mx5--rendered-2jlos {
  display: block;
}
.pesdk-desktop-editor .pesdk-LockOverlay-943mx5--visible-rval4i {
  transition: opacity 0.3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2a2a2e;
  opacity: 0.8;
  display: none;
}
.pesdk-desktop-editor .pesdk-LockOverlay-943mx5--rendered-2jlos--visible-rval4i {
  transition: opacity 0.3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2a2a2e;
  opacity: 0.8;
  display: block;
}
.pesdk-desktop-editor .pesdk-Lock-k1z0lp {
  width: 24px;
  margin-top: 32px;
}
.pesdk-desktop-editor .pesdk-ControlGroupTitle-12fzsea__SectionTitle-t3ptdc {
  margin-bottom: 8px;
  font-size: 13px;
  font-family: sourcesansprosemibold;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  text-align: left;
  color: #888;
  padding: 0 5px;
}
.pesdk-desktop-editor .pesdk-ControlGroup-1bxndxk__Section-ber9o0 {
  padding: 25px 17px;
  padding-bottom: 5px;
}
.pesdk-desktop-editor .pesdk-ControlGroupTitle-12fzsea__SectionTitle-15ebqhq {
  margin-bottom: 16px;
  font-size: 13px;
  font-family: sourcesansprosemibold;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  text-align: left;
  color: #888;
  margin: 0 8px 16px;
}
.pesdk-desktop-editor .pesdk-SectionGrid-1klgjyq {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}
.pesdk-desktop-editor .pesdk-Container-w4knew {
  position: relative;
  height: 100%;
  width: 100%;
  display: table;
}
.pesdk-desktop-editor .pesdk-Image-95qw31 {
  width: 80px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.pesdk-desktop-editor .pesdk-List-13wctlp {
  position: absolute;
  top: 0;
  left: 25px;
  bottom: 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 0;
}
.pesdk-desktop-editor .pesdk-ListItem-18c5asb {
  position: relative;
  display: inline-block;
  width: 88px;
  height: 88px;
  margin-right: 0;
  margin-bottom: 8px;
  margin-top: 0;
  list-style: none;
  background: hsla(0, 0%, 100%, 0.015);
  border-radius: 1px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-ListItem-18c5asb:nth-child(odd) {
  margin-right: 8px;
}
.pesdk-desktop-editor .pesdk-ListItem-18c5asb:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-ListItem-18c5asb--selected-1eawvu9 {
  position: relative;
  display: inline-block;
  width: 88px;
  height: 88px;
  margin-right: 0;
  margin-bottom: 8px;
  margin-top: 0;
  list-style: none;
  background: rgba(27, 112, 255, 0.14);
  border-radius: 1px;
  cursor: pointer;
  border: 1px solid #1b77ff;
  transition: all 0.3s ease-out;
}
.pesdk-desktop-editor .pesdk-ListItem-18c5asb--selected-1eawvu9:nth-child(odd) {
  margin-right: 8px;
}
.pesdk-desktop-editor .pesdk-ListItem-18c5asb--selected-1eawvu9:hover {
  border: 1px solid hsla(0, 0%, 100%, 0.08);
  transition: all 0.3s ease-in;
}
.pesdk-desktop-editor .pesdk-ControlGroup-1bxndxk__Settings-1lqw49n {
  padding: 21px;
  padding-bottom: 8px;
  border-bottom: 1px solid #1b1b1e;
}
.pesdk-desktop-editor .pesdk-ControlGroup-1bxndxk__SettingsBottom-qf5p21 {
  padding: 21px;
  padding-bottom: 5px;
  position: absolute;
  top: 155px;
  bottom: 0;
  left: 0;
  width: 100%;
}
.pesdk-desktop-editor .pesdk-ColorSelection-10jnb6m {
  padding-left: 6px;
  margin-top: 25px;
  margin-bottom: 16px;
}

/* stylelint-enable */
