/*
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.context-menu-container {
  position: relative;
}
.context-menu-icon {
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
}

.context-menu-box {
  position: absolute;
  bottom: -0.5rem;
  transform: translateY(100%);
  background-color: var(--white);
  box-shadow: var(--box-shadow-m);
  z-index: var(--z1000);
  border-radius: 0.4rem;
  padding: 0.8rem 0;
}
[dir="ltr"] .context-menu-container.is-aligned-left .context-menu-box {
  left: 1rem;
}

[dir="rtl"] .context-menu-container.is-aligned-left .context-menu-box {
  right: 1rem;
}

[dir="ltr"] .context-menu-container.is-aligned-right .context-menu-box {
  right: 1rem;
}

[dir="rtl"] .context-menu-container.is-aligned-right .context-menu-box {
  left: 1rem;
}
