/*
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.avatar {
  display: grid;
  grid-template-columns: 32px auto;
  grid-template-rows: auto auto;
  grid-gap: 0 16px;
  align-items: center;
}

.avatar__image-container {
  width: 3.2rem;
  height: 3.2rem;
  overflow: hidden;
  border-radius: 50%;
  background-color: var(--brand-3);
  grid-row: span 2;
}

.avatar__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar__publisher,
.avatar__name {
  font-size: 1.2rem;
}

.avatar__publisher {
  font-weight: bold;
}

.avatar__name {
  color: var(--mono-4);
}

.avatar-container {
  display: flex;
  position: relative;
  cursor: pointer;
}

.profile-details {
  position: absolute;
  top: 4rem;
  left: 0;
  background-color: var(--white);
  border-radius: 0.4rem;
  min-width: 15rem;
  box-shadow: 0 3px 8px 0 rgba(19, 36, 55, 0.1);
}

.profile-actions {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.profile-action-item {
  transition: background-color 0.2s;
  background-color: var(--white);
  cursor: pointer;
}

.profile-action-item:hover {
  background-color: var(--mono-2);
}

.profile-action-link {
  display: block;
  padding: 1rem 1.6rem;
  font-size: var(--fs-s);
  font-weight: var(--fw-normal);
  line-height: var(--lh-5);
  text-decoration: none;
  min-height: 4rem;
  color: var(--mono-5);
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.question-circle {
  display: flex;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
}
