.section-item {
  font-family: var(--brandType);
  font-size: var(--fs-m);
  line-height: var(--lh-4);
  color: var(--mono-5);
  text-decoration: none;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 3.2rem;
  cursor: pointer;
}

.section-item.is-deleted {
  color: var(--mono-3);
}

.sections-list-item {
  background-color: var(--white);
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-m);
}

.section-item-row-info {
  display: flex;
  align-items: center;
}

.section-color-container {
  display: flex;
  align-items: center;
}

.disabled-section-info {
  margin: 0 20px;
}

.disabled-section-info span {
  text-transform: lowercase;
}

[dir="ltr"] .section-color-container {
  margin-right: 0.8rem;
}

[dir="rtl"] .section-color-container {
  margin-left: 0.8rem;
}

[dir="ltr"] .sections-menu {
  left: auto !important;
  right: 10rem !important;
}

[dir="rtl"] .sections-menu {
  right: auto !important;
  left: 10rem !important;
}

[dir="ltr"] :global .section-list-nested-items-list {
  padding: 0 0 0 3.2rem;
}

[dir="rtl"] :global .section-list-nested-items-list {
  padding: 0 3.2rem 0 0;
}
