.collection-filters-buttons {
  margin: 1.6rem 0;
  display: flex;
  justify-content: flex-end;
}

[dir="ltr"] .collection-filters-action-button {
  margin: 0 0 0 1.6rem;
}

[dir="rtl"] .collection-filters-action-button {
  margin: 0 1.6rem 0 0;
}
