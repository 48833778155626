.collection-lane {
  flex-grow: 1;
  flex-basis: 50%;
}

[dir="ltr"] .collection-lane {
  margin-right: 1.6rem;
}

[dir="rtl"] .collection-lane {
  margin-left: 1.6rem;
}

[dir="ltr"] .collection-lane:last-child {
  margin-right: 0;
}

[dir="rtl"] .collection-lane:last-child {
  margin-left: 0;
}

.collection-search-actions {
  display: flex;
  align-items: center;
}

.collection-search-bar {
  flex-grow: 1;
}

[dir="ltr"] .collection-search-bar {
  margin-right: 0.8rem;
}

[dir="rtl"] .collection-search-bar {
  margin-left: 0.8rem;
}

.collections-toggle-advanced-search {
  cursor: pointer;
}

.collection-content-list-tabs {
  padding-top: 1.6rem;
}

.collection-content-switch-container {
  display: flex;
  margin-top: 1.2rem;
  font-size: var(--fs-xs);
  justify-content: flex-end;
}

[dir="rtl"] .collection-content-switch-label {
  padding-left: 0.8rem;
}

[dir="ltr"] .collection-content-switch-label {
  padding-right: 0.8rem;
}

.collection-content-refresh {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}
