.sponsored-content-container {
  height: 20px;
  margin-bottom: 1.6rem;
  transition: all 0.5s;
}

.sponsored-content-container .sponsored-by {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s;
  margin-top: 0.8rem;
}

.sponsored-content-container.is-sponsored-content {
  height: 90px;
  margin-bottom: 0.8rem;
}

.sponsored-content-container .sponsored-by.is-open {
  visibility: visible;
  opacity: 1;
  transition: all 0.5s;
}

.sponsored-content-container .checkbox-container label[for="sponsored-content"] {
  text-transform: none;
}
