.problem {
  display: none;
}

.problem-suggestions {
  display: inline;
  position: relative;
}

.dropdown {
  position: absolute;
  top: 8px;
  height: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  overflow: hidden;
}
.problem-suggestions:hover .dropdown {
  opacity: 1;
  height: auto;
  overflow: initial;
}
