:global .story-element-text {
  font-family: var(--brandType);
  font-size: var(--fs-xl);
  line-height: var(--lh-5);
  color: var(--mono-4);
  white-space: pre-wrap;
}

:global .story-element-text:focus {
  outline: none;
}
