:global .integrations-progress-main-area {
  margin: auto;
  padding: 0px;
  height: calc(100vh - var(--header-height) - 6.4rem);
  display: flex;
  justify-content: center;
}

.settings-integrations-page-container {
  transition: transform 0.5s;
}

.integrations-tabs {
  margin-bottom: 2.4rem;
}

.integrations-push-notification-settings {
  margin-right: 1.6rem;
}