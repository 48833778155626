.collection-headline {
  margin: 0 0 var(--verticalRhythm) 0;
  color: var(--mono-5);
  font-size: var(--fs-l);
  font-weight: var(--fw-normal);
  line-height: var(--lh-4);
}

.collection-headline.threshold-reached {
  color: var(--danger);
}
