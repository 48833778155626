.taskmanager-login-container {
  padding: 1.6rem;
  border-radius: 0.5rem;
  background-color: var(--mono-2);
}

.taskmanager-login-no-content-message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.taskmanager-login-no-content-message .taskmanager-login-message {
  margin-bottom: 0.4rem;
}

.taskmanager-login-no-content-message svg {
  margin-bottom: 0.8rem;
}

.taskmanager-login-no-content-message .taskmanager-connect-now-link {
  font-family: var(--brandType);
  font-size: var(--fs-s);
  line-height: var(--lh-4);
  color: var(--brand-3);
  background: none;
  border: none;
  padding: 0;
  text-decoration: none;
}
