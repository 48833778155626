/*
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
@value viewports: "../../../viewports.css";
@value desktop from viewports;

.settings-section-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.6rem;
  background-color: var(--white);
  padding: 1.6rem;
}

@media (min-width: desktop) {
  .settings-section-container {
    padding: 1.6rem 6.4rem;
    grid-template-columns: 1fr 2fr;
  }
}

.title {
  text-transform: capitalize;
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
  color: var(--mono-5);
  margin: 0 0 0.8rem 0;
}

.subtitle {
  display: none;
}

@media (min-width: desktop) {
  .subtitle {
    display: block;
    grid-column: 1/2;
    font-family: var(--brandType);
    font-size: var(--fs-s);
    line-height: var(--lh-4);
    color: var(--mono-4);
  }
}
