/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.photoeditor-container {
  position: fixed;
  z-index: var(--photoEditor);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.photoeditor {
  height: calc(100% - 32px);
  width: calc(100% - 32px);
}
