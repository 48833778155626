/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.context-menu-list-item {
  padding: 1rem 1.6rem;
  font-size: var(--fs-s);
  min-width: 14rem;
  white-space: nowrap;
  color: var(--mono-5);
  font-weight: var(--fw-normal);
}

.context-menu-list-item:not(.is-disabled):hover {
  cursor: pointer;
  background-color: var(--mono-2);
}

.context-menu-list-item.is-disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.context-menu-list-item.is-danger {
  color: var(--danger);
}
