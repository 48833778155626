.meta-description {
    position: relative;
}

.meta-description .meta-description__ai-generate-btn {
    position: absolute;
    right: 0;
    top: -0.2rem;
    padding: var(--space-xxxs)
}

[dir="rtl"] .meta-description .meta-description__ai-generate-btn {
    left: 0;
    right: unset;
}

.meta-description--show-ai-generate-btn > div > div > div > div > div > span {
    margin-right: 4rem;
}

[dir="rtl"] .meta-description--show-ai-generate-btn > div > div > div > div > div > span {
    margin-left: 4rem;
}