.story-element-container {
  display: grid;
  grid-template-columns: 60px 1fr;
  margin-bottom: 2.4rem;
  position: relative;
}

.story-element-left-pan {
  border-right: 1px solid var(--mono-3);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.2rem;
  position: relative;
}
