/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.dropdown {
  box-shadow: var(--box-shadow-s);
  background-color: var(--white);
  list-style: none;
  width: 16rem;
  position: absolute;
  padding: 0;
  z-index: var(--z1000);
}

.dropdown-item {
  font-size: var(--fs-m);
  padding: 0.8rem 1.6rem;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: var(--mono-2);
}
