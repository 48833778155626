.amp-config {
  display: grid;
  grid-column-gap: 1.6rem;
  grid-template-columns: 1fr;
  background-color: var(--white);
  padding: 1.6rem;
  margin-bottom: 3.2rem;
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
}

@media (min-width: 786px) {
  .amp-config {
    padding: 3.2rem 6.4rem;
    grid-template-columns: 1fr 2fr;
  }
}

.amp-config-title {
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
  line-height: var(--lh-3);
  color: var(--mono-5);
  margin: 0 0 0.8rem 0;
}
.amp-config-description {
  font-size: var(--fs-m);
  line-height: var(--lh-4);
  color: var(--mono-4);
}

:global .amp-config-progress-main-area {
  margin: auto;
  padding: 0px;
  height: calc(100vh - var(--header-height) - 6.4rem);
  display: flex;
  justify-content: center;
}

.amp-config-page-container {
  transition: transform 0.5s;
}
