.summary {
    position: relative;
}

.summary .summary__ai-generate-btn {
    position: absolute;
    right: 0;
    top: -0.2rem;
    padding: var(--space-xxxs)
}

[dir="rtl"] .summary .summary__ai-generate-btn {
    left: -0.2rem;
    right: unset;
}

.summary--show-ai-generate-btn > div > div > div > div > div > span {
    margin-right: 4rem;
}

[dir="rtl"] .summary--show-ai-generate-btn > div > div > div > div > div > span {
    margin-left: 4rem;
}