/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

@value viewports: "../../viewports.css";
@value desktop from viewports;

.page-wrapper {
  position: relative;
  display: flex;
  margin: var(--header-height) 0 0 0;
  overflow-x: hidden;
}

.page-wrapper.with-banner {
  margin: 13rem 0 0 0;
}

.page-wrapper.inside-slider {
  margin-top: 0;
}

.page-contents-container {
  flex-grow: 1;
  padding: 1.6rem 0.8rem 0 0.8rem;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  min-height: calc(100% - var(--header-height));
}

.page-contents {
  flex-grow: 1;
  max-width: 100%;
}

.page-contents-container.story-page-contents {
  padding: 0;
}

.story-page-wrapper {
  padding: 0;
}

.story-page-wrapper.with-banner {
  margin: calc(var(--header-height) + var(--banner-height-mobile)) 0 0 0 !important;
}

:global .zEWidget-launcher {
  display: none !important;
}

:global .verloop-button, :global .verloop-container {
  display: none !important;
}

@media (min-width: desktop) {
  :global [dir="rtl"] .verloop-button, :global [dir="rtl"] .verloop-container {
    display: block !important;
    position: fixed;
    bottom: 2.4rem;
    left: 1.6rem;
    margin-left: 0.8rem;
    right: unset;
  }

  :global [dir="ltr"] .verloop-button, :global [dir="ltr"] .verloop-container {
    display: block !important;
    position: fixed;
    bottom: 2.4rem;
    right: 1.6rem;
    margin-right: 0.8rem;
  }

  .page-wrapper {
    overflow-x: unset;
  }

  [dir="ltr"] .page-wrapper {
    margin: var(--header-height) 0 0 var(--navbar-width);
  }

  [dir="rtl"] .page-wrapper {
    margin: var(--header-height) var(--navbar-width) 0 0;
  }

  [dir="ltr"] .page-wrapper.with-banner {
    margin: 13rem 0 0 var(--navbar-width);
  }

  [dir="rtl"] .page-wrapper.with-banner {
    margin: 13rem var(--navbar-width) 0 0;
  }

  [dir="ltr"] .story-page-wrapper {
    margin: var(--header-height) 0 0 var(--navbar-width) !important;
  }

  [dir="rtl"] .story-page-wrapper {
    margin: var(--header-height) var(--navbar-width) 0 0 !important;
  }

  [dir="ltr"] .story-page-wrapper.with-banner {
    margin: calc(var(--header-height) + var(--banner-height)) 0 0 var(--navbar-width) !important;
  }

  [dir="rtl"] .story-page-wrapper.with-banner {
    margin: calc(var(--header-height) + var(--banner-height)) var(--navbar-width) 0 0 !important;
  }

  .page-contents-container {
    padding: 3.2rem 4.8rem 0 4.8rem;
    overflow-x: auto;
  }

  .page-contents {
    max-width: min(100%, 112.8rem);
  }

  .page-contents.story-page-contents {
    max-width: none;
  }

  :global .zEWidget-launcher {
    display: unset !important;
  }
}
