/*
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.has-no-content {
  min-height: 16rem;
  display: flex;
  flex-direction: column;
  font-size: var(--fs-s);
  line-height: var(--lh-4);
  color: var(--mono-4);
  align-items: center;
  justify-content: center;
}

.has-no-content .no-content-message {
  margin-top: 0.8rem;
}

.has-no-content .retry {
  color: var(--brand-3);
  cursor: pointer;
}
