.attachment-container {
  position: relative;
  background-color: var(--mono-2);
  padding: 3.2rem 0 0.4rem 0;
  color: var(--mono-4);
}

.file-field {
  width: 0.1px;
  height: 0.1px;
  overflow: hidden;
  position: absolute;
  z-index: var(--z1);
}

.attachment-label-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.attachment-input-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--fs-s);
}

.attachment-container label {
  cursor: pointer;
  padding-bottom: 0.8rem;
}

.info {
  font-size: var(--fs-xs);
}

.attachment-details {
  display: flex;
  justify-items: center;
  font-size: var(--fs-s);
  padding: 1.6rem 0 3.2rem 0;
}

.attachment-details a {
  text-decoration: none;
  color: var(--mono-5);
  font-weight: var(--fw-bold);
}

[dir="ltr"] .attachment-data {
  padding-left: 1rem;
}

[dir="ltr"] .attachment-data-edit {
  padding-left: 1.6rem;
}

[dir="rtl"] .attachment-data {
  padding-right: 1rem;
}

[dir="rtl"] .attachment-data-edit {
  padding-right: 1.6rem;
}

.is-disabled {
  pointer-events: none;
}
