.custom-metadata-no-content {
  margin-top: 3.6rem;
  padding: 1.6rem;
  border-radius: 0.5rem;
  background-color: var(--mono-2);
}

.custom-metadata-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
  padding: 1.2rem 3.2rem;
  cursor: pointer;
}

.custom-metadata-row-info {
  display: flex;
  width: 25%;
  flex-direction: column;
  align-items: baseline;
}

.custom-metadata-row-actions {
  display: flex;
  align-items: center;
}

.custom-metadata-row-actions button {
  margin-right: 1.6rem;
  padding: 0.8rem 0.4rem;
}

.custom-metadata-row-actions svg {
  color: var(--black);
}

.custom-metadata-row-actions .button--disabled svg {
  color: var(--mono-3);
}

.custom-metadata-list {
  margin: 0;
  padding: 1.6rem 0;
  list-style: none;
  display: grid;
  grid-row-gap: 0.8rem;
  grid-template-columns: 1fr;
}
