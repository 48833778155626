.push-notification-header {
  display: flex;
  margin-bottom: 2.4rem;
}

.push-notification-search {
  flex-grow: 1;
  margin: 0 0.8rem;
}

.push-notification-title {
  font-size: var(--fs-xxl);
  font-weight: var(--fw-bold);
  flex-grow: 1;
}

[dir="ltr"] .push-notification-action-button {
  margin-left: 1.6rem;
}

[dir="rtl"] .push-notification-action-button {
  margin-left: 1.6rem;
}

.push-notification-list-container .tab-list {
  margin: 0 0.8rem;
}

.push-notification-page-title {
  font-size: var(--fs-xxl);
  font-weight: var(--fw-bold);
  margin: 0 0 2.4rem 0;
}

.push-notification-progress-main-area {
  margin: auto;
  padding: 0px;
  height: calc(100vh - var(--header-height) - 6.4rem);
  display: flex;
  justify-content: center;
}
