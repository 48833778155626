.sections-no-content {
  margin-top: 3.6rem;
  padding: 1.6rem;
  border-radius: 0.5rem;
  background-color: var(--mono-2);
}

.sections-list {
  margin: 0;
  padding: 1.6rem;
  list-style: none;
  background-color: var(--brand-1);
  display: grid;
  grid-row-gap: 0.8rem;
  grid-template-columns: 1fr;
}

.section-list-no-content-message {
  margin-top: 0.8rem;
}
