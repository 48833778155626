@value viewports: "../../../viewports.css";
@value desktop from viewports;

.story-review-container {
  z-index: var(--z100);
}

@media (min-width: desktop) {
  .story-review-container {
    width: 50rem;
    z-index: unset;
  }
}

.story-review-title {
  margin-bottom: 2.7rem;
  font-family: var(--brandType);
  font-size: var(--fs-xxl);
  font-weight: var(--fw-bold);
  line-height: var(--lh-3);
  color: var(--mono-3);
}

.story-review-title .text-area-error-message {
  color: var(--danger);
  margin-top: 0.4rem;
  min-height: 1.6rem;
  font-size: var(--fs-m);
  font-weight: var(--fw-normal);
}
