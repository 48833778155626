.story-settings-spinner {
  margin-top: 8rem;
}

.text-to-speech-checkbox-container {
  display: flex;
  align-items: center;
}

.text-to-speech-checkbox-label {
  font-size: var(--fs-s);
  font-weight: var(--fw-normal);
  cursor: pointer;
}
