.tags-wrapper {
  padding-bottom: 5rem;
}

.tags-header {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}

.tags-title {
  font-size: var(--fs-xxl);
  font-weight: var(--fw-bold);
  flex-grow: 1;
  margin: 0;
}

[dir="ltr"] .tags-action-button {
  margin-left: 1.6rem;
}

[dir="rtl"] .tags-action-button {
  margin-left: 1.6rem;
}

.tags-delete-action-button {
  margin-top: 1.6rem;
}

[dir="ltr"] .tags-delete-action-button {
  text-align: right;
}

[dir="rtl"] .tags-delete-action-button {
  text-align: left;
}

.tags-table-container {
  background-color: var(--white);
  padding: 3.2rem;
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
}

.tags-table .rt-table {
  margin-top: 4rem;
}

.tags-table .-header {
  background-color: var(--mono-2);
  font-weight: var(--fw-bold);
  font-size: var(--fs-s);
  height: 4rem;
  display: flex;
  align-items: center;
  padding: 0 1.6rem;
}

.tags-table .rt-tbody .rt-tr {
  font-size: var(--fs-s);
  padding: 0 1.6rem;
  display: flex;
  align-items: center;
  min-height: 4.8rem;
  border-bottom: 1px solid var(--mono-2);
}

[dir="ltr"] .tags-table .rt-thead .rt-tr {
  text-align: left;
  display: flex;
}

[dir="rtl"] .tags-table .rt-thead .rt-tr {
  text-align: right;
  display: flex;
}

.tags-table .rt-thead .rt-tr:last-child {
  flex-grow: 1;
}

.tags-table .rt-tbody {
  cursor: pointer;
}

.tags-table-container .pagination-top {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
}

.tags-table-container .pagination-top .-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tags-table-container .pagination-top .-pagination .-center {
  width: 9rem;
  margin: 0 1rem;
}

.tags-table-container .pagination-top .-pagination .-prevButton,
.tags-table-container .pagination-top .-pagination .-nextButton {
  border: 1px solid var(--mono-3);
  padding: 0.4rem;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.tags-table-container .pagination-top .-center .-pageInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--fs-s);
}

.tags-table-container .pagination-top .-center .-pageInfo .-pageJumpInput {
  max-width: 4rem;
  height: 100%;
  text-align: center;
}

.tags-table-container .pagination-top .-pagination .-totalCount {
  display: block;
  font-size: var(--fs-s);
}

[dir="ltr"] .tags-table-container .pagination-top .-pagination .-totalCount {
  margin-right: 0.8rem;
}

[dir="rtl"] .tags-table-container .pagination-top .-pagination .-totalCount {
  margin-left: 0.8rem;
}

.tags-edit-action {
  cursor: pointer;
  text-align: right;
  color: var(--brand-3);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}

[dir="ltr"] .tags-edit-action {
  margin-right: 3.2rem;
}

[dir="rtl"] .tags-edit-action {
  margin-left: 3.2rem;
}

.tags-table .rt-tr-group:hover {
  background-color: var(--mono-2);
}

.tags-table .rt-tr-group:hover .tags-edit-action {
  opacity: 1;
  visibility: visible;
}

.tags-table .rt-tr .rt-td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: var(--lh-5);
}

.entity-template-field {
  margin-bottom: 2.4rem;
}

.tags-is-loading {
  opacity: 0.5;
  cursor: not-allowed;
}

.tags-edit-container {
  padding: 0 1.6rem;
}

.tags-table .rt-noData {
  text-align: center;
  margin-top: 2.4rem;
  font-family: var(--brandType);
  font-size: var(--fs-m);
  color: var(--mono-4);
}
