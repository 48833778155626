.settings-publisher-logo-container {
  width: 400px;
  min-height: 100px;
  height: auto;
  margin-top: 0.8rem;
}

.settings-publisher-logo-image {
  width: 100%;
}

.replace-button {
  margin: 0.8rem 0;
}

:global .upload-button {
  --button-border-radius: 3px;
  --button-background: var(--white);
  --button-color: var(--mono-5);
  --border-color: var(--mono-5);
  -webkit-appearance: none;
  min-width: unset;
  min-height: unset;
  display: inline-flex;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: var(--button-border-radius);
  background: var(--button-background);
  color: var(--button-color);
  font-weight: normal;
  font-family: inherit;
  font-size: var(--fs-s);
  padding: 0.8rem 1.6rem;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  line-height: 1;
  text-transform: uppercase;
  width: 125px;
}
[dir="rtl"] :global .upload-button {
  margin: 0 0 0 0.8rem;
}
[dir="ltr"] :global .upload-button {
  margin: 0 0.8rem 0 0;
}

.upload-info {
  font-family: var(--brandType);
  font-size: var(--fs-s);
  line-height: var(--lh-4);
  color: var(--mono-4);
  margin-bottom: 0.8rem;
}

.general-upload-button {
  margin-bottom: 0.8rem;
}
