.form-card-actions {
    padding: 1.2rem 1.6rem 1.2rem 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.form-more-actions {
    height: 24px;
    width: 24px;
    cursor: pointer;
}
