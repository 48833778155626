@value viewports: "../../../viewports.css";
@value desktop from viewports;

.story-editor-card-number {
  font-size: var(--fs-s);
  line-height: var(--lh-4);
  color: var(--mono-4);
  flex-grow: 1;
  margin: 0;
}

@media (min-width: desktop) {
  [dir="ltr"] .story-editor-card-number {
    margin-left: 6rem;
  }

  [dir="rtl"] .story-editor-card-number {
    margin-right: 6rem ;
  }
}
