.static-pages-list-container {
  width: 100%;
  height: 100%;
  padding: 1.6rem 0;
}
.static-pages-item {
  --static-pages-item-shadow: 0 3px 6px 0 rgba(19, 36, 55, 0.1);
  --verticalRhythm: 0.8rem;

  font-family: var(--brandType);
  border-radius: 0.4rem;
  margin: 0.8rem;
  box-shadow: var(--box-shadow-s);
  display: flex;
  flex-direction: column;
  padding: 1.2rem 1.6rem 1.2rem 1.6rem;
  background-color: var(--white);
  cursor: pointer;
}

.static-pages-item:hover {
  background-color: var(--brand-2);
}

.static-pages-item-title {
  margin: 0 0 var(--verticalRhythm) 0;
  color: var(--mono-5);
  font-size: var(--fs-l);
  font-weight: var(--fw-normal);
  line-height: var(--lh-4);
}

.static-pages-item-meta {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: var(--fs-xs);
  line-height: var(--lh-1);
  color: var(--mono-4);
}

.static-pages-item-updated-container {
  display: flex;
  align-items: center;
}

[dir="ltr"] .static-pages-item-updated-at {
  margin-left: 0.4rem;
}

[dir="rtl"] .static-pages-item-updated-at {
  margin-right: 0.4rem;
}

[dir="ltr"] .static-pages-item-status {
  margin-right: 0.8rem;
}

[dir="rtl"] .static-pages-item-status {
  margin-left: 0.8rem;
}
