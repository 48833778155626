/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.breadcrumbs {
  display: inline-block;
  padding: 0 0.4rem;
}

.breadcrumb__level-indicator {
  padding: 0 0.4rem;
}
