@value viewports: "../../../../viewports.css";
@value desktop from viewports;

.collections-header {
  display: grid;
  grid-template-rows: 3.2rem 1fr;
  grid-template-columns: 1fr 8.8rem;
  grid-row-gap: 1.6rem;
  margin-bottom: 1rem;
}

.collections-meta {
  grid-area: 2 / 1 / 3 / 2;
}

.collection-title {
  margin: 0;
}

.collection-labels {
  margin: 0.8rem 0;
}

[dir="ltr"] :global .collection-chip {
  margin: 0 1.6rem 0 0;
}

[dir="rtl"] :global .collection-chip {
  margin: 0 0 0 1.6rem;
}

.collections-last-modified {
  font-size: var(--fs-s);
}

.collections-realtime-members {
  grid-area: 1 / 1 / 2 / 3;
  display: flex;
  justify-content: space-between;
}

.collections-settings-button {
  grid-area: 2 / 2 / 3 / 3;
}

[dir="rtl"] .collections-settings-button {
  margin-left: 2rem;
}

[dir="ltr"] .collections-settings-button {
  margin-right: 2rem;
}

.collection-container {
  display: flex;
}

.dashboard-link {
  display: inline-block;
  text-decoration: none;
}

:global .go-back-to-dashboard:first-child {
  min-height: 0;
}

[dir="ltr"] :global .go-back-to-dashboard {
  padding-left: 0;
}

[dir="rtl"] :global .go-back-to-dashboard {
  padding-right: 0;
}

@media (min-width: desktop) {
  .collections-header {
    display: flex;
    align-items: center;
    margin-top: 1.6rem;
  }

  .collections-meta {
    flex-grow: 1;
  }

  .collection-title {
    margin: unset;
  }

  .collection-labels {
    margin: 1.6rem 0;
  }
}
