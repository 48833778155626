@value viewports: "../../../../viewports.css";
@value desktop from viewports;

.consumers-table-container {
  padding: 4rem 2.4rem 2.4rem 2.4rem;
  margin-bottom: 1.6rem;
  background-color: var(--white);
}

.consumers-actions-title {
  margin: 0;
  font-size: var(--fs-l);
  line-height: var(--lh-3);
}

.consumers-actions-desc {
  margin: 0;
  max-width: 72.8rem;
  font-size: var(--fs-s);
  line-height: var(--lh-4);
}

[dir="ltr"] .consumers-searchbar {
  flex-grow: 1;
  margin-right: 1rem;
}

[dir="rtl"] .consumers-searchbar {
  flex-grow: 1;
  margin-left: 1rem;
}

.search-filter-container {
  display: flex;
}

.consumer-table-pagination {
  justify-content: flex-start;
  margin-top: 1.2rem;
}

@media (min-width: desktop) {
  .consumers-table-pagination-top {
    display: grid;
    grid-template-columns: auto max-content max-content;
    grid-column-gap: 1.6rem;
    margin-bottom: 1.6rem;
  }
}

.consumers-table-avatar-container {
  width: 3.2rem;
  height: 3.2rem;
  overflow: hidden;
  border-radius: 50%;
  background: url("~assets/icons/default-avatar.svg") no-repeat center center;
  background-color: var(--brand-3);
  background-size: cover;
}

.consumers-table-roles {
  margin-right: 0.8rem;
}
.consumers-table-roles-separator {
  margin-right: 0.4rem;
}
.consumers-table-roles-separator:last-child {
  display: none;
}

:global .consumers-table {
  position: relative;
}

:global .consumers-table .rt-tbody .rt-tr .rt-td:nth-child(4) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: var(--lh-5);
}

:global .consumers-table .rt-table {
  margin-top: 4rem;
}

:global .consumers-table .-header {
  background-color: var(--mono-2);
  font-weight: var(--fw-bold);
  font-size: var(--fs-s);
  height: 4rem;
  display: flex;
  align-items: center;
  padding: 0 1.6rem;
  min-width: fit-content !important;
}

:global .consumers-table .rt-tbody .rt-tr {
  font-size: var(--fs-s);
  padding: 1.2rem 1.6rem;
  display: flex;
  align-items: center;
  min-height: 4.8rem;
  border-bottom: 1px solid var(--mono-2);
}

.react-table :global .rt-tbody .rt-tr,
.react-table :global .rt-thead .rt-tr {
  display: grid;
  grid-template-columns: 6rem 2fr 2fr 2fr 1fr 0.5fr;
  grid-column-gap: 0.8rem;
}

:global .consumers-table .rt-thead .rt-tr:last-child {
  flex-grow: 1;
}

.consumers-table-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--mono-2);
  opacity: 0.8;
  transition: all 0.3s ease;
  pointer-events: none;
}

:global .consumers-spinner {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 20%;
  left: 0;
  font-size: var(--fs-m);
  color: var(--mono-4);
  -webkit-transform: translateY(-52%);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.filter-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 1.2rem;
}

.consumers-name-container {
  min-height: 4.8rem;
  flex-grow: 1;
  align-items: center;
  overflow-wrap: break-word;
  cursor: default;
}

.react-table {
  overflow-x: auto;
}

.consumers-avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: desktop) {
  .consumers-name-container {
    display: flex;
  }

  div:has(>.consumers-name-container-phone){
    width:fit-content !important;
  }

  .react-table {
    overflow-x: unset;
  }

  :global .consumers-table .rt-tbody .rt-tr .rt-td:nth-child(2),
  :global .consumers-table .rt-tbody .rt-tr .rt-td:nth-child(3) {
    width: unset !important;
  }
}
