@value viewports: "../../../../viewports.css";
@value desktop from viewports;

.banner {
  display: flex;
  border-radius: 0.2rem;
  width: 75rem;
  background-color: var(--ascent-5);
  color: var(--black);
  opacity: 0.9;
  padding: 1.5rem;
  font-size: var(--fs-s);
  line-height: var(--lh-4);
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  height: 5rem;
}

[dir="rtl"] .content {
  margin-right: 0.8rem;
  font-weight: var(--fw-semi-bold);
}

[dir="ltr"] .content {
  margin-left: 0.8rem;
  font-weight: var(--fw-semi-bold);
}
