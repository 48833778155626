@value viewports: "../../../viewports.css";
@value desktop from viewports;

.story-header {
  padding: 9.6rem 0.8rem 0 0.8rem;
  background-color: var(--white);
  display: grid;
  grid-auto-columns: 100%;
  grid-gap: 0.8rem;
}

.story-header__item {
  display: grid;
  grid-template-rows: 2.4rem 1fr;
  grid-template-columns: 100%;
  grid-gap: 0.8rem;
}

.block {
  position: absolute;
  top: 100px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z100);
}

.story-field-label {
  display: flex;
  font-family: var(--brandType);
  font-size: var(--fs-xs);
  color: var(--mono-4);
}

.story-field-label.has-error {
  color: var(--danger);
  border-color: var(--danger);
}

.headline.has-error,
.subheadline.has-error,
.reference-url.has-error,
.review.has-error {
  border-color: var(--danger);
}

.story-title {
  font-family: var(--brandType);
  font-size: var(--fs-jumbo);
  line-height: var(--lh-2);
  color: var(--mono-5);
  margin: 0;
}

.story-title-text-area {
  font-weight: var(--fw-bold);
}

.story-title-text-area--readonly,
.story-subtitle--readonly {
  z-index: var(--z200);
}

.story-title-text-area--readonly textarea:disabled,
.story-subtitle--readonly textarea:disabled {
  background-color: unset;
  pointer-events: unset;
  cursor: unset;
}

.story-title .text-area-error-message,
.story-subtitle .text-area-error-message {
  color: var(--danger);
  margin-top: 0.4rem;
  min-height: 1.6rem;
  font-size: var(--fs-m);
  font-weight: var(--fw-normal);
}

.story-page-container .story-header .story-title .alternative {
  font-size: var(--fs-s);
}

.story-subtitle .text-field-input,
.story-page-container .story-header .story-title .text-field-input {
  margin-top: 0;
  border: 0;
  padding: 0;
  color: var(--mono-5);
}

.story-subtitle {
  font-family: var(--brandType);
  font-size: var(--fs-xl);
  font-weight: var(--fw-normal);
  line-height: var(--lh-5);
  color: var(--mono-5);
}

.story-reference-url-container {
  width: 50rem;
}

.story-header .story-title .story-title__textarea {
  line-height: var(--lh-6);
}

.story-header .story-subtitle .story-subtitle__textarea {
  line-height: var(--lh-6);
}

.title-icon {
  color: var(--mono-4);
}

.title-icon.has-error {
  color: var(--danger);
}

.import-card-story-container .header-card .hero-image {
  grid-column: 1/-1;
  padding: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--mono-2);
}

.custom-field-icon {
  color: var(--mono-4);
}

.custom-metadata {
  font-family: var(--brandType);
  font-size: var(--fs-xl);
  font-weight: var(--fw-normal);
  line-height: var(--lh-5);
  color: var(--mono-5);
}

.ai-generate-content__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
  padding: 0;
}

.ai-generate-content {
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.story-header__item--ai-suggestions {
  margin-bottom:  2rem;
  overflow: hidden;
}

@media (min-width: desktop) {
  .story-header {
    padding: 0;
    margin-top: 13rem;
    grid-gap: 2.4rem;
  }

  .story-header__item {
    grid-template-rows: unset;
    grid-template-columns: 60px 1fr;
    grid-gap: 2.4rem;
  }

  .story-header.timeline--open {
    margin-top: 0;
  }

  .story-header.preview--open,
  .story-header.story-editor-inspector--open,
  .story-header.seoScore--open {
    margin-top: 8rem;
  }

  .story-field-label {
    justify-items: flex-end;
    display: grid;
    padding: 0 0.8rem;
  }

  [dir="ltr"] .story-field-label {
    border-right: 1px solid var(--mono-3);
  }

  [dir="rtl"] .story-field-label {
    border-left: 1px solid var(--mono-3);
  }

  .ai-generate-content {
    margin-left: 0;
    margin-top: 1rem;
  }

}
