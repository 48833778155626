.collection-search-actions {
  display: grid;
  grid-template-columns: auto max-content max-content;
  grid-column-gap: 1.6rem;
  margin-bottom: 1.6rem;
}

.collection-search-bar {
  flex-grow: 1;
  margin-left: 0.8rem;
}

:global .prevent-normal-pull-to-refresh {
  overscroll-behavior-y: contain;
}
