.opinion-poll-inspector-container {
  margin: 0 1.6rem;
}
.opinion-poll-inspector-field-wrapper {
  display: flex;
  align-items: center;
}
.opinion-poll-field-input-container {
  flex-grow: 1;
}

[dir="ltr"] .opinion-poll-field-input-container {
  margin-right: 0.8rem;
}

[dir="rtl"] .opinion-poll-field-input-container {
  margin-left: 0.8rem;
}

.opinion-poll-inspector-add-option {
  display: flex;
  align-items: center;
  color: var(--brand-3);
  margin-bottom: 5rem;
  cursor: pointer;
  font-size: var(--fs-s);
}

.add-option-label {
  margin-left: 0.2rem;
}

.opinion-poll-field-delete {
  cursor: pointer;
}

:global .opinion-poll-add-new-image {
  background-color: var(--mono-2);
}

.opinion-poll-image-info {
  margin-top: 0.8rem;
  font-size: var(--fs-xs);
  text-align: center;
  color: var(--mono-4);
}

.opinion-poll-image-spinner {
  background-color: var(--mono-2);
  height: 16rem;
  padding: 0.1rem;
  margin-bottom: 3.2rem;
}

:global .opinion-poll-image-spinner-spinner {
  margin: 4.8rem 0;
}

.opinion-poll-image-field-image {
  width: 100%;
}

.opinion-poll-image-file {
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
  z-index: var(--z1);
}

.opinion-poll-image-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 1.6rem;
}

:global .opinion-poll-settings {
  font-size: var(--fs-m);
  margin-bottom: 1.8rem;
}

.opinion-poll-image-container .poll-image-action-bar {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0.8rem;
}

.poll-image-action-bar--hide {
  display: none;
}