.access-levels-container {
  background-color: var(--white);
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
  padding: 3.2rem 4.8rem;
  margin-bottom: 3.2em;
}

.access-levels-config {
  display: grid;
  grid-column-gap: 1.6rem;
  grid-template-columns: 1fr;
  background-color: var(--white);
  padding: 1.6rem;
  margin-bottom: 3.2rem;
}

@media (min-width: 786px) {
  .access-levels-config {
    padding: 3.2rem 6.4rem;
    grid-template-columns: 1fr 2fr;
  }
}
.access-level-row {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  padding: 0.8rem 2.4rem;
}

.access-level-row:not(.access-level-header) {
  border-bottom: 1px solid var(--mono-2);
}

.access-level-action {
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
}

.access-levels-sub-title {
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
  line-height: var(--lh-3);
  color: var(--mono-5);
  margin: 0 0 0.8rem 0;
}

.access-levels-category-details-container .access-levels-sub-title {
  font-size: var(--fs-s);
  font-family: var(--brandType);
  font-weight: var(--fw-bold);
  line-height: var(--lh-3);
  color: var(--mono-5);
  margin: 0 0 0.8rem 0;
}

.access-level-header {
  background-color: var(--mono-2);
  font-family: var(--brandType);
  font-size: var(--fs-s);
  font-weight: var(--fw-bold);
  line-height: var(--lh-4);
  color: var(--mono-5);
}

.access-level-row:not(.access-level-header):hover .access-level-action {
  visibility: visible;
  opacity: 1;
}

.create-new-link {
  color: var(--brand-3);
  font-family: var(--brandType);
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  line-height: var(--lh-4);
}

:global .accesslevel-progress-main-area {
  margin: auto;
  padding: 0px;
  height: calc(100vh - var(--header-height) - 6.4rem);
  display: flex;
  justify-content: center;
}

.access-levels-description {
  font-size: var(--fs-m);
  line-height: var(--lh-4);
  color: var(--mono-4);
}

.access-levels-container .access-levels-description {
  font-size: var(--fs-s);
  line-height: var(--lh-4);
  color: var(--mono-4);
}

.access-level-page-container {
  transition: transform 0.5s;
}
