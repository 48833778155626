.integrations-no-content {
    margin-bottom: 2.4rem;
    border-radius: 0.5rem;
    background-color: var(--mono-2);
}

.no-content-message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.no-content-message {
    max-width: 40rem;
    text-align: center;
}