.menu-items-list-item {
  background-color: var(--white);
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-m);
}

.menu-item-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--brandType);
  font-size: var(--fs-m);
  color: var(--mono-5);
  text-decoration: none;
  line-height: var(--lh-4);
  text-transform: capitalize;
  padding: 1.2rem 3.2rem;
  cursor: pointer;
}

.menu-item-row-info {
  display: flex;
  align-items: center;
}

[dir="ltr"] :global .menu-chip {
  margin: 0 auto 0 1.2rem;
}

[dir="rtl"] :global .menu-chip {
  margin: 0 1.2rem 0 auto;
}

.menu-color-container {
  display: flex;
  align-items: center;
}

[dir="ltr"] .menu-color-container {
  margin-right: 0.8rem;
}

[dir="rtl"] .menu-color-container {
  margin-left: 0.8rem;
}

.menu-item-row-actions {
  display: flex;
  align-items: center;
}

.menu-item-row-actions-button {
  margin-right: 1.6rem;
  padding: 0.8rem 0.4rem;
}

[dir="ltr"] .menu-items-action-dropdown {
  left: auto !important;
  right: 10rem !important;
}

[dir="rtl"] .menu-items-action-dropdown {
  left: 10rem !important;
  right: auto !important;
}

.menu-list-nested-items :global .nested-menu-itemslist {
  padding: 0 0 0 3.2rem;
  display: grid;
  grid-row-gap: 0.8rem;
  grid-template-columns: 1fr;
  list-style: none;
}
