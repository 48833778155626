.push-notification-card {
  --verticalRhythm: 0.8rem;

  font-family: var(--brandType);
  border-radius: 0.3rem;
  margin: 0.8rem;
  box-shadow: var(--box-shadow-s);
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.push-notification-card .push-notification-multi-select {
  display: flex;
  align-items: center;
  padding-left: 10px;
  background-color: var(--white);
}

.push-notification-card .push-notification-card-content {
  flex-grow: 1;
  padding: 1.2rem 1.6rem 1.2rem 1.6rem;
}

.push-notification-card .push-notification-card-actions {
  padding: 1.2rem 1.6rem 1.2rem 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--white);
}

.push-notification-card .push-notification-card-actions .push-notification-submit-button {
  margin-right: 3.2rem;
}

.push-notification-card .push-notification-card-actions .push-notification-preview {
  margin-right: 0.8rem;
}

.push-notification-card .push-notification-card-actions .push-notification-more-actions {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.push-notification-card .push-notification-card-actions .push-notification-submit-button,
.push-notification-card .push-notification-card-actions .push-notification-preview {
  visibility: hidden;
}

.push-notification-card:hover .push-notification-card-actions .push-notification-submit-button,
.push-notification-card:hover .push-notification-card-actions .push-notification-preview {
  visibility: visible;
}

.push-notification-card .checkbox-label {
  padding-left: 10px;
  background-color: var(--white);
}

.push-notification-card:hover .push-notification-card-content,
.push-notification-card:hover .push-notification-card-actions,
.push-notification-card:hover .push-notification-multi-select,
.push-notification-card:hover .checkbox-label {
  background-color: var(--brand-2);
}

.push-notification-card .highlight {
  font-weight: var(--fw-bold);
  background-color: var(--white);
}

.push-notification-headline {
  margin: 0 0 var(--verticalRhythm) 0;
  color: var(--mono-5);
  font-size: var(--fs-l);
  font-weight: var(--fw-normal);
  line-height: var(--lh-4);
  text-decoration: none;
  display: block;
}

.push-notification-byline {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: var(--fs-xs);
  line-height: var(--lh-1);
  color: var(--mono-4);
}

.push-notification-authors {
  display: flex;
  align-items: center;
}

[dir="ltr"] .push-notification-authors {
  margin-right: 1.6rem;
}

[dir="rtl"] .push-notification-authors {
  margin-left: 1.6rem;
}

.push-notification-chip-wrapper,
.push-notification-author {
  cursor: pointer;
}

[dir="ltr"] .push-notification-author,
[dir="ltr"] .push-notification-time {
  margin-left: 0.4rem;
}

[dir="rtl"] .push-notification-author,
[dir="rtl"] .push-notification-time {
  margin-right: 0.4rem;
}

.push-notification-time-wrapper {
  display: flex;
  align-items: center;
}

.push-notification-chips {
  margin-bottom: var(--verticalRhythm);
}

.push-notification-chips .chip {
  margin-right: 0.8rem;
}

.rollout-old-link {
  text-decoration: none;
}

.push-notification-tab-panel {
  width: 100%;
  height: 100%;
  padding: 0.8rem 0 1.6rem;
}

.push-notification-status {
  display: flex;
  margin-bottom: 0.8rem;
  align-items: flex-end;
}

.push-notification-status > *:not(:last-child) {
  margin-right: 0.8rem;
}

a {
  color: inherit;
}

.push-notification-platform {
  border-radius: 0.3rem;
  display: flex;
}
