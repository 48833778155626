.image-action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  padding: 0.8rem;
  cursor: pointer;
  position: relative;
  background: none;
}

.image-action-button:before {
  content: "";
  background-color: var(--mono-5);
  opacity: 0.72;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: background-color .25s ease-out;
}

.image-action-button:hover:before {
  background-color: var(--mono-4);
}

.image-action-button--error:before,.image-action-button--error:hover:before {
  background-color: var(--danger);
}

.image-action-button:first-child:before {
  border-radius: 2px 0 0 2px;
}

.image-action-button:last-child:before {
  border-radius: 0 2px 2px 0;
}

.image-action-button svg {
  z-index: 1;
  position: relative;
}

.image-action-bar {
  display: flex;
}

[dir="rtl"] .image-action-button:first-child:before {
  border-radius: 0 2px 2px 0;
}

[dir="rtl"] .image-action-button:last-child:before {
  border-radius: 2px 0 0 2px;
}

.tooltip-image-action-button {
  top: 0px !important;
  left: 0px !important;
  position: absolute;
  transform: translateX(-25%) translateY(-75%);
}