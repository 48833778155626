.DayPicker {
  width: 100%;
  display: block !important;
}
.DayPicker-Month {
  width: 100%;
}
.DayPicker:focus,
.DayPicker-wrapper:focus,
.DayPicker-Day:focus,
.DayPicker-Month:focus,
.DayPicker-NavButton:focus {
  outline: none;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  border-radius: unset !important;
  background-color: var(--brand-3) !important;
}
.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  border-radius: unset !important;
}
