/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.fixed-tab-list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: var(--brandType);
  font-size: var(--fs-s);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fixed-tab-list__item {
  padding: 0 0.8rem 1rem 0.8rem;
  border-bottom: 4px solid transparent;
  color: var(--mono-4);
  text-transform: uppercase;
  cursor: pointer;
  font-weight: var(--fw-semi-bold);
  transition: color ease-in-out 0.4s;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.fixed-tab-list__item.is-active {
  color: var(--brand-3);
  font-weight: var(--fw-semi-bold);
  border-bottom-color: var(--brand-3);
}
