.video-story-element-video-wrapper {
  position: relative;
  padding-top: 56.65%;
  transition: padding 0.4s ease-in-out;
}

.video-story-element-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
