.link-author-checkbox {
  display: flex;
  justify-content: flex-end;
}

.image-details-container {
  width: 100%;
  flex-direction: column;
  align-items: center;
}
