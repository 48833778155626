.filters-source {
    font-size: var(--fs-s);
    font-weight: var(--fw-bold);
    margin-bottom: 0.8rem;
}

.filters-source-radio-btn {
    margin-bottom: 0.8rem;
    font-size: var(--fs-s);
}
