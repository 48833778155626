@value viewports: "../../../viewports.css";
@value desktop from viewports;

.insert-card-bar {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  height: 64px;
  background: var(--white);
  display: flex;
}

.insert-card-bar.top {
  margin-top: 0.8rem;
}

:global .insert-card-bar-button {
  justify-content: center;
  border: none !important;
  font-size: var(--fs-m) !important;
  width: 50%;
}

.add-new-card-button {
  width: 80%;
  background-color: var(--mono-1);
}

.import-card-button {
  width: 20%;
  background-color: var(--white);
}

.insert-card-bar button:hover {
  background-color: var(--mono-1);
}

[dir="ltr"] .add-new-card-button svg {
  margin-right: 8px;
}

[dir="rtl"] .add-new-card-button svg {
  margin-left: 8px;
}

.insert-card-bar-container {
  position: relative;
}

.insert-card-block-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (min-width: desktop) {
  :global .insert-card-bar-button {
    width: 50%;
    background-color: var(--white);
  }

  [dir="ltr"] .insert-card-bar svg {
    margin-right: 8px;
  }

  [dir="rtl"] .insert-card-bar svg {
    margin-left: 8px;
  }
}
