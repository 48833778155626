.social-accounts {
  margin: 0 0 3.2rem 0;
  background-color: var(--white);
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
  padding: 3.2rem 4.8rem;
}

.social-account {
  display: grid;
  grid-template-columns: 17fr 5fr 1fr max-content;
  grid-column-gap: 1.6rem;
  align-items: center;
  padding: 1.6rem 3.2rem;
  border-bottom: 1px solid var(--mono-2);
  font-family: var(--brandType);
  font-size: var(--fs-s);
  line-height: var(--lh-4);
  color: var(--mono-5);
  list-style: none;
}

.social-account-details {
  display: flex;
  align-items: center;
}

.social-account-provider {
  margin: 0 0.8rem 0 0;
}

[dir="ltr"] .social-account-provider {
  margin-right: 0.8rem;
}
[dir="rtl"] .social-account-provider {
  margin-left: 0.8rem;
}

.social-account-name {
  font-weight: var(--fw-bold);
}

[dir="ltr"] .social-account-name {
  margin-left: 0.8rem;
}
[dir="rtl"] .social-account-name {
  margin-right: 0.8rem;
}

.social-account-menu {
  left: auto !important;
  right: 140px !important;
}

.social-account-menu__linkedin-reconnect {
  display: none;
}