/*
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
@value viewports: "../../../viewports.css";
@value desktop from viewports;

.settings-container {
  background-color: var(--white);
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
}

@media (min-width: desktop) {
  .settings-container {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
    margin-top: 3.2rem;
    margin-bottom: 4rem;
  }
}
