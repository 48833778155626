/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

@value viewports: "../../viewports.css";
@value desktop from viewports;

:global .notifications-bc {
  font-family: inherit;
  position: fixed;
  padding: 0 1rem 4.8rem 1rem;
  z-index: var(--snackbarNotification);
  top: auto;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

:global .notification {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  border-radius: 0.2rem;
  font-size: var(--fs-s);
  padding: 1.2rem 2.4rem;
  opacity: 0;
  transition: 0.3s ease-in-out;
}
:global .notification.notification-hidden {
  opacity: 0;
}
:global .notification.notification-visible {
  opacity: 0.95;
}
:global .notification.notification-success {
  background-color: var(--success);
  color: var(--white);
}
:global .notification.notification-error {
  background-color: var(--danger);
  color: var(--white);
}
:global .notification.notification-warning {
  background-color: var(--warning);
  color: var(--white);
}
:global .notification.notification-info {
  background-color: var(--mono-5);
  color: var(--white);
}

:global .notification-dismiss {
  font-size: var(--fs-xl);
  position: absolute;
  right: 0px;
  text-align: center;
  padding-top: 0.2rem;
  padding-right: 1.6rem;
  cursor: pointer;
}

:global .notification-message {
  display: flex;
}

:global .notification-action-button {
  cursor: pointer;
  padding: 0;
  padding-top: 0.3rem;
  border: 0;
}

:global .notification-error .notification-action-button {
  background-color: var(--danger);
  color: #fff;
  text-decoration: underline;
}

:global .notification-action-wrapper {
  margin: 0;
  padding: 0;
}

:global .notification-not-dismissible {
  cursor: default;
}

:global .notification-message-text {
  padding: 0.5rem;
}

@media (min-width: desktop) {
  :global .notifications-bc {
    left: 50%;
    right: unset;
    transform: translate(-50%, 0);
  }

  :global .notification {
    padding: 1.2rem 10rem;
  }
}
