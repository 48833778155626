.card-container {
  border-bottom: solid 1px var(--mono-3);
  margin-bottom: 2.4rem;
  padding-bottom: 0.6rem;
}

.header-container {
  display: flex;
}

.header {
  margin-right: auto;
}

.domain-name {
  margin-bottom: 1.2rem;
}

.domain-action-btn {
  margin-right: 1.6rem;
  cursor: pointer;
}

.domain-field {
  font-size: var(--fs-s);
  color: var(--mono-4);
  margin-bottom: 1.8rem;
}

.domain-field-value {
  font-size: var(--fs-s);
  color: var(--mono-5);
}

.chip {
  font-size: var(--fs-xs) !important;
  border-radius: 1.6rem !important;
  padding: 0.4rem 0.8rem !important;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

.chip > span {
  text-transform: capitalize;
}

.url {
  text-decoration: none;
  border-bottom: 1px solid var(--mono-3);
}

.url:focus {
  outline: none;
}

.domain-host-url {
  display: block;
}

[dir="ltr"] .language-config {
  margin-left: 1rem;
}

[dir="rtl"] .language-config {
  margin-right: 1rem;
}
