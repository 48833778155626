/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.text-field {
  display: flex;
  flex-direction: column;
  --verticalRhythm: 0.8rem;
  flex-grow: 1;
  margin-bottom: 0;
}

[dir="ltr"] .text-field {
  margin-right: 0.8rem;
}

[dir="rtl"] .text-field {
  margin-left: 0.8rem;
}

.text-field-with-button-wrapper {
  margin-bottom: 2.4rem;
}

.text-field-input {
  border-radius: 0.4rem;
  border: 1px solid var(--mono-3);
  outline: none;
  padding: 0.6rem 0.8rem;
  margin-bottom: 0.8rem;
  color: var(--mono-5);
  font-size: var(--fs-s);
}

.text-field-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--mono-3);
  font-size: var(--fs-s);
}

.text-field-input::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--mono-3);
  font-size: var(--fs-s);
}

.text-field-input:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--mono-3);
  font-size: var(--fs-s);
}
.text-field-input:-moz-placeholder {
  /* Firefox 18- */
  color: var(--mono-3);
  font-size: var(--fs-s);
}

.has-error .text-field-input,
.has-error .text-field-input:hover {
  border-color: var(--danger);
}
.text-field-with-button {
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
}

:global .text-field-with-copy-button {
  margin-top: 1.2rem;
}
