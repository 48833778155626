/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

[dir="ltr"] :global .social-and-signin-container-button {
  margin-right: 1.6rem;
}

[dir="rtl"] :global .social-and-signin-container-button {
  margin-left: 1.6rem;
}

[dir="ltr"] .social-icon {
  margin-right: 1.6rem;
}

[dir="rtl"] .social-icon {
  margin-left: 1.6rem;
}

.signin-container {
  margin-bottom: 2.4rem;
}
