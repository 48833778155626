.collection-settings-spinner {
  margin-top: 8rem;
}

.file-size-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.6rem;
}

.pdf-min-size-field {
  grid-column: 1/2;
}

.pdf-max-size-field {
  grid-column: 2/3;
}
