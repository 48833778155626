.add-new-file-container {
  position: relative;
}

.add-new-file {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  height: 100%;
  min-width: 16rem;
  min-height: 16rem;
}

.add-new-text {
  font-size: var(--fs-s);
  line-height: var(--lh-1);
  color: var(--mono-5);
  text-transform: capitalize;
}

.file-input {
  display: none;
}
