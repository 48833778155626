.promotional-messages {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-row-gap: 0.8rem;
  background-color: var(--white);
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
  padding: 3.2rem 4.8rem;
}

:global .promotional-messages-prosemirror-text-area-editor-wrapper {
  grid-column: 1/-1;
}

:global .promotional-messages-label {
  margin-bottom: 0;
}
