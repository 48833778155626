.poll-element {
  cursor: pointer;
}
.poll-element-image-container {
  margin: 0;
  position: relative;
}
.poll-element-image {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.poll-element-topic {
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
  line-height: var(--lh-5);
  margin-top: 1.6rem;
  color: var(--mono-5);
}

.poll-element-desc {
  font-size: var(--fs-m);
  line-height: var(--lh-2);
  margin-top: 0.8rem;
  color: var(--mono-5);
}

.poll-element-meta {
  margin-top: 1.6rem;
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  color: var(--mono-4);
}

.poll-element-options-container {
  padding: 0;
  margin: 1.6rem 0;
  list-style: none;
}

.poll-element-option {
  min-height: 4rem;
  font-size: 1.4rem;
  line-height: var(--lh-2);
  background-color: var(--mono-2);
  color: var(--mono-5);
  padding: 1rem;
  margin: 0.8rem 0;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.poll-element-option-background {
  background-color: var(--brand-2);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: 0.5rem;
  z-index: var(--z100);
}

.poll-element-option-percentage,
.poll-element-option-text {
  position: relative;
  z-index: var(--z200);
}
.poll-element-option-percentage {
  font-weight: var(--fw-bold);
}
.poll-element-create-new-container {
  min-height: 10rem;
  background-color: var(--mono-2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--mono-4);
  cursor: pointer;
}

.poll-element-published-at,
.poll-element-responses {
  font-size: var(--fs-s);
}

.is-disabled {
  pointer-events: none;
}

.poll-element-image-action-bar {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0.8rem;
}

.poll-element-image-action-bar--hide {
  display: none;
}