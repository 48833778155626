/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.upload-file {
  margin-bottom: 2.4rem;
}
.upload-file label .upload-btn {
  border: 1px solid var(--brand-3);
  vertical-align: middle;
  text-align: center;
  display: inline-block;
  padding: 0.8rem 1.6rem;
  cursor: pointer;
  color: var(--brand-3);
  line-height: 1;
  border-radius: 3px;
}

.file-input {
  display: none;
}

.file-input-label {
  grid-template-columns: 1fr auto;
  display: grid;
  grid-column-gap: 0.8rem;
  font-size: var(--fs-s);
}

.uploaded-file-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  border: solid 1px #c6ccd5;
  line-height: 2em;
  border-radius: 0.3rem;
  padding: 0.1rem 0.8rem;
  overflow: hidden;
  height: 3.2rem;
}
.field-label {
  display: block;
  font-family: var(--brandType);
  font-size: var(--fs-s);
  font-weight: var(--fw-bold);
  color: var(--mono-5);
  margin-bottom: 0.8rem;
}
