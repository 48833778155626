/*
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

@value viewports: "../../viewports.css";
@value desktop from viewports;

.add-new-button-container {
  position: fixed;
  bottom: 2.4rem;

  z-index: var(--z800);
}

[dir="ltr"] .add-new-button-container {
  right: 1.6rem;
}

[dir="rtl"] .add-new-button-container {
  left: 1.6rem;
}

.add-new-button-box {
  position: absolute;
  top: -0.8rem;
  background-color: var(--white);
  box-shadow: var(--box-shadow-l);
  border-radius: 0.4rem;
  padding: 0.8rem 0;
}

[dir="ltr"] .add-new-button-box {
  transform: translate(-100%, -100%);
  left: 5.6rem;
}

[dir="rtl"] .add-new-button-box {
  transform: translate(100%, -100%);
  right: 5.6rem;
}

.button-content-container {
  display: inline-flex;
}

.add-new-button {
  border: 1px solid var(--brand-3);
  border-radius: 50%;
  box-shadow: var(--box-shadow-ml);
  height: 5.6rem;
  width: 5.6rem;
  background: var(--brand-3);
  color: var(--white);
  font-weight: normal;
  font-family: inherit;
  font-size: var(--fs-s);
  cursor: pointer;
}

[dir="ltr"] .add-new-button .label {
  margin-right: 0.8rem;
}

[dir="rtl"] .add-new-button .label {
  margin-left: 0.8rem;
}

@media (min-width: desktop) {
  .add-new-button {
    border-radius: 0.2rem;
    box-shadow: none;
    padding: 0.8rem 1.6rem;
    width: unset;
    height: unset;
  }

  .add-new-button:hover {
    opacity: 0.9;
  }

  .add-new-button-container {
    position: relative;
    bottom: 0;
    right: 0;
  }

  [dir="ltr"] .add-new-button-container {
    margin-left: calc(var(--navbar-width) + 3rem);
  }

  [dir="rtl"] .add-new-button-container {
    margin-right: calc(var(--navbar-width) + 3rem);
  }

  .add-new-button-box {
    top: unset;
    left: unset;
    bottom: -0.5rem;
  }

  [dir="ltr"] .add-new-button-box {
    transform: translateY(100%);
    left: unset;
  }

  [dir="rtl"] .add-new-button-box {
    transform: translateY(100%);
    right: unset;
  }
}
