/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.password-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8rem;
  --verticalRhythm: 0.8rem;
}

.password-field-input {
  margin-bottom: var(--verticalRhythm);
  border-radius: 0.4rem;
  border: 1px solid var(--mono-3);
  outline: none;
  padding: 0.6rem 0.8rem;
  font-size: var(--fs-s);
  color: var(--mono-5);
}

.password-field-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--mono-3);
  font-size: var(--fs-s);
}

.password-field-input::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--mono-3);
  font-size: var(--fs-s);
}

.password-field-input:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--mono-3);
  font-size: var(--fs-s);
}

.password-field-input:-moz-placeholder {
  /* Firefox 18- */
  color: var(--mono-3);
  font-size: var(--fs-s);
}

.has-error .password-field-input,
.has-error .password-field-input:hover {
  border-color: var(--danger);
}
