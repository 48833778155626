.embargo-inspector-subtitle {
  line-height: var(--lh-4);
  color: var(--mono-4);
  margin: 0 0 1.6rem 0;
  font-size: var(--fs-s);
}
.embargo-inspector-title {
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
  line-height: var(--lh-3);
  color: var(--mono-5);
  margin: 0 0 0.8rem 0;
}
