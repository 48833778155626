.display-text {
  display: flex;
  text-transform: capitalize;
  font-size: var(--fs-s);
  align-items: center;
}

.manage-inspector-container {
  padding: 0 1.6rem;
}
