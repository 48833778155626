:global .quote-helper-label,
:global .cta-title-helper,
:global .cta-url-label,
:global .bigfact-helper-label,
:global .answer-helper-label,
:global .answer-attribution-helper-label,
:global .question-helper-label,
:global .question-attribution-helper-label,
:global .q-and-a-answer-helper-label,
:global .q-and-a-answer-attribution-helper-label,
:global .q-and-a-question-helper-label,
:global .q-and-a-question-attribution-helper-label {
  font-weight: var(--fw-bold);
  font-size: var(--fs-s);
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0.8rem 0;
  color: var(--mono-5);
}

:global .story-element-text,
:global .quote,
:global .question_and_answer {
  font-family: var(--brandType);
  font-size: var(--fs-xl);
  line-height: var(--lh-5);
  color: var(--mono-4);
  white-space: pre-wrap;
}

:global .bigfact-title {
  font-size: var(--fs-xxl);
  line-height: var(--lh-5);
  min-height: 3rem;
}

:global .bigfact-description {
  display: flex;
  flex-direction: column-reverse;
  line-height: var(--lh-5);
  margin: 1rem 0;
  min-height: 2rem;
}
