/*
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.color-field {
  position: relative;
  margin-bottom: 0.8rem;
}

.color-field-swatch-container {
  display: flex;
  align-items: center;
}
.color-field-swatch {
  display: block;
  border-radius: 0.4rem;
  width: 6.4rem;
  height: 3.2rem;

  background-color: var(--swatchColor);
  border: 1px solid var(--mono-3);
  cursor: pointer;
}

[dir="ltr"] .color-field-swatch {
  margin-right: 1.6rem;
}

[dir="rtl"] .color-field-swatch {
  margin-left: 1.6rem;
}

.color-field-picker {
  position: absolute;
  left: 0;
  top: 6.5rem;
  z-index: var(--z100);
}

.color-field-hint {
  margin: 0.8rem 0 0 0;
  font-size: var(--fs-xs);
  color: var(--mono-4);
}

.color-field-input {
  border-radius: 0.4rem;
  height: 3.2rem;
  padding: 0 0.8rem;
  border: 1px solid var(--mono-3);
  text-transform: uppercase;
}
