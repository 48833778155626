.collections-config {
  display: grid;
  grid-column-gap: 1.6rem;
  grid-template-columns: 1fr;
  background-color: var(--white);
  padding: 1.6rem;
  margin-bottom: 3.2rem;
}

@media (min-width: 786px) {
  .collections-config {
    padding: 3.2rem 6.4rem;
    grid-template-columns: 1fr 2fr;
  }
}

.collections-config-title {
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
  line-height: var(--lh-3);
  color: var(--mono-5);
  margin: 0 0 0.8rem 0;
}

.collections-config-description {
  font-size: var(--fs-s);
  line-height: var(--lh-4);
  color: var(--mono-4);
}

.configure-card {
  background-color: var(--white);
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
  padding: 3.2rem 4.8rem;
}

:global .collections-configure-progress-main-area {
  margin: auto;
  padding: 0px;
  height: calc(100vh - var(--header-height) - 6.4rem);
  display: flex;
  justify-content: center;
}
