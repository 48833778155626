.static-pages-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.static-pages-title {
  font-size: var(--fs-xxl);
  font-weight: var(--fw-bold);
}

:global .static-page-progress-main-area {
  margin: auto;
  padding: 0px;
  height: calc(100vh - var(--header-height) - 6.4rem);
  display: flex;
  justify-content: center;
}
