/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.spinner {
  margin: 2.4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.spinner-message {
  margin: 1rem 0;
  font-size: var(--fs-s);
  font-weight: var(--fw-normal);
}
