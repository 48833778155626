/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.field-label {
  display: block;
  font-family: var(--brandType);
  font-size: var(--fs-s);
  font-weight: var(--fw-bold);
  color: var(--mono-5);
  margin-bottom: 0.8rem;
}

.field-label--uppercase {
  letter-spacing: 1px;
  text-transform: uppercase;
}
