/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

@value viewports: "../../viewports.css";
@value desktop from viewports;

.collection-content-list-tabs {
  padding-top: 1.6rem;
}

.collection-search-actions {
  display: flex;
  align-items: center;
}

.collection-search-bar {
  flex-grow: 1;
}

[dir="ltr"] .collection-search-bar {
  margin-right: 0.8rem;
}

[dir="rtl"] .collection-search-bar {
  margin-left: 0.8rem;
}

.collections-toggle-advanced-search {
  cursor: pointer;
}

/* .select-container {
  margin-bottom: 0.8rem;
} */

.DayPickerInput {
  width: 100%;
  margin-top: 0.8rem;
}

:global .DayPickerInput input {
  width: 100%;
}

.create-new-link {
  color: var(--brand-3);
  font-family: var(--brandType);
  cursor: pointer;
  background: none;
  padding: 0;
  border: none;
  margin-top: 0;
  line-height: var(--lh-4);
}

.feedback-message {
  line-height: var(--lh-4);
  text-align: center;
  color: var(--mono-4);
  margin: 3px auto;
}

.feedback-box {
  text-align: center;
}

.collection-pagination {
  margin: 0 1.6rem 5rem 3.2rem;
}

[dir="ltr"] .collection-pagination .search-wrapper {
  flex-grow: 1;
  margin-right: 1rem;
}

[dir="rtl"] .collection-pagination .search-wrapper {
  flex-grow: 1;
  margin-left: 1rem;
}

.collection-sidebar-no-results {
  text-align: center;
}

/* convert to global after css modules */
.menu-list.collection-sidebar-menu {
  min-height: 25rem;
  max-height: 40rem;
  overflow: auto;
}

.collection-content-switch-container {
  display: flex;
  margin-top: 1.2rem;
  font-size: var(--fs-xs);
  justify-content: flex-end;
}

.collection-content-switch-container span {
  padding-right: 0.8rem;
}

.collection-file-upload-rodal-header {
  margin-top: 1.6rem;
  font-size: var(--fs-xl);
}

.collection-file-upload-rodal-body {
  font-size: var(--fs-s);
}

.location-container :global .rodal-dialog {
  padding: 2.4rem;
  box-shadow: none;
}

.collection-rodal {
  height: 42rem;
}

.location-rodal-close {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.location-rodal-button {
  margin-top: 1.6rem;
  display: flex;
  justify-content: flex-end;
}

.rodal-header {
  font-size: var(--fs-xxl);
  font-weight: var(--fw-bold);
  padding-bottom: 0.8rem;
  line-height: var(--lh-2);
}

.body {
  font-size: var(--fs-m);
  color: var(--mono-4);
  line-height: var(--lh-4);
}

.page-container {
  transition: transform 0.5s;
}

:global .collections-progress-main-area {
  margin: auto;
  padding: 0px;
  height: calc(100vh - var(--header-height) - 6.4rem);
  display: flex;
  justify-content: center;
}

@media (min-width: desktop) {
  .location-container :global .rodal-dialog {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }
}
