@value viewports: "../../../../viewports.css";
@value desktop from viewports;

.image-preview-container {
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  background-color: var(--brand-2);
}

.image-preview-container .image-preview {
  object-fit: contain;
  height: 40rem;
}

@media (min-width: desktop) {
  .image-preview-container .image-preview {
    height: 45rem;
  }
}

.image-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-preview-with-actions {
  margin-bottom: 2.4rem;
  position: relative;
}

.image-actions {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.8rem;
}

.image-actions--hide {
  display: none;
}

.image-actions--upload-failed {
  display: flex;
  justify-content: flex-end;
}

.image-preview-container .image-focus-point-crosshair {
  display: block;
  position: absolute;
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 0.2rem;
  margin: -0.2rem 0 0 -0.2rem;
}

.image-preview-container .image-focus-point-crosshair:before,
.image-preview-container .image-focus-point-crosshair:after {
  content: "";
  display: block;
  position: absolute;
  background: var(--white);
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.4);
}

.image-preview-container .image-focus-point-crosshair:before {
  height: 0.1rem;
  width: 2.1rem;
  left: -0.9rem;
  top: 0.1rem;
}

.image-preview-container .image-focus-point-crosshair:after {
  height: 2.1rem;
  width: 0.1rem;
  top: -0.9rem;
  left: 0.1rem;
}
