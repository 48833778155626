@value viewports: "../../../../viewports.css";
@value desktop from viewports;

.reference-story-element-container {
  margin-bottom: 0.8rem;
}

.reference-story-element-fields {
  border-bottom: 0.1rem dashed var(--mono-3);
  margin-bottom: 2rem;
}

.reference-story-element-delete {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: var(--fs-xs);
  color: var(--mono-4);
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.reference-story-element-add {
  margin-top: 1rem;
}

@media (min-width: desktop) {
  .reference-story-element-container {
    width: 450px;
  }
}
