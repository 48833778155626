@value viewports: "../../../../viewports.css";
@value desktop from viewports;

.image-preview-carousel {
  width: 100%;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thumbnail-carousel {
  height: 8rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--mono-2);
}

.thumbnail-carousel .slider-slide {
  display: flex !important;
  align-items: center;
}

.thumbnail-carousel .image-thumbnail {
  position: relative;
  min-width: 7.3rem;
  height: 4.8rem;
  margin: 0 0.4rem;
  background-color: var(--mono-5);
}

.thumbnail-carousel .image-thumbnail--is-active {
  position: relative;
  min-width: 6.5rem;
  height: 5.4rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: 5px solid var(--white);
  box-sizing: content-box;
  transition: height 0.1s;
}

.thumbnail-carousel .image-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.thumbnail-error {
  position: absolute;
  color: var(--danger);
  top: 0.2rem;
  left: -0.4rem;
}

.image-arrow-left,
.image-arrow-right {
  width: 4.8rem;
  height: 4.8rem;
  opacity: 0.8;
  background-color: var(--mono-2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.image-arrow-left {
  transform: rotate(270deg);
}

.image-arrow-right {
  transform: rotate(90deg);
}

.image-preview-carousel :global .slider-control-centerright,
.image-preview-carousel :global .slider-control-centerleft {
  top: 225px !important;
}

.hide-arrow {
  visibility: hidden;
}

.image-upload-info {
  font-family: var(--brandType);
  font-size: var(--fs-m);
  color: var(--mono-5);
  user-select: none;
}

.image-selected-info {
  font-family: var(--brandType);
  font-size: var(--fs-m);
  color: var(--mono-5);
  padding: 0.8rem 0;
}

.image-preview-carousel :global .slider-control-topright,
.image-preview-carousel :global .slider-control-topleft {
  top: -40px !important;
}

@media (min-width: desktop) {
  .image-preview-carousel {
    width: 58.4rem;
  }
}

.image-preview {
  padding-bottom: 2.4rem;
}
