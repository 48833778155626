/*
  Bootstrap uses rem values for variables and relies on
  the browser default value of 16px for 1rem. Since, we
  use 62.5% of the default 16px, which is 10px, as 1rem,
  we need to scale the variables proportionally.

  The bootstrap styles are applied to the descendents of the
  'bootstrap-iso' class (defined in the form builder component)
  and the 'formio-dialog'. The 'formio-dialog' specifically because
  it does not have 'bootstrap-iso' div as its ancestor.
*/

@function convert-to-local-rem($original-rem) {
  @return $original-rem * 16 / 10;
}

.bootstrap-iso,
.formio-dialog {
  $spacer: convert-to-local-rem(1rem);
  $form-text-margin-top: convert-to-local-rem(0.25rem);
  $form-check-input-gutter: convert-to-local-rem(1.25rem);
  $form-check-input-margin-y: convert-to-local-rem(0.25rem);
  $form-check-input-margin-x: convert-to-local-rem(0.3rem);
  $form-check-inline-margin-x: convert-to-local-rem(0.25rem);
  $form-check-inline-input-margin-x: convert-to-local-rem(0.75rem);
  $form-group-margin-bottom: convert-to-local-rem(0.3125rem);
  $font-size-base: convert-to-local-rem(0.8rem);
  @import "~bootstrap/scss/bootstrap";
  // Search bar in form builder
  .builder-sidebar_search {
    margin-bottom: 10px;
    box-sizing: border-box;
  }
  // Dropdown in field edit modal
  .dropdown {
    opacity: 1;
  }
  // Dropdown toggle icon in field edit modal
  .choices[data-type*="select-one"]:after {
    top: 75%;
  }
  // Form builder-group header (which holds group of fields)
  .form-builder-group-header {
    padding: 0;
  }
  // Space after dropdown in field edit modal
  .formio-choices.form-group {
    margin-bottom: 1.2rem;
  }
  // Space after info box in logic section of field edit modal
  .list-group {
    margin-bottom: 1rem;
  }
}
