.context-menu-btn {
    border: none;
    background: unset;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.context-menu {
    background-color: var(--white);
    box-shadow: var(--box-shadow-m);
    border-radius: 0.4rem;
    padding: 0.8rem 0;
    list-style: none;
    outline: none;
}

.context-menu-item {
    padding: 1rem 1.6rem;
    font-size: var(--fs-s);
    min-width: 14rem;
    white-space: nowrap;
    color: var(--mono-5);
    font-weight: var(--fw-normal);
    list-style: none;
    outline: none;
}

.context-menu-item--hover {
    cursor: pointer;
    background-color: var(--mono-2);
}

.context-menu-item--disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
}

.context-submenu-item {
    display: flex;
    position: relative;
    align-items: center;
}

.context-submenu-item::after {
    content: '';
    position: absolute;
    right: 1.6rem;
    width: 0.8rem;
    height: 0.8rem;
    border-top: 2px solid var(--mono-5);
    border-left: 2px solid var(--mono-5);
    transform: rotate(135deg);
}

[dir="rtl"] .context-submenu-item::after {
    right: unset;
    left: 1.6rem;
    transform: rotate(-45deg);
}
