@value viewports: "../../../viewports.css";
@value desktop from viewports;

.story-element-container {
  display: grid;
  grid-auto-rows: 40px auto 40px;
  grid-template-columns: 100%;
  margin-bottom: 2.4rem;
  position: relative;
}

.story-element-container:focus {
  outline: none;
  border: 1px dashed var(--mono-4);
}

.story-element-left-pan {
  border-right: none;
  display: block;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 50%;
}

.story-element-right-pan {
  padding: 1.2rem;
  word-break: break-word;
}

.story-element-right-pan :global .react-select__menu .react-select__menu-list {
  background-color: var(--white);
}

@media (min-width: desktop) {
  .story-element-details {
    padding: 2.6rem 0;
  }
}

@media (min-width: desktop) {
  .story-element-left-pan {
    display: flex;
    width: unset;
  }

  [dir="ltr"] .story-element-left-pan {
    border-right: 1px solid var(--mono-3);
  }

  [dir="rtl"] .story-element-left-pan {
    border-left: 1px solid var(--mono-3);
  }
}

.story-editor-story-element-actions {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  transition: opacity, visibility ease 0.4s;
  z-index: var(--z1);
}

[dir="ltr"] .story-editor-story-element-actions {
  right: 0;
}

[dir="rtl"] .story-editor-story-element-actions {
  left: 0;
}

.story-editor-story-element-action {
  cursor: pointer;
  width: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--mono-4);
}

.story-element-container:hover:not(.story-element-disabled) .story-editor-story-element-actions {
  opacity: 1;
  visibility: visible;
}

.story-element-container:hover:not(.story-element-disabled) :global .story-editor-child-story-element-actions {
  visibility: visible;
  opacity: 1;
}

.story-element-has-error .story-element-details svg {
  fill: var(--danger);
}

.story-element-container:hover:not(.story-element-has-error):not(.story-element-disabled) .story-element-details svg {
  fill: var(--brand-3);
}

.story-element-container:hover:not(.story-element-disabled) .story-element-right-pan {
  background-color: var(--mono-1);
}

.story-element-has-error .story-element-left-pan {
  border-color: var(--danger);
}

.story-element-text-container div h2,
.story-element-text-container div h3,
.story-element-text-container div h4,
.story-element-text-container div h5,
.story-element-text-container div h6 {
  margin: 0;
}

.story-element-container ol li p,
.story-element-container ul li p {
  margin: 0;
}

.story-element-container:hover:not(.story-element-disabled) :global .story-element-toolbar {
  opacity: 1;
  visibility: visible;
  transition: opacity, visibility 0.4s;
}

@media (min-width: desktop) {
  :global .story-element-toolbar {
    opacity: 0;
    visibility: hidden;
    transition: opacity, visibility 0.4s;
  }

  .story-element-container {
    grid-auto-rows: unset;
    grid-template-columns: 60px 1fr;
  }

  .story-element-container:hover :global .story-element-toolbar {
    opacity: 1;
    visibility: visible;
  }

  .story-element-container:hover:not(.story-element-disabled) :global .story-element-toolbar {
    opacity: 1;
    visibility: visible;
  }
  .element-toolbar-mobile {
    position: relative;
    left: 2.2rem;
  }

  .left-pan-mobile-element {
    margin-left: unset;
  }

  .story-element-right-pan {
    width: var(--story-element-width);
    word-break: unset;
  }

  .story-element-details .ai-generate-content {
    padding-left: unset;
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ai-generate-content__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
  padding: 0;
}

.story-element-container:hover:not(.story-element-has-error):not(.story-element-disabled) .ai-generate-content__btn svg {
  fill: var(--white);
}

.ai-generate-content {
  padding-left: 1rem;
  display: inline-block;
  position: relative;
  bottom: 0.25rem;
}