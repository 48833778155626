@value viewports: "../../../../viewports.css";
@value desktop from viewports;

.header-card {
  display: grid;
  grid-template-columns: 2.4rem 1fr;
  grid-gap: 1.2rem;
  padding: 0 3rem 0 3.5rem;
}
.header-card .story-title {
  margin: 0;
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
  line-height: var(--lh-3);
  color: var(--mono-5);
}

.header-card .story-subtitle {
  font-size: var(--fs-s);
  line-height: var(--fs-s);
  color: var(--mono-4);
}

.header-card .hero-image {
  grid-column: 1/-1;
  padding: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--mono-2);
}

.cards {
  padding: 0;
}
.card {
  margin: 1.6rem auto;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  padding: 1.6rem 3.2rem;
  cursor: pointer;
}
.card:hover {
  background-color: var(--mono-2);
}
.card.is-selected {
  background-color: var(--brand-2);
}

.story-element iframe {
  width: 100% !important;
}
.import-sticky-bar {
  background-color: var(--white);
  box-shadow: 0 3px 8px 0 rgba(19, 36, 55, 0.25);
  width: 100%;
  height: 5.5rem;
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--mono-4);
  cursor: pointer;
}

.import-sticky-bar.with-banner {
  bottom: var(--banner-height-mobile);
}

.story-element {
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-column-gap: 0.8rem;
  margin-bottom: 1.6rem;
}

.story-element-image-gallery {
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8rem;
}

@media (min-width: desktop) {
  .import-sticky-bar.with-banner {
    bottom: var(--banner-height);
  }
}
