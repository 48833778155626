.collection-filters {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s linear;
}

.collection-filters.is-expanded {
  max-height: 100rem;
  overflow: initial;
}

.collection-filters-set {
  margin-top: 1.6em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}
.collection-filter:nth-child(1),
.collection-filter:nth-child(2),
.collection-filter:nth-child(5) {
  grid-column: span 2;
}
.collection-filters-buttons {
  margin: 1.6rem 0;
  display: flex;
  justify-content: flex-end;
}

[dir="ltr"] .collection-filters-action-button {
  margin: 0 0 0 1.6rem;
}

[dir="rtl"] .collection-filters-action-button {
  margin: 0 1.6rem 0 0;
}

.collection-filter .error-message {
  display: none;
}
