/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.forgot-password-info {
  font-family: var(--brandType);
  font-size: var(--fs-xs);
  color: var(--mono-4);
  line-height: var(--lh-4);
  margin-bottom: 4.8rem;
}
