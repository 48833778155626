.add-new-media-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.add-new-media-dropzone {
  display: grid;
  height: 100%;
  grid-template-rows: repeat(3, 1fr);
}

.add-new-media-spinner {
  user-select: none;
  grid-row: 2 / 3;
}

.add-new-media-buttons-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  grid-row: 2 / 3;
}

.add-new-media-drop-here-text {
  grid-row: 3 / 4;
  align-self: end;
  text-align: center;
  font-size: var(--fs-s);
  color: var(--mono-4);
  margin-bottom: 2rem;
  user-select: none;
}

.add-new-media-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  margin: 0 0.8rem;
}

.add-new-media-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--mono-5);
  border: 1px solid var(--mono-5);
  border-radius: 3px;
  padding: 0.8rem 1.6rem;
  font-size: var(--fs-s);
  cursor: pointer;
}

.file-input {
  display: none;
}

[dir="ltr"] .add-new-media-button-text {
  margin-left: 0.6rem;
}

[dir="rtl"] .add-new-media-button-text {
  margin-right: 0.6rem;
}
