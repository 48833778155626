@value viewports: "../../../../../../viewports.css";
@value desktop from viewports;

.user-profile-page {
  --user-avatar-width: 19.2rem;
  --user-avatar-height: 19.2rem;
  background-color: var(--white);
  padding: 1.2rem;
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
}

.user-image-container {
  grid-row: 1/4;
  width: 24rem;
  position: relative;
  text-align: center;
  margin: 2rem auto 0;
}

@media (min-width: desktop) {
  .user-profile-page {
    display: grid;
    grid-template-columns: max-content 0.9fr;
    grid-column-gap: 8rem;
    padding: 7.2rem 5.6rem 6rem;
  }
  .user-image-container {
    margin: 0;
  }
}

.user-form-container {
  display: grid;
  row-gap: 2.4rem;
}

.avatar-loader-container {
  color: var(--white);
  background-color: var(--brand-2);
  width: var(--user-avatar-width);
  height: var(--user-avatar-height);
  border-radius: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
}

.user-avatar-image {
  width: var(--user-avatar-width);
  height: var(--user-avatar-height);
  margin: 0.8rem auto;
  border-radius: 50%;
  display: block;
  background: url("~assets/icons/default-avatar.svg") no-repeat center center;
  background-size: cover;
  position: relative;
}

.edit-user-avatar-image {
  width: var(--user-avatar-width);
  height: var(--user-avatar-height);
  object-fit: cover;
  border-radius: 50%;
}

.upload-input {
  visibility: hidden;
}

.user-image-edit-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  position: absolute;
  top: 13rem;
  right: 3rem;
  cursor: pointer;
  z-index: var(--z200);
}

.user-common-fields-container {
  border-bottom: 1px solid var(--brand-1);
  padding-bottom: 2.4rem;
}

.user-can-login-toggle {
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  margin-top: 1rem;
  margin-bottom: 2.4rem;
  font-size: var(--fs-s);
  font-weight: var(--fw-bold);
}

:global .edit-user-profile-button {
  justify-content: center;
}

.user-actions .author-button {
  grid-column: span 2;
}

.user-actions.is-teams-disabled .author-button {
  grid-column: span 1;
}

:global .user-name {
  grid-column: 2/-1;
}

.user-access-container {
  grid-column: 2/-1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.user-action-container {
  display: grid;
  row-gap: 2.4rem;
  border-bottom: 1px solid var(--brand-1);
  padding-bottom: 2.4rem;
  font-family: var(--brandType);
  font-size: var(--fs-m);
  font-weight: var(--fw-bold);
  line-height: var(--lh-4);
}

:global .edit-user-chip {
  margin: 0.8rem 0.8rem 0 0;
}

.user-teams-container {
  font-family: var(--brandType);
  font-size: var(--fs-m);
  font-weight: var(--fw-bold);
  line-height: var(--lh-4);
  color: var(--mono-5);
}

.user-teams-container .button {
  margin-top: 0.8rem;
}

.user-selected-roles,
.user-selected-teams {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.edit-user-page-container {
  transition: transform 0.5s;
}

.roles-list-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.6rem;
}

.user-profile-page .text-area-wrapper {
  grid-column: 2/-1;
}

.dark-mode-toggle {
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  align-items: center;
}

.dark-mode-toggle-text {
  font-size: var(--fs-s);
}

.preference-text {
  font-size: var(--fs-m);
}

.select-field {
  width: 218px;
}

.custom-fields-header {
  margin: 0;
  margin-bottom: 2.4rem;
}

.tfa-title-help-text,
.tfa-setup-help-text {
  font-family: var(--brandType);
  font-size: var(--fs-s);
  font-weight: var(--fw-normal);
  line-height: var(--fs-m);
  letter-spacing: normal;
  color: var(--mono-4);
  margin: 0;
  grid-column: 1/2;
  grid-row: 2/3;
}

.tfa-setup-container {
  margin-top: 1.6rem;
}

.tfa-label-container {
  margin-bottom: 0.8rem;
}

.tfa-dialog-content {
  margin-right: -3.2rem;
  margin-top: -1.6rem;
}
