@value viewports: "../../../../viewports.css";
@value desktop from viewports;

.content-header {
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
}
.content-header-details {
  flex-grow: 1;
}
.content-header-title {
  display: flex;
  font-family: var(--brandType);
  font-size: var(--fs-xxl);
  font-weight: var(--fw-bold);
  line-height: var(--lh-1);
  color: var(--mono-5);
  margin: 0;
}
.content-title-info {
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}
.content-header-description {
  font-family: var(--brandType);
  font-size: var(--fs-m);
  line-height: var(--lh-4);
  color: var(--mono-4);
  margin: 0.8rem 0 0 0;
}

[dir="ltr"] .content-header-button-container {
  margin-left: auto;
}

[dir="rtl"] .content-header-button-container {
  margin-right: auto;
}

@media (min-width: desktop) {
  .content-header {
    flex-direction: row;
  }
}
