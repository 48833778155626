/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.text-limit-warning {
  color: var(--warning);
}

.text-limit-error {
  color: var(--danger);
}

.text-limit-ok.is-greater-limit {
  display: flex;
  flex-direction: column;
}

.text-limit-ok.is-greater-limit span:first-child {
  padding-bottom: 0.4rem;
}

.text-limit span:nth-child(2)::before {
  content: "/";
}

.text-limit.is-greater-limit span:nth-child(2)::before {
  padding-right: 0.2rem;
}
