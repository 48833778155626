.filters-form {
  padding: 1.6rem;
}

.filter-access-level {
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 16px;
}

.active-filters-actions {
  display: flex;
  justify-content: flex-end;
}
.active-filters-actions .button:first-child {
  margin-right: 1.6rem;
}

.filter-story-attributes {
  display: grid;
  grid-column-gap: 0.8rem;
  grid-template-columns: 1fr 2fr 32px;
  align-items: center;
}

.attributes-and-value-container {
  margin-bottom: 0.8rem;
}

.filters-form .react-select-wrapper-title {
  font-family: var(--brandType);
  font-size: var(--fs-s);
  font-weight: var(--fw-bold);
  line-height: var(--lh-5);
  color: var(--mono-5);
  text-transform: uppercase;
  margin: 0;
}

.multiple-select-row-container {
  display: grid;
  grid-template-columns: 1fr 1fr 32px;
  grid-gap: 8px;
}

.filters-form :global .filters-form-date-filter-container {
  margin-bottom: 2.8rem;
}

.checkbox-field {
  margin-top: 2rem;
}
