/*
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.media-library {
  display: grid;
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-row-gap: 3.6rem;
}

.inspector-tabs {
  margin-bottom: 2.4rem;
}

.media-library.is-sidebar-active {
  transform: translateX(calc(var(--navbar-width) + var(--sidebar-width) + 4.8rem));
  margin: 0;
}

:global .image-upload-and-edit-container .inline-error {
  margin-bottom: 1.6rem;
}

.archived-images-header-title {
  display: flex;
  font-family: var(--brandType);
  font-size: var(--fs-xxl);
  font-weight: var(--fw-bold);
  line-height: var(--lh-1);
  color: var(--mono-5);
  margin: 0;
  margin-bottom: 4rem;
}
