/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.search-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8rem;
  --verticalRhythm: 0.8rem;
}

.search-field-input {
  border-radius: 0.4rem;
  border: 1px solid var(--mono-3);
  outline: none;
  padding: 0.6rem 0.8rem;
  margin-bottom: 0.8rem;
  color: var(--mono-5);
  font-size: var(--fs-s);
}
