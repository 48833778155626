/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.category-title {
  position: relative;
  font-family: var(--brandType);
  font-size: var(--fs-m);
  font-weight: var(--fw-bold);
  line-height: var(--fs-s);
  color: var(--mono-5);
  margin: 2.4rem 0 1.6rem 0;
}

.category-title span {
  background-color: var(--white);
  padding-right: 0.8rem;
}

.category-title span:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid var(--mono-3);
  right: 0;
  width: 100%;
  top: 50%;
  z-index: var(--z-1);
}
