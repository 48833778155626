[dir="rtl"] .story-page-container .social-cards .social-card-publish-container .checkbox-container .checkbox {
  margin-left: 0.8rem;
}

[dir="ltr"] .story-page-container .social-cards .social-card-publish-container .checkbox-container .checkbox {
  margin-right: 0.8rem;
}

[dir="rtl"] .story-page-container .social-cards .social-card-container .social-account .checkbox-container .checkbox {
  margin-left: 0.8rem;
}

[dir="ltr"] .story-page-container .social-cards .social-card-container .social-account .checkbox-container .checkbox {
  margin-right: 0.8rem;
}

.social-card-container {
  border-bottom: 1px solid var(--brand-1);
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  padding: 0 0 0.8rem 0;
}

.social-card-delete {
  cursor: pointer;
  color: var(--mono-4);
}

.social-cards {
  margin-bottom: 1.6rem;
}

.story-page-container
  .social-cards
  .social-card-container
  .social-card-social-accounts
  .social-account
  .social-account-provider {
  margin: 0 0.8rem 0 0;
}

.story-page-container .social-cards .social-card-container .social-card-social-accounts .social-account {
  margin: 0 0.8rem 1.6rem auto;
}

.social-card-social-accounts {
  margin: 3rem 0;
}

.story-page-container .social-cards .social-card-container .social-card-title-and-action .social-card-title {
  margin: 0;
}

.social-card-title-and-action {
  display: flex;
  margin-bottom: 0.8rem;
  justify-content: flex-end;
  align-items: center;
}

.social-share-message {
  position: relative;
}

.social-share-message .social-share-message__ai-generate-btn {
  position: absolute;
  right: 0;
  top: -0.2rem;
  padding: var(--space-xxxs)
}

[dir="rtl"] .social-share-message .social-share-message__ai-generate-btn {
  left: 0;
  right: unset;
}

.social-share-message--show-ai-generate-btn > div > div > div > div > div > span {
  margin-right: 4rem;
}

[dir="rtl"] .social-share-message--show-ai-generate-btn > div > div > div > div > div > span {
  margin-left: 4rem;
}