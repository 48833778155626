/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.map {
  height: 100%;
  border: 0.1rem solid var(--mono-3);
  border-radius: 0.4rem;
}

.map-disabled{
  height: 100%;
  border: 0.1rem solid var(--mono-3);
  border-radius: 0.4rem;
  pointer-events: none;
}

.location-selected {
  font-family: var(--brandType);
  font-size: var(--fs-xs);
  color: var(--mono-4);
  margin-top: 0.8rem;
  min-height: 1.2rem;
  font-weight: var(--fw-normal);
}

.action-button-label {
  color: var(--brand-4);
  font-size: var(--fs-s);
}

.location-info-and-action {
  display: flex;
  justify-content: space-between;
}

.pac-container.pac-logo.hdpi {
  z-index: var(--googleSearchDropdown);
}

.hide-map {
  display: none;
}
