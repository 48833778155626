@value viewports: "../../../../viewports.css";
@value desktop from viewports;

.story-timeline-container {
  box-shadow: var(--box-shadow-m);
  top: 0;
  bottom: 0;
  position: fixed;
  overflow-x: scroll;
  margin-top: var(--header-height);
  background-color: var(--white);
  z-index: var(--z900);
  width: 100vw;
}

.story-timeline-container.with-banner {
  margin-top: calc(var(--header-height) + var(--banner-height-mobile));
}

[dir="ltr"] .story-timeline-container {
  right: 0;
}

[dir="rtl"] .story-timeline-container {
  left: 0;
}

.story-timeline-header {
  display: flex;
  align-items: center;
  padding: 1.2rem 0;
}

.story-timeline-close {
  margin: 1.2rem;
  cursor: pointer;
}

.story-timeline-title {
  font-family: var(--brandType);
  font-size: 1.6rem;
  font-weight: var(--fw-bold);
  text-transform: uppercase;
  color: var(--mono-5);
  letter-spacing: 2px;
  flex-grow: 1;
}

.story-timeline-events {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.story-timeline-event-container {
  margin-bottom: 0.6rem;
  cursor: pointer;
}

[dir="ltr"] .story-timeline-event-container {
  padding: 1rem 0 1rem 4.8rem;
}

[dir="rtl"] .story-timeline-event-container {
  padding: 1rem 4.8rem 1rem 0;
}

.story-timeline-event-meta-name {
  font-size: var(--fs-m);
  line-height: var(--lh-3);
  color: var(--mono-5);
  padding-bottom: 0.2rem;
  margin: 0;
}

.story-timeline-event-meta-date {
  font-size: var(--fs-xs);
  line-height: var(--lh-5);
  color: var(--mono-4);
  padding-bottom: 0.4rem;
  margin: 0;
}

.story-timeline-event.is-selected {
  background-color: var(--brand-2);
}

.story-timeline-actions {
  margin-top: 1rem;
  width: 16rem;
  display: flex;
  justify-content: space-between;
}

[dir="ltr"] :global .story-timeline-actions-button {
  margin-right: 1.6rem;
}
[dir="rtl"] :global .story-timeline-actions-button {
  margin-left: 1.6rem;
}

@media (min-width: desktop) {
  .story-timeline-container {
    width: var(--timeline-width);
    transition: right .4s ease, left .4s ease;
  }

  .story-timeline-container.with-banner {
    margin-top: calc(var(--header-height) + var(--banner-height));
  }

  [dir="ltr"] .story-timeline-container.with-slider-open {
    right: 30%;
  }

  [dir="rtl"] .story-timeline-container.with-slider-open {
    left: 30%;
  }


}
