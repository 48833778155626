.contributor-roles-container {
  background-color: var(--white);
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
  padding: 3.2rem 4.8rem;
}

.contributor-roles-container .contributor-roles-row {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  padding: 0.8rem 2.4rem;
  cursor: pointer;
}

.contributor-roles-container .contributor-roles-row:not(.contributor-roles-header) {
  border-bottom: 1px solid var(--mono-2);
}

.contributor-roles-container .contributor-roles-header {
  background-color: var(--mono-2);
  font-family: var(--brandType);
  font-size: var(--fs-s);
  font-weight: var(--fw-bold);
  line-height: var(--lh-4);
  color: var(--mono-5);
}

.contributor-roles-container .contributor-role-action {
  visibility: hidden;
  opacity: 0;
}

.contributor-roles-container .contributor-roles-row:not(.contributor-roles-header):hover .contributor-role-action {
  visibility: visible;
  opacity: 1;
}
