.consumers-selected-filters-container {
  transition: all 0.5s;
  display: flex;
  align-items: center;
}

.consumers-selected-filters {
  flex-grow: 1;
}

.consumers-filter-label{
  display: flex;
  width: max-content;
  margin: 0.8rem 0.4rem 0.4rem 0.4rem;
  background-color: var(--brand-1);
  text-transform: uppercase;
  padding: 0.4rem 0.8rem;
  border-radius: 28px;
  font-family: var(--brandType);
  font-size: var(--fs-xs);
  font-weight: var(--fw-semi-bold);
  line-height: var(--lh-1);
  color: var(--mono-4);
  align-items: center;
}

.clear-selected-filters {
  cursor: pointer;
  padding: 0.4rem 0.8rem;
  font-weight: var(--fw-semi-bold);
  margin: 0 0.4rem;
}
