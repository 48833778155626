.collections-rules-settings {
  padding: 1.6rem;
}

.collection-type-group {
  display: flex;
  flex-wrap: wrap;
}

.collection-type {
  flex-basis: 50%;
  margin-bottom: 1.6rem;
}

.collection-rules-title {
  font-size: var(--fs-m);
  font-weight: var(--fw-bold);
  margin: 0 0 1.6rem 0;
}

.collection-settings-rule {
  margin-top: 1.6rem;
}

.location-toggle-container {
  display: flex;
  justify-content: space-between;
  margin: 1.6rem 0;
}

.toggle-label {
  margin: 0;
  font-size: var(--fs-s);
}

.rule-location-container {
  height: 24rem;
}

.collection-settings-rule-button-container {
  margin-top: 0.8rem;
  display: flex;
  justify-content: flex-end;
}

.collection-settings-rule-location-button {
  color: var(--brand-3);
  font-size: var(--fs-xs);
  cursor: pointer;
}
