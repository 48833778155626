/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.go-back-to-main-menu {
  font-family: var(--brandType);
  font-size: var(--fs-m);
  line-height: var(--lh-3);
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: none;
  padding: 0;
  color: var(--mono-4);
  background: transparent;
  border: none;
}

[dir="ltr"] .go-back-to-main-menu {
  padding-left: 3.2rem;
}

[dir="rtl"] .go-back-to-main-menu {
  padding-right: 3.2rem;
}

.go-back-to-main-menu:first-child {
  min-height: 4.8rem;
}

[dir="ltr"] .go-back-to-main-menu svg {
  margin-right: 0.8rem;
}
[dir="rtl"] .go-back-to-main-menu svg {
  margin-left: 0.8rem;
}
