.template-field-container {
  border-bottom: 1px solid var(--mono-2);
  padding: 2.4rem 0 1.6rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8rem;
  align-items: center;
  grid-template-areas:
    "title switch"
    "checkBox numberField";
}

.template-field-container .checkbox-label {
  font-size: var(--fs-s);
  align-items: center;
}

:global .story-template-checkbox {
  grid-area: checkBox;
  margin-bottom: 1rem;
}

:global .field-container-switch {
  justify-self: end;
  grid-area: switch;
}

:global .max-character-limit.container {
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center;
  justify-self: end;
  grid-area: numberField;
  margin-bottom: 0;
}

:global .max-character-limit .fieldlabel {
  font-weight: var(--fw-normal);
}

.template-field-container .text-field .text-field-label {
  font-size: var(--fs-s);
  font-weight: var(--fw-normal);
}

:global .max-character-limit.input {
  width: 6rem;
  margin-right: 0.8rem;
  margin-top: 0;
}

.max-character-limit .fieldlabel {
  font-weight: var(--fw-normal);
}

:global .max-character-limit {
  align-self: flex-start;
}