.menu-items-no-content {
  margin-top: 3.6rem;
  padding: 1.6rem;
  border-radius: 0.5rem;
  background-color: var(--mono-2);
}

.menu-items-list {
  margin: 0;
  padding: 1.6rem;
  list-style: none;
  background-color: var(--brand-1);
  display: grid;
  grid-row-gap: 0.8rem;
  grid-template-columns: 1fr;
}

.no-menu-select {
  padding: 0px;
  height: calc(100vh - var(--header-height) - 6.4rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu-select-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--mono-4);
  margin-top: 1.6rem;
}

.menu-select-message-text-body {
  margin: 0;
  padding-bottom: 0.8rem;
}

.text-link {
  padding-top: 0.8rem;
  cursor: pointer;
  background: none;
  border: none;
  color: var(--brand-3);
}
