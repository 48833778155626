/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
.general-settings-spinner {
  margin-top: 8rem;
}

.general-settings-container {
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
  margin-bottom: 2.8rem;
  margin-top: 3.2rem;
}

.general-settings-sitemap-container {
  margin-bottom: 2.4rem;
}
