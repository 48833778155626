.image-gallery-add-images-container {
  background-color: var(--mono-2);
  height: 24rem;
  user-select: none;
}

.image-gallery-image-wrapper {
  margin-bottom: 2.4rem;
  position: relative;
}

.image-gallery-image-actions-container {
  position: absolute;
  right: -6.3rem;
  bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity, visibility ease 0.4s;
}
.image-gallery-image-action {
  width: 3.2rem;
  height: 3.2rem;
  color: var(--mono-4);
  cursor: pointer;
  text-align: center;
}
.is-disabled {
  pointer-events: none;
}
