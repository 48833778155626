/* Attribute tab */

.story-page-container .select-container:first-child .error-message {
  display: none;
}

/* Metadata tab */

.story-page-container .metadata-container .recommended-tag .is-disabled {
  cursor: no-drop;
  background-color: gray;
}

.manage-inspector-container .tab-list {
  margin-bottom: 0.8rem;
}

.story-page-container .metadata-container .access-level-container {
  margin-bottom: 3.2rem;
}

.story-page-container .metadata-container .listicle-title-numbering-type-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 1.6rem;
  align-items: center;
}
.story-page-container .metadata-container .listicle-title-numbering-container {
  margin: 3.2rem 0;
}

.manage-inspector-container {
  padding: 0 1.6rem;
}
