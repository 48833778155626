@value viewports: "../../../../viewports.css";
@value desktop from viewports;

.advanced-search-wrapper {
  padding: 1rem;
  margin-top: 1rem;
  background-color: var(--mono-2);
}

.advanced-search-wrapper .top-label-row {
  display: flex;
  justify-content: space-between;
}

.advanced-search-wrapper .apply-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}

.advanced-search-wrapper .filter-row {
  display: grid;
  align-items: center;
  grid-column-gap: 1.6rem;
}

::placeholder {
  color: var(--mono-4);
  font-weight: var(--fw-bold);
}

.advanced-search-close {
  cursor: pointer;
}

@media (min-width: desktop) {
  .advanced-search-wrapper .filter-row {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
