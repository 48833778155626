@value viewports: "../../../../viewports.css";
@value desktop from viewports;
.oembed-url-field .oembed-text-field.text-field-input {
  border: none;
}

:global .oembed-text-field .input {
  text-overflow: ellipsis;
}

@media (min-width: desktop) {
  :global .oembed-text-field .input {
    text-overflow: unset;
  }
}

.oembed-url-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1.6rem;
}

.choose-video-button-wrapper {
  display: grid;
  justify-content: center;
  margin-left: 0.8rem;
  padding-bottom: 1.6rem;
}

:global .choose-video-button {
  width: 13rem;
  height: 3.2rem;
  margin: 0.8rem 29.8rem 0 28.8rem;
}

.or-text {
  text-align: center;
  font-size: var(--fs-xs);
  color: var(--mono-4);
}

[dir="ltr"] .video-replace-or-delete-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 2.4rem;
  cursor: pointer;
}

[dir="rtl"] .video-replace-or-delete-buttons {
  display: flex;
  justify-content: flex-start;
  margin-top: 2.4rem;
  cursor: pointer;
}

[dir="ltr"] :global .remove-video-button {
  margin-left: 1.6rem;
}

[dir="rtl"] :global .remove-video-button {
  margin-right: 1.6rem;
}
