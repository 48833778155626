.billing-page-container {
  transition: transform 0.5s;
  background-color: var(--white);
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
}

.billing-page-container iframe {
  border: none;
  min-height: 72rem;
  width: 100%;
  height: 100%;
}
