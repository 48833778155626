@value viewports: "../../../../../viewports.css";
@value desktop from viewports;

.link-tag-container {
  background-color: var(--white);
  padding: 1rem;
  position: absolute;
  left: -0.8rem;
  top: 1.2rem;
  display: inline-flex;
  flex-direction: column;
  box-shadow: 0 3px 6px 0 rgba(19, 36, 55, 0.2);
  width: calc(100vw - 8%);
  outline: none;
}

@media (min-width: desktop) {
  .link-tag-container {
    width: var(--format-toolbar-width);
    left: -2rem;
  }
}

.link-tag-wrapper {
  display: flex;
  align-items: center;
}

.link-tag-input {
  flex-grow: 1;
  border-radius: 0.4rem;
  border: 1px solid var(--mono-3);
  outline: none;
  padding: 0.6rem;
}
.link-tag-button-container {
  display: flex;
  align-items: center;
}

.link-tag-action-button {
  margin: 0 0 0 0.8rem;
}

.link-tag-error {
  color: var(--danger);
  font-size: var(--fs-s);
  margin: 0.5rem 0;
}

.link-tag-actions {
  margin: 0.8rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
