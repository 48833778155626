.switch-setting-container {
  display: flex;
  margin-bottom: 1.2rem;
}

.switch-setting-description {
  margin-right: auto;
}

.switch-setting-hint {
  font-size: var(--fs-xs);
  color: var(--mono-4);
}
