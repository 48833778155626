.media-card {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  height: 158px;
  overflow: hidden;
  position: relative;
}

.media-card:hover {
  border: 2px solid var(--brand-4);
}

.media-card-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.media-card-checkbox-wrapper {
  position: absolute;
  top: 8px;
  right: 8px;
}

.media-card-analytics-container {
  position: absolute;
  bottom: 0.8rem;
  display: flex;
  left: 0.8rem;
  align-items: center;
  color: var(--white);
}


.media-card-source {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.media-card-source span {
  position: relative;
  color: var(--white);
  font-size: var(--fs-xs);
  font-weight: var(--fw-semi-bold);
}

.media-card-source::before {
  content: "";
  background-color: var(--mono-5);
  opacity: 0.7;
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  transition: background-color .25s ease-out;
  border-radius: 0 0 .8rem 0;
}