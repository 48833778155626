.breaking-news-form-container {
  background-color: var(--white);
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
  padding: 3.2rem 4.8rem;
}

.breaking-news-page-container {
  transition: transform 0.5s;
}

.social-content-prefix-preview {
  font-size: var(--fs-xs);
  color: var(--mono-4);
  position: relative;
  top: -22px;
}

.social-content-prefix-textarea {
  font-size: 16px;
}
