@value viewports: "../../../../viewports.css";
@value desktop from viewports;

.collection-lane {
  flex-grow: 1;
  flex-basis: 50%;
}

.open-collection-inspector-button {
  display: flex;
}

[dir="ltr"] .collection-lane {
  margin-right: 1.6rem;
}

[dir="rtl"] .collection-lane {
  margin-left: 1.6rem;
}

[dir="ltr"] .collection-lane:last-child {
  margin-right: 0;
}

[dir="rtl"] .collection-lane:last-child {
  margin-left: 0;
}

@media (min-width: desktop) {
  .collection-item-list {
    margin-top: 1.6rem;
  }
}
