/*
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

@value viewports: "../../viewports.css";
@value desktop from viewports;

.breaking-news-header {
  margin-bottom: 4.2rem;
}

.breaking-news-title {
  color: var(--mono-5);
  font-size: var(--fs-xxl);
  font-weight: var(--fw-bold);
}

.breaking-news-form-card {
  background-color: var(--white);
  padding: 0.8rem 1.2rem;
  border-radius: 0.4rem;
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 2.4rem;
}

@media (min-width: desktop) {
  .breaking-news-form-card {
    padding: 4.8rem 6.4rem;
  }
}

.breaking-news-form-field-container--headline {
  display: grid;
  grid-template-columns: 4.8rem minmax(0, 1fr);
  grid-gap: 2.4rem;
}

@media (min-width: desktop) {
  .breaking-news-form-field-container--headline {
    grid-template-columns: 4.8rem 1fr;
  }
}

.breaking-news-form-field-icon {
  border-right: 0.1rem solid var(--mono-3);
  font-size: var(--fs-xs);
  color: var(--mono-4);
}

.breaking-news-headline {
  font-weight: var(--fw-bold);
  font-size: 4rem;
}

.breaking-news-form-container {
  width: 100%;
}

@media (min-width: desktop) {
  .breaking-news-form-container {
    width: 36rem;
  }
}

.breaking-news-advanced-fields-button {
  margin-top: 1.6rem;
  margin-bottom: 3.2rem;
}

.breaking-news-social-accounts {
  display: flex;
  flex-direction: column;
  padding-top: 2.8rem;
  padding-left: 3.2rem;
}

.breaking-news-social-account-container {
  display: flex;
  padding-bottom: 1.8rem;
}

.breaking-news-social-account-container .social-account-provider {
  margin: 0 0.8rem 0 0;
}

.breaking-news-social-post-container {
  margin-top: 2rem;
  position: relative;
}

.story-inspector-bn-title-link-btn {
  position: absolute;
  top: -0.2rem;
  padding: var(--space-xxxs);
  right: 0;
}
