@value viewports: "../../../../../viewports.css";
@value desktop from viewports;

.entity-link-container {
    background-color: var(--white);
    padding: 1rem;
    position: absolute;
    left: -0.8rem;
    top: 1.2rem;
    display: inline-flex;
    flex-direction: column;
    box-shadow: 0 3px 6px 0 rgba(19, 36, 55, 0.2);
    width: calc(100vw - 8%);
    outline: none;
}

@media (min-width: desktop) {
    .entity-link-container {
        width: var(--format-toolbar-width);
        left: -2rem;
    }
}

.entity-link-button-container {
    display: flex;
    justify-content: flex-end;
}

.entity-link-button-container__btn {
    margin-left: 2rem;
}