.dropzone-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--mono-4);
  user-select: none;
}

.dropzone-message-text {
  font-weight: var(--fw-bold);
  margin-top: 0.8rem;
}

.text-small {
  font-size: 1.4rem;
}

.text-medium {
  font-size: 1.6rem;
}

.text-large {
  font-size: 2rem;
}

.dropzone-message-label {
  font-weight: var(--fw-normal);
  margin-top: 0.8rem;
}

.label-small {
  font-size: 1rem;
}

.label-medium {
  font-size: 1.1rem;
}

.label-large {
  font-size: 1.2rem;
}
