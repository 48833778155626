.recommended-tag {
  display: inline-flex;
  cursor: pointer;
  margin: 0.8rem 0.8rem 0.8rem 0;
}

.add-tag {
  display: inline-flex;
}

.recommended-tags-container {
  margin: 0.8rem 0 2.4rem;
}

.publisher-tags {
  margin: 0.8rem 0 0 0;
}
