.story-element {
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-gap: 1.2rem;
  padding-bottom: 1.6rem;
}

.story-texts {
  font-size: var(--fs-s);
  line-height: var(--lh-4);
  color: var(--mono-5);
}

.story-element-image {
  grid-column: 1/-1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--mono-2);
}

.story-element-image-gallery,
.story-element-composite {
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8rem;
}
