.sidebar-header-container {
  padding: 1.6rem 1.6rem 0 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title.sidebar-menu-title {
  padding: 0;
  margin: 0;
  text-transform: uppercase;
}

.sidebar-header-add-new-menu {
  cursor: pointer;
  display: flex;
}
