.integration {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1.6rem;
  background-color: var(--white);
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
  padding: 1.6rem;
  margin-bottom: 1.6rem;
}

@media (min-width: 786px) {
  .integration {
    padding: 3.2rem 6.4rem;
    grid-template-columns: 1fr 2fr;
  }
}

.integration.is-disabled {
  background-color: var(--mono-2);
}

.integration.is-disabled .text-field-input {
  background-color: var(--mono-3);
  border-color: var(--mono-4);
}

.integration-more-actions {
  grid-column: 1/-1;
}

[dir="rtl"] .integration-more-actions {
  text-align: left;
}
[dir="ltr"] .integration-more-actions {
  text-align: right;
}

.integration-icon-row {
  display: flex;
  justify-content: flex-end;
}

[dir="ltr"] .integration-action-dropdown {
  left: auto !important;
  right: 12rem !important;
  text-align: left;
}

[dir="rtl"] .integration-action-dropdown {
  right: auto !important;
  left: 12rem !important;
  text-align: right;
}

.integration-title {
  text-transform: capitalize;
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
  color: var(--mono-5);
  margin: 0;
  padding-bottom: 1.6rem;
}

:global .integrations-progress-main-area {
  margin: auto;
  padding: 0px;
  height: calc(100vh - var(--header-height) - 6.4rem);
  display: flex;
  justify-content: center;
}
