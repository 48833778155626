.progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50% auto;
}

.progress svg + p {
  margin-top: 1.2em;
}

.progress p + button {
  margin-top: 1.2em;
}

.progress .__react-svg-spinner_circle {
  color: var(--brand-3);
}
