/*
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
@value viewports: "../../viewports.css";
@value desktop from viewports;

@media (min-width: desktop) {
  .formatting-toolbar-wrapper {
    display: flex;
  }

  [dir="ltr"] .formatting-toolbar-wrapper {
    justify-content: flex-start;
  }

  [dir="rtl"] .formatting-toolbar-wrapper {
    justify-content: flex-end;
  }
}

:global .ProseMirror-focused + .format-toolbar-container .format-toolbar--focused {
  display: inline-flex;
  justify-content: space-evenly;
  background-color: var(--white);
  width: calc(100vw - 6%);
}

@media (min-width: desktop) {
  :global .ProseMirror-focused + .format-toolbar-container .format-toolbar--focused {
    width: unset;
    justify-content: unset;
    background-color: unset;
  }
}

:global .format-toolbar-container .format-toolbar--active {
  display: inline-flex;
  justify-content: space-evenly;
  background-color: var(--white);
  width: calc(100vw - 6%);
}

@media (min-width: desktop) {
  :global .format-toolbar-container .format-toolbar--active {
    width: unset;
    justify-content: unset;
    background-color: unset;
  }
}

:global .format-toolbar-container {
  position: relative;
  bottom: 6rem;
  z-index: var(--z1000);
}

@media (min-width: desktop) {
  :global .format-toolbar-container {
    bottom: unset;
  }
}

.format-toolbar {
  --toolBarShadow: 0 3px 10px 0 rgba(19, 36, 55, 0.2); /* --mono-4 with 75% opacity */

  padding: 0;
  list-style-type: none;
  display: none;
  will-change: transform;
  margin: -4.2rem 0.8rem 0 0.8rem;
  transform: translateX(-2rem);
  box-shadow: var(--toolBarShadow);
  position: absolute;
  transition: transform 0.2s ease;
}

@media (min-width: desktop) {
  .format-toolbar {
    margin: -4.2rem 0 0 0;
  }
}

[dir="ltr"] :global .format-toolbar-container .format-toolbar--active {
  flex-direction: row;
}

[dir="rtl"] :global .format-toolbar-container .format-toolbar--active {
  flex-direction: row-reverse;
}

[dir="ltr"] :global .ProseMirror-focused + .format-toolbar-container .format-toolbar--focused {
  flex-direction: row;
}

[dir="rtl"] :global .ProseMirror-focused + .format-toolbar-container .format-toolbar--focused {
  flex-direction: row-reverse;
}

.format-toolbar-item {
  color: var(--black);
  padding: 0.8rem 0;
  font-size: var(--fs-xl);
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

@media (min-width: desktop) {
  .format-toolbar-item {
    padding: 1rem 0.8rem;
  }
}

.format-toolbar-item:hover,
.format-toolbar-item:focus {
  background-color: var(--mono-2);
}

.format-toolbar-item.is-active {
  color: var(--brand-3);
}

.format-toolbar-submenu-wrapper {
  position: absolute;
  left: 0;
  top: 4.4rem;
  z-index: var(--z1000);
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  flex-direction: column;
  background-color: var(--white);
  width: 3.6rem;
  box-shadow: 0 3px 6px 0 rgba(19, 36, 55, 0.2);
}

@media (min-width: desktop) {
  .format-toolbar-submenu-wrapper {
    width: 5.4rem;
  }
}

.format-toolbar-submenu-item {
  color: var(--black);
  padding: 0.8rem;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.format-toolbar-submenu-item:hover {
  background-color: var(--mono-2);
}
