.form-header-buttons {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

[dir="ltr"] .form-header-button {
    margin-right: 1.6rem;
}

[dir="rtl"] .form-header-button {
    margin-left: 1.6rem;
}

.form-saving-text {
    font-size: var(--fs-xs);
    line-height: var(--lh-5);
    color: var(--mono-3);
    margin-right: 0.8rem;
}
