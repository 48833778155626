.form-editor-spinner {
    margin-top: 8rem;
}

.form-editor-builder-spinner {
    margin-top: 9.6rem;
}

.form-editor-use-desktop {
    background: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--box-shadow-l);
    margin-bottom: 0.8rem;
    padding: 2.4rem 1.2rem;
}
