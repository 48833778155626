.image-focus-point-crosshair {
  display: block;
  position: absolute;
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 0.2rem;
  margin: -0.2rem 0 0 -0.2rem;
}

.image-focus-point-crosshair:before,
.image-focus-point-crosshair:after {
  content: "";
  display: block;
  position: absolute;
  background: var(--white);
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.4);
}

.image-focus-point-crosshair:before {
  height: 0.1rem;
  width: 2.1rem;
  top: 0.1rem;
}

[dir="ltr"] .image-focus-point-crosshair:before {
  left: -0.9rem;
}

[dir="rtl"] .image-focus-point-crosshair:before {
  right: -0.9rem;
}

.image-focus-point-crosshair:after {
  height: 2.1rem;
  width: 0.1rem;
  top: -0.9rem;
}

[dir="ltr"] .image-focus-point-crosshair:after {
  left: 0.1rem;
}

[dir="rtl"] .image-focus-point-crosshair:after {
  right: 0.1rem;
}
