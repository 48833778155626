.delta-container {
  display: inline-block;
  justify-self: left;
}
.delta-container--positive {
  color: var(--success);
}

.delta-container--negative {
  color: var(--danger);
}

.delta-container--positive::before {
  display: inline-block;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--accent-3);
  margin-right: 0.8rem;
}

.delta-container--negative::before {
  display: inline-block;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--danger);
  margin-right: 0.8rem;
}

.delta-container--new::before {
  display: inline-block;
  content: "";
  width: 0;
  height: 0;
  border: 4px solid var(--brand-4);
  border-radius: 50%;
  margin-right: 0.8rem;
}

.delta-container .zero-icon {
  display: none;
}

.delta-container--zero .zero-icon {
  display: inline-block;
}

.delta-container--zero .zero-icon::before {
  display: inline-block;
  content: "";
  width: 0;
  height: 0;
  border-bottom: 5px solid transparent;
  border-right: 5px solid var(--mono-4);
  border-top: 5px solid transparent;
  margin-right: 0.2rem;
}

.delta-container--zero .zero-icon::after {
  display: inline-block;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid var(--mono-4);
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
}

.delta-container--large {
  font-size: var(--fs-l);
}

.delta-container--medium {
  font-size: var(--fs-m);
}

.analytics-container .delta-container--small {
  font-size: var(--fs-xs);
}
