@value viewports: "../../../../viewports.css";
@value desktop from viewports;

.webhooks-table-container {
  background-color: var(--white);
  padding: 0.8rem;
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
}

.webhooks-table :global .rt-tbody {
  cursor: pointer;
}

.webhooks-table :global .rt-td:nth-child(2) {
  margin: auto;
}

.webhooks-table :global .rt-thead,
.webhooks-table :global .rt-tbody {
  min-width: unset !important;
}

.webhooks-table :global .-header {
  background-color: var(--mono-2);
  font-weight: var(--fw-bold);
  font-size: var(--fs-s);
  height: 4rem;
  display: flex;
  align-items: center;
  padding: 0 1.6rem;
}

.webhooks-table :global .rt-tbody .rt-tr,
.webhooks-table :global .rt-thead .rt-tr {
  display: grid;
  grid-template-columns: minmax(0, 1fr) 4.8rem 4.8rem 2.4rem;
  grid-column-gap: 0.4rem;
}

.webhooks-table .webhook-url-container {
  min-height: 4.8rem;
  flex-grow: 1;
  width: 100%;
  display: flex;
  align-items: center;
}

.webhooks-table .webhook-url {
  overflow: hidden;
  text-overflow: ellipsis;
}

.webhooks-table :global .rt-tbody .rt-tr {
  font-size: var(--fs-s);
  padding: 0 1.6rem;
  align-items: center;
  min-height: 4.8rem;
  border-bottom: 1px solid var(--mono-2);
}

.webhooks-table :global .rt-tbody .rt-tr .rt-td {
  display: flex;
  width: unset !important;
}

[dir="ltr"] .webhooks-table :global .rt-thead .rt-tr {
  text-align: left;
}

[dir="rtl"] .webhooks-table :global .rt-thead .rt-tr {
  text-align: right;
}

.webhooks-table :global .rt-thead .rt-tr:last-child {
  flex-grow: 1;
}

.webhooks-table :global .rt-tr-group:hover {
  background-color: var(--mono-2);
}

.webhooks-table :global .rt-noData {
  text-align: center;
  margin-top: 2.4rem;
  font-family: var(--brandType);
  font-size: var(--fs-m);
  color: var(--mono-4);
}

@media (min-width: desktop) {
  .webhooks-table :global .rt-td:nth-child(2) {
    margin: unset;
  }
  .webhooks-table-container {
    padding: 3.2rem;
  }
  .webhooks-table :global .rt-tbody .rt-tr,
  .webhooks-table :global .rt-thead .rt-tr {
    grid-template-columns: minmax(0, 1fr) 7.2rem 8.4rem 4.8rem;
  }
}
