.slider-window {
    height: 100%;
    position: relative;
}

.slider-seperator {
    height: 100%;
    width: .5rem;
    background: var(--brand-3);
    position: absolute;
    left: -5px;
}

[dir="rtl"] .slider-seperator {
    right: -5px;
    left: unset;
}

.toggle {
    position: absolute;
    top: 50%;
    writing-mode: vertical-rl;
    left: -4px;
    background: var(--brand-3);
    color: var(--white);
    padding: 2rem 0.5rem;
    transform: translateY(-50%) translateX(-100%);
    border-radius: 100px 0 0 100px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: var(--fw-semi-bold);
    font-size: var(--fs-m);
    transition: all 0.3s ease;
    min-width: 2rem;
    min-height: 12rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toggle:hover {
    transform: translateY(-50%) translateX(-100%) scale(1.03);
    box-shadow: 0 0 15px rgba(76, 110, 245, 0.7);
}

.toggle__content {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 1px;
}

.toggle__content--updates-indicator::after {
    content: "";
    height: .8rem;
    width: .8rem;
    background-color: var(--success);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: 7px;
    bottom: 0;
}

[dir="rtl"] .toggle {
    transform: rotate(180deg) translateX(-100%);
    right: -4px;
    left: unset;
}

[dir="rtl"] .toggle:hover {
    transform: rotate(180deg) translateX(-100%) scale(1.03);
    box-shadow: 0 0 15px rgba(76, 110, 245, 0.7);
}

.toggle p {
    margin-top: .8rem;
    transform: rotate(180deg);
}

.toggle__arrow {
    transform: rotate(0deg);
    transition: all .5s ease;
}

[dir="rtl"] .toggle__arrow {
    transform: rotate(0deg);
}

.toggle__arrow.toggle__arrow--open {
    transform: rotate(180deg);
}

[dir="rtl"] .toggle__arrow.toggle__arrow--open {
    transform: rotate(180deg);
}

.iframe-content {
    outline: none;
    border: none;
    height: 100%;
    width: 100%;
}