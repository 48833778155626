.workspace-realtime {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: var(--mono-5);
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.6rem 1rem;
    transform: translateY(100%);
    transition: transform .4s ease;
    cursor: pointer;
    z-index: var(--z800);
    outline: none;
    border: none;
}

.workspace-realtime--show {
    transform: translateY(0);
}

.content {
    position: relative;
    color: var(--white);
    font-weight: var(--fw-semi-bold);
    font-size: var(--fs-s);
    text-align: center;
    text-transform: uppercase;
    display: flex;
    align-items: center;
}

.content::before {
    content: "";
    height: .8rem;
    width: .8rem;
    background-color: var(--success);
    border-radius: 50%;
    display: block;
    margin-right: .5rem;
}

.content p {
    margin: 0;
}