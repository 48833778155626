.use-desktop-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: var(--mono-2);
  width: 100%;
  padding: 12rem 0;
}

.use-desktop-label {
  color: var(--mono-4);
  font-size: var(--fs-l);
  font-weight: var(--fw-normal);
}

.use-desktop-hint {
  color: var(--mono-4);
  font-size: var(--fs-s);
  font-weight: var(--fw-normal);
  text-align: center;
  max-width: 70%;
}
