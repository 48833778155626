/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.badge {
  border-radius: 2px;
  background-color: var(--brand-3);
  color: var(--white);
  text-align: center;
  font-size: 1.4rem;
  padding: 0.2rem 0.8rem;
  font-weight: var(--fw-semi-bold);
}
.badge.is-muted {
  color: var(--mono-4);
  background-color: var(--brand-2);
}
