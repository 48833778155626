.category-toggles-container {
  background-color: var(--white);
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
  padding: 3.2rem 4.8rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 16px;
  list-style: none;
}
.category-item {
  display: grid;
  grid-template-columns: 1fr max-content max-content;
  grid-row-gap: 0.8rem;
  border-bottom: 1px solid var(--mono-3);
}

[dir="rtl"] .category-item {
  padding: 1.6rem 0 1.6rem 0.8rem;
}
[dir="ltr"] .category-item {
  padding: 1.6rem 0.8rem 1.6rem 0;
}

.category-item-title {
  font-family: var(--brandType);
  font-size: var(--fs-m);
  font-weight: var(--fw-normal);
  line-height: var(--lh-4);
  color: var(--mono-5);
  margin: 0;
  text-transform: capitalize;
}
.category-item-description {
  font-family: var(--brandType);
  font-size: var(--fs-xs);
  font-weight: var(--fw-normal);
  line-height: var(--fs-xxs);
  letter-spacing: normal;
  color: var(--mono-4);
  margin: 0;
  grid-column: 1/2;
  grid-row: 2/3;
}
.category-item-edit-action {
  cursor: pointer;
  align-self: center;
}

[dir="rtl"] .category-item-edit-action {
  margin-left: 1.6rem;
}
[dir="ltr"] .category-item-edit-action {
  margin-right: 1.6rem;
}
