.domain-manager-spinner {
  margin-top: 8rem;
}

.add-new-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.add-new-text {
  color: var(--brand-3);
  font-size: var(--fs-s);
  font-weight: var(--fw-semi-bold);
  margin-left: 0.4rem;
}
