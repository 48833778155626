@value viewports: "../../../../viewports.css";
@value desktop from viewports;

.form-editor-container {
    background: white;
    margin-bottom: 0.8rem;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
}

.form-editor-content {
    background: white;
}

@media (min-width: desktop) {
    .form-editor-container {
        display: grid;
        grid-template-columns: auto var(--story-editor-width) auto;
        background: white;
        margin-bottom: 0.8rem;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    }

    .form-editor-content {
        grid-column: 2/3;
    }
}
