@value viewports: "../../../../viewports.css";
@value desktop from viewports;

.collection-realtime-members {
  display: flex;
  margin: 1.6rem;
  align-items: center;
  position: relative;
}

.collection-realtime-members .collection-realtime-members-list {
  position: relative;
  width: 6rem;
  height: 3.2rem;
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
}
.collection-realtime-members .collection-realtime-member {
  display: flex;
  position: absolute;
  border-radius: 50%;
}

.collection-realtime-members .collection-realtime-member.member-1 {
  left: 0.8rem;
  z-index: var(--z300);
}
.collection-realtime-members .collection-realtime-member.member-2 {
  left: 2rem;
  z-index: var(--z200);
}
.collection-realtime-members .collection-realtime-member.member-3 {
  left: 3.2rem;
  z-index: var(--z100);
}

.collection-realtime-members .collection-realtime-member-meta {
  position: absolute;
  top: 2rem;
  left: -70px;
  right: 0;
  width: 150px;
  font-size: var(--fs-xs);
  line-height: var(--lh-5);
  visibility: hidden;
  opacity: 0;
  padding: 0.8rem;
  color: var(--black);
  background-color: var(--mono-3);
  transition: all 0.5s;
}
.collection-realtime-members .collection-realtime-member:hover .collection-realtime-member-meta {
  visibility: visible;
  opacity: 1;
  top: 3.4rem;
}
.collection-realtime-members .collection-realtime-member.current-member {
  border: 3px solid var(--brand-3);
}
.collection-realtime-member-image {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  object-fit: cover;
  background: url("~assets/icons/default-avatar.svg") no-repeat center center;
  background-size: cover;
}
.collection-realtime-members .collection-realtime-member-image.dropdown-image {
  margin-right: 0.8rem;
}

.collection-realtime-members .collection-ably-message {
  font-size: var(--fs-xs);
}

.collection-realtime-members .members-info-list {
  width: 200px;
  background-color: var(--white);
  padding: 0.8rem;
  visibility: hidden;
  opacity: 0;
  border-radius: 0.4rem;
  position: absolute;
  top: 2.4rem;
  left: 0;
  transform: translateY(-2.4rem);
  z-index: var(--z-1);
  transition: all 0.3s ease-out;
  box-shadow: var(--box-shadow-m);
}

.collection-realtime-members .members-info-list .member-info {
  padding: 0.8rem;
  list-style: none;
  display: flex;
  align-items: center;
}

.collection-realtime-members .collection-realtime-member-image.member-info {
  margin-right: 0.8rem;
}

.collection-realtime-members .members-info-list .member-name {
  font-size: var(--fs-s);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: var(--lh-5);
}

.collection-realtime-members:hover .members-info-list {
  visibility: visible; /* shows sub-menu */
  opacity: 1;
  z-index: var(--z-100);
  transition: all 0.3s ease-out;
  transform: translateY(0%);
}

.collection-realtime-member-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

@media (min-width: desktop) {
  .collection-realtime-members .collection-realtime-members-list {
    align-items: center;
  }
}
