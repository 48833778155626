@value viewports: "../../../../viewports.css";
@value desktop from viewports;

.ai-multi-suggestions {
    position: relative;
    transition: all 0.5s ease-in;
}

.ai-multi-suggestions .story-field {
    position: relative;
}

.ai-multi-suggestions--open {
    z-index: var(--z900);
    transition: unset;
}

.ai-multi-suggestions--open .story-field {
    z-index: 2;
    transition: all 0.4s ease-in;
    width: 100%;
}

.ai-multi-suggestions--open .story-field-wrapper {
    display: flex;
}

.ai-multi-suggestions__background {
    position: absolute;
    content: "";
    box-shadow: var(--box-shadow-l);
    border-radius: 1.2rem;
    left: -2rem;
    top: -2rem;
    z-index: 1;
    background: var(--white);
    overflow: hidden;
    width: calc(100% + 4rem);
    transform: scaleY(0);
    transform-origin: top;
    transition: all 0.2s ease-in;
    opacity: 0;
}

.ai-multi-suggestions--open .ai-multi-suggestions__background {
    transform: scaleY(1);
    opacity: 1;
}

@keyframes expandFadeIn {
    0% {
        max-height: 0;
        opacity: 0;
    }
    50% {
        max-height: 50rem;
        opacity: 0.1;
    }
    100% {
        max-height: 50rem;
        opacity: 1;
    }
}

.ai-suggestions-wrapper {
    font-size: var(--fs-m);
    background: var(--white);
    position: absolute;
    z-index: 2;
    overflow: hidden;
    opacity: 0;
    width: calc(100% + 2rem);
    left: -1rem;
    transition: all .2s ease-in;
    max-height: 0;
}

.ai-suggestions-wrapper--open {
    animation: expandFadeIn .4s forwards;
}

.ai-header {
    padding: 0 1.5rem;
    border-top: 1px solid var(--mono-2);
    border-bottom: 1px solid var(--mono-2);
    display: flex;
    justify-content: space-between;
}

.ai-suggestions {
    padding: .5rem;
    max-height: 24rem;
    overflow-y: auto;
    margin: 1rem 0;
}

.ai-suggestions::-webkit-scrollbar {
    width: 7px;
}

.ai-suggestions::-webkit-scrollbar-track {
    background: transparent;
}

.ai-suggestions::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 30px;
}

.ai-suggestions__suggestion {
    padding: 1rem;
    margin: 2rem 1rem;
    box-shadow: var(--box-shadow-s);
    color: var(--brand-4);
    font-weight: var(--fw-semi-bold);
    border-radius: 0.5rem;
    line-height: var(--lh-3);
    background: #f1f3fa;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    min-height: 4rem;
}

.ai-suggestions__suggestion:first-child {
    margin-top: 0;
}

.ai-suggestions__suggestion:last-child {
    margin-bottom: 0;
}

:not(.ai-suggestions__suggestion--loader).ai-suggestions__suggestion:hover {
    background: #e6eaf6;
}

.ai-suggestions__suggestion--loader {
    cursor: unset;
    display: block;
}

.ai-suggestions__suggestion__text {
    margin: 0;
    display: block;
    width: 100%;
}

.ai-suggestions__suggestion--highlight:before {
    position: absolute;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    content: "";
    left: -2px;
    top: -2px;
    z-index: -1;
    border-radius: 0.5rem;
    border: 2px solid var(--brand-3);
}

.ai-suggestions__suggestion__copy {
    visibility: hidden;
    position: absolute;
    right: 0;
    top: 0.25rem;
}

.shimmer-wrapper {
    margin-bottom: 0 !important;
}

.shimmer-wrapper div {
    background: linear-gradient(to right, #D5DCF4 8%, #E2E7F9 18%, #D5DCF4 33%);
    background-size: 1000px 100%;
}

.action-btn {
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    font-weight: var(--fw-semi-bold);
    color: var(--brand-3);
    display: grid;
    grid-gap: 1rem;
    grid-auto-flow: column;
    align-items: center;
}

.action-btn:hover {
    color: var(--brand-4);
}

.action-btn:disabled {
    cursor: unset;
    color:var(--mono-4)
}

.action-btn--close-btn {
    display: none;
}

.ai-header__right-side {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: desktop) {
    .action-btn--close-btn {
        display: unset;
    }

    .ai-multi-suggestions--shrink-header-element .ai-multi-suggestions__background {
        width: 100%;
        left: 1rem;
    }

    .ai-suggestions-wrapper--shrink-header-element {
        width: 100%;
        left: 1rem;
    }

    .ai-multi-suggestions--shrink-pm-editor-element .ai-multi-suggestions__background {
        width: calc(100% - 1.5rem);
        left: 1rem;
    }

    .ai-multi-wrapper--shrink-pm-editor-element {
        width: calc(100% - 1.5rem);
        left: 1rem;
    }

    .ai-suggestions__suggestion__text {
        margin: 0 1.5rem 0 0;
    }

    [dir="rtl"] .ai-multi-suggestions--shrink-header-element .ai-multi-suggestions__background {
        left: unset;
        right: 1rem;
    }

    [dir="rtl"] .ai-suggestions-wrapper--shrink-header-element {
        left: unset;
        right: 1rem;
    }

    [dir="rtl"] .ai-multi-suggestions--shrink-pm-editor-element .ai-multi-suggestions__background {
        left: unset;
        right: 1rem;
    }

    [dir="rtl"] .ai-multi-wrapper--shrink-pm-editor-element {
        left: unset;
        right: 1rem;
    }

    [dir="rtl"] .ai-suggestions__suggestion__text {
        margin: 0 0 0 1.5rem;
    }

    [dir="rtl"] .ai-suggestions__suggestion__copy {
        right: unset;
        left: 0;
    }

    .ai-suggestions__suggestion:hover .ai-suggestions__suggestion__copy  {
        visibility: unset;
    }
}
