/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

[dir="ltr"] .add-new-menu {
  left: calc(var(--navbar-width) + 4rem) !important;
  top: calc(var(--header-height) - 0.8rem) !important;
}

[dir="rtl"] .add-new-menu {
  left: calc(100% - var(--navbar-width) - 4rem) !important;
  top: calc(var(--header-height) - 0.8rem) !important;
}
