.collections-content-settings {
  padding: 1.6rem;
}

.collection-delete {
  margin-top: 1.6rem;
  padding-top: 1.6rem;
  border-top: 1px solid var(--mono-3);
}

.collection-delete-message {
  font-size: var(--fs-s);
  line-height: var(--lh-4);
  color: var(--mono-4);
  margin: 0 0 0.8rem 0;
}
