@value viewports: "../../../../viewports.css";
@value desktop from viewports;

[dir="ltr"] .image-upload-and-edit-container {
  padding: 0 0.8rem 0 1.2rem;
}

[dir="rtl"] .image-upload-and-edit-container {
  padding: 0 1.2rem 0 0.8rem;
}

@media (min-width: desktop) {
  [dir="ltr"] .image-upload-and-edit-container {
    padding: 0 2.4rem 0 3rem;
  }

  [dir="rtl"] .image-upload-and-edit-container {
    padding: 0 3rem 0 2.4rem;
  }
}
