.video-list-element {
  display: grid;
  grid-template-columns: 0.8fr 2.2fr;
  margin-top: 1.6rem;
  grid-gap: 0.8rem;
  cursor: pointer;
}

.oembed-inspector-search-and-filter {
  display: grid;
  grid-template-columns: 5fr 0.5fr;
}

.oembed-inspector-search {
  flex-grow: 1;
}

.oembed-inspector-filter-button {
  cursor: pointer;
  margin: 0 0.8rem 0.8rem 0.8rem;
}

.search-filters-wrapper {
  padding: 1.6rem;
  margin-top: 0.8rem;
  background-color: var(--mono-1);
}

.oembed-video-selector-wrapper {
  display: flex;
  flex-direction: column;
}

.video-list-element .video-list-element-fineprint {
  font-size: var(--fs-xs);
  color: var(--mono-4);
}

.video-list-pagination {
  margin-top: 1.6rem;
  display: flex;
  justify-content: space-evenly;
}

.oembed-inspector-filter-chips {
  margin-top: 0.8rem;
}

.no-videos-to-show {
  min-height: 16rem;
  display: flex;
  flex-direction: column;
  font-size: var(--fs-s);
  line-height: var(--lh-4);
  color: var(--mono-4);
  align-items: center;
  justify-content: center;
}

.video-paginate-small :global .-center {
  width: 9rem;
}

.video-paginate-medium :global .-center {
  width: 12rem;
}
