.card-container {
    background-color: var(--white);
    width: 100%;
    height: 100%;
    min-width: 16rem;
    min-height: 16rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    border-radius: 0.4rem;
    border: var(--mono-2) 1px solid;
}

.card-header-container {
    margin-bottom: auto;
}

.card-header {
    display: flex;
    align-items: flex-start;
}

.card-title {
    color: var(--mono-5);
    text-decoration: none;
    font-size: var(--fs-m);
    font-weight: var(--fw-bold);
    word-break: break-word;
}

.card-pin {
    cursor: pointer;
}

[dir="ltr"] .card-title {
    margin-right: auto;
}

[dir="rtl"] .card-title {
    margin-left: auto;
}

.card-delete {
    cursor: pointer;
}

[dir="ltr"] .card-delete {
    margin-left: 0.4rem;
}

[dir="rtl"] .card-delete {
    margin-right: 0.4rem;
}

.card-time-container {
    display: flex;
    align-items: center;
}

.card-time-wrapper {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 0.2rem;
    align-items: center;
}

[dir="ltr"] .card-time-wrapper {
    margin-right: 1.2rem;
}

[dir="rtl"] .card-time-wrapper {
    margin-left: 1.2rem;
}

.card-date {
    font-size: var(--fs-xs);
    color: var(--mono-4);
}

[dir="ltr"] .card-info {
    margin-right: 0.8rem;
}

[dir="rtl"] .card-info {
    margin-left: 0.8rem;
}

.card-time-tooltip {
    margin-top: 0.4rem !important;
    direction: ltr;
}
