/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.radio-button[type="radio"]:checked,
.radio-button[type="radio"]:not(:checked) {
  display: none;
}

.radio-button-label {
  position: relative;
  padding-left: 3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: var(--brandType);
  font-size: var(--fs-s);
  line-height: var(--lh-2);
  color: var(--black);
}

.radio-button-label.disabled {
  cursor: not-allowed;
  color: var(--mono-3);
}

.radio-button[type="radio"]:checked + .radio-button-label:before,
.radio-button[type="radio"]:not(:checked) + .radio-button-label:before {
  --radioSize: 1.8rem;
  content: "";
  position: absolute;
  left: 0;
  width: var(--radioSize);
  height: var(--radioSize);
  border-radius: 50%;
}

.radio-button[type="radio"]:not(:checked) + .radio-button-label:before {
  border: solid 1px var(--mono-4);
}

.radio-button[type="radio"]:not(:checked) + .radio-button-label.radio-button-label.disabled:before {
  border: solid 1px var(--mono-3);
}

.radio-button[type="radio"]:checked + .radio-button-label:before {
  border: 1px solid var(--brand-3);
}

.radio-button[type="radio"]:checked + .radio-button-label:after {
  content: "";
  position: absolute;
  left: 0.4rem;
  top: 0.3rem;
  width: 1rem;
  height: 1rem;
  background-color: var(--brand-3);
  border-radius: 50%;
}

.radio-button[type="radio"]:checked + .radio-button-label.disabled:before {
  border: 1px solid var(--mono-3);
}

.radio-button[type="radio"]:checked + .radio-button-label.disabled:after {
  background-color: var(--mono-3);
}

.vertical {
  margin-bottom: 8px;
}
