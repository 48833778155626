.amp-config-title {
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
  line-height: var(--lh-3);
  color: var(--mono-5);
  margin: 0 0 0.8rem 0;
}
.amp-config-description {
  font-size: var(--fs-m);
  line-height: var(--lh-4);
  color: var(--mono-4);
}

.amp-config-switch-field-container {
  margin-top: 3.2rem;
  display: flex;
  justify-content: space-between;
}
