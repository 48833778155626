@value viewports: "../../../viewports.css";
@value desktop from viewports;

.elements-toolbar-container {
  display: flex;
  z-index: var(--z700);
  width: 3rem;
  outline: none;
}

.elements-toolbar-plus {
  cursor: pointer;
  display: inline-flex;
  transition: transform 0.4s;
  outline: none;
}

.elements-toolbar-wrapper {
  display: inline-flex;
  visibility: hidden;
  opacity: 0;
  z-index: var(--z1);
  border-radius: 0.3rem;
  background-color: var(--white);
  box-shadow: 2px 3px 10px 0 rgba(19, 36, 55, 0.2);
  padding: 0.4rem 0.8rem;
  height: auto;
  position: absolute;
}

[dir="ltr"] .elements-toolbar-wrapper {
  transform: translateX(-10%);
}

[dir="rtl"] .elements-toolbar-wrapper {
  transform: translateX(10%);
}

.elements-toolbar-element,
.vertical-dots {
  position: relative;
  cursor: pointer;
  margin: 1.2rem;
  color: var(--mono-4);
  transition: color 0.2s;
  display: flex;
  flex-direction: column-reverse;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.elements-toolbar-element-tooltip {
  font-size: var(--fs-s);
  background: var(--white);
  padding: 0.8rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  display: none;
  transition: all 0.2s;
  background-color: var(--mono-1);
}

.elements-toolbar-element-tooltip::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid var(--mono-1);
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}

[dir="ltr"] .elements-toolbar--is-expanded .elements-toolbar-plus {
  transform: rotate(-45deg);
}

[dir="rtl"] .elements-toolbar--is-expanded .elements-toolbar-plus {
  transform: rotate(45deg);
}
.elements-toolbar--is-expanded .elements-toolbar-wrapper {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
  z-index: var(--z1);
  height: max-content;
}

.elements-toolbar--TOP .elements-toolbar-wrapper {
  top: -1.6rem;
}

.elements-toolbar--BOTTOM .elements-toolbar-wrapper {
  bottom: 0;
}

[dir="ltr"] .elements-toolbar--is-expanded .elements-toolbar-wrapper {
  left: 3.2rem;
}

[dir="rtl"] .elements-toolbar--is-expanded .elements-toolbar-wrapper {
  right: 3.2rem;
}

.elements-toolbar-element:hover .elements-toolbar-element-tooltip {
  display: block;
  top: -5rem;
}

.elements-toolbar--TOP {
  top: 0;
}

.elements-toolbar--BOTTOM {
  bottom: 0;
  top: auto;
}

.partial-expanded-toolbar-wrapper {
  display: flex;
  width: 19.2rem;
}

.elements-toolbar--is-expanded .partial-expanded-toolbar-wrapper {
  top: -1.5rem;
}

.fully-expanded-toolbar-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 25.6rem;
}

@media (min-width: desktop) {
  .elements-toolbar-container {
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .elements-toolbar-plus {
    padding: 0;
  }

  .elements-toolbar-wrapper {
    transition: all 0.3s;
    height: unset;
    position: unset;
  }

  .elements-toolbar-element,
  .vertical-dots {
    margin: 0.8rem;
  }

  .elements-toolbar--is-expanded .elements-toolbar-wrapper {
    top: unset;
    height: unset;
  }

  .elements-toolbar--TOP {
    top: -2rem;
  }

  .elements-toolbar--BOTTOM {
    bottom: -2rem;
  }

  .fully-expanded-toolbar-wrapper {
    flex-wrap: unset;
    width: unset;
  }
}
