.story-list-container {
  margin-top: 0.8rem;
}
.story-list-item {
  border-bottom: 1px solid var(--mono-3);
  padding-top: 1.2rem;
  cursor: pointer;
}

.story-list-headline {
  margin: 0 0 var(--verticalRhythm) 0;
  color: var(--mono-5);
  font-size: var(--fs-l);
  font-weight: var(--fw-normal);
  line-height: var(--lh-4);
  padding-bottom: 0.4rem;
}

.story-list-text {
  font-size: var(--fs-s);
  line-height: var(--lh-5);
  color: var(--mono-4);
  padding-bottom: 0.4rem;
}

.story-list-item :global .highlight {
  font-weight: var(--fw-bold);
  background-color: transparent;
}

.story-list-details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: var(--fs-xs);
  line-height: var(--lh-1);
  color: var(--mono-4);
  padding-bottom: 1.2rem;
}

.no-content-message {
  margin-top: 0.8rem;
}

.story-authors {
  display: flex;
  align-items: center;
}

[dir="ltr"] .story-authors {
  margin-right: 1.6rem;
}

[dir="rtl"] .story-authors {
  margin-left: 1.6rem;
}

.story-time-wrapper {
  display: flex;
  align-items: center;
}

[dir="ltr"] .story-time-wrapper {
  margin-right: 1.6rem;
}

[dir="rtl"] .story-time-wrapper {
  margin-left: 1.6rem;
}

[dir="ltr"] .story-time {
  margin-left: 0.4rem;
}

[dir="rtl"] .story-time {
  margin-right: 0.4rem;
}
