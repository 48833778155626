/*
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
@value viewports: "../../viewports.css";
@value desktop from viewports;

.image-field {
  position: relative;
  background-color: var(--mono-2);
  display: flex;
  justify-content: center;
}

.image-field--update-image {
  margin-bottom: 2.4rem;
}

.image-field-image {
  height: 28.8rem;
  object-fit: contain;
}

.image-preview-overlay {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.image-field-actions {
  position: absolute;
  padding: 0.8rem;
  bottom: 0;
  right: 0;
}

.image-field-actions--hide {
  display: none;
}