.no-social-accounts {
  margin: 0 0 3.2rem 0;
  background-color: var(--white);
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
  padding: 3.2rem 4.8rem;
  text-align: center;
}

.social-share-page-container {
  transition: transform 0.5s;
}
