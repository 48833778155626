.image-element-container {
  display: grid;
  grid-template-columns: 500px 1fr;
  grid-column-gap: 3.2rem;
  align-items: start;
  position: relative;
}

.image-preview {
  background-color: var(--mono-2);
  width: 100%;
}

.is-disabled {
  pointer-events: none;
}
