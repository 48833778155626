.attributes-no-content {
  margin-top: 3.6rem;
  padding: 1.6rem;
  border-radius: 0.5rem;
  background-color: var(--mono-2);
}

.attributes-list {
  margin: 0;
  padding: 1.6rem 0;
  list-style: none;
  display: grid;
  grid-row-gap: 0.8rem;
  grid-template-columns: 1fr;
}