.attributes-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white);
    border-radius: 0.3rem;
    box-shadow: var(--box-shadow-s);
    padding: 1.2rem 3.2rem;
    cursor: pointer;
}

.attribute-row-info {
    display: flex;
    width: 25%;
    flex-direction: column;
    align-items: baseline;
}

:global .attributes-list-chip {
    margin-bottom: 0.4rem;
}

:global .attribute-link {
    font-family: var(--brandType);
    font-size: var(--fs-m);
    line-height: var(--lh-4);
    color: var(--mono-5);
    text-decoration: none;
    text-transform: capitalize;
    cursor: pointer;
}

.attribute-values {
    display: flex;
    width: 60%;
    margin: 0;
}

.attribute-row-actions {
    display: flex;
    align-items: center;
}

.attribute-row-actions button {
    margin-right: 1.6rem;
    padding: 0.8rem 0.4rem;
}

.attribute-row-actions svg {
    color: var(--black);
}

.attribute-row-actions .button--disabled svg {
    color: var(--mono-3);
}