.menu-page-container {
  transition: transform 0.5s;
}

:global .menu-progress-main-area {
  margin: auto;
  padding: 0px;
  height: calc(100vh - var(--header-height) - 6.4rem);
  display: flex;
  justify-content: center;
}
