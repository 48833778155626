.dropzone-container {
  z-index: var(--z1);
  border-radius: inherit;
}

.dropzone-overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: var(--z100);
  border: 2px var(--mono-4) dashed;
  border-radius: inherit;
}
