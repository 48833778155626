.story-subtask-row {
  display: grid;
  grid-template-columns: 1fr 8fr 2fr;
  align-items: center;
  padding: 0.8rem 0.8rem;
  border-bottom: 1px solid var(--mono-2);
  grid-column-gap: 0.8rem;
}

.story-subtask-name {
  line-height: 2rem;
  overflow: hidden;
  padding-right: 5.6rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.story-subtask-footer {
  display: grid;
  grid-template-columns: 9fr 2fr;
  align-items: center;
  margin-left: 0.8rem;
  padding: 0.8rem 0.8rem;
  grid-column-gap: 0.8rem;
  border-bottom: 1px solid var(--mono-2);
}

.footer-text {
  color: var(--brand-3);
  font-weight: bold;
}

.story-subtask-assignee-image-container {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  object-fit: cover;
  background: url("~assets/icons/default-avatar.svg") no-repeat center center;
  background-size: cover;
  display: block;
}

.story-subtask-assignee-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.task-error-container {
  padding: 1.6rem;
  border-radius: 0.5rem;
  background-color: var(--mono-2);
}

.task-error-retry-message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.task-error-retry-message .task-error-message {
  margin-bottom: 0.4rem;
}

.task-error-retry-message svg {
  margin-bottom: 0.8rem;
}

.task-error-retry-message .task-error-retry-link {
  font-family: var(--brandType);
  font-size: var(--fs-s);
  line-height: var(--lh-4);
  color: var(--brand-3);
  padding: 0;
  background: none;
  border: none;
  text-decoration: none;
}
