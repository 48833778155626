.schedule-publish-targets-error-message {
  color: var(--danger);
  margin-top: 1rem;
  min-height: 1.6rem;
  font-size: var(--fs-m);
  font-weight: var(--fw-normal);
}

.schedule-publish-inspector-options {
  display: flex;
  margin-bottom: 2.4rem;
}

[dir="ltr"] :global .schedule-publish-inspector-radio-button {
  margin-right: 2rem;
}

[dir="rtl"] :global .schedule-publish-inspector-radio-button {
  margin-left: 2rem;
}

.schedule-publish-message {
  width: 100%;
}

.time-select {
  width: 100%;
}

.time-select .form-control {
  width: 100%;
}

.time-select.rdtOpen .rdtPicker {
  width: 100%;
}

.collection-publish-inspector-title {
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
  line-height: var(--lh-3);
  color: var(--mono-5);
  margin: 0 0 0.8rem 0;
}

.collection-publish-inspector-sub-title {
  font-size: var(--fs-m);
  font-weight: var(--fw-bold);
  line-height: var(--lh-3);
  color: var(--mono-5);
  margin: 0 0 0.8rem 0;
}

.publish-text {
  line-height: var(--lh-4);
  color: var(--mono-4);
  margin: 0 0 1.6rem 0;
  font-size: var(--fs-s);
}
