/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.error-message {
  visibility: hidden;
  font-family: var(--brandType);
  font-size: var(--fs-xs);
  color: var(--danger);
  margin-bottom: 0;
  min-height: 1.2rem;
  font-weight: var(--fw-normal);
}

.error-message.visible {
  visibility: visible;
}

.has-error .error-message {
  visibility: visible;
}
