.analytics-share-container {
  text-align: right;
  margin: 0 2.4rem 0 2.4rem;
  position: relative;
}

.analytics-share-container .analytics-share {
  width: 35rem;
  padding: 1.6rem 1.6rem 1.6rem 1.6rem;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  display: none;
  transition: visibility opacity 0.3s ease;
}

.analytics-share-container .analytics-share.is-active {
  display: block;
  background-color: var(--white);
  position: absolute;
  right: 0;
  z-index: var(--z100);
}

.analytics-share-container .analytics-share .button,
.analytics-share-container .analytics-share .text-field-input,
.analytics-share-container .analytics-share .text-field-with-button-wrapper {
  margin: 0;
}
