.static-pages-form-wrapper {
  --static-pages-form-width: 91.2rem;
  background-color: var(--white);
  padding: 2.4rem 0;
  margin: 2.4rem 0;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
}
.static-pages-form-main-container {
  position: relative;
  max-width: var(--static-pages-form-width);
  padding: 2.4rem 0;
  margin: 0 4rem;
  width: 100%;
}
.static-pages-form-container {
  max-width: calc(var(--static-pages-form-width) - 6rem);
  margin: 2.4rem auto;
}
.static-pages-form-field-container {
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-gap: 2.4rem;
  background-color: var(--white);
}
.static-pages-form-field-icon {
  display: flex;
  color: var(--mono-4);
  border-right: 1px solid var(--mono-3);
}

.static-pages-form-field--title .text-area-error-message {
  color: var(--danger);
}

.static-pages-form-field {
  margin-top: 1.6rem;
}

.static-pages-form-field--options {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0.8rem 0 2.8rem;
}

[dir="ltr"] .static-pages-form-checkbox {
  margin-right: 2.4rem;
}

[dir="rtl"] .static-pages-form-checkbox {
  margin-left: 2.4rem;
}

:global .static-page-content-textarea {
  min-height: 20rem;
}
