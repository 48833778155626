@value viewports: "../../../../viewports.css";
@value desktop from viewports;

.form-panel {
  --verticalRhythm: 0.8rem;
  font-family: var(--brandType);
  border-radius: 0.3rem;
  background-color: var(--white);
  box-shadow: var(--box-shadow-s);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0.8rem 0.2rem;
}

.form-panel-content {
  flex-grow: 1;
  padding: 1.2rem 0.8rem;
  overflow: auto;
}

.form-panel:hover .form-panel-content,
.form-panel:hover .form-panel-actions {
  background-color: var(--mono-2);
}

.form-panel :global .highlight {
  font-weight: var(--fw-bold);
  background-color: var(--white);
}

.form-panel:hover .highlight {
  background-color: var(--mono-2);
}

.form-panel-headline {
  cursor: pointer;
  margin: 0 0 var(--verticalRhythm) 0;
  color: var(--mono-5);
  font-size: var(--fs-l);
  font-weight: var(--fw-normal);
  line-height: var(--lh-4);
  text-decoration: none;
  display: block;
}

.form-panel-byline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: var(--fs-xs);
  line-height: var(--lh-1);
  color: var(--mono-4);
}

[dir="ltr"] .form-panel-time {
  margin-left: 0.4rem;
}

[dir="rtl"] .form-panel-time {
  margin-right: 0.4rem;
}

.form-panel-time-wrapper {
  display: flex;
  align-items: center;
  margin-right: 1.6rem;
}

.form-panel-chips {
  margin-bottom: var(--verticalRhythm);
}

:global [dir="rtl"] .form-panel-chip {
  margin-left: 0.8rem;
}

:global [dir="ltr"] .form-panel-chip {
  margin-right: 0.8rem;
}

@media (min-width: desktop) {
  .form-panel-byline {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .form-panel .form-panel-content {
    padding: 1.2rem 1.6rem;
  }
}
