.video-attachment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 24rem;
}

.file-field {
  width: 0.1px;
  height: 0.1px;
  overflow: hidden;
  position: absolute;
  z-index: var(--z1);
}

.video-attachment-label-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.video-attachment-input-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--fs-s);
}

.video-attachment-container label {
  cursor: pointer;
  padding-bottom: 0.8rem;
}

.info {
  font-size: var(--fs-xs);
  margin-top: 10px;
}

.video-attachment-details {
  display: flex;
  justify-items: center;
  font-size: var(--fs-s);
  padding: 1.6rem 0 3.2rem 0;
}

.video-attachment-details a {
  text-decoration: none;
  color: var(--mono-5);
  font-weight: var(--fw-bold);
}

[dir="ltr"] .video-attachment-data {
  padding-left: 1rem;
}

[dir="ltr"] .video-attachment-data-edit {
  padding-left: 1.6rem;
}

[dir="rtl"] .video-attachment-data {
  padding-right: 1rem;
}

[dir="rtl"] .video-attachment-data-edit {
  padding-right: 1.6rem;
}

.is-disabled {
  pointer-events: none;
}

.add-new-media-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  margin: 0 0.8rem;
  padding-top: 10rem
}

.add-new-video-dropzone {
  display: grid;
  height: 100%;
  grid-template-rows: repeat(3, 1fr);
}

.add-new-video-drop-here-text {
  grid-row: 3 / 4;
  align-self: end;
  text-align: center;
  font-size: var(--fs-s);
  color: var(--mono-4);
  margin-bottom: 2rem;
  user-select: none;
}

.add-new-media-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--mono-5);
  border: 1px solid var(--mono-5);
  border-radius: 3px;
  padding: 0.8rem 1.6rem;
  font-size: var(--fs-s);
  cursor: pointer;
}

[dir="ltr"] .add-new-media-button-text {
  margin-left: 0.6rem;
}

[dir="rtl"] .add-new-media-button-text {
  margin-right: 0.6rem;
}

[dir="ltr"] .video-replace-or-delete-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 2.4rem;
  cursor: pointer;
}

[dir="rtl"] .video-replace-or-delete-buttons {
  display: flex;
  justify-content: flex-start;
  margin-top: 2.4rem;
  cursor: pointer;
}

[dir="ltr"] :global .remove-video-button {
  margin-left: 1.6rem;
}

[dir="rtl"] :global .remove-video-button {
  margin-right: 1.6rem;
}

