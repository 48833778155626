/*
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

:global .react-select__indicator-separator {
  width: 0 !important;
}

.react-select__menu {
  border: 0 !important;
}

.react-select__--is-disabled {
  opacity: 0.5;
}

.react-select__single-value {
  line-height: 1.5;
}

.react-select__multi-value .react-select__multi-value__remove:hover {
  cursor: pointer;
  background-color: var(--mono-3);
  color: var(--mono-4);
}

.react-select-rotate-chevron-down {
  transform: rotate(180deg);
}

.react-select-wrapper-help-text {
  font-size: var(--fs-xs);
  color: var(--mono-4);
}

:global .has-error .react-select__control,
:global .has-error .react-select__control:hover {
  border-color: var(--danger);
}

.select-field {
  margin-bottom: 0.8rem;
}

.select-container {
  margin-bottom: 0.8rem;
}

:global .react-select__option--is-focused {
  background-color: var(--brand-2) !important;
}

:global .react-select__menu .react-select__menu-list .react-select__option--is-selected {
  background-color: var(--brand-3) !important;
}

:global .react-select__single-value {
  color: var(--mono-5) !important;
}

:global .react-select__input {
  color: var(--black) !important;
}
