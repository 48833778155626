/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.image-field-container {
  margin-bottom: 1.6rem;
}

.image-field-add-new-media {
  background-color: var(--mono-2);
  height: 24rem;
  user-select: none;
}

.image-field-container.has-error {
  border: 1px solid var(--danger);
}
