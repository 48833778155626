.analytics-top-list-container {
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
  background-color: var(--white);
  padding: 3.2rem;
  margin: 2.4rem 0;
}

.analytics-top-list-container__title {
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
  line-height: var(--fs-xs);
  color: var(--mono-5);
  margin: 0 0 2.4rem 0;
}

.analytics-top-list-container__top-list {
  list-style: none;
  padding: 0;
}

.analytics-top-list-container .top-list .top-list__item,
.top-sublist__item {
  padding: 1.2rem 0;
  display: grid;
  grid-template-columns: 70% 30%;
  grid-column-gap: 0.8rem;
  border-bottom: 1px solid var(--mono-2);
}

.analytics-top-list-container .top-list__item-title,
.top-sublist__item-title {
  margin: 0;
  padding-left: 2.4rem;
  font-size: var(--fs-s);
  font-weight: var(--fw-normal);
  line-height: var(--lh-4);
  color: var(--mono-5);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

:global .analytics-top-list-item-title-button {
  padding-left: 0.8rem;
}

.analytics-top-list-container .top-list__item.item-header {
  font-weight: var(--fw-bold);
  background-color: var(--mono-2);
  font-size: var(--fs-s);
  line-height: var(--lh-4);
  color: var(--mono-5);
}

.top-sublist__item span:first-child,
.analytics-top-list-container .top-list__item span:first-child {
  text-align: end;
}

.top-sublist__item.item-header span:nth-child(2),
.analytics-top-list-container .top-list__item.item-header span:nth-child(2) {
  justify-self: center;
}

.analytics-top-list-container .top-list__item.item-header span:first-child {
  text-align: left;
  padding-left: 2.4rem;
}

.analytics-top-list-container .top-list__item-view,
.analytics-top-list-container .top-list__item.item-header span:nth-child(2),
.top-sublist__item-view {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.8rem;
}

.analytics-top__sublist {
  list-style: none;
  grid-column: 1/-1;
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;
  padding: 0;
}

.analytics-top__sublist.is--open {
  max-height: 44rem;
  height: auto;
  visibility: visible;
  opacity: 1;
}

.top-sublist__item-title {
  padding-left: 4rem;
}
