/*
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

@value viewports: "../../viewports.css";
@value desktop from viewports;

/* -- container -- */
:global .rodal,
:global .rodal-mask {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--confirmationModal);
}

:global .rodal {
  position: fixed;
}

/* -- mask -- */
:global .rodal-mask {
  position: absolute;
  background: var(--white);
  opacity: 0.9;
}

/* -- dialog -- */
:global .rodal-dialog {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: var(--confirmationModal);
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

:global .rodal-container .rodal-dialog {
  padding: 2.4rem 2.4rem;
  height: max-content !important;
  width: 95vw !important;
  max-width: 52.8rem;
}

@media (min-width: desktop) {
  :global .rodal-container .rodal-dialog {
    padding: 6.4rem 6.4rem;
    height: max-content !important;
    width: 52.8rem !important;
  }
}

:global .rodal-dialog:focus {
  outline: none;
}

/* -- close button -- */
:global .rodal-close {
  visibility: hidden;
  position: absolute;
  cursor: pointer;
  top: 16px;
  right: 16px;
  width: 16px;
  height: 16px;
}

:global .rodal-close:before,
:global .rodal-close:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #999;
  border-radius: 100%;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
}

:global .rodal-close:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

:global .rodal-close:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

:global .rodal-close:hover:before,
:global .rodal-close:hover:after {
  background: #333;
}

/* -- fade -- */
@-webkit-keyframes :global(rodal-fade-enter) {
  from {
    opacity: 0;
  }
}

@keyframes :global(rodal-fade-enter) {
  from {
    opacity: 0;
  }
}

:global .rodal-fade-enter {
  -webkit-animation: rodal-fade-enter both ease-in;
  animation: rodal-fade-enter both ease-in;
}

@-webkit-keyframes :global(rodal-fade-leave) {
  to {
    opacity: 0;
  }
}

@keyframes :global(rodal-fade-leave) {
  to {
    opacity: 0;
  }
}

:global .rodal-fade-leave {
  -webkit-animation: rodal-fade-leave both ease-out;
  animation: rodal-fade-leave both ease-out;
}

/* -- zoom -- */
@-webkit-keyframes :global(rodal-zoom-enter) {
  from {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes :global(rodal-zoom-enter) {
  from {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

:global .rodal-zoom-enter {
  -webkit-animation: rodal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
  animation: rodal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes :global(rodal-zoom-leave) {
  to {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes :global(rodal-zoom-leave) {
  to {
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

:global .rodal-zoom-leave {
  -webkit-animation: rodal-zoom-leave both;
  animation: rodal-zoom-leave both;
}

/* -- slideDown -- */
@-webkit-keyframes :global(rodal-slideDown-enter) {
  from {
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }
}

@keyframes :global(rodal-slideDown-enter) {
  from {
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }
}

:global .rodal-slideDown-enter {
  -webkit-animation: rodal-slideDown-enter both cubic-bezier(0.4, 0, 0, 1.5);
  animation: rodal-slideDown-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes :global(rodal-slideDown-leave) {
  to {
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }
}

@keyframes :global(rodal-slideDown-leave) {
  to {
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }
}

:global .rodal-slideDown-leave {
  -webkit-animation: rodal-slideDown-leave both;
  animation: rodal-slideDown-leave both;
}

/* -- slideLeft -- */
@-webkit-keyframes :global(rodal-slideLeft-enter) {
  from {
    -webkit-transform: translate3d(-150px, 0, 0);
    transform: translate3d(-150px, 0, 0);
  }
}

@keyframes :global(rodal-slideLeft-enter) {
  from {
    -webkit-transform: translate3d(-150px, 0, 0);
    transform: translate3d(-150px, 0, 0);
  }
}

:global .rodal-slideLeft-enter {
  -webkit-animation: rodal-slideLeft-enter both cubic-bezier(0.4, 0, 0, 1.5);
  animation: rodal-slideLeft-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes :global(rodal-slideLeft-leave) {
  to {
    -webkit-transform: translate3d(-150px, 0, 0);
    transform: translate3d(-150px, 0, 0);
  }
}

@keyframes :global(rodal-slideLeft-leave) {
  to {
    -webkit-transform: translate3d(-150px, 0, 0);
    transform: translate3d(-150px, 0, 0);
  }
}

:global .rodal-slideLeft-leave {
  -webkit-animation: rodal-slideLeft-leave both;
  animation: rodal-slideLeft-leave both;
}

/* -- slideRight -- */
@-webkit-keyframes :global(rodal-slideRight-enter) {
  from {
    -webkit-transform: translate3d(150px, 0, 0);
    transform: translate3d(150px, 0, 0);
  }
}

@keyframes :global(rodal-slideRight-enter) {
  from {
    -webkit-transform: translate3d(150px, 0, 0);
    transform: translate3d(150px, 0, 0);
  }
}

:global .rodal-slideRight-enter {
  -webkit-animation: rodal-slideRight-enter both cubic-bezier(0.4, 0, 0, 1.5);
  animation: rodal-slideRight-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes :global(rodal-slideRight-leave) {
  to {
    -webkit-transform: translate3d(150px, 0, 0);
    transform: translate3d(150px, 0, 0);
  }
}

@keyframes :global(rodal-slideRight-leave) {
  to {
    -webkit-transform: translate3d(150px, 0, 0);
    transform: translate3d(150px, 0, 0);
  }
}

:global .rodal-slideRight-leave {
  -webkit-animation: rodal-slideRight-leave both;
  animation: rodal-slideRight-leave both;
}

/* -- slideUp -- */
@-webkit-keyframes :global(rodal-slideUp-enter) {
  from {
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
  }
}

@keyframes :global(rodal-slideUp-enter) {
  from {
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
  }
}

:global .rodal-slideUp-enter {
  -webkit-animation: rodal-slideUp-enter both cubic-bezier(0.4, 0, 0, 1.5);
  animation: rodal-slideUp-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes :global(rodal-slideUp-leave) {
  to {
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
  }
}

@keyframes :global(rodal-slideUp-leave) {
  to {
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
  }
}

:global .rodal-slideUp-leave {
  -webkit-animation: rodal-slideUp-leave both;
  animation: rodal-slideUp-leave both;
}

/* -- flip -- */
@-webkit-keyframes :global(rodal-flip-enter) {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes :global(rodal-flip-enter) {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

:global .rodal-flip-enter {
  -webkit-animation: rodal-flip-enter both ease-in;
  animation: rodal-flip-enter both ease-in;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@-webkit-keyframes :global(rodal-flip-leave) {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
  }
}

@keyframes :global(rodal-flip-leave) {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
  }
}

:global .rodal-flip-leave {
  -webkit-animation: rodal-flip-leave both;
  animation: rodal-flip-leave both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

/* -- rotate -- */
@-webkit-keyframes :global(rodal-rotate-enter) {
  from {
    -webkit-transform: rotate3d(0, 0, 1, -180deg) scale3d(0.3, 0.3, 0.3);
    transform: rotate3d(0, 0, 1, -180deg) scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes :global(rodal-rotate-enter) {
  from {
    -webkit-transform: rotate3d(0, 0, 1, -180deg) scale3d(0.3, 0.3, 0.3);
    transform: rotate3d(0, 0, 1, -180deg) scale3d(0.3, 0.3, 0.3);
  }
}

:global .rodal-rotate-enter {
  -webkit-animation: rodal-rotate-enter both;
  animation: rodal-rotate-enter both;
  -webkit-transform-origin: center;
  transform-origin: center;
}

@-webkit-keyframes :global(rodal-rotate-leave) {
  to {
    -webkit-transform: rotate3d(0, 0, 1, 180deg) scale3d(0.3, 0.3, 0.3);
    transform: rotate3d(0, 0, 1, 180deg) scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes :global(rodal-rotate-leave) {
  to {
    -webkit-transform: rotate3d(0, 0, 1, 180deg) scale3d(0.3, 0.3, 0.3);
    transform: rotate3d(0, 0, 1, 180deg) scale3d(0.3, 0.3, 0.3);
  }
}

:global .rodal-rotate-leave {
  -webkit-animation: rodal-rotate-leave both;
  animation: rodal-rotate-leave both;
  -webkit-transform-origin: center;
  transform-origin: center;
}

/* -- door -- */
@-webkit-keyframes :global(rodal-door-enter) {
  from {
    -webkit-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1);
  }
}

@keyframes :global(rodal-door-enter) {
  from {
    -webkit-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1);
  }
}

:global .rodal-door-enter {
  -webkit-animation: rodal-door-enter both cubic-bezier(0.4, 0, 0, 1.5);
  animation: rodal-door-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes :global(rodal-door-leave) {
  60% {
    -webkit-transform: scale3d(0.01, 1, 1);
    transform: scale3d(0.01, 1, 1);
  }
  to {
    -webkit-transform: scale3d(0, 1, 0.1);
    transform: scale3d(0, 1, 0.1);
  }
}

@keyframes :global(rodal-door-leave) {
  60% {
    -webkit-transform: scale3d(0.01, 1, 1);
    transform: scale3d(0.01, 1, 1);
  }
  to {
    -webkit-transform: scale3d(0, 1, 0.1);
    transform: scale3d(0, 1, 0.1);
  }
}

:global .rodal-door-leave {
  -webkit-animation: rodal-door-leave both;
  animation: rodal-door-leave both;
}

.rodal-content {
  display: flex;
  justify-content: center;
}

.rodal-header {
  font-size: var(--fs-xxl);
  font-weight: var(--fw-bold);
  padding-bottom: 0.8rem;
  line-height: var(--lh-2);
}

.body {
  font-size: var(--fs-m);
  color: var(--mono-4);
  line-height: var(--lh-4);
}

.confirmation-actions {
  margin-top: 1.6rem;
  display: flex;
  align-items: center;
}

[dir="ltr"] .confirmation-actions button:first-child {
  margin-right: 1.6rem;
}

[dir="rtl"] .confirmation-actions button:first-child {
  margin-left: 1.6rem;
}
