/*
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.multiple-row-container__header {
  display: grid;
  grid-template-columns: calc(40% - 3.2rem) repeat(auto-fit, minmax(4rem, 1fr)) 3.2rem;
  grid-gap: 0.8rem;
}

.multiple-row-container__row {
  display: grid;
  grid-template-columns: 1fr 3.2rem;
}

.multiple-row-container__row-inputs {
  display: grid;
  grid-template-columns: calc(40% - 2rem) repeat(auto-fit, minmax(4rem, 1fr));
  grid-gap: 0.8rem;
}

.multiple-row-container__row .error-message {
  display: none;
}

.multiple-row-container__trash-icon {
  cursor: pointer;
  color: var(--mono-4);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.6rem;
}

.multiple-row-container__trash-icon--disabled {
  opacity: .2;
  cursor: unset;
}

.multiple-row-container__add {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: max-content;
}

.multiple-row-container__add-label {
  color: var(--brand-3);
  font-size: var(--fs-s);
  font-weight: var(--fw-semi-bold);
  padding: 0.4rem 0.2rem;
}

[dir="ltr"] .multiple-row-container__add-label {
  margin-left: 0.4rem;
}

[dir="rtl"] .multiple-row-container__add-label {
  margin-right: 0.4rem;
}
