.stats-container__total {
  font-size: var(--fs-jumbo);
  line-height: var(--lh-1);
  color: var(--mono-5);
}
.stats-container.is-positive {
  color: var(--accent-3);
}

.stats-container.is-positive .stats-container__delta:before {
  display: inline-block;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--accent-3);
  margin-right: 0.8rem;
}

.stats-container.is-negative {
  color: var(--danger);
}

.stats-container.is-negative .stats-container__delta:before {
  display: inline-block;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--danger);
  margin-right: 0.8rem;
}

.stats-container.is-new .stats-container__delta:before {
  display: inline-block;
  content: "";
  width: 0;
  height: 0;
  border: 5px solid var(--mono-4);
  border-radius: 50%;
  margin-right: 0.8rem;
}

.stats-container.is-zero .stats-container__delta:before {
  display: inline-block;
  content: "";
  width: 0;
  height: 0;
  border-bottom: 5px solid transparent;
  border-right: 5px solid var(--mono-4);
  border-top: 5px solid transparent;
  margin-right: 0.2rem;
}

.stats-container.is-zero .stats-container__delta:after {
  display: inline-block;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid var(--mono-4);
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
}

.stats-container__delta-value {
  color: inherit;
  font-size: var(--fs-l);
  line-height: var(--lh-3);
}

.stats-container__label {
  font-size: var(--fs-xs);
  font-weight: var(--fw-bold);
  line-height: var(--lh-4);
  color: var(--mono-5);
  margin: 0 0 1.2rem 0;
  text-transform: uppercase;
}

.stats-container:first-child {
  margin-right: 3.2rem;
}
