@value viewports: "../../../../viewports.css";
@value desktop from viewports;

.collection-card {
  --storycardShadow: 0 3px 6px 0 rgba(19, 36, 55, 0.1);
  --verticalRhythm: 0.8rem;

  font-family: var(--brandType);
  border-radius: 0.3rem;
  box-shadow: var(--storycardShadow);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  cursor: -webkit-row-resize;
  cursor: -moz-row-resize;
  cursor: row-resize;
  margin: 0 0.4rem var(--verticalRhythm);
}

.collection-card .collection-card-content {
  flex-grow: 1;
  padding: 0.8rem 1.6rem;
  background-color: var(--white);
}

.headline-container:hover {
  text-decoration: underline;
  cursor: pointer;
}

.collection-card .collection-card-content.is-dragging {
  opacity: 0.5;
}

.collection-card .highlight {
  font-weight: var(--fw-bold);
}

.collection-meta {
  display: flex;
  align-items: center;
  margin-bottom: var(--verticalRhythm);
}

.collection-meta-content {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

[dir="ltr"] .collection-badge {
  margin-right: 0.8rem;
}

[dir="rtl"] .collection-badge {
  margin-left: 0.8rem;
}

[dir="ltr"] :global .collection-chips {
  margin-right: 0.8rem;
}

[dir="rtl"] :global .collection-chips {
  margin-left: 0.8rem;
}

.collection-headline {
  margin: 0 0 var(--verticalRhythm) 0;
  color: var(--mono-5);
  font-size: var(--fs-l);
  font-weight: var(--fw-normal);
  line-height: var(--lh-4);
}

.collection-byline {
  font-size: var(--fs-xs);
  line-height: var(--lh-1);
  color: var(--mono-4);
}

.collection-authors {
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
}

[dir="ltr"] .collection-authors {
  margin-right: 1.6rem;
}

[dir="rtl"] .collection-authors {
  margin-left: 1.6rem;
}

.collection-time-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: var(--mono-2);
  color: var(--mono-5);
}

[dir="ltr"] .collection-time-wrapper {
  margin-right: 0.8rem;
}

[dir="rtl"] .collection-time-wrapper {
  margin-left: 0.8rem;
}

[dir="ltr"] .collection-time {
  margin-left: 0.4rem;
}

[dir="rtl"] .collection-time {
  margin-right: 0.4rem;
}

[dir="ltr"] .collection-story-status {
  margin-right: 0.8rem;
}

[dir="rtl"] .collection-story-status {
  margin-left: 0.8rem;
}

[dir="ltr"] .analytics-icon {
  margin-right: 0.4rem;
}

[dir="rtl"] .analytics-icon {
  margin-right: 0;
}

.analytics-data {
  display: flex;
  align-items: center;
  margin-right: 0.8rem;
  font-size: var(--fs-xs);
  color: var(--mono-4);
}

.collection-manage-container {
  border-top: 1px solid var(--mono-2);
  margin-top: 1.6rem;
}

.collection-options-container {
  display: flex;
  background-color: var(--white);
  align-items: center;
  padding-top: 0.8rem;
}

.collection-info {
  display: flex;
  flex-grow: 1;
  font-size: var(--fs-s);
}

.public-icon {
  display: flex;
  align-items: center;
}

.collection-manage-button {
  cursor: pointer;
  font-size: var(--fs-xs);
  line-height: var(--lh-5);
  color: var(--brand-3);
}

.collection-star {
  cursor: pointer;
  justify-self: flex-end;
  color: var(--mono-3);
}

.collection-star.is-selected {
  color: var(--brand-3);
}

.collection-percent {
  font-size: var(--fs-xs);
  color: var(--mono-4);
}

[dir="ltr"] .collection-percent {
  padding-left: 0.5rem;
}

[dir="rtl"] .collection-percent {
  padding-right: 0.5rem;
}

.collection-percent.green {
  color: var(--success);
}

.collection-percent.red {
  color: var(--danger);
}

@media (min-width: desktop) {
  .collection-card {
    margin: 0 0 var(--verticalRhythm);
  }

  .collection-byline {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .collection-authors {
    margin-bottom: 0;
  }

  .collection-time-wrapper {
    margin-bottom: 0;
  }
}
