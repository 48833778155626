@value viewports: "../../../../viewports.css";
@value desktop from viewports;

.seo-stats-container {
  height: 100%;
  position: fixed;
  top: var(--header-height);
  bottom: 0;
  background-color: var(--white);
  z-index: var(--z1000);
  padding: 1.2rem;
  box-shadow: var(--box-shadow-m);
  overflow: scroll;
  padding-bottom: var(--header-height);
  width: 100vw;
  transition: right .4s ease, left .4s ease;
}

[dir="ltr"] .seo-stats-container {
  right: 0;
}
[dir="rtl"] .seo-stats-container {
  left: 0;
}

[dir="ltr"] .seo-stats-container.with-slider-open {
  right: 30%;
}

[dir="rtl"] .seo-stats-container.with-slider-open {
  left: 30%;
}

.seo-stats-container.with-banner {
  top: calc(var(--header-height) + var(--banner-height-mobile));
  height: calc(100vh - var(--header-height) - var(--banner-height-mobile));
}

.seo-stats-header {
  display: flex;
  align-items: center;
  padding: 2.4rem 0;
}

.seo-stats-close {
  margin: 1.2rem;
  cursor: pointer;
}

.seo-stats-title {
  font-family: var(--brandType);
  font-size: 1.6rem;
  font-weight: var(--fw-bold);
  text-transform: uppercase;
  color: var(--mono-5);
  letter-spacing: 2px;
  flex-grow: 1;
}

.seo-action {
  padding: 0 1.4rem;
}

.seo-action .text-field-with-button-wrapper {
  margin-bottom: 0;
}

.seo-check-help {
  font-family: var(--brandType);
  font-size: var(--fs-xs);
  color: var(--danger);
  margin-bottom: 0;
  min-height: 1.2rem;
  font-weight: var(--fw-normal);
  padding: 0 1.4rem;
}

.seo-checkbox {
  padding: 1.6rem 2.4rem;
  margin-bottom: 4rem;
}

@media (min-width: desktop) {
  .seo-stats-container {
    width: var(--timeline-width);
  }

  .seo-stats-container.with-banner {
    top: calc(var(--header-height) + var(--banner-height));
    height: calc(100vh - var(--header-height) - var(--banner-height));
  }
}
