.task-page-title {
  font-size: var(--fs-xxl);
  font-weight: var(--fw-bold);
  flex-grow: 1;
}

.task-header {
  display: flex;
  align-items: baseline;
  margin-bottom: 2.4rem;
}

.task-header .task-action-buttons-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.8rem;
}

.subtask-container {
  background: var(--white);
  background-color: var(--white);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  padding: 1.6rem;
}

.media-gallery-container {
  margin-top: 2.4rem;
  background: var(--white);
  background-color: var(--white);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  padding: 1.6rem;
}

.subtask-container .subtask-row {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 0.5fr;
  align-items: center;
  padding: 0.8rem 2.4rem;
}

.subtask-container .subtask-row .subtask-name {
  display: flex;
  align-items: flex-end;
}

.subtask-container .subtask-row .subtask-name .subtask-status {
  margin-right: 0.8rem;
}

.subtask-container .subtask-row:not(.subtask-header) {
  border-bottom: 1px solid var(--mono-2);
}

.subtask-container .subtask-title {
  font-size: var(--fs-m);
  font-weight: var(--fw-bold);
}

.subtask-container .subtask-header {
  background-color: var(--mono-2);
  font-family: var(--brandType);
  font-size: var(--fs-s);
  font-weight: var(--fw-bold);
  line-height: var(--lh-4);
  color: var(--mono-5);
}

:global .tasks-progress-main-area {
  margin: auto;
  padding: 0px;
  height: calc(100vh - var(--header-height) - 6.4rem);
  display: flex;
  justify-content: center;
}
