.multi-value {
    display: flex;
    background-color: var(--mono-2);
    margin: 2px;
    border-radius: 2px;
}

.multi-value--invalid {
    background-color: var(--danger);
    color: white;
}

.multi-label {
    padding: 3px;
    padding-left: 6px;
    font-size: 1.4rem;
}

.multi-label--invalid {
    color: white;
}