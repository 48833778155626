/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.attributes-and-value-container {
  margin-bottom: 0.8rem;
}
