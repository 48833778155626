/*
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

@value viewports: "../../viewports.css";
@value desktop from viewports;

.navbar-wrapper {
  --navbar-shadow: 0 2px 20px 0 rgba(19, 36, 55, 0.25);
  display: none;
  position: fixed;
  top: var(--header-height);
  bottom: 0;
  width: var(--navbar-width);
  height: calc(100vh - var(--header-height));
  background: linear-gradient(235deg, var(--brand-3), var(--accent-2) 100%);
  box-shadow: var(--navbar-shadow);
  z-index: var(--z1000);
  padding-top: 2rem;
}

.navbar-wrapper.is-expanded {
  display: initial;
}

.navbar-wrapper.with-banner {
  top: calc(var(--header-height) + var(--banner-height-mobile));
  height: calc(100vh - var(--header-height) - var(--banner-height-mobile));
}

[dir="ltr"] .navbar-wrapper {
  left: 0;
}

[dir="rtl"] .navbar-wrapper {
  right: 0;
}

.navbar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 12rem);
}

.navbar {
  padding: 0;
  list-style: none;
  margin: 0;
}

.navbar-item {
  position: relative;
  text-align: center;
  margin-bottom: 1.6rem;
}

.navbar-link {
  position: relative;
  padding: 1.2rem 0;
  min-height: 4.8rem;
  display: block;
  color: var(--white);
  cursor: pointer;
}

.navbar-link-disabled {
  color: var(--brand-4);
}

.navbar-help {
  padding: 1.2rem 0;
  min-height: 4.8rem;
  text-align: center;
}

.navbar-item:hover {
  background-color: var(--brand-4);
}

.navbar-link.is-selected {
  background-color: var(--brand-4);
  position: relative;
}

.navbar-link.is-selected::before {
  content: "";
  display: block;
  width: 0.3rem;
  height: 100%;
  background-color: var(--accent-1);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.quintype-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--header-height);
  background-color: var(--brand-4);
  position: fixed;
  top: 0;
  width: var(--navbar-width);
  z-index: var(--z1000);
  border: none;
}

.quintype-logo.with-banner {
  top: var(--banner-height-mobile);
}

.quintype-logo a {
  align-self: center;
}

@media (min-width: desktop) {
  .navbar-wrapper {
    display: initial;
  }

  .navbar-wrapper.with-banner {
    top: calc(var(--header-height) + var(--banner-height));
    height: calc(100vh - var(--header-height) - var(--banner-height));
  }

  .quintype-logo.with-banner {
    top: var(--banner-height);
  }
}
