.media-gallery-content {
  margin-top: 3.6rem;
  padding: 1.6rem;
  border-radius: 0.5rem;
  background-color: var(--brand-1);
  position: relative;
}

.media-gallery-content .has-no-content svg {
  color: var(--mono-4);
}

.media-gallery-actions {
  display: flex;
  flex-direction: column;
}

.media-gallery-select-provider {
  margin-top: 1.6rem;
}

.media-gallery-search {
  display: grid;
  grid-template-columns: 5fr 0.5fr;
  align-items: center;
  grid-column-gap: 1.2rem;
}

:global .date-time-field_date-field-input-icon__jjt3m {
  top: 0;
}
