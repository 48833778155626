.error-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 9.2rem;
}

.error-page-type {
  color: var(--mono-4);
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
}

.error-page-message {
  color: var(--mono-4);
  font-size: var(--fs-s);
  font-weight: var(--fw-normal);
  max-width: 70%;
  text-align: center;
  line-height: var(--lh-3);
}
