.header {
  margin: 0 0 2.4rem;
}

.main-container {
  background-color: var(--white);
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
  padding: 3.2rem 4.8rem;
}

.add-new-image-button {
  display: block;
  width: 100%;
  border: 0;
  background: none;
}

.add-new-image {
  background: var(--mono-2);
  color: var(--mono-4);
}

.main-label {
  font-size: var(--fs-m);
}

.image-upload-container {
  width: 100%;
}

.loader-wrapper {
  margin: 0;
}
