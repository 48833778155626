/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.login-wrapper {
  --login-width-desktop: 30%;
  --login-width-tablet: 50%;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--z200);
}

[dir="ltr"] .login-wrapper {
  right: 0;
}

[dir="rtl"] .login-wrapper {
  left: 0;
}

.action-link {
  font-family: var(--brandType);
  font-size: var(--fs-s);
  color: var(--brand-3);
  border: none;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
}

.login-form {
  min-height: 15.5rem;
}

.login-form .text-field {
  margin-bottom: 0;
}

.action-link--icon {
  margin: 0 0.8rem 0 0;
}

.action-container {
  margin-bottom: 2.4rem;
}

.publisher-logo {
  margin: 0 0 3.2rem 0;
  height: 3.8rem;
  width: 17rem;
  background: url("~assets/icons/logo.png") no-repeat center center;
}

.login-wrapper .action-name {
  font-family: var(--brandType);
  font-size: var(--fs-s);
  color: var(--mono-5);
  margin-bottom: 2.4rem;
}

.title {
  display: none;
  font-family: "Playfair Display", serif;
  font-size: var(--fs-jumbo);
  line-height: 1.11;
  font-weight: bold;
  margin: 0;
}

.description {
  display: none;
  font-family: "Playfair Display", serif;
  font-size: var(--fs-m);
  letter-spacing: 3.4px;
  color: var(--mono-5);
  margin: 2.4rem 0 0 0;
}

[dir="ltr"] .title,
[dir="ltr"] .description {
  text-align: right;
}

[dir="rtl"] .title,
[dir="rtl"] .description {
  text-align: left;
}

.login-container {
  width: 70%;
}

@media (min-width: 786px) {
  .login-wrapper {
    width: var(--login-width-tablet);
  }

  .login-background-image {
    position: relative;
    width: 90vw;
    height: 90vh;
    margin: 5vh;
    background: url("~assets/icons/login-background-image.svg") no-repeat center center;
    z-index: var(--z1);
  }

  .title {
    display: block;
  }

  .people-image-container {
    position: absolute;
    bottom: 5%;
    width: 34rem;
    height: 34.8rem;
    background: url("~assets/icons/people-login.svg") no-repeat center center;
  }

  [dir="ltr"] .people-image-container {
    left: 1%;
  }

  [dir="rtl"] .people-image-container {
    right: 1%;
    transform: scaleX(-1);
  }

  .quote-box {
    position: absolute;
    top: 12%;
    width: 35rem;
    height: 24rem;
  }

  [dir="ltr"] .quote-box {
    border-right: 1px solid var(--mono-5);
    padding-right: 0.8rem;
    right: 55%;
  }

  [dir="rtl"] .quote-box {
    border-left: 1px solid var(--mono-5);
    padding-left: 0.8rem;
    left: 55%;
  }

  .description {
    display: block;
  }
}

@media (min-width: 1024px) {
  .login-wrapper {
    width: var(--login-width-desktop);
  }
  .login-container {
    width: 60%;
  }
  .quote-box {
    padding-right: 1.6rem;
    width: 40rem;
  }

  [dir="ltr"] .quote-box {
    right: 32%;
  }

  [dir="rtl"] .quote-box {
    left: 32%;
  }

  .people-image-container {
    width: 40rem;
    height: 40.8rem;
  }

  [dir="ltr"] .people-image-container {
    left: 5%;
  }

  [dir="rtl"] .people-image-container {
    right: 5%;
  }

  .tfa-setup-content {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
