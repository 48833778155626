.text-and-select-text-attribution :global .input {
  background-color: transparent;
  border-color: transparent;
  font-size: var(--fs-xl);
  line-height: var(--lh-5);
  min-height: 3rem;
  padding: 0.2rem 0;
  margin-bottom: 2.2rem;
}

.text-and-select-text-attribution :global .input:hover {
  background-color: var(--mono-2);
}
