.bulk-input-container {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem;
}

.bulk-input-container .action-buttons {
  display: flex;
  width: 19rem;
  justify-content: space-between;
  align-items: center;
}

.bulk-input-container .stories-select {
  display: flex;
  align-items: center;
}

.bulk-input-container .stories-select .select-all {
  margin: 0;
  cursor: pointer;
}

.bulk-input-container .stories-select .checkbox-container {
  margin-right: 10px;
}

.bulk-input-container .stories-select .checkbox-container > .checkbox {
  margin-right: 10px;
}

.bulk-input-container .close-bulk-action {
  cursor: pointer;
}
