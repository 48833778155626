.button {
    background: var(--mono-2) !important;
    height: 24px;
    width: 24px;
}

.button svg {
    position: absolute;
    top: 0;
    left: 0;
}

