.story-editor-load-more-cards {
  cursor: pointer;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  height: 6.4rem;
  background: var(--white);
  text-align: center;
  font-size: var(--fs-m);
  color: var(--mono-4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.load-more-cards-message {
  margin: 0;
}
.story-editor-load-more-cards:hover {
  background-color: var(--mono-1);
}
