/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.6rem;
  background-color: var(--white);
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
  padding: 1.6rem;
  margin-bottom: 1.6rem;
}

@media (min-width: 786px) {
  .container {
    padding: 3.2rem 6.4rem;
    grid-template-columns: 1fr 2fr;
  }
}

.title {
  text-transform: capitalize;
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
  color: var(--mono-5);
  margin: 0;
}
