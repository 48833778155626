@value viewports: "../../../../viewports.css";
@value desktop from viewports;

.add-new-hero-image {
  background-color: var(--mono-2);
  height: 28rem;
}

.hero-image-container {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-column-gap: 3.2rem;
  grid-row-gap: 1.2rem;
  align-items: start;
  position: relative;
}

.hero-image-wrapper {
  position: relative;
  grid-row: span 2;
  display: flex;
  justify-content: center;
  background-color: var(--mono-2);
  align-items: center;
}

.hero-image-wrapper--error:before {
  content: "";
  width: 100%;
  height: 100%;
  border: 2px solid var(--danger);
  position: absolute;
}

.hero-image-wrapper .image-action-buttons {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0.8rem;
}

.hero-image-container .image-preview {
  object-fit: contain;
  height: 28.8rem;
}

.hero-image-container .image-preview-overlay {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.hero-image-container .overlay-button {
  border-radius: 3px;
  background-color: var(--white);
  border: solid 1px var(--mono-5);
  font-size: var(--fs-xxs);
  font-weight: normal;
  line-height: 1.43;
  text-align: center;
  color: var(--mono-5);
  padding: 0.4rem;
  cursor: pointer;
}

[dir="ltr"] .hero-image-container .overlay-button {
  margin-right: 0.8rem;
}

[dir="rtl"] .hero-image-container .overlay-button {
  margin-left: 0.8rem;
}

.hero-image-container :global .hero-image-focus-point-crosshair {
  display: block;
  position: absolute;
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 0.2rem;
  margin: -0.2rem 0 0 -0.2rem;
}

.hero-image-container :global .hero-image-focus-point-crosshair:before,
.hero-image-container :global .hero-image-focus-point-crosshair:after {
  content: "";
  display: block;
  position: absolute;
  background: var(--white);
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.4);
}

.hero-image-container :global .hero-image-focus-point-crosshair:before {
  height: 0.1rem;
  width: 2.1rem;
  top: 0.1rem;
}

[dir="ltr"] .hero-image-container :global .hero-image-focus-point-crosshair:before {
  left: -0.9rem;
}

[dir="rtl"] .hero-image-container :global .hero-image-focus-point-crosshair:before {
  right: -0.9rem;
}

.hero-image-container :global .hero-image-focus-point-crosshair:after {
  height: 2.1rem;
  width: 0.1rem;
  top: -0.9rem;
}

[dir="ltr"] .hero-image-container :global .hero-image-focus-point-crosshair:after {
  left: 0.1rem;
}

[dir="rtl"] .hero-image-container :global .hero-image-focus-point-crosshair:after {
  right: 0.1rem;
}

.overlay-button.has-error {
  border-color: var(--danger);
  color: var(--danger);
}

.image-element-details {
  user-select: none;
  -webkit-user-select: text;
}

.image-element-details .text-area-error-message {
  color: var(--danger);
  margin-top: 0.4rem;
  min-height: 1.6rem;
  font-size: var(--fs-m);
  font-weight: var(--fw-normal);
}

@media (min-width: desktop) {
  .hero-image-container {
    grid-template-columns: 500px 1fr;
    grid-row-gap: unset;
  }

  .hero-image-container .overlay-button {
    padding: 0.4rem 0.8rem;
  }

  .hero-image-wrapper {
    height: 28.8rem;
  }
}

.hero-image-action-bar {
  z-index: var(--z200);
}

.hero-image-action-bar--hide {
  display: none;
}

.set-focus-point-container {
  position: absolute;
  cursor: pointer;
}

.set-focus-point-container .set-focus-point-chip {
  padding: 0.8rem 1.2rem;
  color: var(--white);
  border-radius: 1.6rem;
  font-size: var(--fs-xs);
  background: none;
}

.set-focus-point-container .set-focus-point-chip:before {
  content: "";
  background-color: var(--mono-5);
  opacity: 0.72;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 1.6rem;
  transition: background-color 0.25s ease-out;
}

.set-focus-point-container .set-focus-point-chip:hover:before {
  background-color: var(--mono-4);
}

.set-focus-point-container .set-focus-point-chip--error:before,
.set-focus-point-container .set-focus-point-chip--error:hover:before {
  background-color: var(--danger);
}

.set-focus-point-container .set-focus-point-chip span {
  z-index: 1;
  text-transform: unset;
}
