/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

@value viewports: "../../viewports.css";
@value desktop from viewports;

.lane-header {
  background-color: var(--white);
  min-height: 4.8rem;
  padding: 1rem 1.6rem;
  border-radius: 0.4rem 0.4rem 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lane-title,
.lane-header-attributes {
  font-size: var(--fs-m);
  font-weight: var(--fw-bold);
  line-height: var(--lh-5);
  letter-spacing: 1px;
  color: var(--mono-5);
  text-transform: uppercase;
  margin: 0;
}

.lane-content {
  min-height: 30rem;
  background-color: var(--brand-1);
  padding: 1.6rem 0.8rem;
}

@media (min-width: desktop) {
  .lane-content {
    padding: 1.6rem;
  }
}
