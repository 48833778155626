/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.analytics-container {
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
  background-color: var(--white);
  padding: 1.6rem 0 1.6rem 0;
}

:global .analytics-progress-main-area {
  margin: auto;
  padding: 0px;
  height: calc(100vh - var(--header-height) - 6.4rem);
  display: flex;
  justify-content: center;
}

.analytics-container-header__title {
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
  line-height: var(--fs-xs);
  color: var(--mono-5);
  padding: 0 0 2.4rem 2.4rem;
  border-bottom: 1px solid var(--mono-2);
}