.story-templates-page-container {
  transition: transform 0.5s;
}

.default-configuration {
  display: flex;
  background-color: var(--white);
  border-bottom: 1px solid var(--mono-3);
}

[dir="rtl"] .default-configuration {
  padding: 1.6rem 4.8rem 1.6rem 0.8rem;
}

[dir="ltr"] .default-configuration {
  padding: 1.6rem 0.8rem 1.6rem 4.8rem;
}

.default-configuration-title {
  font-family: var(--brandType);
  font-size: var(--fs-m);
  font-weight: var(--fw-normal);
  line-height: var(--lh-4);
  color: var(--mono-5);
  margin: 0;
  text-transform: capitalize;
}

.default-configuration-edit-action {
  cursor: pointer;
  align-self: center;
}

[dir="rtl"] .default-configuration-edit-action {
  margin-left: 1.6rem;
}

[dir="ltr"] .default-configuration-edit-action {
  margin-right: 1.6rem;
}
