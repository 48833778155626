/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

:global .two-factor-auth-progress-main-area {
  margin: 30%;
  padding: 0px;
}

.two-factor-auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 36rem;
  padding: 3.2rem;
  background-color: var(--white);
  border-radius: 0.3rem;
  box-shadow: 0 3px 8px 0 rgba(19, 36, 55, 0.25);
}

.two-factor-auth-container__info {
  text-align: center;
  font-family: var(--brandType);
  font-size: var(--fs-s);
  color: var(--mono-4);
  line-height: var(--lh-4);
  margin-bottom: 0;
}

.two-factor-auth-container__qr-code {
  margin-bottom: 1.6rem;
}
