@value viewports: "../../../../viewports.css";
@value desktop from viewports;

.story-editor-info-bar {
  display: flex;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1), 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(19, 36, 55, 0.1);
  border-radius: 0.2rem;
  width: 100vw;
  background-color: var(--mono-4);
  color: var(--white);
  opacity: 0.9;
  margin: 8.8rem 0 -5.6rem 0rem;
  padding: 1.5rem;
  font-size: var(--fs-s);
  line-height: var(--lh-4);
  z-index: var(--z900);
  justify-content: center;
  align-items: flex-start;
}

@media (min-width: desktop) {
  .story-editor-info-bar {
    position: absolute;
    min-width: 75rem;
    width: unset;
    height: 4.8rem;
  }

  [dir="ltr"] .story-editor-info-bar {
    margin: -6.4rem 0 0.8rem 6rem;
  }

  [dir="rtl"] .story-editor-info-bar {
    margin: -6.4rem 6rem 0.8rem 0;
  }
}
