.media-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  grid-gap: 1.6rem;
}

.dropzone-message {
  position: absolute;
  top: 250px;
}
