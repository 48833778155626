/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.date-picker-container {
  width: 100%;
}
.date-picker-container .time-zone {
  font-size: var(--fs-s);
  line-height: var(--lh-4);
  color: var(--mono-4);
  margin-top: 0.8rem;
}

.date-picker-container .time-zone span {
  margin-right: 0.8rem;
}

.date-picker-container :global .react-datepicker-wrapper,
.date-picker-container :global .react-datepicker__input-container {
  display: block;
}

.date-picker-container :global .react-datepicker {
  font-family: var(--brandType);
  border: 1px solid var(--mono-2);
  color: var(--mono-5);
  font-size: var(--fs-xs);
  border-radius: 0;
}

.date-picker-container :global .react-datepicker__header {
  background-color: var(--brand-2);
  border-bottom: 1px solid var(--mono-2);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0.8rem;
}

.date-picker-container :global .react-datepicker__current-month {
  color: var(--mono-5);
  font-size: var(--fs-s);
  margin-bottom: 0.8rem;
  line-height: var(--lh-5);
}

.date-picker-container :global .react-datepicker__navigation--next {
  border-left-color: var(--mono-5);
}
.date-picker-container :global .react-datepicker__navigation--previous {
  border-right-color: var(--mono-5);
}

.date-picker-container :global .react-datepicker__time-container {
  width: 9rem;
  border-left: 1px solid var(--mono-2);
}

.date-picker-container :global .react-datepicker__day-name {
  color: var(--mono-5);
  font-size: var(--fs-xs);
  width: 3.2rem;
  height: 3.2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.date-picker-container :global .react-datepicker__day {
  width: 3.2rem;
  height: 3.2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.date-picker-container :global .react-datepicker__day:hover {
  background-color: var(--mono-2);
}

.date-picker-container :global .react-datepicker__day--disabled,
.date-picker-container
  :global
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  color: var(--mono-3);
}

.date-picker-container :global .react-datepicker__day--selected,
:global .react-datepicker__day--range-start,
:global .react-datepicker__day--in-range,
:global .react-datepicker__day--range-end,
:global .react-datepicker__day--in-selecting-range {
  background-color: var(--brand-3);
  color: var(--white);
}

.date-picker-container :global .react-datepicker__day--selected:hover,
:global .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background-color: var(--brand-4);
}

.date-picker-container .input-container {
  width: 100%;
  height: 3.3rem;
  border-radius: 3px;
  background-color: var(--white);
  box-shadow: none;
  border: 1px solid var(--mono-3);
  padding: 0.8rem;
  font-size: var(--fs-s);
  line-height: var(--lh-4);
  color: var(--mono-5);
}

.date-picker-container .input-container::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--mono-5);
}

.date-picker-container .input-container::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--mono-5);
}

[dir="ltr"] .date-picker-container .input-container {
  background: url("~assets/icons/calendar.svg") no-repeat right;
  background-size: 3rem 1.8rem;
}

[dir="rtl"] .date-picker-container .input-container {
  background: url("~assets/icons/calendar.svg") no-repeat left;
  background-size: 3rem 1.8rem;
}

.date-picker-container :global .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 9rem;
}

.date-picker-container
  :global
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: auto;
  font-weight: var(--fw-normal);
}

.date-picker-container :global .react-datepicker__time-list-item .react-datepicker__time-list-item--disabled {
  color: var(--mono-3);
}

.date-picker-container
  :global
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: var(--brand-3);
}

.date-picker-container
  :global
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: var(--brand-4);
}

.date-picker-container :global .react-datepicker__navigation {
  top: 1.5rem;
}

.date-picker-container
  :global
  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 10rem;
}

.date-picker-container :global .react-datepicker-time__header {
  font-size: var(--fs-xs);
  color: var(--mono-5);
}

.date-picker-container
  :global
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background-color: var(--mono-2);
}

.date-picker-container :global .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.date-picker-container :global .react-datepicker__year-read-view--down-arrow::before,
.date-picker-container :global .react-datepicker__month-read-view--down-arrow::before,
.date-picker-container :global .react-datepicker__month-year-read-view--down-arrow::before {
  border-top-color: var(--mono-2);
}
.date-picker-container :global .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.date-picker-container :global .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: var(--brand-2);
}

.date-picker-container .input-container.is-disabled {
  color: var(--mono-3);
  border-color: var(--mono-3);
}

.date-picker-container .input-container:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--mono-5);
}
.date-picker-container .input-container:-moz-placeholder {
  /* Firefox 18- */
  color: var(--mono-5);
}
