.role-card {
  background-color: var(--white);
  padding: 3.2rem;
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
  margin-bottom: 1.6rem;
}

.role-name {
  display: flex;
  justify-content: space-between;
  font-family: var(--brandType);
  font-size: var(--fs-m);
  font-weight: var(--fw-bold);
  line-height: var(--lh-4);
  color: var(--mono-5);
  text-transform: capitalize;
}

.category-name {
  font-family: var(--brandType);
  font-size: var(--fs-s);
  font-weight: var(--fw-bold);
  line-height: var(--lh-4);
  color: var(--mono-5);
  text-transform: capitalize;
  margin-bottom: 0.8rem;
}

.role-actions {
  margin-bottom: 1.6rem;
  display: flex;
  flex-wrap: wrap;
}

.role-actions :global .roles-chip {
  margin-bottom: 0.8rem;
  font-size: var(--fs-xs);
  font-weight: var(--fw-semi-bold);
  line-height: var(--lh-2);
  padding: 0.4rem 0.8rem;
}

[dir="rtl"] :global .roles-chip {
  margin-left: 0.8rem;
}
[dir="ltr"] :global .roles-chip {
  margin-right: 0.8rem;
}

.roles-page .role-author-container,
.roles-page .role-default-container {
  display: grid;
  grid-column-gap: 1.6rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 3.2rem;
}

/* .roles-page .select-container {
  margin-bottom: 3.2rem;
} */
.roles-page .role-full-width-label {
  grid-column: 1/-1;
}

.roles-page .actions-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.6rem;
  margin: 3.2rem 0;
}

.roles-action-dropdown {
  font-weight: var(--fw-normal);
}

[dir="ltr"] .roles-action-dropdown {
  left: auto !important;
  right: 9rem !important;
}

[dir="rtl"] .roles-action-dropdown {
  right: auto !important;
  left: 9rem !important;
}

/* Convert to gloabl after converting it to CSS Modules */
.roles-progress-main-area {
  margin: auto;
  padding: 0px;
  height: calc(100vh - var(--header-height) - 6.4rem);
  display: flex;
  justify-content: center;
}
