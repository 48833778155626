.send-report-button {
  margin-top: 2.4rem;
}

.button-wrapper {
  display: flex;
}

[dir="ltr"] .send-report-button {
  margin-left: auto;
}

[dir="rtl"] .send-report-button {
  margin-right: auto;
}

.send-report-button-mbl {
  margin-top: 2.4rem;
}

[dir="ltr"] .send-report-button-mbl {
  margin-left: auto;
}

[dir="rtl"] .send-report-button-mbl {
  margin-right: auto;
}
