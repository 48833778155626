.analytics-graph {
  width: 100%;
  height: 30.5rem;
  margin-bottom: 1.6rem;
}

.analytics-graph .custom-tooltip {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--white);
  padding: 1.6rem;
  font-size: var(--fs-xs);
  display: grid;
  grid-row-gap: 1.6rem;
}

.analytics-graph .custom-tooltip__label {
  grid-column: 1/-1;
  font-size: var(--fs-xs);
  font-weight: var(--fw-bold);
  line-height: var(--lh-5);
  color: var(--mono-5);
  margin-bottom: 0.8rem;
}

.analytics-graph .custom-tooltip__total-value {
  display: inline-flex;
  font-size: var(--fs-xxl);
  color: var(--mono-5);
}

.analytics-graph .custom-tooltip__total-value.current-range::before {
  display: inline-block;
  align-self: center;
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 3px solid var(--brand-4);
  margin-right: 0.8rem;
}

.analytics-graph .custom-tooltip__total-value.prev-range::before {
  display: inline-block;
  align-self: center;
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 3px solid var(--accent-3);
  margin-right: 0.8rem;
}

.analytics-graph .custom-tooltip__range-details {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  align-items: flex-end;
  justify-items: left;
}

.analytics-graph .custom-tooltip__metric {
  font-size: var(--fs-xs);
  color: var(--mono-5);
  padding: 0 0.8rem 0 0.4rem;
  justify-self: left;
}
