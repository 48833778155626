.push-notification-channels-container {
  background-color: var(--white);
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
  padding: 3.2rem 4.8rem;
}

.push-notification-channels-container .push-notification-channels-row {
  display: grid;
  align-items: center;
  padding: 0.8rem 2.4rem;
  grid-template-columns: 28rem 1fr max-content;
}

.push-notification-channels-container .push-notification-channels-row:not(.push-notification-channels-header) {
  border-bottom: 1px solid var(--mono-2);
}

.push-notification-channels-container .push-notification-channels-header {
  background-color: var(--mono-2);
  font-family: var(--brandType);
  font-size: var(--fs-s);
  font-weight: var(--fw-bold);
  line-height: var(--lh-4);
  color: var(--mono-5);
}

.push-notification-channels-row .push-notification-channels-trash-btn {
  color: var(--mono-4);
  visibility: hidden;
}

.push-notification-channels-row:hover .push-notification-channels-trash-btn {
  visibility: visible;
}

.loader-wrapper {
  margin: 0;
}
