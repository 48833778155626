.dropdown-option-label {
  text-transform: uppercase;
  font-size: var(--fs-s);
  font-weight: var(--fw-semi-bold);
  color: var(--mono-4);
}

[dir="ltr"] .dropdown-option-label {
  margin-right: 0.8rem;
}

[dir="rtl"] .dropdown-option-label {
  margin-left: 0.8rem;
}

.dropdown-option-label.is-selected {
  font-weight: var(--fw-bold);
  color: var(--mono-5);
  letter-spacing: 1px;
}

.select-label {
  text-transform: uppercase;
  font-size: var(--fs-s);
  font-weight: var(--fw-bold);
  color: var(--mono-5);
  letter-spacing: 1px;
  line-height: 2rem;
}

[dir="ltr"] .select-label {
  margin: 0 0.8rem 0 0;
}

[dir="rtl"] .select-label {
  margin: 0 0 0 0.8rem;
}

:global .select-tabs div.react-select__control {
  background: none;
  border: none;
  padding-bottom: 0.8rem;
  border-bottom: 4px solid var(--brand-3);
  box-shadow: none;
}

:global .select-tabs .react-select__value-container {
  padding: 0.4rem;
}

:global .select-tabs .react-select__menu {
  margin-top: 0;
  border-radius: 0;
}

:global .select-tabs .react-select__menu .react-select__menu-list {
  max-height: none;
  margin: 0.8rem 0;
}

:global .select-tabs .react-select__menu .react-select__menu-list .react-select__option {
  padding: 1.6rem;
}

:global .select-tabs .react-select__menu .react-select__menu-list .react-select__option--is-selected {
  background-color: unset;
}
