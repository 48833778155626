.attribute-menu-trigger {
  cursor: pointer;
}

.page-container {
  transition: transform 0.5s;
  margin-bottom: 2rem;
}

:global .attributes-progress-main-area {
  margin: auto;
  padding: 0px;
  height: calc(100vh - var(--header-height) - 6.4rem);
  display: flex;
  justify-content: center;
}
