.claim-review-header {
  display: flex;
  justify-content: space-between;
  margin: 1.6rem 0;
}

.claim-review-date {
  display: flex;
  flex-direction: column;
}

.claim-review-date :global .DayPickerInput {
  margin: 0 0 2.8rem 0;
}

.claim-review-date :global .DayPickerInput input {
  border-radius: 0.3rem;
  border: 0.1rem var(--mono-3) solid;
  padding: 0.6rem;
}

.claim-review-fields {
  max-height: 0;
  min-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  margin-top: 0.8rem;
}

.claim-review-fields.is-open {
  min-height: 39.2rem;
  max-height: 50rem;
  opacity: 1;
  visibility: visible;
  transition: all 0.5s;
}
