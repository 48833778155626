.last-updated-timer {
    color: var(--mono-4);
    font-size: var(--fs-xs);
    text-decoration: underline;
}

.last-updated-timer:hover {
    cursor: pointer;
}

.last-updated-timer-tooltip {
    margin-top: 0.4rem !important;
    direction: ltr;
}
