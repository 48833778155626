.custom-urls-list {
  background-color: var(--white);
  border-radius: 0.3rem;
  box-shadow: var(--box-shadow-s);
  padding: 3.2rem;
}

.custom-urls-table :global .rt-table {
  margin-top: 4rem;
}

.custom-urls-table :global .rt-tbody {
  cursor: pointer;
}

.custom-urls-table :global .-header {
  background-color: var(--mono-2);
  font-weight: var(--fw-bold);
  font-size: var(--fs-s);
  height: 4rem;
  display: flex;
  align-items: center;
  padding: 0 1.6rem;
}

.custom-urls-table :global .rt-tbody .rt-tr {
  font-size: var(--fs-s);
  padding: 1.6rem;
  display: flex;
  align-items: center;
  min-height: 4.8rem;
  border-bottom: 1px solid var(--mono-2);
}

[dir="ltr"] .custom-urls-table :global .rt-thead .rt-tr {
  text-align: left;
  display: flex;
}

[dir="rtl"] .custom-urls-table :global .rt-thead .rt-tr {
  text-align: right;
  display: flex;
}

.custom-urls-table :global .rt-thead .rt-tr:last-child {
  flex-grow: 1;
}

.custom-urls-list .pagination-top {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
}

[dir="ltr"] .custom-urls-list-search-bar {
  flex-grow: 1;
  margin-right: 1rem;
}

[dir="rtl"] .custom-urls-list-search-bar {
  flex-grow: 1;
  margin-left: 1rem;
}

.custom-urls-edit-action {
  cursor: pointer;
  text-align: right;
  color: var(--brand-3);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}

[dir="ltr"] .custom-urls-edit-action-wrapper {
  text-align: right;
}

[dir="rtl"] .custom-urls-edit-action-wrapper {
  text-align: left;
}

[dir="ltr"] .custom-urls-edit-action {
  margin-right: 3.2rem;
}

[dir="rtl"] .custom-urls-edit-action {
  margin-left: 3.2rem;
}

.custom-urls-table :global .rt-tr-group:hover {
  background-color: var(--mono-2);
}

.custom-urls-table :global .rt-tr-group:hover .custom-urls-edit-action {
  opacity: 1;
  visibility: visible;
}

.custom-urls-is-loading {
  opacity: 0.5;
  cursor: not-allowed;
}

.custom-url-progress-main-area {
  margin: 20% 30% 30% 30%;
  padding: 0;
}

.custom-urls-table :global .rt-noData {
  text-align: center;
  margin-top: 2.4rem;
  font-family: var(--brandType);
  font-size: var(--fs-m);
  color: var(--mono-4);
}

[dir="ltr"] .custom-urls-searchbar {
  flex-grow: 1;
  margin-right: 1rem;
}

[dir="rtl"] .custom-urls-searchbar {
  flex-grow: 1;
  margin-left: 1rem;
}
