.plagiarism-report-results {
  background-color: var(--white);
  width: 100%;
  height: 100vh;
  border: 0;
}

.plagiarism-report-results-container {
  position: relative;
}

.plagiarism-report-message-container {
  margin: 0.8rem;
}

.plagiarism-report-message {
  margin: 0 0 0.8rem 0;
}

.plagiarism-report-hint {
  color: var(--mono-4);
  font-size: var(--fs-s);
}
