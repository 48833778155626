@value viewports: "../../../../viewports.css";
@value desktop from viewports;


.analytics-container__date-wrapper {
  justify-content: space-between;
  margin: 2.4rem 2.4rem 2.4rem 4.8rem;
}

.analytics-container__date-wrapper>:nth-child(1) {
  padding-top: 4px;
}

:global .container.analytics-select-container:first-child {
  width: 28rem;
  margin-bottom: 0;
}

.date-range-picker-container label:first-child::after {
  border-color: transparent;
}

:global .container.analytics-select-container:first-child label::after {
  display: inline-block;
  content: "";
  width: 0;
  height: 0;
  border: 5px solid var(--brand-4);
  border-radius: 50%;
  margin-left: 0.8rem;
}

.date-comparison-container label {
  margin-bottom: 0.8rem;
}

.date-comparison-container label:after,
.date-range-picker-container label:after {
  display: inline-block;
  content: "";
  width: 0;
  height: 0;
  border: 5px solid var(--accent-3);
  border-radius: 50%;
  margin-left: 0.8rem;
}

.date-comparison-container span {
  font-size: var(--fs-s);
  border-radius: 0.3rem;
  background-color: var(--white);
  border: solid 1px var(--mono-3);
  min-height: 3.3rem;
  padding: 0.4rem 0.8rem;
  display: flex;
  align-items: center;
}

.date-range-picker-container {
  display: grid;
  width: 280px;
  grid-column-gap: 1.6rem;
}

.date-range-picker-container>*>*>:nth-child(2) {
  width: 280px;
  height: 33px;
}

.date-range-picker-container>*>*>*>:nth-child(2) {
  padding-top: 4px;
}

:global .date-wrapper-date-picker:first-child {
  grid-column: 1/2;
  grid-row: 2/3;
}

:global .date-wrapper-date-picker:nth-child(2) {
  grid-row: 2/3;
}

.comparison-date-container {
  display: flex;
}

[dir="ltr"] :global .date-wrapper-date-picker:first-child {
  margin-right: 0.8rem;
}

[dir="rtl"] :global .date-wrapper-date-picker:first-child {
  margin-left: 0.8rem;
}

@media (min-width: desktop) {
  .analytics-container__date-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 2.4rem 2.4rem 2.4rem 4.8rem;
  }

  .date-range-picker-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 0 0 5.6rem;
    grid-column-gap: 1.6rem;
    width: auto;
  }
}
