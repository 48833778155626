@value viewports: "../../../../../viewports.css";
@value desktop from viewports;

.format-toolbar-submenu-wrapper {
  position: absolute;
  top: 4.4rem;
  z-index: var(--z1000);
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  flex-direction: column;
  background-color: var(--white);
  box-shadow: 0 3px 6px 0 rgba(19, 36, 55, 0.2);
}

[dir="ltr"] .format-toolbar-submenu-wrapper {
  left: 0;
}

[dir="rtl"] .format-toolbar-submenu-wrapper {
  right: 0;
}

@media (min-width: desktop) {
  .format-toolbar-submenu-wrapper {
    width: 5.4rem;
  }
}

.format-toolbar-submenu-item {
  color: var(--black);
  padding: 0.8rem;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.format-toolbar-submenu-item:hover {
  background-color: var(--mono-2);
}
