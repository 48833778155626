.icon-on-left {
  display: flex;
  align-items: center;
}

.icon-on-right{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
