.seo-stat-group {
  padding: 1rem 1.4rem;
}

.stat-group-title {
  text-transform: capitalize;
  margin: 0;
}

.stat-group-items {
  padding-bottom: 0.8rem;
  border-bottom: 0.1rem solid var(--mono-2);
}

.seo-items-list {
  padding: 0;
  margin: 0;
}

.seo-item {
  display: flex;
  font-size: var(--fs-xs);
  margin: 1.2rem;
}

.seo-status-marker {
  line-height: var(--lh-5);
}

.seo-item-text {
  line-height: var(--lh-5);
}

[dir="ltr"] .seo-item-text {
  margin-left: 0.8rem;
}

[dir="rtl"] .seo-item-text {
  margin-right: 0.8rem;
}
