.story-inspector-pn-title,
.story-inspector-pn-message {
  position: relative;
}

.story-inspector-pn-title--show-ai-generate-btn .story-inspector-pn-title__ai-generate-btn,
.story-inspector-pn-message--show-ai-generate-btn .story-inspector-pn-message__ai-generate-btn,
.story-inspector-pn-title--show-link .story-inspector-pn-title-link-btn,
.story-inspector-pn-message--show-link .story-inspector-pn-message-link-btn {
  position: absolute;
  right: 0;
  top: -0.2rem;
  padding: var(--space-xxxs);
}

.story-inspector-pn-title--show-ai-generate-btn-and-link .story-inspector-pn-title-link-btn,
.story-inspector-pn-message--show-ai-generate-btn-and-link .story-inspector-pn-message-link-btn {
    position: absolute;
    right: 3rem;
    top: -0.3rem;
}

.story-inspector-pn-title--show-ai-generate-btn > div > div > div > div > div > span,
.story-inspector-pn-message--show-ai-generate-btn > div > div > div > div > div > span {
    margin-right: 4rem;
}

.story-inspector-pn-title--show-link > div > div > div > div > div > span,
.story-inspector-pn-message--show-link > div > div > div > div > div > span {
    margin-right: 4rem;
}

.story-inspector-pn-title--show-ai-generate-btn-and-link > div > div > div > div > div > span,
.story-inspector-pn-message--show-ai-generate-btn-and-link > div > div > div > div > div > span {
    margin-right: 6rem;
}

.push-notification-scheduler-checkbox {
    margin-bottom: 1.6rem;
}

[dir="rtl"] .story-inspector-pn-title--show-link > div > div > div > div > div > span,
[dir="rtl"] .story-inspector-pn-message--show-link > div > div > div > div > div > span {
    margin-left: 4rem;
}

[dir="rtl"] .story-inspector-pn-title--show-ai-generate-btn > div > div > div > div > div > span,
[dir="rtl"] .story-inspector-pn-message--show-ai-generate-btn > div > div > div > div > div > span {
    margin-left: 4rem;
}

[dir="rtl"] .story-inspector-pn-title--show-ai-generate-btn-and-link .story-inspector-pn-title-link-btn,
[dir="rtl"] .story-inspector-pn-message--show-ai-generate-btn-and-link .story-inspector-pn-message-link-btn {
    left: 3rem;
    right: unset;
}

[dir="rtl"] .story-inspector-pn-title--show-ai-generate-btn .story-inspector-pn-title__ai-generate-btn,
[dir="rtl"] .story-inspector-pn-message--show-ai-generate-btn .story-inspector-pn-message__ai-generate-btn,
[dir="rtl"] .story-inspector-pn-title--show-link .story-inspector-pn-title-link-btn,
[dir="rtl"] .story-inspector-pn-message--show-link .story-inspector-pn-message-link-btn{
    left: 0;
    right: unset;
}


[dir="rtl"] .story-inspector-pn-title--show-ai-generate-btn-and-link > div > div > div > div > div > span,
[dir="rtl"] .story-inspector-pn-message--show-ai-generate-btn-and-link > div > div > div > div > div > span {
    margin-left: 6rem;
}

[dir="rtl"] .push-notification-scheduler-container {
  padding: 0.8rem;
  margin-left: 0.8rem;
}

[dir="ltr"] .push-notification-scheduler-container {
  padding-top: 0.8rem;
  margin-right: 0.8rem;
}