/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

@value viewports: "../../viewports.css";
@value desktop from viewports;

:global .workspace-storycard {
  margin: 0 0 0.8rem 0;
  box-shadow: var(--box-shadow-m);
}

.workspace-actions {
  display: grid;
  grid-template-columns: auto max-content max-content;
  grid-column-gap: 1.6rem;
  margin-bottom: 1.6rem;
}

@media (min-width: desktop) {
  :global .workspace-storycard {
    margin: 0.8rem;
    box-shadow: var(--box-shadow-s);
  }

  .workspace-lanes {
    margin-top: 2.4rem;
  }
}

.workspace-search {
  flex-grow: 1;
  margin-left: 0.8rem;
}

.save-filters {
  display: flex;
  align-items: center;
}

.save-filters .text-field {
  flex-grow: 1;
  margin-right: 1.6rem;
}

.workspace-tab-panel {
  width: 100%;
  height: 100%;
  padding: 0.8rem 0 1.6rem;
  border-radius: 0 0 0.5rem 0.5rem;
}

:global .workspace-scroll-tablist {
  margin: 0 0.8rem;
}

.workspace.is-loading,
.workspace-sidebar.is-loading {
  opacity: 0.2;
}

.story-card-menu {
  left: auto !important;
  right: 9rem !important;
}
