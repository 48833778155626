/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.text-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8rem;
  --verticalRhythm: 0.8rem;
}

.text-field-input {
  border-radius: 0.4rem;
  border: 1px solid var(--mono-3);
  outline: none;
  padding: 0.6rem 0.8rem;
  margin-bottom: 0.8rem;
  color: var(--mono-5);
  font-size: var(--fs-s);
}

.text-field-hint {
  font-size: var(--fs-xs);
  color: var(--mono-4);
}

.text-field-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--mono-3);
  font-size: var(--fs-s);
}

.text-field-input::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--mono-3);
  font-size: var(--fs-s);
}

.text-field-input:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--mono-3);
  font-size: var(--fs-s);
}

.text-field-input:-moz-placeholder {
  /* Firefox 18- */
  color: var(--mono-3);
  font-size: var(--fs-s);
}

.has-error .text-field-input,
.has-error .text-field-input:hover {
  border-color: var(--danger);
}

.text-field.is-disabled > .text-field-input {
  background-color: var(--mono-2);
  pointer-events: none;
  cursor: not-allowed;
}

.text-field-with-count {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.text-field-count {
  font-size: var(--fs-xs);
  color: var(--mono-4);
  line-height: var(--lh-5);
}

.text-field-count.is-exceeding {
  color: var(--danger);
}

/* .text-field-with-button-wrapper {
  margin-bottom: 2.4rem;
} */

.text-field-with-button {
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
}

.text-field-with-button .text-field {
  flex-grow: 1;
  margin-bottom: 0;
}

[dir="ltr"] .text-field-with-button .text-field {
  margin-right: 0.8rem;
}

[dir="rtl"] .text-field-with-button .text-field {
  margin-left: 0.8rem;
}

.text-area-input {
  resize: none;
  border-radius: 0.3rem;
  border: 1px solid var(--mono-3);
  padding: 0.6rem 0.8rem;
  margin-bottom: 0.8rem;
  color: var(--mono-5);
  font-size: var(--fs-s);
}

.has-error .text-area-input,
.has-error .text-area-input:hover {
  border-color: var(--danger);
}
