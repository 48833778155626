/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.context-menu-list {
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: var(--white);
  border-radius: 0.4rem;
}
