.story-texts {
  font-size: var(--fs-s);
  line-height: var(--lh-4);
  color: var(--mono-5);
}

.gallery-element-image {
  background-color: var(--mono-2);
  display: flex;
  justify-content: center;
  align-items: center;
}
