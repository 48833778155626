.task-card {
  --task-cardShadow: 0 3px 6px 0 rgba(19, 36, 55, 0.1);
  --verticalRhythm: 0.8rem;

  font-family: var(--brandType);
  border-radius: 0.3rem;
  margin-bottom: 0.8rem;
  box-shadow: var(--task-cardShadow);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.task-card .task-card-content {
  flex-grow: 1;
  padding: 1.2rem 1.6rem 1.2rem 1.6rem;
  background-color: var(--white);
}

.task-card .task-card-actions {
  padding: 1.2rem 1.6rem 1.2rem 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--white);
}

.task-card .task-card-actions .task-action-button {
  visibility: hidden;
}

.task-card:hover .task-card-actions .task-action-button {
  visibility: visible;
}

.task-card:hover .task-card-content,
.task-card:hover .task-card-actions {
  background-color: var(--brand-2);
}

.task-card .highlight {
  font-weight: var(--fw-bold);
  background-color: var(--white);
}

.task-headline {
  margin: 0 0 var(--verticalRhythm) 0;
  color: var(--mono-5);
  font-size: var(--fs-l);
  font-weight: var(--fw-normal);
  line-height: var(--lh-4);
}

.task-byline {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: var(--fs-xs);
  line-height: var(--lh-1);
  color: var(--mono-4);
}

.task-metadata {
  display: flex;
  align-items: center;
}

[dir="ltr"] .task-metadata {
  margin-right: 1.6rem;
}

[dir="rtl"] .task-metadata {
  margin-left: 1.6rem;
}

[dir="ltr"] .task-author,
[dir="ltr"] .task-time,
[dir="ltr"] .task-count-details {
  margin-left: 0.4rem;
}

[dir="rtl"] .task-author,
[dir="rtl"] .task-time,
[dir="ltr"] .task-count-details {
  margin-right: 0.4rem;
}

.task-time-wrapper-overdue {
  display: flex;
  align-items: center;
  color: var(--danger);
}

.task-time-wrapper {
  display: flex;
  align-items: center;
}

.task-chips {
  margin-bottom: var(--verticalRhythm);
}

.task-chips .chip {
  margin-right: 0.8rem;
}

.task-link {
  width: 100%;
  text-align: start;
  padding: 0;
  background: none;
  border: none;
}

.task-tab-panel {
  width: 100%;
  height: 100%;
  padding: 1.6rem;
  border-radius: 0.5rem;
  background-color: var(--brand-1);
}

.mytasks {
  width: 100%;
}

.group-container {
  margin-bottom: 1.6rem;
}

.group-container .group-header {
  margin: 0 0 1.6rem 0;
}

.task-menu {
  left: auto !important;
  right: 9rem !important;
}

:global .mytasks-progress-main-area {
  margin: auto;
  padding: 0px;
  height: calc(100vh - var(--header-height) - 6.4rem);
  display: flex;
  justify-content: center;
}
