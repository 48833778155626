.story-element-text-container {
  font-family: var(--brandType);
  font-size: var(--fs-xl);
  line-height: var(--lh-5);
  color: var(--mono-4);
  white-space: pre-wrap;
}

.story-element-blurb-container blockquote,
.story-element-blockquote-container blockquote,
.story-element-quote-container blockquote {
  font-size: var(--fs-xl);
  line-height: var(--lh-5);
  margin: 0;
}

.story-element-blockquote-container :global .attribution,
.story-element-quote-container :global .attribution {
  display: flex;
  flex-direction: column-reverse;
  padding: 1rem 0;
}

.story-element-title-container :global .title-text {
  font-size: var(--fs-xxl);
  font-weight: var(--fw-bold);
  line-height: var(--lh-5);
}
