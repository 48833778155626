/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.-center {
  width: 9rem;
  margin: 0 1rem;
}

.-totalCount {
  display: block;
  font-size: var(--fs-s);
}

[dir="ltr"] .-totalCount {
  margin-right: 0.8rem;
}

[dir="rtl"] .-totalCount {
  margin-left: 0.8rem;
}

.-pageInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--fs-s);
}

.-pageJumpInput {
  max-width: 4rem;
  height: 100%;
  text-align: center;
}

.-prevButton,
.-nextButton {
  border: 1px solid var(--mono-3);
  padding: 0.4rem;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
