@value viewports: "../../../../viewports.css";
@value desktop from viewports;

.image-metadata {
  display: grid;
  grid-template-columns: 2fr 5fr;
  grid-template-rows: auto;
  grid-gap: 1.6rem;
  font-family: var(--brandType);
  font-size: var(--fs-s);
  color: var(--mono-5);
  padding-top: 2.4rem;
  border-top: 1px dotted var(--mono-3);
  margin-bottom: 1.20rem;
}

@media (min-width: desktop) {
  .image-metadata {
    margin-top: 4rem;
    margin-bottom: 2.4rem;
  }
}

[dir='ltr'] .image-metadata-field {
  text-align: left;
}

[dir='rtl'] .image-metadata-field {
  text-align: right;
}
