.attribute-header {
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
}

.attribute-title {
  font-size: var(--fs-xxl);
  font-weight: var(--fw-bold);
  flex-grow: 1;
  margin: 0;
}
