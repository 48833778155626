@value viewports: "../../../../viewports.css";
@value desktop from viewports;

.image-element-add-new-image {
  background-color: var(--mono-2);
  height: 24rem;
}

.hero-image-container,
.image-element-container {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1.2rem;
  position: relative;
}

.hero-image-wrapper {
  position: relative;
}

.hero-image-wrapper .image-action-buttons {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 1.6rem 0.8rem;
}
.image-element-container .image-preview {
  background-color: var(--mono-2);
  width: 100%;
}

.hero-image-container .image-preview {
  object-fit: contain;
  background-color: var(--mono-2);
}

.hero-image-container .image-preview-overlay,
.image-element-container .image-preview-overlay {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 1.6rem 0.8rem;
}

.hero-image-container .overlay-button,
.image-element-container .overlay-button {
  border-radius: 3px;
  background-color: var(--white);
  border: solid 1px var(--mono-5);
  font-size: var(--fs-xxs);
  font-weight: normal;
  line-height: 1.43;
  text-align: center;
  color: var(--mono-5);
  padding: 4px 8px;
  cursor: pointer;
}

[dir="ltr"] .hero-image-container .overlay-button,
[dir="ltr"] .image-element-container .overlay-button {
  margin-right: 0.8rem;
}

[dir="rtl"] .hero-image-container .overlay-button,
[dir="rtl"] .image-element-container .overlay-button {
  margin-left: 0.8rem;
}

.hero-image-container .image-focus-point-crosshair,
.image-element-container .image-focus-point-crosshair {
  display: block;
  position: absolute;
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 0.2rem;
  margin: -0.2rem 0 0 -0.2rem;
}

.hero-image-container .image-focus-point-crosshair:before,
.hero-image-container .image-focus-point-crosshair:after,
.image-element-container .image-focus-point-crosshair:before,
.image-element-container .image-focus-point-crosshair:after {
  content: "";
  display: block;
  position: absolute;
  background: var(--white);
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.4);
}

.hero-image-container .image-focus-point-crosshair:before,
.image-element-container .image-focus-point-crosshair:before {
  height: 0.1rem;
  width: 2.1rem;
  top: 0.1rem;
}

[dir="ltr"] .hero-image-container .image-focus-point-crosshair:before,
[dir="ltr"] .image-element-container .image-focus-point-crosshair:before {
  left: -0.9rem;
}

[dir="rtl"] .hero-image-container .image-focus-point-crosshair:before,
[dir="rtl"] .image-element-container .image-focus-point-crosshair:before {
  right: -0.9rem;
}

.hero-image-container .image-focus-point-crosshair:after,
.image-element-container .image-focus-point-crosshair:after {
  height: 2.1rem;
  width: 0.1rem;
  top: -0.9rem;
}

[dir="ltr"] .hero-image-container .image-focus-point-crosshair:after,
[dir="ltr"] .image-element-container .image-focus-point-crosshair:after {
  left: 0.1rem;
}

[dir="rtl"] .hero-image-container .image-focus-point-crosshair:after,
[dir="rtl"] .image-element-container .image-focus-point-crosshair:after {
  right: 0.1rem;
}

.overlay-button.has-error {
  border-color: var(--danger);
  color: var(--danger);
}

.image-element-details,
.image-element-container {
  user-select: none;
  -webkit-user-select: text;
}

.image-element-details .text-area-error-message {
  color: var(--danger);
  margin-top: 0.4rem;
  min-height: 1.6rem;
  font-size: var(--fs-m);
  font-weight: var(--fw-normal);
}

.image-element-disabled {
  pointer-events: none;
}

@media (min-width: desktop) {
  .hero-image-container,
  .image-element-container {
    grid-template-columns: 500px 1fr;
    grid-gap: 3.2rem;
    align-items: start;
  }
}

.image-preview-action-bar {
  pointer-events: all;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0.8rem;
}

.image-preview-action-bar--hide {
  display: none;
}