.saved-list-filter-title {
  font-family: var(--brandType);
  font-size: var(--fs-s);
  font-weight: var(--fw-bold);
  line-height: var(--lh-4);
  color: var(--mono-5);
  text-transform: uppercase;
  margin: 2.8rem 0 1.2rem 0;
}

.saved-list-filter {
  margin: 0 0 3.2rem 0;
  padding: 0;
  list-style: none;
}

.saved-list-filter .filter-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: var(--brandType);
  font-size: var(--fs-m);
  line-height: var(--lh-5);
  color: var(--black);
  padding-bottom: 0.8rem;
}
.saved-list-filter .filter-list-item-content {
  display: flex;
  align-items: center;
  cursor: pointer;
}

[dir="ltr"] .saved-filter-dropdown {
  right: 6rem !important;
  left: auto !important;
}

[dir="rtl"] .saved-filter-dropdown {
  left: 6rem !important;
  right: auto !important;
}

.saved-list-filter .filter-name {
  margin-right: 0.8rem;
}

.saved-list-filter .default-filter {
  font-family: var(--brandType);
  font-size: var(--fs-s);
  line-height: var(--lh-4);
  color: var(--mono-4);
  margin-right: 0.8rem;
}

.saved-list-filter-container {
  padding: 0 1.6rem;
}

.saved-list-filter-container .no-content-message {
  color: var(--mono-4);
  font-size: var(--fs-s);
  font-style: italic;
}

.saved-list-filter .filter-list-item.is-selected {
  color: var(--brand-3);
  font-weight: var(--fw-semi-bold);
}

/* Change to global when converted to module */
.save-filter-textfield {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8rem;
  --verticalRhythm: 0.8rem;
}
