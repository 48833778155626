@value viewports: "../../../../../viewports.css";

@value desktop from viewports;

.spinner {
    margin-top: 8rem;
}

.infinite-scroll-spinner {
    margin-top: 4rem;
}

.collection-list {
    display: grid;
    grid-gap: 0.8rem;
}

@media (min-width: desktop) {
    .collection-list {
        grid-template-columns: repeat(3, 2fr);
        grid-gap: 1.6rem;
    }
}
