@value viewports: "../../../../viewports.css";
@value desktop from viewports;

.form-builder-container {
  padding: 2.4rem 1.2rem;
}

.form-builder-container.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

@media (min-width: desktop) {
  .form-builder-container {
    padding: 4rem 0;
  }
}
