.tag-header {
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
}
[dir="ltr"] .tag-action button {
  --button-background: transparent;
  --button-color: var(--brand-3);
  --border-color: var(--brand-3);
  flex-grow: 2;
}
[dir="rtl"] .tag-action button {
  --button-background: transparent;
  --button-color: var(--brand-3);
  --border-color: var(--brand-3);
}
.tag-search-wrapper {
  flex-grow: 2;
  margin-right: 20px;
}

/* Convert to global after converting it to CSS Module */
.tags-progress-main-area {
  margin: 20% 30% 30% 30%;
  padding: 0;
}

[dir="ltr"] .tags-container-searchbar {
  flex-grow: 1;
  margin-right: 1rem;
}

[dir="rtl"] .tags-container-searchbar {
  flex-grow: 1;
  margin-left: 1rem;
}
