[dir="ltr"] .social-account-provider {
  margin: 0 0.8rem 0 0;
}

[dir="rtl"] .social-account-provider {
  margin: 0 0 0 0.8rem;
}

[dir="ltr"] .social-account-publish-result {
  margin: 0 0.8rem 1.6rem auto;
}

[dir="rtl"] .social-account-publish-result {
  margin: 0 auto 1.6rem 0.8rem;
}

.social-account {
  display: flex;
  align-items: center;
}
