/* 
 *  *************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

.two-factor-auth-container {
  width: 32rem;
  margin: 10rem auto 0;
  padding: 4.8rem;
  background-color: var(--white);
  border-radius: 0.3rem;
  box-shadow: 0 3px 8px 0 rgba(19, 36, 55, 0.25);
}

.publisher-logo {
  margin: 0 0 3.2rem 0;
  height: 3.8rem;
  width: 17rem;
  background: url("~assets/icons/logo.png") no-repeat center center;
}
